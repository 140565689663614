<div fxLayout= "column" fxLayoutAlign="center center">
    <mat-dialog-content >
        <div fxLayout= "row" >
            <div fxLayoutAlign="center center"  fxFlex="20%">
                <span *ngIf="localData.task.length > 0"><strong>ALL TASK FOR</strong></span>
                <span *ngIf="localData.task.length <= 0">ADD A TASK</span>
            </div>
            <div fxLayoutAlign="center center"  fxFlex="60%">
                <span *ngIf="localData.task.length > 0">{{localData.contactName}}{{' - '}}{{localData.opportunityName}}</span>
            </div>
        <div fxLayoutAlign="center end" fxFlex="20%"><button mat-fab color="accent" (click)="addTask()"><mat-icon>add</mat-icon></button></div>
        </div>
        <hr>
            <mat-table #mytable  matSort  matSortDirection="asc" [dataSource]="dataSource" class="task-dialogue">
                <ng-container matColumnDef="index" class="project">
                    <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index;">
                        {{i+1}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="taskDiscription" >
                    <mat-header-cell *matHeaderCellDef>Discription</mat-header-cell>
                    <mat-cell *matCellDef="let element" class="description">
                        <mat-form-field class="lead-dilogue"  appearance="outline" >
                            <!-- <mat-label>Task Details</mat-label> -->
                            <textarea [disabled]= "!element.taskStatus" matInput
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="2"
                                matInput [(ngModel)]= "element.taskDiscription"
                                placeholder = "Enter task details">
                            </textarea>
                        </mat-form-field >
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="taskDate">
                    <mat-header-cell *matHeaderCellDef>Due Date</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-form-field fxLayoutAlign="center center" color="accent" >
                            <input [disabled]= "!element.taskStatus" matInput [matDatepicker]="picker" [(ngModel)]="element.taskDate"
                            required
                            [errorStateMatcher]="matcher"
                            >
                            <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="dueDateFormControl.hasError('required')">
                                A Due Date is <strong>Required</strong>
                            </mat-error>
                        </mat-form-field>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="taskAssigned" class="project">
                    <mat-header-cell *matHeaderCellDef>Assignee</mat-header-cell>
                    <mat-cell *matCellDef="let element ; let i = index;">
                        <mat-form-field  fxLayoutAlign="center center">
                            <!-- <mat-select [disabled]= "!element.taskStatus" [(value)]="element.taskAssigned" required>
                                    <mat-option>None</mat-option>
                                    <mat-option value="Aaron Seutter">Aaron Seutter</mat-option>
                                    <mat-option value="Ashley Tillapaugh">Ashley Tillapaugh</mat-option>
                                    <mat-option value="Charlon Fabela">Charlon Fabela</mat-option>
                                    <mat-option value="Dave Hetke">Dave Hetke</mat-option>
                                    <mat-option value="Darryl Benn">Darryl Benn</mat-option>
                                    <mat-option value="Gary Julius">Gary Julius</mat-option>
                                    <mat-option value="Jayden Mark">Jayden Mark</mat-option>
                                    <mat-option value="Kyle Tadei">Kyle Tadei</mat-option>
                                    <mat-option value="Matthew Pennycook">Matt Pennycook</mat-option>
                                    <mat-option value="Michael Thompson">Michael Thompson</mat-option>
                                    <mat-option value="Nicole Dauvin">Nicole Dauvin</mat-option>
                                    <mat-option value="Ron Procyk">Ron Procyk</mat-option>
                                    <mat-option value="Ryan Benn">Ryan Benn</mat-option>
                                    <mat-option value="Tom Mark">Tom Mark</mat-option>
                            </mat-select> -->
                            <mat-select [disabled]= "!element.taskStatus" [(value)]="element.taskAssigned" required>
                              <!-- <mat-option value="All CIS">All</mat-option> -->
                              <mat-option>None</mat-option>
                              <mat-option *ngFor="let staff of this.dbStaffUsersTask" [value]="staff.displayName" >{{staff.displayName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="delete" class="project">
                    <mat-header-cell *matHeaderCellDef>Delete</mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index;">
                        <a fxLayoutAlign="center center" fxLayoutGap="20px" (click)="deleteTask(element.id)"><mat-icon aria-hidden="delete" aria-label="delete icon">delete_forever</mat-icon></a>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="taskStatus" class="project">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index;">
                        <mat-slide-toggle [(ngModel)]="element.taskStatus"></mat-slide-toggle>
                        <span *ngIf = "element.taskStatus">&nbsp;Active</span>
                        <span *ngIf = "!element.taskStatus">&nbsp;Completed!</span>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="createdBy">
                    <mat-header-cell *matHeaderCellDef>Created By</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div *ngIf = "element.createdBy; else showAnon">{{element.createdBy}}</div>
                        <ng-template #showAnon>
                            <p>
                              NA
                            </p>
                          </ng-template>
                    </mat-cell >
                  </ng-container>

                  <!-- <ng-container matColumnDef="entredby" class="project">
                    <mat-header-cell *matHeaderCellDef>Project</mat-header-cell>
                    <mat-cell *matCellDef="let element.task">
                       Hello
                    </mat-cell>
                  </ng-container> -->

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row >
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row >
            </mat-table>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button type="submit" mat-button mat-flat-button color="primary" (click)="doAction(); openSnackBar(localData, action)">{{this.action}}</button>
        <button mat-button (click)="closeDialog(localData)" mat-flat-button color="warn">Cancel</button>
    </mat-dialog-actions>
</div>
