<mat-card >
    <mat-card-title *ngIf="!chosenToCopy">
        Start New Quote
    </mat-card-title>
    <mat-card-title *ngIf="chosenToCopy">
        Search Existing Orders To Copy To New Quote
    </mat-card-title>
    <mat-card-content >
        <button *ngIf="!chosenToCopy" mat-raised-button color="primary" (click)="doAction('','empty')"> Create New Empty Quote</button>&nbsp;
        <button  *ngIf="!chosenToCopy" mat-raised-button color="primary" (click)="doCopy()"> Copy Existing Order </button>
    
            <div class="table-responsive" [hidden]="!chosenToCopy">
                <div fxLayout="row" fxLayoutAlign="space-between" class="openclosefilter">
                    <mat-form-field  class="input-search" fxFlex="40%" fxFlex.xs="100%" appearance="outline">
                      <mat-icon *ngIf="searchShow" matPrefix>search</mat-icon>
                      <input matInput  type="text" (keyup)="doFilter($event.target.value, 'prospect')" [(ngModel)]="searchAll"
                      (focus) = "searchShow = !searchShow" (blur) = "searchShow = true" appearance="outline">
                      <!-- <button mat-button *ngIf="searchProspect" matSuffix mat-icon-button aria-label="Clear" (click)="searchProspect=''; clearFilters(dbCompleted); searchShow = true">
                        <mat-icon>close</mat-icon>
                      </button> -->
                      <a *ngIf="searchAll" matSuffix mat-icon-button aria-label="Clear" (click)="searchAll=''; clearFilters(dbExistingJobs)">
                        <mat-icon>close</mat-icon>
                    </a>
                    </mat-form-field>
                </div>
                <mat-card *ngIf="isLoading"
                  style="display: flex; justify-content: center; align-items: center">
                  <mat-progress-spinner
                    color="primary"
                    mode="indeterminate">
                  </mat-progress-spinner>
                </mat-card>
                <mat-table #table1 [dataSource]="dbExistingJobs"  >
  
                <ng-container matColumnDef="projectNumber" class="project">
                  <mat-header-cell *matHeaderCellDef >Project#</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.projectNumber }}</mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="contactName">
                  <mat-header-cell *matHeaderCellDef >Contact Name</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                      {{ element.contactName }}
                  </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="opportunityName">
                  <mat-header-cell *matHeaderCellDef>Opportunity Name</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.opportunityName }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="milestone">
                    <mat-header-cell *matHeaderCellDef>Milestone</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.milestone | milestone}}</mat-cell>
                  </ng-container>
  
                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{hovered: row.hovered}"
                (mouseover)="row.hovered = true" (mouseout)="row.hovered = false" (click)="doAction(row,'copyId')"></mat-row>
              </mat-table>
              <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
        </mat-card-content>
    </mat-card>