import { Component, OnInit, Optional, Inject, OnDestroy, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from '../../service/admin.service';
import { Subscription } from 'rxjs';
import { PriceList } from '../../models/quote.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';

declare function require(name:string);

var dayjs = require('dayjs');
var weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);
var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);
var weekday = require('dayjs/plugin/weekday');
dayjs.extend(weekday);

@Component({
  selector: 'app-estimate-dialogue',
  templateUrl: './estimate-dialogue.component.html',
  styleUrls: ['./estimate-dialogue.component.css']
})
export class EstimateDialogueComponent implements OnInit, OnDestroy {
  actionType: string;
  pricelistSubscription: Subscription;
  dbPriceList: PriceList[];
  selectedMaterialValue: string;
  isLoading = true;
  pricelistGraphics = new MatTableDataSource<PriceList>();
  pricelistChannelLetters = new MatTableDataSource<PriceList>();
  pricelistLed = new MatTableDataSource<PriceList>();
  pricelistElectrical = new MatTableDataSource<PriceList>();
  pricelistSheetStock = new MatTableDataSource<PriceList>();
  pricelistSteel = new MatTableDataSource<PriceList>();
  pricelistLabour = new MatTableDataSource<PriceList>();

  searchCompany = '';
  searchPerson = '';
  searchShow = true;
  searchAll = '';

  @ViewChild('table', { read: MatSort, static: false }) sort: MatSort;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();

  displayedPricelist: string[] = ['item', 'description', 'unit', 'price'];

  constructor(
    public dialogRef: MatDialogRef<EstimateDialogueComponent>,
    // *** @Optional() is used to prevent error if no data is passed ***
    @Optional() @Inject(MAT_DIALOG_DATA) public action: string,
    private priceListService: AdminService, private _snackBar: MatSnackBar
  ) {
    this.actionType = action;
  }

  ngOnInit(): void {

    this.pricelistSubscription = this.priceListService.priceListChanged.subscribe(
      (price: PriceList[]) => {
        // this.dbPriceList  = JSON.parse(JSON.stringify(price));
        // this.dbPriceList = this.dbPriceList.filter(value => value.type === this.actionType);
        this.pricelistGraphics.data  = JSON.parse(JSON.stringify(price));
        this.pricelistChannelLetters.data = JSON.parse(JSON.stringify(price));
        this.pricelistLed.data = JSON.parse(JSON.stringify(price));
        this.pricelistSheetStock.data = JSON.parse(JSON.stringify(price));
        this.pricelistSteel.data = JSON.parse(JSON.stringify(price));
        this.pricelistElectrical.data = JSON.parse(JSON.stringify(price));
        this.pricelistLabour.data = JSON.parse(JSON.stringify(price));

        this.pricelistGraphics.data = this.pricelistGraphics.data.filter(value => value.type === 'Graphics');
        this.pricelistChannelLetters.data = this.pricelistChannelLetters.data.filter(value => value.type === 'Channel Letters');
        this.pricelistLed.data = this.pricelistLed.data.filter(value => value.type === 'LED and Lighting');
        this.pricelistElectrical.data = this.pricelistElectrical.data.filter(value => value.type === 'LED and Lighting');
        this.pricelistSheetStock.data = this.pricelistSheetStock.data.filter(value => value.type === 'Sheet Stock');
        this.pricelistSteel.data = this.pricelistSteel.data.filter(value => value.type === 'Steel and Extrusions');
        this.pricelistLabour.data = this.pricelistLabour.data.filter(value => value.type === 'Labour');

        this.isLoading = false;
     }
    );
    this.priceListService.fetchPriceListData();
    this.pricelistGraphics.sort = this.sort;
    this.pricelistChannelLetters.sort = this.sort;
    this.pricelistLed.sort = this.sort;
    this.pricelistElectrical.sort = this.sort;
    this.pricelistSheetStock.sort = this.sort;
    this.pricelistSteel.sort = this.sort;
    this.pricelistLabour.sort = this.sort;

  }

  ngAfterViewInit() {

    this.pricelistGraphics.paginator = this.paginator.toArray()[0];
    this.pricelistGraphics.sort = this.sort;
    this.pricelistChannelLetters.paginator = this.paginator.toArray()[0];
    this.pricelistChannelLetters.sort = this.sort;
    this.pricelistLed.paginator = this.paginator.toArray()[0];
    this.pricelistLed.sort = this.sort;
    this.pricelistElectrical.paginator = this.paginator.toArray()[0];
    this.pricelistElectrical.sort = this.sort;
    this.pricelistSheetStock.paginator = this.paginator.toArray()[0];
    this.pricelistSheetStock.sort = this.sort;
    this.pricelistSteel.paginator = this.paginator.toArray()[0];
    this.pricelistSteel.sort = this.sort;
    this.pricelistLabour.paginator = this.paginator.toArray()[0];
    this.pricelistLabour.sort = this.sort;
  }

  // doAction(id) {
  //   console.log(id.id)
  //   const filterResult = this.dbPriceList.filter( t => t.id = id);
  //   const typeOfItem = filterResult[0].type;
  //   this.dialogRef.close({event: this.action, data: id, type: typeOfItem});
  // }

  doAction(obj) {
    // const filterResult = this.dbPriceList.filter( t => t.id = id);
    // const filterResult = obj.id;
    // const typeOfItem = filterResult[0].type;
    const typeOfItem = obj.type;
    this.dialogRef.close({event: this.action, data: obj.id, type: typeOfItem});
  }

  closeDialog() {
    this.dialogRef.close({event: 'Cancel'});
  }

  doFilter(filterValue: string, milestone: string) {
    this.pricelistGraphics.filter = filterValue.trim().toLowerCase();
    this.pricelistChannelLetters.filter = filterValue.trim().toLowerCase();
    this.pricelistLed.filter = filterValue.trim().toLowerCase();
    this.pricelistElectrical.filter = filterValue.trim().toLowerCase();
    this.pricelistSheetStock.filter = filterValue.trim().toLowerCase();
    this.pricelistSteel.filter = filterValue.trim().toLowerCase();
    this.pricelistLabour.filter = filterValue.trim().toLowerCase();
  }

  clearFilters(milestone: any) {
    // milestone.filter = '';
    this.pricelistGraphics.filter = '';
    this.pricelistChannelLetters.filter = '';
    this.pricelistLed.filter ='';
    this.pricelistElectrical.filter = '';
    this.pricelistSheetStock.filter = '';
    this.pricelistSteel.filter = '';
    this.pricelistLabour.filter = '';
  }

  openSnackBar() {
    type MatSnackBarHorizontalPosition = 'left';
      this._snackBar.open('Send For Approval - ', 'ADDED', {
        duration: 500,
      });
    
  }

  ngOnDestroy() {
    this.pricelistSubscription.unsubscribe();
    // this.contactsSubscription.unsubscribe();
  }
}
