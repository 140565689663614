import { Component, OnInit, NgZone, ViewChild, OnDestroy, HostListener, ViewEncapsulation,
   Input, Output, EventEmitter, ChangeDetectionStrategy} from '@angular/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { OrdersService } from '../../service/orders.service';
import { CustomerService } from 'src/app/service/customer.service';
import { AddNewLeadComponent } from '../add-new-lead/add-new-lead.component';
import { AngularFirestore} from '@angular/fire/compat/firestore';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Orders } from '../../models/orders.model';
import { AuthService } from '../../service/auth.service';
import { AdminService } from '../../service/admin.service';
import { Weeklies } from 'src/app/models/weeklies.model';
import { Admin } from '../../models/admin.model';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MatMenuTrigger } from '@angular/material/menu';
import { TaskDialogueComponent } from '../task/task-dialogue/task-dialogue.component';
import { EmailsService } from 'src/app/service/emails.service';
import { MatSnackBar } from '@angular/material/snack-bar';

/* import * as dayjs from 'dayjs';
* Set Date/ Timezones usinf DayJs
*/
declare function require(name:string);
var dayjs = require('dayjs');
var weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);
var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);
var weekday = require('dayjs/plugin/weekday');
dayjs.extend(weekday);
var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
var Holidays = require('date-holidays');
var hd = new Holidays();
hd.getCountries();
hd.getStates('CA');
hd.getRegions('CA', 'ab');
hd = new Holidays('CA', 'ab');
var weekYear = require('dayjs/plugin/weekYear') // dependent on weekOfYear plugin
dayjs.extend(weekYear);
var isoWeek = require('dayjs/plugin/isoWeek')
dayjs.extend(isoWeek)
var timezone = require('dayjs/plugin/timezone');
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Edmonton");
require('dayjs/locale/ca');
dayjs.locale('en-ca');

export interface Tile {
  /**
   * Interface for Angular Material Gridlist
   * Gridlist for body of the calendar
   */
  color: string;
  cols: number;
  rows: number;
  text: string;
  border: string;
  data: any;
  textcolor: string;
}

export interface HeaderTiles {
  /**
   * Interface for Angular Material Gridlist
   * Gridlist for header of the calendar
   */
  color: string;
  cols: number;
  rows: number;
  text: string;
  border: string;
  textcolor: string;
}

@Component({
  selector: 'app-installcalendar',
  templateUrl: './installcalendar.component.html',
  styleUrls: ['./installcalendar.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class InstallcalendarComponent implements OnDestroy, OnInit {
  /**
   * description
   *
   */

  options: UntypedFormGroup;
  floatLabelControl = new UntypedFormControl('includeOpen');
  currentUserLogin: string;
  loginUser: any;
  company = [];
  localeString = 'en-ca';
  navDate: any;
  weekDaysHeaderArr: Array<string> = [];
  gridArr: Array<any> = [];
  dbProspect: Orders[];
  dbQualifying: Orders[];
  dbDesign: Orders[];
  dbProposal: Orders[];
  dbOperations: Orders[];
  dbProductionDesign: Orders[];
  dbInProduction: Orders[];
  dbOpen: Orders[];
  dbSum: Orders[];
  dbCombine: Orders[];
  dbInstall: Orders[];
  dbFollowUp: Orders[];
  dbProjectComplete: Orders[];
  dbServiceOrders: Orders[];
  dbWeeklies: Weeklies[];
  weeklies: any;
  testtester: [];
  leadsWonSubscription: Subscription;
  leadsOpenSubscription: Subscription;
  weekliesSubscription: Subscription;
  adminSettingSubscription: Subscription;
  invalidEntries = 0;
  lastDayOfWeek: any;
  arrSatSunDays = [0, 6, 9, 15, 18, 24, 27, 33, 36, 42, 45, 51];
  public innerHeight: any;
  matToolPosition = 'right';
  isLoading = true;
  fourweeksum: number;
  eightweeksum: number;
  twelftweeksum: number;
  sixteenweeksum: number;
  fourweeksumOpen: number;
  eightweeksumOpen: number;
  twelftweeksumOpen: number;
  sixteenweeksumOpen: number;
  tillEndofYearSumOpen: number;
  tillEndofYearSumClosed: number;
  tillEndofYearSum: number;
  gridCellCount: number;
  currentWeekNow: number;
  screenHeight: number;
  screenWidth: number;
  adminDb: any;
  objectKeys = Object.keys;
  weeklyTarget: number;
  budgetedWeeklyTarget: number;
  currentYearData: any;
  toggleOpenChecked: boolean;
  toggleHideShowCompleteChecked: boolean;

  // Only Update changes in *ngFor
  trackByDate = (index, tile) => tile.text;

  @Output() radioChangeEvent = new EventEmitter<string>();
  @Output() toggleChangeEvent = new EventEmitter<boolean>();
  @Output() toggleHideShowCompleteEvent = new EventEmitter<boolean>();

  // we create an object that contains coordinates
  menuTopLeftPosition =  {x: '0', y: '0'}

  // reference to the MatMenuTrigger in the DOM
  @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger;
  ytdTargetInvoice: number;

  constructor(public dialog: MatDialog, private ordersService: OrdersService, private customerService: CustomerService,
    private db: AngularFirestore, public authService: AuthService, private ngZone: NgZone, private emailServices: EmailsService,
    private adminData: AdminService, fb: UntypedFormBuilder, private snackBar: MatSnackBar,
    analytics: AngularFireAnalytics) {
      this.getScreenSize();
      this.options = fb.group({
      floatLabel: this.floatLabelControl,
      });
      this.toggleOpenChecked = true;
      this.toggleHideShowCompleteChecked = false;
      const fullNameLogin = JSON.parse(localStorage.getItem('user'));
      this.currentUserLogin = fullNameLogin.displayName;
      analytics.logEvent('Install Calendar Being Used', { userLogin:this.currentUserLogin });
    }

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  @Input()
  showBottombar: boolean;

  tilesHeader: HeaderTiles[] = [
    {text: 'Sun', cols: 1, rows: 1, color: '#fff', border: 'thin solid lightgray', textcolor: '#2e7d32'},
    {text: 'Mon', cols: 1, rows: 1, color: '#fff', border: 'thin solid lightgray', textcolor: '#2e7d32'},
    {text: 'Tue', cols: 1, rows: 1, color: '#fff', border: 'thin solid lightgray', textcolor: '#2e7d32'},
    {text: 'Wed', cols: 1, rows: 1, color: '#fff', border: 'thin solid lightgray', textcolor: '#2e7d32'},
    {text: 'Thu', cols: 1, rows: 1, color: '#fff', border: 'thin solid lightgray', textcolor: '#2e7d32'},
    {text: 'Fri', cols: 1, rows: 1, color: '#fff', border: 'thin solid lightgray', textcolor: '#2e7d32'},
    {text: 'Sat', cols: 1, rows: 1, color: '#fff', border: 'thin solid lightgray', textcolor: '#2e7d32'},
    {text: 'Notes', cols: 2, rows: 1, color: '#fff', border: 'thin solid lightgray', textcolor: '#2e7d32'},
    {text: 'Invoice Value', cols: 1, rows: 1, color: '#fff', border: 'thin solid lightgray', textcolor: '#2e7d32'}
  ];

  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
      this.screenHeight = window.innerHeight;
      this.screenWidth = window.innerWidth;
      let vh = window.innerHeight * 0.01;
      let vw = window.innerWidth * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      document.documentElement.style.setProperty('--vw', `${vw}px`);
    }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable.pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }


  ngOnInit() {
    this.currentWeekNow = dayjs().isoWeek();

    this.navDate = dayjs();

    this.adminSettingSubscription = this.adminData.adminChanged.subscribe(
      (adminstuff: Admin[]) => {
        this.adminDb = JSON.parse(JSON.stringify(adminstuff));
        this.currentYearData =  this.adminDb[0].financials.filter(t => t.year === dayjs().year());
        this.ytdTargetInvoice = this.currentYearData[0].ytdTargetInvoice;
        this.budgetedWeeklyTarget =(this.currentYearData[0].fiftyTwoWeekTarget -
          this.currentYearData[0].ytdTargetInvoice) / (dayjs().isoWeeksInYear() - dayjs().isoWeek());
      }
    );

    this.weekliesSubscription = this.ordersService.weekliesChanged.subscribe(
      (weekliesUpdate: Weeklies[]) => {
        this.dbWeeklies  = weekliesUpdate;
        this.isLoading = false;
        this.makeGrid();
      }
    );
    this.leadsOpenSubscription = this.ordersService.openCloseChanged.subscribe(
      (leadsOpen: Orders[]) => {
        this.dbOpen = JSON.parse(JSON.stringify(leadsOpen));
        this.dbOperations = JSON.parse(JSON.stringify(leadsOpen));
        this.dbOperations = this.dbOperations.filter(t => t.milestone === '5. Operations');
        this.dbOpen = this.dbOpen.filter(t => t.milestone !== '5. Operations');
        this.fourweeksumOpen = this.adminData.getWeekSumTotals(0, this.dbOpen, 'weekType');
        this.eightweeksumOpen = this.adminData.getWeekSumTotals(4, this.dbOpen, 'weekType') + this.fourweeksumOpen;
        this.twelftweeksumOpen = this.adminData.getWeekSumTotals(8, this.dbOpen, 'weekType') +  this.eightweeksumOpen;
        this.sixteenweeksumOpen = this.adminData.getWeekSumTotals(12, this.dbOpen, 'weekType') + this.twelftweeksumOpen;
        this.tillEndofYearSumOpen = this.adminData.getWeekSumTotals(( 52 - this.currentWeekNow), this.dbOpen, 'ytd' );
        this.isLoading = false;
        this.makeGrid();
      }
    );

    this.leadsWonSubscription = this.ordersService.openCloseCompletedChanged.subscribe(
      (leadsWon: Orders[]) => {
        this.dbInProduction  = JSON.parse(JSON.stringify(leadsWon));
        this.fourweeksum = this.adminData.getWeekSumTotals(0, this.dbInProduction, 'weekType')
          + this.adminData.getWeekSumTotals(0, this.dbOperations, 'weekType');
        this.eightweeksum = this.adminData.getWeekSumTotals(4, this.dbInProduction, 'weekType')
          + this.fourweeksum + this.adminData.getWeekSumTotals(4, this.dbOperations, 'weekType');
        this.twelftweeksum = this.adminData.getWeekSumTotals(8, this.dbInProduction, 'weekType')
          +  this.eightweeksum + this.adminData.getWeekSumTotals(8, this.dbOperations, 'weekType');
        this.sixteenweeksum = this.adminData.getWeekSumTotals(12, this.dbInProduction, 'weekType')
          + this.twelftweeksum + this.adminData.getWeekSumTotals(12, this.dbOperations, 'weekType');
        this.tillEndofYearSumClosed = this.adminData.getWeekSumTotals(( 52 - this.currentWeekNow), this.dbInProduction, 'ytd' );
        this.tillEndofYearSum = this.tillEndofYearSumClosed + this.tillEndofYearSumOpen;
        this.isLoading = false;
        this.makeGrid();
      }
    );
    this.adminData.fetchAdminData();
    this.ordersService.fetchWeeklies();
    this.ordersService.fetchOrdersWon();
    this.ordersService.fetchAvailableOrders('created');
    this.ordersService.fetchOrdersIncludeCompleted();
  }

  changeNavMonth(num: number){
    if(num === 0){
      this.navDate = dayjs();
      this.makeGrid();
    }
    if(this.canChangeNavMonth(num)){
      this.navDate = this.navDate.add(num, 'month');
      this.makeGrid();
    }
  }

  canChangeNavMonth(num: number){
    let clonedDate = dayjs(this.navDate);
    clonedDate = dayjs(this.navDate).add(num, 'month');
    const minDate = dayjs().add(-24, 'month');
    const maxDate = dayjs().add(12, 'month');
    return clonedDate.isBetween(minDate, maxDate);
  }

  sameMonth(date) {
    const thisMonth = dayjs().isSame(date, 'month');
    return thisMonth;
  }

  radioChange($event: MatRadioChange) {
    this.radioChangeEvent.emit($event.value);
    this.makeGrid();
  }

  toggleOpenCloseChange($event: MatSlideToggleChange) {
    this.toggleChangeEvent.emit($event.checked);
    this.makeGrid();
  }
  toggleShowHideCompleteChange($event: MatSlideToggleChange) {
    this.toggleHideShowCompleteEvent.emit($event.checked);
    this.makeGrid();
  }

  makeGrid() {
    /**
     * Build the Angular Material Gridlist
     * navDate - set innitialy to current month. Increment
     * or decrement to get to other months with canChangeNavMonth()
     */

    this.gridArr = []; //The array that will make up the gridlist
    const firstDayDate = dayjs(this.navDate).startOf('month');
    const initialEmptyCells = dayjs(firstDayDate).weekday(); //Number of days beginning of the 1st week thats not part of the month
    const lastDayDate = dayjs(this.navDate).endOf('month');
    const lastEmptyCells = 6 - dayjs(lastDayDate).weekday(); //Number of days end of the last week thats not part of the month
    const daysInMonth = dayjs(this.navDate).daysInMonth();
    const previousMonthDate = dayjs(this.navDate).subtract(1, 'month');
    const daysInPreviousMonth = dayjs(previousMonthDate).daysInMonth();
    const nextMonthDate =  dayjs(this.navDate).add( 1, 'month');
    const arrayLengthWithoutNotes = initialEmptyCells + lastEmptyCells + daysInMonth;
    const numOfWeeks = arrayLengthWithoutNotes / 7;
    const arrayLength = arrayLengthWithoutNotes + (numOfWeeks * 2);
    let weekcount = 0;
    let weekTotalSum = 0;
    let daysNextMonth = 0;

    /*
    * add data to each cell of the Angular Material Gridlist for the calendar body
    */
    for (let i = 0; i < arrayLength; i++) {
      const obj: any = {};
      this.gridCellCount = i;

      /* Fill in the empty grid tiles before current month */
      if ( i < initialEmptyCells ) {
          obj.text = daysInPreviousMonth - ((initialEmptyCells - 1) - i);
          obj.date =  dayjs(previousMonthDate).format('YYYY-MM') + '-' + obj.text;

          this.getInstallData(obj, i, 'beforeCalendar', daysInPreviousMonth)

          // Get values for each install date
          obj.data.forEach(element =>  { if ( element.multiInstall !== undefined ) {
            element.multiInstall.forEach(t => {
              if (dayjs(t.installDate).format('DD/MM/YYYY') === dayjs(obj.date).format('DD/MM/YYYY')) {
                weekTotalSum += (1 * t.progressValue);
              }
            });
          }});
          obj.installsLength = obj.data.length > 3 ? true : false;
       /* Fill empty tiles after last day*/
      } else if (((i - weekcount) > initialEmptyCells + daysInMonth -1 &&
      ((i - weekcount) < arrayLength - 10) && (i < 52))) {
          daysNextMonth++;
          obj.text = daysNextMonth;
          obj.date =  dayjs(nextMonthDate).format('YYYY-MM') + '-' + obj.text;

          this.getInstallData(obj, i, 'afterCalendar', nextMonthDate)

          // Get values for each install date
          obj.data.forEach(element =>  { if ( element.multiInstall !== undefined ) {
            element.multiInstall.forEach(t => {
              // if (dayjs(t.installDate).isSame(obj.date)) {
              if (dayjs(t.installDate).format('DD/MM/YYYY') === dayjs(obj.date).format('DD/MM/YYYY')) {
                weekTotalSum += (1 * t.progressValue);
              }
            });
          }});
          obj.installsLength = obj.data.length > 3 ? true : false;

      /* Fill grid tiles for notes */
      } else if (i === 7 || i === 16 || i === 25 || i === 34 || i === 43 || i === 52) {
          obj.text = '';
          obj.cols = 2;
          obj.rows = 1;
          obj.date = dayjs(this.lastDayOfWeek);
          obj.notes = true;
          obj.weekNumber = dayjs(this.lastDayOfWeek).isoWeek();
          obj.data = ((this.dbWeeklies === []) || (this.dbWeeklies === undefined)) ? [{note: ' '}] :
           !this.dbWeeklies.some( it => dayjs(it.lastDayOfWeekDate).format('DD/MM/YYYY') === dayjs(obj.date).format('DD/MM/YYYY')) ? [{note: ' '}] :
           !this.dbWeeklies ? [{note: ' '}] :
            this.dbWeeklies.filter( it => dayjs(it.lastDayOfWeekDate).format('DD/MM/YYYY') === dayjs(obj.date).format('DD/MM/YYYY'));
          obj.color = '#fff';
          obj.border = 'thin solid lightgray';
          weekcount++;
      /* Fill grid tiles for Week Total and Production Weekly Task */
      } else if (i === 8 || i === 17 || i === 26 || i === 35 || i === 44 || i === 53) {
          obj.text = '';
          obj.cols = 1;
          obj.rows = 1;
          obj.color = '#fff';
          obj.border = 'thin solid lightgray';
          weekcount++;
          obj.weekSum = (weekTotalSum === 0 ? 0 : weekTotalSum);
          obj.weekNumber = 'WEEK' + ' ' + dayjs(this.lastDayOfWeek).isoWeek();
          weekTotalSum = 0;
      /* Fill grid tiles for Calendar days */
      } else {
        obj.text = (i - weekcount) - initialEmptyCells + 1;
        obj.date = dayjs(this.navDate).format('YYYY-MM') + '-' + obj.text;

        this.getInstallData(obj, i, 'mainbody', this.navDate)
        // console.log(i, obj.data)
        /* Get values for each install date */
        obj.data.forEach(element =>  { if ( element.multiInstall !== undefined ) {
          element.multiInstall.forEach(t => {
            // if (dayjs(t.installDate).isSame(obj.date)) {
              if (dayjs(t.installDate).format('DD/MM/YYYY') === dayjs(obj.date).format('DD/MM/YYYY')) {
              weekTotalSum += (1 * t.progressValue);
            }
          });
        }});
        obj.installsLength = obj.data.length > 3 ? true : false;
        this.lastDayOfWeek = dayjs(obj.date).format();
      }
      this.gridArr.push(obj);
    }
  }
   /*END BUILDING GRID */

   getInstallData(obj, i, calendarSection, beforeAfter) {

      // Check to See if its a Holiday
      let holidayDateToComapre = dayjs(beforeAfter).format('YYYY-MM');
      holidayDateToComapre = holidayDateToComapre.concat('-'+obj.text+' '+'00:00:00');
      let todayCurrentMonthHolidays = hd.getHolidays(obj.date);
      todayCurrentMonthHolidays = todayCurrentMonthHolidays.filter( t => t.type === 'public');
      let todaySelectedMonthHolidays = todayCurrentMonthHolidays.filter(t => dayjs(holidayDateToComapre).isSame(t.date));
      obj.isholiday = todaySelectedMonthHolidays.length !== 0? todaySelectedMonthHolidays[0].name : 'Add +';

      // Filter each day
      this.dbCombine = this.dbInProduction === undefined ? this.dbOpen : this.dbOpen === undefined ?
        this.dbInProduction : this.dbInProduction.concat(this.dbOpen);
      this.dbCombine = this.dbCombine !== undefined ? this.dbCombine.concat(this.dbOperations ) : [];
      obj.data = this.dbCombine !== undefined ? this.dbCombine.filter
      (it => it !== undefined ? it.hasOwnProperty('multiInstall') === true : false) : this.dbCombine;
      obj.data = obj.data.filter(it => it.multiInstall.length > 0);
      obj.data = this.toggleOpenChecked === true ? obj.data : this.filterByMilestone(obj.data);
      obj.date =  dayjs(obj.date).toISOString();
      // this.getDateSame(obj, obj.date)
      obj.data = obj.data.filter(it => it.multiInstall.some(t => dayjs(t.installDate).format('DD/MM/YYYY') === dayjs(obj.date).format('DD/MM/YYYY')));
      // If holiday disable drag of label
      (!obj.data[0] ? obj.data[0] = ({contactName: obj.isholiday, valuePerDuration :
          0, actualInstallationDate: dayjs(obj.date).format(), disabled: true}) : obj.data);
      obj.cols = 1;
      obj.rows = 1;
      obj.color = (calendarSection === 'beforeCalendar') || (calendarSection ===
         'afterCalendar') ? 'lightgray' : this.colorTiles(obj.date, todaySelectedMonthHolidays, i);
      obj.border = (calendarSection === 'beforeCalendar') || (calendarSection === 'afterCalendar') ? 'thin solid #fff' : 'thin solid lightgray';
      obj.textcolor = this.colorText(obj.date, todaySelectedMonthHolidays, i);
      return obj;
   }

  //  getDateSame(obj, calendarDate) {
  //   for (const key in obj.data) {
  //     if(obj.data[key].hasOwnProperty('multiInstall')) {
  //       obj.data[key].multiInstall.forEach( t =>
  //         dayjs(t.installDate).format('DD/MM/YYYY') === dayjs(calendarDate).format('DD/MM/YYYY'))
  //     } else {
  //       continue
  //     }
  //   }
  //  }

  updateWeeklies(data) {
    this.ordersService.setWeeklyProductionNotes(data);
  }
  // Radio Change
  filterByMilestone(obj) {
    obj = obj.filter(
      t => (t.milestone === '5. Operations') ||
      (t.milestone === 'A. Production Design') ||
      (t.milestone === 'B. In Production') ||
      (t.milestone === 'B. Service Order') ||
      (t.milestone === 'C. Installation Complete') ||
      (t.milestone === 'D. Customer Follow Up') ||
      (t.milestone === 'D. Customer Follow Up') ||
      (t.milestone === 'E. Project Complete') ||
      (t.milestone === 'CIS'));

    return  obj;
  }

  colorTiles(date, isHoliday, i) {
    if (isHoliday.length !== 0) {
        return '#81c784';
    } else if (this.arrSatSunDays.some( t => t === i)) {
      return 'rgb(239, 239, 239)';
    } else if (dayjs().isSame(date, 'day')){
      return '#c8e6c9';
    } else {
      return '#fff';
    }
  }

  colorText(date, isHoliday, i) {
    if (isHoliday.length !== 0) {
      return '#fff';
    } else if (this.arrSatSunDays.some( t => t === i)) {
      return 'transparent';
    } else if (dayjs().isSame(date, 'day')){
      return 'transparent';
    } else if (dayjs().isSame(date, 'month')) {
        return '#fff';
    } else {
      return 'transparent';
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
        this.ordersService.installCalendarDragUpdate(event.previousContainer.data,
          dayjs(event.container.id).format('DD MMM YYYY hh:mm a'),
          dayjs(event.previousContainer.id).format('DD MMM YYYY hh:mm a'),
          event.previousIndex, this.currentUserLogin);
          this.saveSnackBar(event.previousContainer.data, event.previousIndex);
      }
    }

    openDialog(action, obj, status) {
      obj.status = status;
      obj.action = action;
      obj.company = this.company;
      const dialogRef = this.dialog.open(AddNewLeadComponent, {
        width: (obj.action === 'Update' || obj.action === 'Add') ? 'auto' : (obj.action === 'Save Task') ? '800px' : '300px',
        data: obj
      });
      dialogRef.afterClosed().subscribe(result => {
        if (!result) {
          result = null;
        } else if (result.event === 'Update') {
          if (result.data.milestone !== result.oldMilestone) {
            const notes = this.ordersService.formatNotesMilestonesMessages(result.oldMilestone, result.data.milestone);
            result.data = this.ordersService.updateMeetingMinutes(result.data, notes, 'milestoneChange', this.currentUserLogin);
            this.ordersService.updateOrder(result.data, this.currentUserLogin);
          } else {
            this.ordersService.updateOrder(result.data, this.currentUserLogin);
          }
        } else if (result.event === 'Save Task') {
          this.ordersService.updateOrder(result.data, this.currentUserLogin);
        } else if (result.event === 'Delete') {
          this.ordersService.deleteOrder(result.data, null, this.currentUserLogin);
        } else if (result.event === 'Add') {
          this.ordersService.addNewOrderData(result.data, this.currentUserLogin);
        }
      });
    }

    onRightClick(event: MouseEvent, item) {
      // preventDefault avoids to show the visualization of the right-click menu of the browser
      event.preventDefault();

      // we record the mouse position in our object
      this.menuTopLeftPosition.x = event.clientX + 'px';
      this.menuTopLeftPosition.y = event.clientY + 'px';

      // we open the menu
      // we pass to the menu the information about our object
      this.matMenuTrigger.menuData = {item};

      // we open the menu
      this.matMenuTrigger.openMenu();

  }

  openTaskDialog(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(TaskDialogueComponent, {
      width:  (obj.action === 'Update' || obj.action === 'Add') ? 'auto' : (obj.action === 'Save Task') ? '90vw' : '800px',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      if ((result === undefined) || (result === {event: 'Cancel'})) {
      } else if (result.event === 'Update') {
        this.ordersService.updateOrder(result.data, this.currentUserLogin);
      } else if (result.event === 'Save Task') {
          if (result.data.task.length > obj.task.length) {
            this.emailServices.sendTaskEmail((result.data.task.length - obj.task.length), result.data);
          }
          this.ordersService.updateOrder(result.data, this.currentUserLogin);
      } else if (result.event === 'Delete') {
        this.ordersService.deleteOrder(result.data, null, this.currentUserLogin);
      } else if (result.event === 'Add') {
        this.ordersService.addNewOrderData(result.data, this.currentUserLogin);
      }
    });
  }
  activityCompleted(obj, index, status) {
    if (status === 'completed') {
      obj.multiInstall[index].isCompleted = true;
    } else {
      obj.multiInstall[index].isCompleted = false;
    }
    this.ordersService.updateOrder(obj, this.currentUserLogin);
  }

  saveSnackBar(obj, index) {
    type MatSnackBarHorizontalPosition = 'left';
      this.snackBar.open('Saving...', obj[index].contactName +' - '+obj[index].opportunityName,  {
        duration: 500,
      });
  }

  ngOnDestroy() {
    this.weekliesSubscription.unsubscribe();
    this.leadsWonSubscription.unsubscribe();
    this.leadsOpenSubscription.unsubscribe();
    this.adminSettingSubscription.unsubscribe();
  }
}
