<div fxLayout= "column" fxLayoutAlign="center center">
    <h1 mat-dialog-title *ngIf="localData.name == null && localData.action == 'Add Company'">Enter New Organization</h1>
    <h1 mat-dialog-title *ngIf="localData.name == null && localData.action == 'Contact'">Enter New Contact</h1>
    <h1 mat-dialog-title *ngIf="localData.name!==null">{{localData.name}}</h1>

  <mat-dialog-content *ngIf="(action == 'Add Company') || (action == 'Update') ">
      <div fxLayout= "column">
        <mat-form-field class="description">
              <input matInput placeholder="Organization Name" [(ngModel)]="localData.name"  (keyup.enter) ="doAction(action)">
        </mat-form-field>
    </div>
    <div  fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayoutGap="5px">
        <div>
          <mat-form-field>
          <mat-label>Tags</mat-label>
            <mat-select required [(value)]="localData.tags">
              <mat-option>None</mat-option>
              <mat-option value="Customer">Customer</mat-option>
              <mat-option value="Developer">Developer</mat-option>
              <mat-option value="General Contractor">General Contractor</mat-option>
              <mat-option value="Vendor">Vendor</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>

        <mat-form-field>
            <input matInput placeholder="Phone Number" [(ngModel)]="localData.phoneNumber" />
        </mat-form-field>
        </div>
      </div>
  <div  fxLayout.md="row" fxLayout.sm="column" fxLayoutGap="5px">

    <mat-form-field>
        <input matInput placeholder="Email" [(ngModel)]="localData.email" >
    </mat-form-field>

      <mat-form-field>
          <input matInput placeholder="Website" [(ngModel)]="localData.website" >
      </mat-form-field>

  </div>
  <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Address
        </mat-panel-title>
        <!-- <mat-panel-description>
          Type your name and age
        </mat-panel-description> -->
      </mat-expansion-panel-header>
      <div fxLayout= "column">
          <mat-form-field class="description">
            <textarea matInput placeholder="Address" [(ngModel)]= "localData.addressStreet"
              (keyup.enter) ="doAction(action) ;openSnackBar(localData, action)">
            </textarea>
          </mat-form-field>
      </div>
        <div  fxLayout.md="row" fxLayout.sm="column" fxLayoutGap="5px">
          <mat-form-field >
              <input matInput placeholder="City / Town" [(ngModel)]="localData.city"  (keyup.enter) ="doAction(action)">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Province</mat-label>
            <mat-select required [(value)]="localData.state" >
              <mat-option>Select...</mat-option>
              <mat-option value="ab">Alberta</mat-option>
              <mat-option value="bc">British Columbia</mat-option>
              <mat-option value="mb">Manitoba</mat-option>
              <mat-option value="nb">New Brunswick</mat-option>
              <mat-option value="nl">Newfoundland and Labrador</mat-option>
              <mat-option value="nt">Northwest Territories</mat-option>
              <mat-option value="ns">Nova Scotia</mat-option>
              <mat-option value="nv">Nunavut</mat-option>
              <mat-option value="on">Ontario</mat-option>
              <mat-option value="pei">Prince Edward Island</mat-option>
              <mat-option value="que">Quebec</mat-option>
              <mat-option value="sk">Saskatchewan</mat-option>
              <mat-option value="yk">Yukon</mat-option>
            </mat-select>
        </mat-form-field>
        </div>
        <div  fxLayout.md="row" fxLayout.sm="column" fxLayoutGap="5px">
          <mat-form-field >
            <input matInput placeholder="Postal/ Zip Code" [(ngModel)]="localData.postcode"  (keyup.enter) ="doAction(action)">
          </mat-form-field>
          <mat-form-field>
              <mat-label>Country</mat-label>
                <mat-select required [(value)]="localData.country">
                  <mat-option>None</mat-option>
                  <mat-option value="Canada">Canada</mat-option>
                  <mat-option value="USA">USA</mat-option>
                  <mat-option value="Mexico">Mexico</mat-option>
                </mat-select>
              </mat-form-field>
        </div>
    </mat-expansion-panel>&nbsp;
</mat-dialog-content>

<mat-dialog-content *ngIf="(action == 'Add Person') || (action == 'UpdatePerson') ">
  <div  fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayoutGap="5px">
    <div>
      <mat-form-field>
          <input required matInput placeholder="First Name" [(ngModel)]="localData.firstName" [formControl]="inputCtrl">
          <mat-error *ngIf="inputCtrl.hasError('required')">
              Please enter a name
          </mat-error>
      </mat-form-field>
    </div>
    <div>
        <mat-form-field>
            <input matInput placeholder="Last Name" [(ngModel)]="localData.lastName">
            <mat-error *ngIf="inputCtrl.hasError('required')">
                Please enter a last name
            </mat-error>
        </mat-form-field>
      </div>
  </div>
<div  fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayoutGap="5px">
  <div>
    <mat-form-field  >
      <input matInput placeholder="Company" aria-label="State" [matAutocomplete]="auto" [formControl]="companyCtrl"
        (keyup.enter) ="doAction(action) ;openSnackBar(localData, action)">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChanged($event)" [displayWith]="AutoCompleteDisplay">
        <mat-option *ngFor="let company of filteredCompanies | async" [value]="company">
          <span>{{company.name}}</span>
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="companyCtrl.hasError('required')">
        Please enter to find an organization
      </mat-error>
      <mat-error *ngIf="companyCtrl.hasError('incorrect')">
          No organization match your input.
      </mat-error>
    </mat-form-field>
  </div>
  <div>
      <mat-form-field>
        <input matInput placeholder="Job Title" [(ngModel)]="localData.jobTitle">
      </mat-form-field>
  </div>
</div>
<div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayoutGap="5px">
  <div>
    <mat-form-field>
        <input matInput placeholder="Email" [(ngModel)]="localData.email">
    </mat-form-field>
  </div>
  <div>
        <mat-form-field>
          <input matInput placeholder="Phone Number" [(ngModel)]="localData.phoneNumber">
        </mat-form-field>
  </div>
</div>
<mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Address
      </mat-panel-title>
      <!-- <mat-panel-description>
        Type your name and age
      </mat-panel-description> -->
    </mat-expansion-panel-header>
    <div fxLayout= "column">
        <mat-form-field class="description">
          <textarea matInput placeholder="Address" [(ngModel)]= "localData.addressStreet"
            (keyup.enter) ="doAction(action) ;openSnackBar(localData, action)">
          </textarea>
        </mat-form-field>
    </div>
      <div  fxLayout.md="row" fxLayout.sm="column" fxLayoutGap="5px">
        <mat-form-field >
            <input matInput placeholder="City / Town" [(ngModel)]="localData.city"  (keyup.enter) ="doAction(action)">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Province</mat-label>
          <mat-select required [(value)]="localData.state" >
            <mat-option>Select...</mat-option>
            <mat-option value="ab">Alberta</mat-option>
            <mat-option value="bc">British Columbia</mat-option>
            <mat-option value="mb">Manitoba</mat-option>
            <mat-option value="nb">New Brunswick</mat-option>
            <mat-option value="nl">Newfoundland and Labrador</mat-option>
            <mat-option value="nt">Northwest Territories</mat-option>
            <mat-option value="ns">Nova Scotia</mat-option>
            <mat-option value="nv">Nunavut</mat-option>
            <mat-option value="on">Ontario</mat-option>
            <mat-option value="pei">Prince Edward Island</mat-option>
            <mat-option value="que">Quebec</mat-option>
            <mat-option value="sk">Saskatchewan</mat-option>
            <mat-option value="yk">Yukon</mat-option>
          </mat-select>
      </mat-form-field>
      </div>
      <div  fxLayout.md="row" fxLayout.sm="column" fxLayoutGap="5px">
        <mat-form-field >
          <input matInput placeholder="Postal/ Zip Code" [(ngModel)]="localData.postcode"  (keyup.enter) ="doAction(action)">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Country</mat-label>
              <mat-select required [(value)]="localData.country">
                <mat-option>None</mat-option>
                <mat-option value="Canada">Canada</mat-option>
                <mat-option value="USA">USA</mat-option>
                <mat-option value="Mexico">Mexico</mat-option>
              </mat-select>
        </mat-form-field>
      </div>
  </mat-expansion-panel>&nbsp;
</mat-dialog-content>

  <mat-dialog-actions>
    <button *ngIf="action == 'Update'" type="submit" mat-button mat-flat-button color="primary"
      [disabled]='(!localData.name)' (click)="doAction(action); openSnackBar(localData, action)">Update</button>
      <button *ngIf="action == 'Add Company'" type="submit" mat-button mat-flat-button color="primary"
      [disabled]='(!localData.name)' (click)="doAction(action); openSnackBar(localData, action)">Add Organization</button>
      <button *ngIf="(action == 'Add Person')" type="submit" mat-button mat-flat-button color="primary"
        (click)="doAction(action); openSnackBar(localData, action)" [disabled]='(!localData.firstName && !localData.lastName)'>Add Contact
      </button>
      <button *ngIf="(action == 'UpdatePerson') " type="submit" mat-button mat-flat-button color="primary"
        (click)="doAction(action); openSnackBar(localData, action)" [disabled]='(!localData.firstName && !localData.lastName)'>Update Contact
      </button>
      <button type="submit" mat-button mat-flat-button color="warn"
        (click)="doAction('delete'); openSnackBar(localData, 'delete')" [disabled]='(!localData.firstName && !localData.lastName)'>Delete
      </button>
      <button mat-button (click)="closeDialog(localData)" mat-flat-button color="warn">Cancel</button>
  </mat-dialog-actions>
</div>
