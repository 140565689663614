<mat-tab-group>

  <mat-tab label="Admin Settings" *ngIf = "(currentUserLogin === 'Aaron Seutter') || (currentUserLogin === 'Gary Julius')">
    <div class="main-div">
      <mat-card>
       <mat-card-title>
        Yearly Financial Projections
       </mat-card-title>
       <mat-card-content>
         <mat-table *ngIf = "!this.isLoading"
         #table [dataSource]="financialTableData[0].financials" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="year">
          <mat-header-cell *matHeaderCellDef>YEAR</mat-header-cell>
          <mat-cell *matCellDef="let element">
              <mat-form-field appearance="legacy">
                <input type="number" matInput [(ngModel)]="element.year">
              </mat-form-field>
          </mat-cell>
        </ng-container>
        <!-- Name Column -->

        <ng-container matColumnDef="fiftyTwoWeekTarget">
          <mat-header-cell *matHeaderCellDef>Yearly Target</mat-header-cell>
          <mat-cell *matCellDef="let element">
              <mat-form-field appearance="legacy">
                <input type="number" matInput [(ngModel)]="element.fiftyTwoWeekTarget">
              </mat-form-field>
          </mat-cell>
        </ng-container>

        <!-- Weekly Target -->
        <!-- <ng-container matColumnDef="weeklyTarget">
          <mat-header-cell *matHeaderCellDef>Weekly Target</mat-header-cell>
          <mat-cell *matCellDef="let element">
              <mat-form-field appearance="legacy">
                <input type="number" matInput [(ngModel)]="element.weeklyTarget">
              </mat-form-field>
          </mat-cell>
        </ng-container> -->

        <!-- Symbol Column -->
        <!-- <ng-container matColumnDef="variableWeeklyTarget">
          <mat-header-cell *matHeaderCellDef>Weekly Variable Target</mat-header-cell>
          <mat-cell *matCellDef="let element">
              <mat-form-field appearance="legacy">
                <input type="number" matInput [(ngModel)]="element.variableWeeklyTarget">
              </mat-form-field>
          </mat-cell>
        </ng-container> -->

        <ng-container matColumnDef="jan">
          <mat-header-cell *matHeaderCellDef>January Invoices</mat-header-cell>
          <mat-cell *matCellDef="let element">
              <mat-form-field appearance="legacy">
                <input type="number" matInput [(ngModel)]="element.monthlyInvoiceValues[0].value">
              </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="feb">
          <mat-header-cell *matHeaderCellDef>February Invoices</mat-header-cell>
          <mat-cell *matCellDef="let element">
              <mat-form-field appearance="legacy">
                <input type="number" matInput [(ngModel)]="element.monthlyInvoiceValues[1].value">
              </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="mar">
          <mat-header-cell *matHeaderCellDef>March Invoices</mat-header-cell>
          <mat-cell *matCellDef="let element">
              <mat-form-field appearance="legacy">
                <input type="number" matInput [(ngModel)]="element.monthlyInvoiceValues[2].value">
              </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="apr">
          <mat-header-cell *matHeaderCellDef>April Invoices</mat-header-cell>
          <mat-cell *matCellDef="let element">
              <mat-form-field appearance="legacy">
                <input type="number" matInput [(ngModel)]="element.monthlyInvoiceValues[3].value">
              </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="may">
          <mat-header-cell *matHeaderCellDef>May Invoices</mat-header-cell>
          <mat-cell *matCellDef="let element">
              <mat-form-field appearance="legacy">
                <input type="number" matInput [(ngModel)]="element.monthlyInvoiceValues[4].value">
              </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="jun">
          <mat-header-cell *matHeaderCellDef>June Invoices</mat-header-cell>
          <mat-cell *matCellDef="let element">
              <mat-form-field appearance="legacy">
                <input type="number" matInput [(ngModel)]="element.monthlyInvoiceValues[5].value">
              </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="jul">
          <mat-header-cell *matHeaderCellDef>July Invoices</mat-header-cell>
          <mat-cell *matCellDef="let element">
              <mat-form-field appearance="legacy">
                <input type="number" matInput [(ngModel)]="element.monthlyInvoiceValues[6].value">
              </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="aug">
          <mat-header-cell *matHeaderCellDef>August Invoices</mat-header-cell>
          <mat-cell *matCellDef="let element">
              <mat-form-field appearance="legacy">
                <input type="number" matInput [(ngModel)]="element.monthlyInvoiceValues[7].value">
              </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sep">
          <mat-header-cell *matHeaderCellDef>September Invoices</mat-header-cell>
          <mat-cell *matCellDef="let element">
              <mat-form-field appearance="legacy">
                <input type="number" matInput [(ngModel)]="element.monthlyInvoiceValues[8].value">
              </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="oct">
          <mat-header-cell *matHeaderCellDef>October Invoices</mat-header-cell>
          <mat-cell *matCellDef="let element">
              <mat-form-field appearance="legacy">
                <input type="number" matInput [(ngModel)]="element.monthlyInvoiceValues[9].value">
              </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="nov">
          <mat-header-cell *matHeaderCellDef>November Invoices</mat-header-cell>
          <mat-cell *matCellDef="let element">
              <mat-form-field appearance="legacy">
                <input type="number" matInput [(ngModel)]="element.monthlyInvoiceValues[10].value">
              </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="dec">
          <mat-header-cell *matHeaderCellDef>December Invoices</mat-header-cell>
          <mat-cell *matCellDef="let element">
              <mat-form-field appearance="legacy">
                <input type="number" matInput [(ngModel)]="element.monthlyInvoiceValues[11].value">
              </mat-form-field>
          </mat-cell>
        </ng-container>

         <!-- Symbol Column -->

        <ng-container matColumnDef="ytdTargetInvoice">
          <mat-header-cell *matHeaderCellDef>YTD Invoice</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.monthlyInvoiceValues | sum: 'value' | currency}}
              <!-- <mat-form-field appearance="legacy">
                <input type="number" matInput [(ngModel)]="element.ytdTargetInvoice">
              </mat-form-field> -->
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row >
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row >
         </mat-table>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button (click)="addNewYearFinancials()">Add More Years</button>
      <button mat-raised-button (click)="saveFinancials(financialTableData)">Save</button>
    </mat-card-actions>
     </mat-card>
     </div>
  </mat-tab>
  <mat-tab label="Profile Settings">
    <div class="main-div">
      <mat-card>
       <mat-card-title>

       </mat-card-title>

     </mat-card>
     </div>
  </mat-tab>
</mat-tab-group>

