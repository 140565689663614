<div *ngIf= "actionType ===  'addTemplateTitle'">
    <mat-form-field class="example-full-width">
        <mat-label>TEMPLATE TITLE</mat-label>
        <input matInput placeholder="Please add a short title of your template." [(ngModel)]="this.title">
    </mat-form-field>
    <mat-dialog-actions>
        <button mat-button [disabled]="!this.title" (click)="doAction(this.title, '', '')" mat-flat-button color="primary">Save</button>
        <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button>
    </mat-dialog-actions>
</div>

    <mat-card *ngIf= "actionType ===  'personToApproveQuote'">
    <mat-form-field class="example-full-width">
            <mat-label>CHOOSE PERSON THAT IS AUTHORIZE TO APPROVE THE QUOTE</mat-label>
            <mat-select [(value)]="this.title" required>
                <mat-option value="Aaron Seutter">Aaron Seutter</mat-option>
                <mat-option value="Darryl Benn">Darryl Benn</mat-option>
                <mat-option value="Matthew Pennycook">Matthew Pennycook</mat-option>
                <mat-option value="Ron Procyk">Ron Procyk</mat-option>
                <mat-option value="Kyle Tadei">Kyle Tadei</mat-option>
            </mat-select>
        </mat-form-field>
    </mat-card>&nbsp;
    <mat-card *ngIf= "(actionType ===  'personToApproveQuote') || 
    (actionType ===  'approvalComplete') " >
        <h3>Include any of the following people</h3>
        <span class="example-list-section">
            <ul>
              <li *ngFor="let person of ccPersons">
                <mat-checkbox [(ngModel)]="person.completed"
                [disabled]="(person.name === this.title) || (person.name === 'Kyle Tadei') || (person.name === 'Nicole Dauvin')"  
                [checked] = "person.completed">
                  {{person.name}}
                </mat-checkbox>
              </li>
            </ul>
          </span>
    </mat-card>&nbsp;
    <mat-card *ngIf = "actionType !==  'addTemplateTitle'">
        <mat-form-field appearance="outline" class="example-full-width"
        >
            <mat-label>Add Notes To Email</mat-label>
            <textarea [(ngModel)]= "emailNotes" matInput></textarea>
          </mat-form-field>
    </mat-card>
      
    <mat-dialog-actions *ngIf= "(actionType ===  'personToApproveQuote')">
        <button mat-button [disabled]="!this.title" (click)="doAction(this.title, ccPersons, emailNotes)" mat-flat-button color="primary">Send</button>
        <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button>
    </mat-dialog-actions>
    <mat-dialog-actions *ngIf= "(actionType ===  'approvalComplete')">
        <button mat-button (click)="doAction(this.title, ccPersons, emailNotes)" mat-flat-button color="primary">Send</button>
        <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button>
    </mat-dialog-actions>
