import { Injectable, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { AngularFirestore} from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';

import { Customer } from '../models/customer.model';
import { User } from '../models/user.model';

declare function require(name:string);

var dayjs = require('dayjs');
var weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);
var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);
var weekday = require('dayjs/plugin/weekday');
dayjs.extend(weekday);

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  contactsChanged = new Subject<Customer[]>();
  usersChanged = new Subject<User[]>();
  availableContacts: Customer[] = [];
  availableUsers: User[] = [];
  users: User[];
  contacts: Customer[];
  companyTest: Customer[];
  staffUsersSubscription: Subscription;
  staffUserEmail: string;

  constructor(private db: AngularFirestore, private dbStaff: AngularFirestore) {}
  fetchStaffMembers() {
    this.dbStaff
    .collection('users', ref => { return ref
      .orderBy('displayName', 'asc');
      })
      .snapshotChanges()
      .pipe(map(docArray => {
        return docArray.map(doc => {
          return {
            uid: doc.payload.doc.id,
            email: doc.payload.doc.data()['email'],
            displayName: doc.payload.doc.data()['displayName'],
            photoURL: doc.payload.doc.data()['photoURL'],
            emailVerified: doc.payload.doc.data()['emailVerified'],
            role: doc.payload.doc.data()['role'],
            type: doc.payload.doc.data()['type'],
            permissions: doc.payload.doc.data()['permissions'],
          };
        });
      }))
      .subscribe((user: User[]) => {
        this.availableUsers = user;
        this.usersChanged.next([...this.availableUsers]);
      });
  }
  fetchAvailableContacts() {
    this.db
    .collection('contacts', ref => { return ref
      .orderBy('name', 'asc');
      })
      .snapshotChanges()
      .pipe(map(docArray => {
        return docArray.map(doc => {
          return {
            type: doc.payload.doc.data()['type'],
            id: doc.payload.doc.id,
            name: doc.payload.doc.data()['name'],
            clientId: doc.payload.doc.data()['clientId'],
            title: doc.payload.doc.data()['title'],
            firstName: doc.payload.doc.data()['firstName'],
            lastName: doc.payload.doc.data()['lastName'],
            jobTitle: doc.payload.doc.data()['jobTitle'],
            organization: doc.payload.doc.data()['organization'],
            created: doc.payload.doc.data()['created'],
            updated: doc.payload.doc.data()['updated'],
            lastContacted: doc.payload.doc.data()['lastContacted'],
            email: doc.payload.doc.data()['email'],
            emailAddress: doc.payload.doc.data()['emailAddress'],
            homeEmail: doc.payload.doc.data()['homeEmail'],
            workEmail: doc.payload.doc.data()['workEmail'],
            phoneNumber: doc.payload.doc.data()['phoneNumber'],
            homePhone: doc.payload.doc.data()['homePhone'],
            workPhone: doc.payload.doc.data()['workPhone'],
            mobilePhone: doc.payload.doc.data()['mobilePhone'],
            faxPhone: doc.payload.doc.data()['faxPhone'],
            directPhone: doc.payload.doc.data()['directPhone'],
            addressStreet: doc.payload.doc.data()['addressStreet'],
            city: doc.payload.doc.data()['city'],
            state: doc.payload.doc.data()['state'],
            postcode: doc.payload.doc.data()['postcode'],
            country: doc.payload.doc.data()['country'],
            homeAddressStreet: doc.payload.doc.data()['homeAddressStreet'],
            homeCity: doc.payload.doc.data()['homeCity'],
            homeState: doc.payload.doc.data()['homeState'],
            homePostcode: doc.payload.doc.data()['homePostcode'],
            homeCountry: doc.payload.doc.data()['homeCountry'],
            postalAddressStreet: doc.payload.doc.data()['postalAddressStreet'],
            postalCity: doc.payload.doc.data()['postalCity'],
            postalState: doc.payload.doc.data()['postalState'],
            postalPostcode: doc.payload.doc.data()['postalPostcode'],
            postalCountry: doc.payload.doc.data()['postalCountry'],
            officeAddressStreet: doc.payload.doc.data()['officeAddressStreet'],
            officeCity: doc.payload.doc.data()['officeCity'],
            officeState: doc.payload.doc.data()['officeState'],
            officePostcode: doc.payload.doc.data()['officePostcode'],
            officeCountry: doc.payload.doc.data()['officeCountryd'],
            billingAddressStreet: doc.payload.doc.data()['billingAddressStreet'],
            billingCity: doc.payload.doc.data()['billingCity'],
            billingState: doc.payload.doc.data()['billingState'],
            billingPostcode: doc.payload.doc.data()['billingPostcode'],
            billingCountry: doc.payload.doc.data()['billingCountry'],
            shippingAddressStreet: doc.payload.doc.data()['shippingAddressStreet'],
            shippingCity: doc.payload.doc.data()['shippingCity'],
            shippingState: doc.payload.doc.data()['shippingState'],
            shippingPostcode: doc.payload.doc.data()['shippingPostcode'],
            shippingCountry: doc.payload.doc.data()['shippingCountry'],
            tags: doc.payload.doc.data()['tags'],
            website: doc.payload.doc.data()['website'],
            homeWebsite: doc.payload.doc.data()['homeWebsite'],
            workWebsite: doc.payload.doc.data()['workWebsite'],
            facebook: doc.payload.doc.data()['facebook'],
            twitter: doc.payload.doc.data()['twitter'],
            skype: doc.payload.doc.data()['skype'],
            linkedin: doc.payload.doc.data()['linkedin'],
            youtube: doc.payload.doc.data()['youtube'],
            instagram: doc.payload.doc.data()['instagram'],
            organizationAddressStreet: doc.payload.doc.data()['organizationAddressStreet'],
            organizationCity: doc.payload.doc.data()['organizationCity'],
            organizationState: doc.payload.doc.data()['organizationState'],
            organizationPostcode: doc.payload.doc.data()['organizationPostcode'],
            organizationCountry: doc.payload.doc.data()['organizationCountry'],
            mailingAddressStreet: doc.payload.doc.data()['mailingAddressStreet'],
            mailingCity: doc.payload.doc.data()['imailingCityd'],
            mailingState: doc.payload.doc.data()['mailingState'],
            mailingPostcode: doc.payload.doc.data()['mailingPostcode'],
            mailingCountry: doc.payload.doc.data()['mailingCountry'],
          };
        });
      }))
      .subscribe((contacts: Customer[]) => {
        this.availableContacts = contacts;
        this.contactsChanged.next([...this.availableContacts]);
        // this.companyTest = this.availableContacts;
      });
  }

  addNewAndUpdateClientData( data ) {
    if (data.action === 'Add Company') {
      data.type = 'Organization';
      delete data.action;
      data.updated = new Date();
      data.created = new Date();
      this.db.collection('contacts').add(data);
      this.contactsChanged.next([...this.availableContacts]);
    } else if ( data.action === 'Add Person' ) {
      data.type = 'Person';
      data.name = data.firstName + ' ' + data.lastName;
      delete data.action;
      data.updated = new Date();
      data.created = new Date();
      this.db.collection('contacts').add(data);
      this.contactsChanged.next([...this.availableContacts]);
    } else if ( data.action === 'delete' ) {
      // *** Dialog Delete Action *** //
      this.db
        .collection('contacts')
        .doc(data.id)
        .delete();
      this.contactsChanged.next([...this.availableContacts]);
    } else if (data.action === 'Update') {
      // updateClientData
      this.checkEntries(data);
      this.db.collection('contacts')
        .doc(data.id)
        .set(data, { merge: true });
      this.contactsChanged.next([...this.availableContacts]);
    } else if (data.action === 'UpdatePerson') {
      data.name = data.firstName + ' ' + data.lastName;
      this.checkEntries(data);
      this.db.collection('contacts')
        .doc(data.id)
        .set(data, { merge: true });
      this.contactsChanged.next([...this.availableContacts]);
    }
  }


  // ***Check if fields are undefined before saving to the database ***
  private checkEntries(data) {
    // delete data.id;
    delete data.action;
    data.updated = dayjs().format('YYYY MMMM DD');

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        (data[key] !== undefined ) ? data[key]  : delete data[key];
      }
    }
    return data;
  }

}
