<mat-toolbar class="header-height" color="primary" *ngIf = "authService.isLoggedIn == true">
  <div>
    <button mat-icon-button (click)="onToggleSidenav()">
      <mat-icon style="color:#fff">menu</mat-icon>
    </button>
  </div>
  <div>
    <a routerLink="/dashboard">CIS PROJECTS</a>
  </div>
  <div fxFlex fxLayout fxLayoutAlign="flex-end" fxHide.xs>
    <ul fxLayout fxLayoutGap="10px" class="navigation-items">
      <!-- <li> -->
        <!-- <a routerLink="/openclosed">
          <mat-icon aria-hidden="false" aria-label="Example home icon">bar_chart
          </mat-icon>
        </a>
      </li>
      <li>
        <a routerLink="/login">
          <mat-icon aria-hidden="false" aria-label="Example home icon">settings
          </mat-icon>
        </a>
      </li>
      <li>
        <a routerLink="/training">
          <mat-icon aria-hidden="false" aria-label="Example home icon">account_circle
          </mat-icon>
        </a>
      </li> -->
      <li>
          <div *ngIf="authService.userData as user">
              <h3>Welcome, {{ user.displayName }}</h3>
          </div>
      </li>
      <li>
        <a (click)="authService.SignOut()"><mat-icon class ="icon" aria-hidden="false" aria-label="Exit Icon" >exit_to_app
          </mat-icon></a>
      </li>
    </ul>
  </div>
</mat-toolbar>
