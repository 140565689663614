<mat-table #table [dataSource]="localData" *ngIf="actionType !== 'Delete'" >
    <ng-container matColumnDef="item" class="project">
      <mat-header-cell *matHeaderCellDef >Item</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <input matInput [(ngModel)]="element.item" placeholder="Item name">
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description" class="project">
      <mat-header-cell *matHeaderCellDef >Description</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <input matInput [(ngModel)]="element.description" placeholder="Give short description">
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="unit" class="project">
      <mat-header-cell *matHeaderCellDef >Unit</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <input matInput [(ngModel)]="element.unit" placeholder="For example foot (ft)">
    </mat-cell>
    </ng-container>

    <ng-container matColumnDef="price" class="project">
      <mat-header-cell *matHeaderCellDef >Price</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <input matInput [(ngModel)]="element.price">
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status" class="project">
      <mat-header-cell *matHeaderCellDef >Status</mat-header-cell>
      <mat-cell *matCellDef="let element">Updated on {{ element.dateUpdated | date}} by {{element.updatedBy }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="type" class="project">
      <mat-header-cell *matHeaderCellDef >Type</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-select required [(value)]="element.type">
          <mat-option>None</mat-option>
          <mat-option value="Channel Letters">Channel Letters</mat-option>
          <mat-option value="Electrical">Electrical</mat-option>
          <mat-option value="Graphics">Graphics</mat-option>
          <mat-option value="LED and Lighting">LED and Lighting</mat-option>
          <mat-option value="Sheet Stock">Sheet Stock</mat-option>
          <mat-option value="Steel and Extrusions">Steel and Extrusions</mat-option>
          <mat-option value="Labour">Labour</mat-option>
        </mat-select>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedPricelist; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedPricelist;"></mat-row>
</mat-table>
  <div *ngIf = "actionType === 'Delete'">
    <h2>Are you sure you want to delete?</h2>
  </div>
  <mat-dialog-actions>
    <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button>
    <button mat-button (click)="doAction(localData)" mat-flat-button color="accent">{{actionType}}</button>
  </mat-dialog-actions>
