import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSortModule } from '@angular/material/sort';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OrdersComponent} from './orders/orders.component';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';
import { HeaderComponent } from './navigation/header/header.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';

// import { AngularFirestoreModule} from '@angular/fire/compat';
import { AngularFireAuth, AngularFireAuthModule, PERSISTENCE } from '@angular/fire/compat/auth';
import { MaterialModule } from './material.module'
import { OrdersService } from './service/orders.service';
import { CustomerService } from './service/customer.service';
import { AddNewLeadComponent } from './orders/add-new-lead/add-new-lead.component';
import { CustomersComponent } from './customers/customers.component';
import { ErrorStateMatcher } from '@angular/material/core';
import { ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { environment } from '../environments/environment';
import { ContactsfilterPipe } from './pipes/contactsfilter.pipe';
import { OpenclosedComponent } from './orders/openclosed/openclosed.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthService } from './service/auth.service';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './auth/verify-email/verify-email.component';
import { CurrencyPipe } from '@angular/common';
import { TaskComponent } from './orders/task/task.component';
import { NegativeDaysPipe, FilterDateTaskDuePipe, FilterUserPipe, SumPipe, FilterPipe, SumQuotesPipe,
   MilestonePipe, FilterTaskPipe, FilterActivePipe, InstallDetailPipe, ActionTypePipe,
   FilterPricelIistPipe, MomentPipe, MilestoneTitlePipe, InstallValidPipe, OverdueLeadsPipe, EstimateItemsExcludePipe,
   StaffPicPipe } from './pipes/pipes.pipe';
import { TaskDialogueComponent } from './orders/task/task-dialogue/task-dialogue.component';
import { InstallcalendarComponent } from './orders/installcalendar/installcalendar.component';
import { InstallDatePipe } from './pipes/install-date.pipe';
import { CountOrdersPipe } from './pipes/pipes.pipe';
import { AdminComponent } from './admin/admin.component';
import { MatPaginatorModule, MatPaginator } from '@angular/material/paginator';
import { EstimateComponent } from './estimate/estimate.component';
import { EstimateDialogueComponent } from './estimate/estimate-dialogue/estimate-dialogue.component';
import { HttpClientModule } from '@angular/common/http';
import { CustomerDashboardComponent } from './customers/customer-dashboard/customer-dashboard.component';
import { InputDialogueComponent } from './service/input-dialogue/input-dialogue.component';
import { PricelistComponent } from './estimate/pricelist/pricelist.component';
import { PricelistDialogueComponent } from './estimate/pricelist/pricelist-dialogue.component'
import { MatTabsModule } from '@angular/material/tabs';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { MatTableExporterModule } from 'mat-table-exporter';
import { EmailsService } from './service/emails.service';
import { PermissionsComponent } from './admin/permissions/permissions.component';
import { EstimateCloneDialogueComponent } from './estimate/estimate-clone-dialogue/estimate-clone-dialogue.component'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { DeadLeadsComponent } from './orders/dead-leads/dead-leads.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CheckForUpdateService } from './service/check-for-update.service';
import { ToasterComponent } from './toaster/toaster.component';
import { ReportsComponent } from './reports/reports.component';
import { ChangelogDialogueComponent } from './dialogue/changelog-dialogue/changelog-dialogue.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment';
import { UnauthorizedComponent } from './auth/login/unauthorized/unauthorized.component'; // optional, provides moment-style pipes for date formatting
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';

@NgModule({
    declarations: [
        AppComponent,
        OrdersComponent,
        SidenavListComponent,
        HeaderComponent,
        DashboardComponent,
        AddNewLeadComponent,
        CustomersComponent,
        ContactsfilterPipe,
        SumPipe,
        FilterPipe,
        OpenclosedComponent,
        LoginComponent,
        CustomersComponent,
        SignUpComponent,
        ForgotPasswordComponent,
        VerifyEmailComponent,
        TaskComponent,
        FilterUserPipe,
        MilestonePipe,
        FilterTaskPipe,
        FilterActivePipe,
        FilterDateTaskDuePipe,
        NegativeDaysPipe,
        TaskDialogueComponent,
        InstallcalendarComponent,
        InstallDatePipe,
        CountOrdersPipe,
        InstallDetailPipe,
        ActionTypePipe,
        AdminComponent,
        EstimateComponent,
        FilterPricelIistPipe,
        StaffPicPipe,
        EstimateDialogueComponent,
        SumQuotesPipe,
        CustomerDashboardComponent,
        MomentPipe,
        EstimateItemsExcludePipe,
        InputDialogueComponent,
        PricelistComponent,
        PricelistDialogueComponent,
        MilestoneTitlePipe,
        InstallValidPipe,
        OverdueLeadsPipe,
        PermissionsComponent,
        EstimateCloneDialogueComponent,
        DeadLeadsComponent,
        ToasterComponent,
        ReportsComponent,
        ChangelogDialogueComponent,
        UnauthorizedComponent,
    ],
    exports: [
        DashboardComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MaterialModule,
        AppRoutingModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireAuthModule,
        MatSortModule,
        MatPaginatorModule,
        HttpClientModule,
        MatTabsModule,
        AngularFireAnalyticsModule,
        MatTableExporterModule,
        NgxSkeletonLoaderModule,
        // AngularFirestoreModule.enablePersistence(),
        PickerModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        GoogleChartsModule,
        MomentModule,
        NgIdleKeepaliveModule.forRoot()
    ],
    providers: [OrdersService, CustomerService, AuthService, CurrencyPipe, EmailsService, CheckForUpdateService,
      { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
      { provide: PERSISTENCE, useValue: 'local' },
      { provide: FIREBASE_OPTIONS, useValue: environment.firebase }

     ],
    bootstrap: [AppComponent]
})
export class AppModule { }
