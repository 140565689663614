import { Component, OnDestroy, Input, OnChanges, SimpleChanges, ViewChild, ViewChildren, QueryList, AfterViewInit, ElementRef, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragEnter, CdkDragExit, CdkDragEnd} from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { OrdersService } from '../../service/orders.service';
import { CustomerService } from 'src/app/service/customer.service';
import { AddNewLeadComponent } from '../add-new-lead/add-new-lead.component';
import { AngularFirestore} from '@angular/fire/compat/firestore';
import { Observable, Subscription, concat } from 'rxjs';
import { AuthService } from '../../service/auth.service';
import { Orders } from '../../models/orders.model';
import { TaskDialogueComponent } from '../task/task-dialogue/task-dialogue.component';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { EmailsService } from 'src/app/service/emails.service';
import { User } from 'src/app/models/user.model';
declare function require(name:string);

var auth = require('firebase/auth');

var dayjs = require('dayjs');
var weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);
var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);
var weekday = require('dayjs/plugin/weekday');
dayjs.extend(weekday);


@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css']
})
export class TaskComponent implements OnInit ,OnDestroy, AfterViewInit{
  displayedTaskColumns: string[] = [
    'assigned','contactName','details', 'status', 'task'];
  displayedNotesColumns: string[] = [
      'person', 'notes'];

    value = 'Clear me';
    company = [];
    dbOpen = [];
    dbWon = [];
    dbOpenAll = [];
    dbWonAll = [];

    isAuth = false;
    firstName: string;
    firstNameLogin: string;
    newArr: any;
    iconType = 'edit';
    isLoading = true;

  taskSubscriptionOpen : Subscription;
  taskSubscriptionClosed : Subscription;
  lostOrdersSubscription : Subscription;
  arrTaskOpen = [];
  arrTaskClosed = [];
  arrNotesClose = [];
  arrNotesOpen = [];
  arrNotesLost = [];
  arrTaskClosedStatus= [];
  deepClone = [];
  dbTask = new MatTableDataSource<Orders>();
  dbNotes = new MatTableDataSource<Orders>();
  activeUserSelectedTask : boolean;
  userHasTaskAssignedNewLeads : boolean;
  userHasTaskAssignedLeadsWon : boolean;
  nameSurname: string;
  staffUsersSubscription: Subscription;
  dbStaffUsersTask: User[];
  userWonTaskComplete: Boolean;
  userOpenTaskComplete: Boolean;
  searchCompany = '';
  searchPerson = '';
  searchShow = true;
  searchAll = '';
  notesFilterValue: string;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChildren(MatTable) table !: QueryList<MatTable<any>>;
  // @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();


  // @Input()
  // userSelected: string;

  // @ViewChild('toto', {static: true}) mySelect: ElementRef;


  // @Input()
  // showtaskCompleted: boolean;
  currentUserLogin: any;
  selectedName: any;
  showCompleted: boolean;
  showTypeofTNotes: string;
  showUserNamme: any;


  constructor(public dialog: MatDialog, private ordersService: OrdersService, private customerService: CustomerService,
    private db: AngularFirestore , public authService: AuthService, private emailServices: EmailsService,
      analytics: AngularFireAnalytics) {
        this.currentUserLogin = JSON.parse(localStorage.getItem('user'));
        const fullNameLogin = this.currentUserLogin.displayName.split(' ');
        this.firstNameLogin = fullNameLogin[0];
        analytics.logEvent('Task Being Used', { userLogin:this.firstNameLogin });
        this.nameSurname =  fullNameLogin[1];
        this.selectedName = this.currentUserLogin.displayName === 'Matthew Pennycook'? 'Matt Pennycook': this.currentUserLogin.displayName;
        this.showCompleted = false;
        this.notesFilterValue = 'All'
       }


  ngOnInit() {
    /**
     * * Get current user and filter task for that user
     * * Options includes filter by ALL, any user and completed task
     * TODO: Make sure all milestones get filtered: Perhaps more tables in tabs
     */

    this.staffUsersSubscription = this.customerService.usersChanged.subscribe(
      (users: User[]) => {
        this.dbStaffUsersTask = JSON.parse(JSON.stringify(users));
        this.dbStaffUsersTask = this.dbStaffUsersTask.filter( t => 'cityimagesigns.com'
          === (t.email.substring(t.email.lastIndexOf( '@' ) + 1)));

        this.dbStaffUsersTask.unshift({
          uid: '',
          email: '',
          displayName: 'All CIS',
          photoURL: '',
          emailVerified: true,
          role: '',
          type: '',
          permissions: []} );
      }
    );
    this.fetchSubscribeDate();

    this.dbTask.paginator = this.paginator.toArray()[0];
    this.dbNotes.paginator = this.paginator.toArray()[1];
    // this.ordersService.fetchOrdersWon();
    // this.ordersService.fetchAvailableOrders('created'); // ! Remove created, instead sort by due date.
    this.customerService.fetchStaffMembers();
    this.dbTask.sort = this.sort;
      }
    ngAfterViewInit() {
      this.dbTask.paginator = this.paginator.toArray()[1];
      this.dbNotes.paginator = this.paginator.toArray()[0];
    }

  onChangeTaskNameSelected() {
    this.isLoading = true;
    this.fetchSubscribeDate();
    }

  fetchSubscribeDate() {
    this.ordersService.fetchAvailableOrders('created'); // ! Remove created, instead sort by due date.
    this.ordersService.fetchOrdersWon();
    this.ordersService.fetchOrdersLost();

    const nameSurname = this.selectedName;
    // this.nameSurname = nameSurname;
    const fullNameSelected = nameSurname.split(' ');
    this.firstName = fullNameSelected[0];


    this.taskSubscriptionOpen = this.ordersService.openCloseChanged.subscribe(
      (newTask: Orders[]) => {
        this.arrTaskOpen = JSON.parse(JSON.stringify(newTask));
        this.arrNotesOpen = this.getNotes(JSON.parse(JSON.stringify(newTask)));
        // this.arrNotesOpen = this.notesFilterValue === 'All'? this.arrNotesOpen :this.arrNotesOpen.filter(t =>
        //   {if(this.notesFilterValue === 'notes'){
        //     t.type === undefined
        //   } else {t.type === this.notesFilterValue}
        // });
        this.dbNotes.data = (this.arrNotesOpen).concat(this.arrNotesClose).concat(this.arrNotesLost);
        this.dbNotes.data = this.sortArrayByDates(this.dbNotes.data);
        this.dbOpen = JSON.parse(JSON.stringify(newTask));
        this.dbOpen = this.dbOpen.filter(el =>
          ((el.task !== undefined) && (el.taskCount > 0)) ||
          ((el.task !== undefined) && (el.taskCount !== undefined)));
        this.userOpenTaskComplete = this.userTaskAllComplete(this.dbTask.data, this.selectedName);
        if (this.userHasTask(this.dbTask.data, this.selectedName ) && !this.userOpenTaskComplete) {
            this.userHasTaskAssignedNewLeads = true;
            this.dbTask.data = this.selectedName === 'All CIS' ? this.dbTask.data :
             this.removeUsersNotFiltered(this.dbTask.data, this.selectedName);
            this.dbTask.data = this.showCompleted === false ?
            this.removeTaskStatusFalse(this.dbTask.data) : this.dbTask.data;
            this.dbTask.data = this.removeEmptyTaskRows(this.dbTask.data);
            this.isLoading = false;
            this.table.first.renderRows();
            this.table.last.renderRows();
          } else if (this.userHasTask(this.dbTask.data, this.selectedName ) && this.userOpenTaskComplete && this.showCompleted) {
            this.userHasTaskAssignedNewLeads = true;
            this.dbTask.data = this.selectedName === 'All CIS' ? this.dbTask.data :
             this.removeUsersNotFiltered(this.dbTask.data, this.selectedName);
            this.dbTask.data = this.removeEmptyTaskRows(this.dbTask.data);
          this.isLoading = false;
            this.table.first.renderRows();
            this.table.last.renderRows();
            } else {
              this.dbTask.data = [];
              this.userHasTaskAssignedNewLeads = false;
              this.isLoading = false;
              }
      }
    ),error => {
      window.alert(error);
      this.authService.SignOut();
      };

    this.taskSubscriptionClosed = this.ordersService.wonOrdersChanged.subscribe(
      (newTaskWon: Orders[]) => {
        this.dbOpen = this.dbOpen;
        this.arrTaskClosed = JSON.parse(JSON.stringify(newTaskWon));
        this.arrNotesClose = this.getNotes((JSON.parse(JSON.stringify(newTaskWon))));
        // this.arrNotesClose = this.notesFilterValue === 'All'? this.arrNotesClose :this.arrNotesClose.filter(t =>
        //   {if(this.notesFilterValue == 'notes'){
        //     console.log(t.type)
        //     t.type === undefined
        //   } else {t.type === this.notesFilterValue}
        // });
          this.arrNotesClose.filter(t => t.type === this.notesFilterValue);
        this.dbNotes.data = (this.arrNotesOpen).concat(this.arrNotesClose).concat(this.arrNotesLost);
        this.dbNotes.data = this.sortArrayByDates(this.dbNotes.data);
        this.dbWon = JSON.parse(JSON.stringify(newTaskWon));
        this.dbWon = this.dbWon.filter(el =>
          ((el.task !== undefined) && (el.taskCount > 0)) ||
          ((el.task !== undefined) && (el.taskCount !== undefined)));
        this.dbTask.data = (this.dbOpen).concat(this.dbWon);
        this.userOpenTaskComplete = this.userTaskAllComplete(this.dbTask.data, this.selectedName);
        if (this.userHasTask(this.dbTask.data, this.selectedName ) && !this.userOpenTaskComplete) {
          this.userHasTaskAssignedNewLeads = true;
          this.dbTask.data = this.selectedName === 'All CIS'? this.dbTask.data :
           this.removeUsersNotFiltered(this.dbTask.data, this.selectedName);
          this.dbTask.data = this.showCompleted === false?
          this.removeTaskStatusFalse(this.dbTask.data): this.dbTask.data;
          this.dbTask.data = this.removeEmptyTaskRows(this.dbTask.data);
          this.isLoading = false;
          this.table.first.renderRows();
          this.table.last.renderRows();
        } else if (this.userHasTask(this.dbTask.data, this.selectedName ) && this.userOpenTaskComplete && this.showCompleted) {
          this.userHasTaskAssignedNewLeads = true;
          this.dbTask.data = this.selectedName === 'All CIS'? this.dbTask.data :
           this.removeUsersNotFiltered(this.dbTask.data, this.selectedName);
          this.dbTask.data = this.removeEmptyTaskRows(this.dbTask.data);
          this.isLoading = false;
          this.table.first.renderRows();
          this.table.last.renderRows();
          } else {
            this.dbTask.data = [];
            this.userHasTaskAssignedNewLeads = false;
            this.isLoading = false;
            }
          }
    );

    this.lostOrdersSubscription = this.ordersService.lostOrdersChanged.subscribe(
      (lostOrders: Orders[]) => {
        this.arrNotesLost = this.getNotes(JSON.parse(JSON.stringify(lostOrders)));
        // this.arrNotesLost =  this.notesFilterValue === 'All' ? this.arrNotesLost :
        this.arrNotesLost.filter(t => t.type === this.notesFilterValue);
        this.dbNotes.data = (this.arrNotesOpen).concat(this.arrNotesClose).concat(this.arrNotesLost);
        this.dbNotes.data = this.sortArrayByDates(this.dbNotes.data);
        this.isLoading = false;
      }
    );

    this.dbTask.paginator = this.paginator.toArray()[0];
    this.dbNotes.paginator = this.paginator.toArray()[1];
  }


  public openDialog(action, obj, index) {
    let arr = [];
    arr = this.arrTaskOpen.some(a => a.id === obj.id)
      ? this.arrTaskOpen.filter(t => t.id === obj.id) :
      this.arrTaskClosed.filter(t => t.id === obj.id);
    Object.assign(arr[0], {action: action});
    const dialogRef = this.dialog.open(AddNewLeadComponent, {
      data: arr[0]
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Add') {
        this.ordersService.addNewOrderData(result.data, this.currentUserLogin);
      } else if (result.event === 'Update') {
        this.ordersService.updateOrder(result.data, this.currentUserLogin);
        this.fetchSubscribeDate();
      } else if (result.event === 'Save Task') {
        this.ordersService.updateOrder(result.data, this.currentUserLogin);
      } else if (result.event === 'Delete') {
        this.ordersService.deleteOrder(result.data, null, this.currentUserLogin);
      }
    });
  }


  getNotes(obj) {
    const arrObj = [];
    let arr = obj.filter(t => t.meetingMinutesDetail !== undefined);
    arr = arr.filter( t => t.milestone !== 'CIS');
    for (const key in arr) {
      arr[key].meetingMinutesDetail.forEach( t =>
        { t.projectName = arr[key].opportunityName;
          /* Change the format 'yyyy Month dd' to Date Object*/
          let dateConvert = (dayjs(t.dateUpdated, 'YYYY-MM-DD').isValid()=== false)&&(typeof t.dateUpdated)==='string'? t.dateUpdated.split(' '): t.dateUpdated;
          t.dateFormated = dayjs(t.dateUpdated, 'YYYY-MM-DD').isValid()=== false ?(dayjs(dateConvert[1] + ' ' + dateConvert[2] + ' ' + dateConvert[0]).format()):
          t.dateUpdated;
          t.contactName = arr[key].contactName;
          arrObj.push(t);
        });
    }
    return arrObj;
  }

  sortArrayByDates(arrObj) {
    /* SORT By Date*/
    const sortedActivities =  arrObj.slice().sort((a, b) => (dayjs(a.dateFormated).isAfter(dayjs(b.dateFormated)) ? -1 : 1));
    return sortedActivities;
  }

  openTaskDialog(action, obj, index) {
    let arr = [];
    arr = this.arrTaskOpen.some(a => a.id === obj.id)
      ? this.arrTaskOpen.filter(t => t.id === obj.id) :
      this.arrTaskClosed.filter(t => t.id === obj.id);
    Object.assign(arr[0], {action: action});
    const dialogRef = this.dialog.open(TaskDialogueComponent, {
      width:  (arr[0].action === 'Update' || arr[0].action === 'Add') ? 'auto' : (arr[0].action === 'Save Task') ? '90vw' : '800px',
      data: arr[0]
    });
    dialogRef.afterClosed().subscribe(result => {
      if ((result === undefined) || (result === {event: 'Cancel'})) {
      } else if (result.event === 'Update') {
        this.ordersService.updateOrder(result.data, this.currentUserLogin);
        this.fetchSubscribeDate();
      } else if (result.event === 'Save Task') {
        const filterTask = result.data.task.filter(t => t.taskStatus === true);
        if (filterTask.length > obj.task.length) {
          this.emailServices.sendTaskEmail((filterTask.length - obj.task.length), result.data);
        }
        this.ordersService.updateOrder(result.data, this.currentUserLogin);
        this.fetchSubscribeDate();
        this.table.last.renderRows();
        this.table.first.renderRows();
      } else if (result.event === 'Delete') {
        this.ordersService.deleteOrder(result.data, null, this.currentUserLogin);
      } else if (result.event === 'Add') {
        this.ordersService.addNewOrderData(result.data, this.currentUserLogin);
      }
    });
  }


  // TODO Remove delete and use splice so that empty arrays not vissible.
  public userHasTask(obj, user) {
    if( user === 'All CIS') {
      return true
    } else {
      for (let k = 0; k < obj.length; k++) {
          for (let i = 0; i < obj[k].task.length; i++) {
              if ((obj[k].task[i].taskAssigned === user)){
                return true;
              }
          }
      }
    }
    return false;
  }

  private userTaskAllComplete(obj, user) {
    if( user === 'All CIS') {
      return false;
    } else {
      for (let k = 0; k < obj.length; k++) {
          for (let i = 0; i < obj[k].task.length; i++) {
              if ((obj[k].task.some( t => ((t.taskStatus === true) && (t.taskAssigned === user)))) ){
                return false;
              }
          }
      }
      return true;
    }
  }

 // TODO Remove delete and use splice so that empty arrays not vissible.
  public removeUsersNotFiltered(obj, nameSur) {
    var arr: any = [];
    for (let k = 0; k < obj.length; k++) {
      // if(obj.hasOwnProperty(prop)){
        arr = obj[k].task.filter( t => t.taskAssigned === nameSur)
          // for (let i = 0; i < obj[k].task.length; i++) {
          //     if ( (obj[k].task[i].taskAssigned !== nameSur)){
          //       obj[k].task.splice(i, 1);
          //       i--;
          //     }
          // }
        // }
        obj[k].task = arr;
    }
    return obj;
  }

  public removeTaskStatusFalse(obj) {
    var arr: any = [];
    for (let k = 0; k < obj.length; k++) {
      // if(obj.hasOwnProperty(prop)){
          for (let i = 0; i < obj[k].task.length; i++) {
            // if(obj[prop].task.hasOwnProperty(i)){
              if (obj[k].task[i].taskStatus === false){
                // var removeIndex = obj[prop].task.map(function(item) { return item.taskAssigned; }).indexOf(!user);
                obj[k].task.splice(i, 1);
                i--;
              }
          }
    }
    return obj;
  }

  public removeEmptyTaskRows(obj) {
    let arr = this.arrayRemove(obj, 0);
    return arr;
  }

  arrayRemove(arr, value) {
    return arr.filter(function(ele){
        return Object.keys(ele.task).length !== 0;
    });
  }
  doFilter(filterValue: string) {
    this.dbNotes.filter = filterValue.trim().toLowerCase();

  }

  clearFilters() {
    this.dbNotes.filter = '';
  }
  ngOnDestroy() {
    this.taskSubscriptionOpen.unsubscribe();
    this.taskSubscriptionClosed.unsubscribe();
    this.staffUsersSubscription.unsubscribe();
  }
}
