import { Component, OnInit, Pipe, PipeTransform, EventEmitter, ViewChild, AfterViewInit, OnDestroy, ViewChildren, QueryList  } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragEnter, CdkDragExit, CdkDragEnd} from '@angular/cdk/drag-drop';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OrdersService } from '../../service/orders.service';
import { CustomerService } from 'src/app/service/customer.service';
import { AngularFirestore} from '@angular/fire/compat/firestore';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../service/auth.service';
import { Customer } from '../../models/customer.model';
import { CustomersComponent } from '../customers.component';


@Component({
  selector: 'app-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.css']
})
export class CustomerDashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  displayedColumns: string[] = [
  'name', 'type', 'updated'];

  displayedPersonColumns: string[] = [
    'name', 'company', 'id', 'updated', 'clientId'];

  contactsSubscription: Subscription;
  contacts = new MatTableDataSource<Customer>();
  contactperson = new MatTableDataSource<Customer>();
  isLoading = true;
  searchCompany = '';
  searchPerson = '';
  searchShow = true;
  searchAll = '';

  @ViewChild('table1', { read: MatSort, static: false }) sort: MatSort;
  @ViewChild('table2', { read: MatSort, static: false }) sortPerson: MatSort;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();


  constructor(
    private customerService: CustomerService,
    public dialog: MatDialog, private ordersService: OrdersService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.contactsSubscription = this.customerService.contactsChanged.subscribe(
      (customer: Customer[]) => {
        this.contacts.data = customer.filter(value => value.type === 'Organization');
        this.contactperson.data = customer.filter(value => value.type === 'Person');
        this.isLoading = false;
      });
    this.customerService.fetchAvailableContacts();

    this.contacts.sort = this.sort;
  }

  ngAfterViewInit() {
    this.contacts.paginator = this.paginator.toArray()[0];
    this.contacts.sort = this.sort;
    this.contactperson.paginator = this.paginator.toArray()[1];
    this.contactperson.sort = this.sortPerson;
  }

  contactDialog(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(CustomersComponent, {
      width: 'auto',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      this.customerService.addNewAndUpdateClientData(result.data);
    });
  }

  doFilter(filterValue: string, milestone: string) {
    this.contacts.filter = filterValue.trim().toLowerCase();
    this.contactperson.filter = filterValue.trim().toLowerCase();

  }

  clearFilters(milestone: any) {
    // milestone.filter = '';
    this.contacts.filter = '';
    this.contactperson.filter = '';
  }

  ngOnDestroy() {
    this.contactsSubscription.unsubscribe();
  }

}
