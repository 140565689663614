<div class="main">
  <mat-card>
    <mat-card-title>
      MENU
    </mat-card-title>
  </mat-card>
  <mat-nav-list role="list" *ngIf = "authService.isLoggedIn == true">
    <!-- <mat-list-item>
      <a routerLink="/profile" (click)="onClose()" *ngIf = "authService.isLoggedIn == true">
        <mat-icon>face</mat-icon>
        <span class="nav-caption">Profile</span>
      </a>
    </mat-list-item> -->
    <mat-list-item>
      <a *ngIf = "authService.isLoggedIn == true" routerLink="/dashboard"  (click)="onClose()">
        <mat-icon class="icon">home
        </mat-icon>
        <span class="nav-caption">&nbsp; Home</span>
      </a>
    </mat-list-item>
    <mat-list-item>
      <a *ngIf = "authService.isLoggedIn == true" routerLink="/completed"  (click)="onClose()">
        <mat-icon class="icon">done_all
        </mat-icon>
        <span class="nav-caption">&nbsp; Completed Projects</span>
      </a>
    </mat-list-item>
    <mat-list-item>
      <a *ngIf = "authService.isLoggedIn == true" (click)="onClose()">
        <mat-icon class="icon">thumb_down
        </mat-icon>
        <span class="nav-caption">&nbsp; Dead Leads</span>
      </a>
    </mat-list-item>
    <mat-list-item>
      <a *ngIf = "authService.isLoggedIn == true"(click)="onClose()">
        <mat-icon class="icon">trending_up
        </mat-icon>
        <span class="nav-caption">&nbsp; Reports</span>
      </a>
    </mat-list-item>
    <mat-list-item>
      <a *ngIf = "authService.isLoggedIn == true" routerLink="/admin" (click)="onClose()">
        <mat-icon class="icon">settings
        </mat-icon>
        <span class="nav-caption">&nbsp; Settings</span>
      </a>
    </mat-list-item>
    <mat-list-item>
      <a *ngIf = "authService.isLoggedIn == true" routerLink="/customer-dashboard" (click)="onClose()">
        <mat-icon class="icon">person
        </mat-icon>
        <span class="nav-caption">&nbsp; Customers</span>
      </a>
    </mat-list-item>
    <mat-list-item>
      <a *ngIf = "authService.isLoggedIn == true" routerLink="/pricelist" (click)="onClose()">
        <mat-icon class="icon">shopping_cart
        </mat-icon>
        <span class="nav-caption">&nbsp; Pricelist</span>
      </a>
    </mat-list-item>
    <mat-list-item>
      <a *ngIf = "authService.isLoggedIn == true" (click)="authService.SignOut(); onClose()">
        <mat-icon class="icon">exit_to_app
        </mat-icon>
        <span class="nav-caption">&nbsp; Logout</span>
      </a>
    </mat-list-item>
  </mat-nav-list>
  <div id="bottom">CISPM Version CISPM V3-20</div>
</div>
