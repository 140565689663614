<div fxLayout= "column" fxLayoutAlign="center center" class="addNew">
  <h1 mat-dialog-title *ngIf="localData.contactName==null">Enter New Lead</h1>
  <h1 mat-dialog-title *ngIf="localData.contactName!==null">{{localData.contactName}}{{' - '}}{{localData.opportunityName}}</h1>

  <mat-dialog-content *ngIf="action == 'Update' || action == 'Add'">
    &nbsp;
    <mat-accordion multi="false" >
      <mat-expansion-panel [expanded] ='isInstallOpenExpanded'>
        <mat-expansion-panel-header>
          <mat-panel-title>
            ORDER/ LEAD INFO
          </mat-panel-title>
          <!-- <mat-panel-description>
            Type your name and age
          </mat-panel-description> -->
        </mat-expansion-panel-header>
        &nbsp;
        <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayoutGap="5px">
      <div fxFlex.sm="100%">
      <mat-form-field class="lead-dilogue">
        <input required matInput placeholder="Company" aria-label="Company" [matAutocomplete]="auto" [formControl]="companyCtrl"
        (keyup.enter) ="doAction() ;openSnackBar(localData, action)">
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="onSelectionChanged($event)"
          [displayWith]="AutoCompleteDisplay">
          <mat-option *ngFor="let option of filteredCompanies | async" [value]="option">
            <span>{{option.name}}</span>
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="companyCtrl.hasError('required')">
          Please enter a value
      </mat-error>

      <mat-error *ngIf="companyCtrl.hasError('incorrect')">
          No company match your input.
      </mat-error>
      {{filteredCompanies[0]}}
      </mat-form-field>
      </div>
      <div fxFlex.sm="100%">
        <mat-form-field class="lead-dilogue">
          <input matInput placeholder="Opportunity Name" [(ngModel)]="localData.opportunityName"  (keyup.enter) ="doAction()">
        </mat-form-field>
      </div>
    </div>
      <div fxLayout= "column">
        <mat-form-field class="description">
          <textarea matInput placeholder="Description"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="10"
            [(ngModel)]= "localData.opportunityDescription">
          </textarea>
        </mat-form-field>
    </div>
    <div  fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayoutGap="5px">
      <div>
        <mat-form-field class="lead-dilogue">
        <mat-label>Milestone</mat-label>
          <mat-select required [(value)]="localData.milestone" #matSelect = 'matSelect'>
            <mat-option [disabled]="((loginUser.displayName !== 'Aaron Seutter') && (loginUser !== 'Matthew Pennycook')) && ((localData.milestone === 'C. Installation Complete') || (localData.milestone === 'D. Customer Follow Up'))" value="1. Prospect">Prospect</mat-option>
            <mat-option [disabled]="((loginUser.displayName !== 'Aaron Seutter') && (loginUser !== 'Matthew Pennycook')) && ((localData.milestone === 'C. Installation Complete') || (localData.milestone === 'D. Customer Follow Up'))" value="2. Qualifying">Qualifying</mat-option>
            <mat-option [disabled]="((loginUser.displayName !== 'Aaron Seutter') && (loginUser !== 'Matthew Pennycook')) && ((localData.milestone === 'C. Installation Complete') || (localData.milestone === 'D. Customer Follow Up'))" value="3. Design">Design</mat-option>
            <mat-option [disabled]="((loginUser.displayName !== 'Aaron Seutter') && (loginUser !== 'Matthew Pennycook')) && ((localData.milestone === 'C. Installation Complete') || (localData.milestone === 'D. Customer Follow Up'))" value="EstimatesMilestone">Estimate</mat-option>
            <mat-option [disabled]="((loginUser.displayName !== 'Aaron Seutter') && (loginUser !== 'Matthew Pennycook')) && ((localData.milestone === 'C. Installation Complete') || (localData.milestone === 'D. Customer Follow Up'))" value="4. Proposal">Proposal</mat-option>
            <mat-option [disabled]="((loginUser.displayName !== 'Aaron Seutter') && (loginUser !== 'Matthew Pennycook')) && ((localData.milestone === 'C. Installation Complete') || (localData.milestone === 'D. Customer Follow Up'))" value="5. Operations">Operations</mat-option>
            <mat-option [disabled]="((loginUser.displayName !== 'Aaron Seutter') && (loginUser !== 'Matthew Pennycook')) && ((localData.milestone === 'C. Installation Complete') || (localData.milestone === 'D. Customer Follow Up'))" value="A. Production Design">Production Design</mat-option>
            <mat-option [disabled]="((loginUser.displayName !== 'Aaron Seutter') && (loginUser !== 'Matthew Pennycook')) && ((localData.milestone === 'C. Installation Complete') || (localData.milestone === 'D. Customer Follow Up'))" value="B. In Production">In Production</mat-option>
            <mat-option [disabled]="((loginUser.displayName !== 'Aaron Seutter') && (loginUser !== 'Matthew Pennycook')) && ((localData.milestone === 'C. Installation Complete') || (localData.milestone === 'D. Customer Follow Up'))" value="B. Service Order">Service Order</mat-option>
            <mat-option [disabled]="((loginUser.displayName !== 'Aaron Seutter') && (loginUser !== 'Matthew Pennycook')) && ((localData.milestone === 'C. Installation Complete') || (localData.milestone === 'D. Customer Follow Up'))" value="C. Installation Complete">Installation Complete</mat-option>
            <mat-option [disabled]="(loginUser.displayName !== 'Aaron Seutter') && (loginUser !== 'Matthew Pennycook')" value="D. Customer Follow Up">Customer Follow Up</mat-option>
            <mat-option [disabled]="(loginUser.displayName !== 'Aaron Seutter') && (loginUser !== 'Matthew Pennycook')" value="E. Project Complete">Project Complete</mat-option>
            <mat-option [disabled]="((loginUser.displayName !== 'Aaron Seutter') && (loginUser !== 'Matthew Pennycook')) && ((localData.milestone === 'C. Installation Complete') || (localData.milestone === 'D. Customer Follow Up'))" value="Dead Lead">Dead Lead</mat-option>
            <mat-option [disabled]="((loginUser.displayName !== 'Aaron Seutter') && (loginUser !== 'Matthew Pennycook')) && ((localData.milestone === 'C. Installation Complete') || (localData.milestone === 'D. Customer Follow Up'))" value="CIS">City Image Signs</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="lead-dilogue">
            <input matInput placeholder="Expected Value"  #nameValue="ngModel" [ngModel]="localData.valuePerDuration" required
            (blur) = "changeToNumber($event.srcElement.value, 'valuePerDuration')">
            <span matPrefix>$&nbsp;</span>
          <div *ngIf="nameValue.invalid && (nameValue.dirty || nameValue.touched)" class="alert alert-danger">
            <div *ngIf="nameValue.errors.required">
              Value is required.
            </div>
        </div>
        </mat-form-field>
      </div>
    </div>
    <div  fxLayout.md="row" fxLayout.sm="column" fxLayoutGap="5px">
    <mat-form-field class="lead-dilogue">
      <mat-label>Project Manager</mat-label>
      <mat-select required [(value)]="localData.owner">
        <mat-option>None</mat-option>
        <mat-option *ngFor="let staff of this.dbStaffUsers"[value]="staff.displayName" >{{staff.displayName}}</mat-option>

      </mat-select>
    </mat-form-field>
    <mat-form-field class="lead-dilogue">
        <input matInput [matDatepicker]="pickerClose" placeholder="Closing Date" [(ngModel)]="localData.expectedCloseDate">
        <mat-datepicker-toggle matSuffix [for]="pickerClose"></mat-datepicker-toggle>
        <mat-datepicker #pickerClose></mat-datepicker>
    </mat-form-field>
    </div>
    <div  fxLayout.md="column" fxLayout.sm="column" fxLayoutGap="5px">
      <mat-form-field class="lead-dilogue">
        <mat-label>Project Coordinator</mat-label>
        <mat-select required [(value)]="localData.projectCoordinator">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let staff of this.dbStaffUsers"[value]="staff.displayName" >{{staff.displayName}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="lead-dilogue">
        <input matInput [matDatepicker]="pickerFollow" placeholder="Follow-Up By" [(ngModel)]="localData.followupBy">
        <mat-datepicker-toggle matSuffix [for]="pickerFollow"></mat-datepicker-toggle>
        <mat-datepicker #pickerFollow></mat-datepicker>
    </mat-form-field>
      </div>
      <div>

        <mat-form-field class="lead-dilogue">
          <mat-label>Lead Source</mat-label>
          <mat-select [(value)]="localData.leadSource" >
            <mat-option>Select...</mat-option>
            <mat-option value="cold_call">Cold Call</mat-option>
            <mat-option value="website">Website</mat-option>
            <mat-option value="social_meida">Social Media</mat-option>
            <mat-option value="reference">Reference</mat-option>
            <mat-option value="past_customer">Past Customer</mat-option>
          </mat-select>
      </mat-form-field >
      <ng-container >
        <mat-form-field class="lead-dilogue">
          <mat-label>Billing Contact</mat-label>
          <!-- <mat-select   [ngModel]="billingContactPerson[0].id === undefined ? billingContactPerson : billingContactPerson[0].id " (ngModelChange)="onChange($event)" name="sel2"> -->
          <mat-select   [ngModel]="localData.contactId" (ngModelChange)="onChange($event)" name="sel2">
            <mat-option [value] ="" >Choose a Billing Contact</mat-option>
            <mat-option [value] ="vendor.id" *ngFor="let vendor of contactsPerson"  >{{vendor.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

     </div>
      <div fxLayout="column">
        <mat-grid-list cols="4" rowHeight="40px" class="grid-dialogue"  *ngFor="let permit of localData.budgetConfirmed">
          <mat-grid-tile class="checkbox-label" [colspan]="1">
            <label>Budget Confirmed:</label>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-radio-group  [(value)]="permit.isYesOrNo"
            name="radioOpt8"
            (change)="radioChange($event, 'budgetConfirmed' )">
              <mat-radio-button class="checkbox-margin" ng-click="permit.isYesOrNo === false" [value]="false">No</mat-radio-button>
              <mat-radio-button class="checkbox-margin" ng-click="permit.isYesOrNo === true" [value]="true">Yes</mat-radio-button>
            </mat-radio-group>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="2" *ngIf = "permit.updatedBy">
            <span>&nbsp;&nbsp; Updated on {{permit.updatedBy}} ON {{ permit.dateUpdated }}</span>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    <div fxLayout= "column" *ngIf="localData.milestone == 'Dead Lead'">
      <mat-form-field class="description lead-dilogue" appearance="outline">
        <mat-label>Reason For Lost</mat-label>
        <textarea matInput placeholder="Describe how lead was lost" [(ngModel)]= "localData.lostReason"
          (keyup.enter) ="doAction() ;openSnackBar(localData, action)">
        </textarea>
        <mat-icon matSuffix>sentiment_very_dissatisfied</mat-icon>
      </mat-form-field>
    </div>
      </mat-expansion-panel>&nbsp;
      <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            PROJECT INFO
          </mat-panel-title>
          <!-- <mat-panel-description>
            Type your name and age
          </mat-panel-description> -->
        </mat-expansion-panel-header>
        <div  fxLayout.md="row" fxLayout.sm="column" fxLayoutGap="5px">
          <mat-form-field class="description">
              <input matInput placeholder="Rendering File Name" [(ngModel)]="localData.renderingFileName"  (keyup.enter) ="doAction()">
          </mat-form-field>
        </div>
        <div  fxLayout.md="row" fxLayout.sm="column" fxLayoutGap="5px">
          <mat-form-field class="description">
              <input matInput placeholder="Folder Location URL" [(ngModel)]="localData.folderLocation"  (keyup.enter) ="doAction()">
          </mat-form-field>
        </div>
        <div  fxLayout.md="row" fxLayout.sm="column" fxLayoutGap="5px">
            <mat-form-field class="lead-dilogue">
                <input matInput placeholder="Address" [(ngModel)]="localData.address"  (keyup.enter) ="doAction()">
            </mat-form-field>
            <mat-form-field class="lead-dilogue">
              <input matInput placeholder="City/ Town" [(ngModel)]="localData.city"  (keyup.enter) ="doAction()">
            </mat-form-field>
          </div>
          <div  fxLayout.md="row" fxLayout.sm="column" fxLayoutGap="5px">
            <!-- <mat-form-field >
                <input matInput placeholder="Decision Maker" [(ngModel)]="localData.decisionMaker"  (keyup.enter) ="doAction()">
            </mat-form-field> -->

            <mat-form-field class="lead-dilogue">
              <mat-label>Province</mat-label>
              <mat-select required [(value)]="localData.state" >
                <mat-option>Select...</mat-option>
                <mat-option value="ab">Alberta</mat-option>
                <mat-option value="bc">British Columbia</mat-option>
                <mat-option value="mb">Manitoba</mat-option>
                <mat-option value="nb">New Brunswick</mat-option>
                <mat-option value="nl">Newfoundland and Labrador</mat-option>
                <mat-option value="nt">Northwest Territories</mat-option>
                <mat-option value="ns">Nova Scotia</mat-option>
                <mat-option value="nv">Nunavut</mat-option>
                <mat-option value="on">Ontario</mat-option>
                <mat-option value="pei">Prince Edward Island</mat-option>
                <mat-option value="que">Quebec</mat-option>
                <mat-option value="sk">Saskatchewan</mat-option>
                <mat-option value="yk">Yukon</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div  fxLayout.md="row" fxLayout.sm="column" fxLayoutGap="5px">
              <!-- <mat-form-field >
                  <input matInput placeholder="Decision Maker" [(ngModel)]="localData.decisionMaker"  (keyup.enter) ="doAction()">
              </mat-form-field> -->
              <mat-form-field class="lead-dilogue">
                <input matInput placeholder="Site Contact" [(ngModel)]="localData.siteContact"  (keyup.enter) ="doAction()">
              </mat-form-field>
              <mat-form-field class="lead-dilogue">
                <input matInput placeholder="Landlord Info" [(ngModel)]="localData.landlordInfo"  (keyup.enter) ="doAction()">
            </mat-form-field>
            </div>
            <div  fxLayout.md="row" fxLayout.sm="column" fxLayoutGap="5px">
                <mat-form-field class="lead-dilogue">
                    <input matInput placeholder="Site Contact Phone" [(ngModel)]="localData.siteContactPhone"  (keyup.enter) ="doAction()">
                </mat-form-field>
                <mat-form-field class="lead-dilogue">
                    <input matInput placeholder="Site Contact Email" [(ngModel)]="localData.siteContactEmail"  (keyup.enter) ="doAction()">
                </mat-form-field>
            </div>
            <div  fxLayout.md="row" fxLayout.sm="column" fxLayoutGap="5px">
                <mat-form-field class="lead-dilogue">
                    <mat-label>Payment Terms</mat-label>
                    <mat-select required [(value)]="localData.paymentMethod" >
                      <mat-option>Select...</mat-option>
                      <mat-option value="Cheque (50/50)">Cheque (50/50)</mat-option>
                      <mat-option value="Cheque/PO Net 30">Cheque/PO Net 30</mat-option>
                      <mat-option value="Credit card (50/50)">Credit card (50/50)</mat-option>
                      <mat-option value="Credit Card Net 30">Credit Card Net 30</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="lead-dilogue">
                    <input matInput placeholder="Project Number" [(ngModel)]="localData.projectNumber"  (keyup.enter) ="doAction()">
                </mat-form-field>
            </div>
      </mat-expansion-panel>&nbsp;
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
              JOB STATUS AND PERMISSIONS
          </mat-panel-title>
        </mat-expansion-panel-header>
        <hr>

        <mat-grid-list cols="4" rowHeight="40px" class="grid-dialogue"  *ngFor="let permit of localData.customerAgreementReceived">
          <mat-grid-tile class="checkbox-label" [colspan]="1">
            <label>Customer Agreement Received:</label>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-radio-group  [(value)]="permit.isYesOrNo"
            name="radioOpt1"
            (change)="radioChange($event, 'customerAgreementReceived' )">
              <mat-radio-button class="checkbox-margin" ng-click="permit.isYesOrNo === false" [value]="false">No</mat-radio-button>
              <mat-radio-button class="checkbox-margin" ng-click="permit.isYesOrNo === true" [value]="true">Yes</mat-radio-button>
            </mat-radio-group>
          </mat-grid-tile>
          <mat-grid-tile class="checkbox-label" [colspan]="2" *ngIf = "permit.updatedBy">
            <small>&nbsp;&nbsp; Updated on {{ permit.dateUpdated  }} by {{permit.updatedBy}}</small>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
        <mat-grid-list cols="4" rowHeight="40px" class="grid-dialogue"  *ngFor="let permit of localData.depositReceived">
          <mat-grid-tile class="checkbox-label" [colspan]="1">
            <label>Deposit/PO# Received:</label>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-radio-group  [(value)]="permit.isYesOrNo"
            name="radioOpt2"
            (change)="radioChange($event, 'depositReceived' )">
              <mat-radio-button class="checkbox-margin" ng-click="permit.isYesOrNo === false" [value]="false">No</mat-radio-button>
              <mat-radio-button class="checkbox-margin" ng-click="permit.isYesOrNo === true" [value]="true">Yes</mat-radio-button>
            </mat-radio-group>
          </mat-grid-tile>
          <mat-grid-tile class="checkbox-label" [colspan]="2" *ngIf = "permit.updatedBy">
            <small>&nbsp;&nbsp; Updated on {{ permit.dateUpdated  }} by {{permit.updatedBy}} </small>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
        <mat-grid-list cols="4" rowHeight="40px" class="grid-dialogue"  *ngFor="let permit of localData.addToCommissionSpreadsheet">
          <mat-grid-tile class="checkbox-label" [colspan]="1">
            <label>Added to Commission Spreadsheet:</label>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-radio-group  [(value)]="permit.isYesOrNo"
            name="radioOpt11"
            (change)="radioChange($event, 'addToCommissionSpreadsheet' )">
              <mat-radio-button class="checkbox-margin" ng-click="permit.isYesOrNo === false" [value]="false">No</mat-radio-button>
              <mat-radio-button class="checkbox-margin" ng-click="permit.isYesOrNo === true" [value]="true">Yes</mat-radio-button>
            </mat-radio-group>
          </mat-grid-tile>
          <mat-grid-tile class="checkbox-label" [colspan]="2" *ngIf = "permit.updatedBy">
            <small>&nbsp;&nbsp; Updated on {{ permit.dateUpdated  }} by {{permit.updatedBy}} </small>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
        <mat-grid-list cols="4" rowHeight="40px" class="grid-dialogue"  *ngFor="let permit of localData.signedDrawingsReceived">
          <mat-grid-tile class="checkbox-label" [colspan]="1">
            <label>Signed Renderings Received:</label>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-radio-group  [(value)]="permit.isYesOrNo"
            name="radioOpt3"
            (change)="radioChange($event, 'signedDrawingsReceived' )">
              <mat-radio-button class="checkbox-margin" ng-click="permit.isYesOrNo === false" [value]="false">No</mat-radio-button>
              <mat-radio-button class="checkbox-margin" ng-click="permit.isYesOrNo === true" [value]="true">Yes</mat-radio-button>
            </mat-radio-group>
          </mat-grid-tile>
          <mat-grid-tile class="checkbox-label" [colspan]="2" *ngIf = "permit.updatedBy">
            <small>&nbsp;&nbsp; Updated on {{ permit.dateUpdated  }} by {{permit.updatedBy}}</small>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
        <mat-grid-list cols="4" rowHeight="40px" class="grid-dialogue"  *ngFor="let permit of localData.landlordDocsReceived">
          <mat-grid-tile class="checkbox-label" [colspan]="1">
            <label>Landlord Authorization Received:</label>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-radio-group  [(value)]="permit.isYesOrNo"
            name="radioOpt4"
            (change)="radioChange($event, 'landlordDocsReceived' )">
              <mat-radio-button class="checkbox-margin" ng-click="permit.isYesOrNo === false" [value]="false">No</mat-radio-button>
              <mat-radio-button class="checkbox-margin" ng-click="permit.isYesOrNo === true" [value]="true">Yes</mat-radio-button>
            </mat-radio-group>
          </mat-grid-tile>
          <mat-grid-tile class="checkbox-label" [colspan]="2" *ngIf = "permit.updatedBy">
            <small>&nbsp;&nbsp; Updated on {{ permit.dateUpdated  }} by {{permit.updatedBy}}</small>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
        <mat-grid-list cols="4" rowHeight="40px" class="grid-dialogue"  *ngFor="let permit of localData.engineeringReceived">
          <mat-grid-tile class="checkbox-label" [colspan]="1">
            <label>Engineering Received:</label>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-radio-group  [(value)]="permit.isYesOrNo"
            name="radioOpt9"
            (change)="radioChange($event, 'engineeringReceived' )">
              <mat-radio-button class="checkbox-margin" ng-click="permit.isYesOrNo === false" [value]="false">No</mat-radio-button>
              <mat-radio-button class="checkbox-margin" ng-click="permit.isYesOrNo === true" [value]="true">Yes</mat-radio-button>
            </mat-radio-group>
          </mat-grid-tile>
          <mat-grid-tile class="checkbox-label" [colspan]="2" *ngIf = "permit.updatedBy">
            <small>&nbsp;&nbsp; Updated on {{ permit.dateUpdated  }} by {{permit.updatedBy}}</small>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
        <mat-grid-list cols="4" rowHeight="40px" class="grid-dialogue"  *ngFor="let permit of localData.siteSurveyReceived">
          <mat-grid-tile class="checkbox-label" [colspan]="1">
            <label>Site Survey Received:</label>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-radio-group  [(value)]="permit.isYesOrNo"
            name="radioOpt5"
            (change)="radioChange($event, 'siteSurveyReceived' )">
              <mat-radio-button class="checkbox-margin" ng-click="permit.isYesOrNo === false" [value]="false">No</mat-radio-button>
              <mat-radio-button class="checkbox-margin" ng-click="permit.isYesOrNo === true" [value]="true">Yes</mat-radio-button>
            </mat-radio-group>
          </mat-grid-tile>
          <mat-grid-tile class="checkbox-label" [colspan]="2" *ngIf = "permit.updatedBy">
            <small>&nbsp;&nbsp; Updated on {{ permit.dateUpdated  }} by {{permit.updatedBy}}</small>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
        <mat-grid-list cols="4" rowHeight="40px" class="grid-dialogue"  *ngFor="let permit of localData.permitAppliedFor">
          <mat-grid-tile class="checkbox-label" [colspan]="1">
            <label>Permit Applied For:</label>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-radio-group  [(value)]="permit.isYesOrNo"
            name="radioOpt6"
            (change)="radioChange($event, 'permitAppliedFor' )">
              <mat-radio-button class="checkbox-margin" ng-click="permit.isYesOrNo === false" [value]="false">No</mat-radio-button>
              <mat-radio-button class="checkbox-margin" ng-click="permit.isYesOrNo === true" [value]="true">Yes</mat-radio-button>
            </mat-radio-group>
          </mat-grid-tile>
          <mat-grid-tile class="checkbox-label" [colspan]="2" *ngIf = "permit.updatedBy">
            <small>&nbsp;&nbsp; Updated on {{ permit.dateUpdated  }} by {{permit.updatedBy}}</small>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
        <mat-grid-list cols="4" rowHeight="40px" class="grid-dialogue"  *ngFor="let permitApprove of localData.permitApproved">
          <mat-grid-tile class="checkbox-label" [colspan]="1">
            <label>Permit Approved:</label>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-radio-group  [(value)]="permitApprove.isYesOrNo"
            name="radioOpt8"
            (change)="radioChange($event, 'permitApproved' )">
              <mat-radio-button class="checkbox-margin" ng-click="permitApprove.isYesOrNo === false" [value]="false">No</mat-radio-button>
              <mat-radio-button class="checkbox-margin" ng-click="permitApprove.isYesOrNo === true" [value]="true">Yes</mat-radio-button>
            </mat-radio-group>
          </mat-grid-tile>
          <mat-grid-tile class="checkbox-label" [colspan]="2" *ngIf = "permitApprove.updatedBy">
            <small>&nbsp;&nbsp; Updated on {{ permitApprove.dateUpdated }} by {{permitApprove.updatedBy}}</small>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
        <mat-grid-list cols="4" rowHeight="40px" class="grid-dialogue"  *ngFor="let approve of localData.approvedForProduction">
          <mat-grid-tile class="checkbox-label" [colspan]="1">
            <label>Approve For Production</label>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-radio-group  [(value)]="approve.isYesOrNo"
            name="radioOpt7"
            (change)="radioChange($event, 'approvedForProduction' )">
              <mat-radio-button class="checkbox-margin" ng-click="approve.isYesOrNo === false" [value]="false">No</mat-radio-button>
              <mat-radio-button class="checkbox-margin" ng-click="approve.isYesOrNo === true" [value]="true">Yes</mat-radio-button>
            </mat-radio-group>
          </mat-grid-tile>
          <mat-grid-tile class="checkbox-label" [colspan]="2" *ngIf = "approve.updatedBy">
            <small>&nbsp;&nbsp; Updated on {{ approve.dateUpdated }} by {{approve.updatedBy}}</small>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="4" rowHeight="40px" class="grid-dialogue"  *ngFor="let approve of localData.readyToInvoice">
          <mat-grid-tile class="checkbox-label" [colspan]="1">
            <label>Ready To Invoice</label>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-radio-group  [(value)]="approve.isYesOrNo" [disabled]="(loginUser.displayName !== localData.owner)"
            name="radioOpt10"
            (change)="radioChange($event, 'readyToInvoice' )">
              <mat-radio-button class="checkbox-margin" ng-click="approve.isYesOrNo === false" [value]="false">No</mat-radio-button>
              <mat-radio-button class="checkbox-margin" ng-click="approve.isYesOrNo === true" [value]="true">Yes</mat-radio-button>
            </mat-radio-group>
          </mat-grid-tile>
          <mat-grid-tile class="checkbox-label" [colspan]="2" *ngIf = "approve.updatedBy">
            <small>&nbsp;&nbsp; Updated on {{ approve.dateUpdated }} by {{approve.updatedBy}}</small>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-expansion-panel>&nbsp;
      <mat-expansion-panel [expanded] = 'isInstallExpanded'>
        <mat-expansion-panel-header>
          <mat-panel-title>
              CALENDAR ACTIVITIES
          </mat-panel-title>
        </mat-expansion-panel-header>
        <hr>
        <div  fxLayout.md="row" fxLayout.sm="column" fxLayoutGap="5px">
          <mat-form-field class="lead-dilogue" *ngIf = "localData.multiInstall.length == 0">
              <input matInput [matDatepicker]="dp1" placeholder="Actual Installation Date"
              [(ngModel)]="localData.actualInstallationDate"
              (dateInput)="addInstalls(localData.actualInstallationDate, localData.valuePerDuration)">
              <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
              <mat-datepicker #dp1></mat-datepicker>
          </mat-form-field>
      </div>
        <mat-grid-list cols="4" rowHeight="40px" class="grid-dialogue">
          <mat-grid-tile class="checkbox-label" [colspan]="1">
            <label>Multiple Activities:</label>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-radio-group  [(value)]="localData.isMultiInstall">
              <mat-radio-button class="checkbox-margin" (click)="localData.isMultiInstall = false" [value]="false">No</mat-radio-button>
              <mat-radio-button class="checkbox-margin" (click)="localData.isMultiInstall = true" [value]="true">Yes</mat-radio-button>
            </mat-radio-group>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button mat-raised-button  type="submit"  color="accent" (click)="addInstalls('', 0 )" [disabled] = "localData.isMultiInstall == false">Add Activity</button>
          </mat-grid-tile>
        </mat-grid-list>&nbsp;
        <div *ngFor="let installs of localData.multiInstall; index as i"
        style="margin-bottom: 10px;"
        id="installcard{{i}}"
        tabindex="{{i}}">
          <mat-card class = "installCard"
        >
        <div><span class="numberCircle">{{i+1}}</span> &nbsp;&nbsp;&nbsp; -   <strong>{{installs.actionTypes | uppercase}}</strong></div>&nbsp;
          <mat-grid-list cols="5" rowHeight="60px" class="grid-dialogue">
            <mat-grid-tile class="checkbox-label" [colspan]="2">
              <mat-form-field class="lead-dilogue">
                <mat-label>Vendor</mat-label>
                <mat-select matNativeControl required [(ngModel)]="installs.installCrew" >
                  <mat-option value="To Be Booked">To Be Booked</mat-option>
                  <mat-option *ngFor="let vendor of vendors"[value]="vendor.name" >{{vendor.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="2">
              <mat-form-field class="lead-dilogue">
                <input matInput [matDatepicker]="picker" placeholder="Activity Date" [(ngModel)]="installs.installDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1" style="justify-content: left !important;">
              <mat-form-field class="installValue">
                <mat-label>Invoice Value</mat-label>
                <input matInput [(ngModel)]="installs.progressValue">
                <span matPrefix>$&nbsp;</span>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>

          <mat-grid-list cols="4" rowHeight="120px" style="padding-top:10px">
            <mat-grid-tile [colspan]="4">
              <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Description Title</mat-label>
                <textarea matInput placeholder="Description"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="3"
                  cdkAutosizeMaxRows="10"
                  [(ngModel)]= "installs.discription">
                </textarea>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="9" rowHeight="80px" class="grid-dialogue">
            <mat-grid-tile [colspan]="4">
              <mat-form-field class="lead-dilogue">
                <mat-label>Activity Type</mat-label>
                <mat-select matNativeControl required [(ngModel)]="installs.actionTypes" >
                  <mat-option value="Install"><span style="background-color: rgb(18, 187, 18);" class="dot"></span>&nbsp; Install </mat-option>
                  <mat-option value="Site Survey"><span style="background-color:rgb(112, 31, 133);" class="dot"></span>&nbsp;Site Survey</mat-option>
                  <mat-option value="Service"><span style="background-color:rgb(42, 19, 145);" class="dot"></span>&nbsp;Service</mat-option>
                  <mat-option value="Re-work"><span style="background-color: rgb(86, 72, 219);" class="dot"></span>&nbsp;Re-Work</mat-option>
                  <mat-option value="Locate"><span style="background-color: rgb(65, 16, 39);" class="dot"></span>&nbsp;Locates</mat-option>
                  <mat-option value="Supply"><span style="background-color: rgb(30, 148, 119);" class="dot"></span>&nbsp;Supply Only</mat-option>
                  <mat-option value="Shipping"><span style="background-color: rgb(148, 30, 95);" class="dot"></span>&nbsp;Shipping</mat-option>
                  <mat-option value="Invoice"><span style="background-color: rgb(159, 161, 15);" class="dot"></span>&nbsp;Invoice</mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="4">
              <mat-radio-group  [(value)]="installs.isCompleted">
                <mat-radio-button class="checkbox-margin" (click)="installs.isCompleted = false" [value]="false">Active</mat-radio-button>
                <mat-radio-button class="checkbox-margin" (click)="installs.isCompleted = true" [value]="true">Completed</mat-radio-button>
              </mat-radio-group>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1">
              <a (click)="deleteInstalls(i)"><mat-icon aria-hidden="delete" aria-label="delete icon">delete_forever</mat-icon></a>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="4" rowHeight="20px" class="grid-dialogue">
            <mat-grid-tile [colspan]="4" style="color:grey">
              Added on {{installs.dateUpdated}} by {{installs.updatedBy}}
            </mat-grid-tile>
          </mat-grid-list>

          <!-- <mat-divider class="customdivider"></mat-divider> -->
        </mat-card>
        </div>

      </mat-expansion-panel>&nbsp;
    </mat-accordion>
  </mat-dialog-content>

  <mat-dialog-content *ngIf="action == 'Delete'">
    Are you sure you want to delete? <b>{{localData.name}}</b>
  </mat-dialog-content>
  <div *ngIf="!localData.valuePerDuration && action != 'Delete'" style="color: red;"><strong>PLEASE MAKE SURE TO ADD A VALUE TO THIS PROJECT</strong></div>
  <mat-dialog-actions style="width: 100%;">
    <div fxLayout="row" style ="width: 100%;" fxLayoutGap="10px">
        <div fxLayoutAlign="start center" fxFlex="20%">
        <a  *ngIf="(this.action !== 'Add') && (this.action !== 'Delete')"  style="color:#949494;cursor: pointer;" (click)="openTaskDialog('Save Task',localData)">
        <mat-icon matTooltip="Task">alarm</mat-icon>
        </a>
      <a (click)="closeDialog(localData)" [routerLink]="['/quote',{ id: localData.id, status: 'default'}]"  *ngIf="localData.hasQuote === true"><mat-icon matTooltip="Edit Estimate" style="color:#949494;cursor: pointer;">layers</mat-icon></a>
      </div>
      <div fxLayoutAlign="end center" fxFlex="80%">
        <button type="submit" [disabled]="!localData.valuePerDuration && (localData.valuePerDuration < 0)" mat-button mat-flat-button color="primary" (click)="doAction(); openSnackBar(localData, action)">{{action}}</button>
        <button mat-button (click)="closeDialog(localData)" mat-flat-button color="warn">Cancel</button>
      </div>
    </div>
  </mat-dialog-actions>
</div>
