import { Component, Inject, Optional, ViewEncapsulation, OnInit, NgZone, ViewChild, AfterViewInit, OnDestroy, Input, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {take} from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UntypedFormControl, FormGroupDirective, UntypedFormBuilder, NgForm, Validators, UntypedFormGroup,} from '@angular/forms';
import {ErrorStateMatcher, MatOption} from '@angular/material/core';
import { CustomerService} from 'src/app/service/customer.service';
import {Observable, Subscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Customer } from 'src/app/models/customer.model';
import { Orders } from 'src/app/models/orders.model';
// import * as dayjs from 'dayjs';
import { AbstractControl } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import { MatRadioChange } from '@angular/material/radio';
import { TaskDialogueComponent } from '../task/task-dialogue/task-dialogue.component';
import { OrdersService } from 'src/app/service/orders.service';
import { EmailsService } from 'src/app/service/emails.service';
import { Router, Scroll, NavigationEnd } from '@angular/router';
import { Location, ViewportScroller } from '@angular/common';
import { User } from 'src/app/models/user.model';
import { MatSelect, MatSelectChange } from '@angular/material/select';


declare function require(name:string);

var dayjs = require('dayjs');
var weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);
var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);
var weekday = require('dayjs/plugin/weekday');
dayjs.extend(weekday);

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export function RequireMatch(control: AbstractControl) {
    const selection: any = control.value;
    if (typeof selection === 'string') {
        return { incorrect: true };
    }
    return null;
}

@Component({
  selector: 'app-add-new-lead',
  templateUrl: './add-new-lead.component.html',
  styleUrls: ['./add-new-lead.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AddNewLeadComponent implements OnInit, OnDestroy {
  dbStaffUsers: User[];
  companyCtrl = new UntypedFormControl('', [Validators.required, RequireMatch]);
  valueCtrl = new UntypedFormControl('', [Validators.required, CustomValidator.numberValidator]);
  filteredCompanies: Observable<Customer[]>;
  companytest: any;
  action: string;
  localData: any;
  selected: true;
  dueDate: Date;
  dueDateFormControl = new UntypedFormControl('', [Validators.required]);
  matcher = new MyErrorStateMatcher();
  formTask: UntypedFormGroup;
  formOrder: UntypedFormGroup;
  leadOrderExpanded = false;
  loginUser: any;
  isInstallExpanded: boolean;
  isInstallOpenExpanded: boolean;
  billingContactPerson: any = undefined;
  milestonePrevious: string;
  isReadyForInvoice: boolean;
  ids: Array<String> = ['installcard0', 'installcard1', 'installcard2', 'installcard3', 'installcard4', 'installcard5', 'installcard6'];

  private itemDoc: AngularFirestoreDocument<Customer>;
  item: Observable<Customer>;
  contactTest: any[];
  contacts: Customer[] = [];
  contactperson: Customer[] = [];
  billingContactsAll: Customer[] = [];
  contactsSubscription: Subscription;
  staffUsersSubscription: Subscription;
  vendors: Customer[] = [];
  contactsPerson: Customer[] = [];
  currentUserLogin: string;
  // @Input() milestone: string;
  // @ViewChild(MatSelect) child: MatSelect;


  constructor(
    public dialog: MatDialog,
    private ordersService: OrdersService,
    fbTask: UntypedFormBuilder,
    private fbOrder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<any>,
    private emailServices: EmailsService,
    private _vps: ViewportScroller,
    // *** @Optional() is used to prevent error if no data is passed ***
    @Optional()@Inject(MAT_DIALOG_DATA) public data: Orders,
    private _ngZone: NgZone, private _snackBar: MatSnackBar, private customerService: CustomerService,
    private authService: AuthService,
    private afs: AngularFirestore)
    {
      this.loginUser = this.authService.userData;
      this.localData = JSON.parse(JSON.stringify({...data}));
      this.localData.owner = this.localData.owner === 'Matt Pennycook' ? 'Matthew Pennycook' : this.localData.owner;
      this.milestonePrevious = this.localData.milestone;
      this.isInstallExpanded = this.localData.status === 'Install' ? true : this.localData.status === 'close' ? true : false;
      this.isInstallOpenExpanded = this.localData.status === 'open' ? true : false;
      this.action = this.localData.action;
      this.checkIfDataIsValid(this.localData);
      this.isReadyForInvoice = false;
      const name = JSON.parse(localStorage.getItem('user'));
      this.currentUserLogin = name.displayName ?? '';
    }

  ngOnInit() {

    this.contactsSubscription = this.customerService.contactsChanged.subscribe(
      (contacts: Customer[]) => {
        this.vendors = contacts.filter( value => value.tags === 'Vendor');
        this.contacts = contacts.filter( value => value.type === 'Organization');
        this.billingContactsAll = JSON.parse(JSON.stringify(contacts.filter( value => value.type === 'Person')));
        this.contactsPerson = JSON.parse(JSON.stringify(contacts.filter( value => value.type === 'Person')));
        this.contactsPerson = this.contactsPerson.filter( value => value.clientId !== undefined)
        this.contactsPerson = this.contactsPerson.filter( value => value.clientId === this.localData.clientId);
      }
    );
    this.staffUsersSubscription = this.customerService.usersChanged.subscribe(
      (users: User[]) => {
        this.dbStaffUsers = JSON.parse(JSON.stringify(users));
        this.dbStaffUsers = this.dbStaffUsers.filter( t => 'cityimagesigns.com' === (t.email.substring(t.email.lastIndexOf("@") +1)));
      }
    );
    this.customerService.fetchAvailableContacts();
    this.customerService.fetchStaffMembers();

    this.formOrder = this.fbOrder.group({
      numberInput: ['', [Validators.required, CustomValidator.numberValidator]]
    });
    this.companyCtrl.setValue({name:this.localData.contactName});
    this.filteredCompanies = this.companyCtrl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(company => company ? this._filterCompanies(company) : this.contacts.slice())
      );
  }

  // ** Filter Client Company Name in Auto Complete */
  private _filterCompanies(value: string): Customer[] {
    const filterValue = value.toString().toLowerCase();
    const client = this.contacts.filter(company => company.name.toString().toLowerCase().indexOf(filterValue) === 0);
    this.localData.clientId = this.companyCtrl.value.id;
    return client;
  }
  // ngAfterViewInit() {
  //   console.log('Values on ngAfterViewInit():');
  //   console.log("timyAttributetle:", this.child);
  // }

  checkIfDataIsValid(data) {
    data.approvedForProduction = data.approvedForProduction === undefined  ? [] : data.approvedForProduction;
    data.permitAppliedFor = data.permitAppliedFor === undefined  ? [] : data.permitAppliedFor;
    data.permitApproved = data.permitApproved === undefined  ? [] : data.permitApproved;
    data.readyToInvoice = data.readyToInvoice === undefined  ? [] : data.readyToInvoice;
    data.engineeringReceived = data.engineeringReceived === undefined  ? [] : data.engineeringReceived;
    data.isMultiInstall = data.isMultiInstall === undefined ? false : data.isMultiInstall;
    data.budgetConfirmed = data.budgetConfirmed === undefined  ? [] : data.budgetConfirmed;
    data.signedDrawingsReceived = data.signedDrawingsReceived === undefined  ? [] : data.signedDrawingsReceived;
    data.depositReceived = data.depositReceived === undefined  ? [] : data.depositReceived;
    data.addToCommissionSpreadsheet = data.addToCommissionSpreadsheet === undefined  ? [] : data.addToCommissionSpreadsheet;
    data.landlordDocsReceived = data.landlordDocsReceived === undefined  ? [] : data.landlordDocsReceived;
    data.customerAgreementReceived = data.customerAgreementReceived === undefined  ? [] : data.customerAgreementReceived;
    data.siteSurveyReceived = data.siteSurveyReceived === undefined  ? [] : data.siteSurveyReceived;
    data.valuePerDuration = data.valuePerDuration === undefined  ? 0.00 : data.valuePerDuration;

    data.projectCoordinator = data.projectCoordinator === undefined ? false : data.projectCoordinator;
    data.multiInstall = data.multiInstall === undefined ? [] : data.multiInstall;
    data.task = data.task === undefined ? [] : data.task;
    if (  data.multiInstall.lenght === 0 ) {
      this.addInstalls('', 0);
    }
    if ( data.permitAppliedFor.length === 0 ) {
      this.localData.permitAppliedFor.push( {
        isYesOrNo: false ,
        updatedBy: '' ,
        dateUpdated: dayjs().format('YYYY MMMM DD')
      });
    }
    if ( data.budgetConfirmed.length === 0 ) {
      this.localData.budgetConfirmed.push( {
        isYesOrNo: false ,
        updatedBy: '' ,
        dateUpdated: dayjs().format('YYYY MMMM DD')
      });
    }
    if ( data.customerAgreementReceived.length === 0 ) {
      this.localData.customerAgreementReceived.push( {
        isYesOrNo: false ,
        updatedBy: '' ,
        dateUpdated: dayjs().format('YYYY MMMM DD')
      });
    }
    if ( data.addToCommissionSpreadsheet.length === 0 ) {
      this.localData.addToCommissionSpreadsheet.push( {
        isYesOrNo: false ,
        updatedBy: '' ,
        dateUpdated: dayjs().format('YYYY MMMM DD')
      });
    }
    if ( data.engineeringReceived.length === 0 ) {
      this.localData.engineeringReceived.push( {
        isYesOrNo: false ,
        updatedBy: '' ,
        dateUpdated: dayjs().format('YYYY MMMM DD')
      });
    }
    if ( data.siteSurveyReceived.length === 0 ) {
      this.localData.siteSurveyReceived.push( {
        isYesOrNo: false ,
        updatedBy: '' ,
        dateUpdated: dayjs().format('YYYY MMMM DD')
      });
    }
    if ( data.signedDrawingsReceived.length === 0 ) {
      this.localData.signedDrawingsReceived.push( {
        isYesOrNo: false ,
        updatedBy: '' ,
        dateUpdated: dayjs().format('YYYY MMMM DD')
      });
    }
    if ( data.depositReceived.length === 0 ) {
      this.localData.depositReceived.push( {
        isYesOrNo: false ,
        updatedBy: '' ,
        dateUpdated: dayjs().format('YYYY MMMM DD')
      });
    }
    if ( data.landlordDocsReceived.length === 0 ) {
      this.localData.landlordDocsReceived.push( {
        isYesOrNo: false ,
        updatedBy: '' ,
        dateUpdated: dayjs().format('YYYY MMMM DD')
      });
    }
    if ( data.readyToInvoice.length === 0 ) {
      this.localData.readyToInvoice.push( {
        isYesOrNo: false ,
        updatedBy: '' ,
        dateUpdated: dayjs().format('YYYY MMMM DD')
      });
    }
    if ( data.approvedForProduction.length === 0 ) {
      this.localData.approvedForProduction.push( {
        isYesOrNo: false ,
        updatedBy: '' ,
        dateUpdated: dayjs().format('YYYY MMMM DD')
      });
    }
    return data;
  }

  doAction() {
    if (this.isReadyForInvoice &&  this.localData.readyToInvoice[0].isYesOrNo) {
      this.localData.task.unshift( {taskDiscription: 'Ready For Invoicing',
      taskDate: dayjs().add(3, 'day').format(), taskAssigned: 'Aaron Seutter',  taskStatus: true, createdBy: this.loginUser.displayName });
      this.emailServices.sendTaskEmailToAccounting(this.localData);
    }
    this.dialogRef.close({event: this.action, data: this.localData, oldMilestone: this.milestonePrevious});
  }

  closeDialog(order) {
    this.localData.data = null;
    this.dialogRef.close({event: 'Cancel'});
  }

  deleteInstalls( installItemIndex ) {
    this.localData.multiInstall.splice(installItemIndex, 1);
    this.localData.isMultiInstall = this.localData.multiInstall.length > 1 ? true : false;
    this.localData.actualInstallationDate = this.localData.multiInstall.length === 0 ? '' : this.localData.actualInstallationDate;

   }

  addInstalls(date, installvalue) {
    this.localData.multiInstall.push( {
      updatedBy: this.loginUser.displayName ,
      dateUpdated: dayjs().format('YYYY MMMM DD'),
      installCrew: 'To Be Booked',
      installDate: date === ''?date: dayjs(date).format(),
      discription: '',
      actionTypes: 'Install',
      progressValue: installvalue,
      isCompleted: false });
  }

  public changeToNumber(data, field) {
    if( field === 'valuePerDuration') {
      this.localData.valuePerDuration = Number(data.replace(/\$/g,''));
      this.localData.valuePerDuration = Number(data.replace(/\,/g,''));
      this.localData.valuePerDuration === 0? 0.00 :this.localData.valuePerDuration;
    }
   }

  radioChange($event: MatRadioChange, fieldData) {
    if ($event.value === true) {
        this.localData[fieldData][0].updatedBy = this.loginUser.displayName;
        this.localData[fieldData][0].dateUpdated =  dayjs().format('YYYY MMMM DD');
        this.localData[fieldData][0].isYesOrNo = true;
        this.isReadyForInvoice = fieldData === 'readyToInvoice' ? true : false;

    } else {
      this.localData[fieldData][0].updatedBy = '';
      this.localData[fieldData][0].dateUpdated =  '';
      this.localData[fieldData][0].isYesOrNo = false;
    }
  }

  onSelectionChanged(event$) {
    this.localData.clientId = event$.option.value.id;
    this.localData.contactName = event$.option.value.name;
    this.contactsPerson = JSON.parse(JSON.stringify(this.billingContactsAll.filter( value => value.type === 'Person')));
    this.contactsPerson = this.contactsPerson.filter( value => value.clientId !== undefined)
    this.contactsPerson = this.contactsPerson.filter( value => value.clientId ===  event$.option.value.id);
  }

  onChange(event$) {
    this.localData.contactId = event$;
    // this.billingContactPerson = event$;
    // event$.action = 'UpdatePerson';
  }

  openTaskDialog(action, obj) {
    obj.action = action;
    // obj.company = this.company;
    const dialogRef = this.dialog.open(TaskDialogueComponent, {
      width:  (obj.action === 'Update' || obj.action === 'Add') ? 'auto' : (obj.action === 'Save Task') ? '90vw' : '800px',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      if ((result === undefined) || (result === {event: "Cancel"})) {
      }
      else if (result.event === 'Update') {
        this.ordersService.updateOrder(result.data, this.currentUserLogin);
      } else if (result.event === 'Save Task') {
        if (result.data.task.length > obj.task.length) {
          this.emailServices.sendTaskEmail((result.data.task.length - obj.task.length), result.data);
        }
        this.ordersService.updateOrder(result.data, this.currentUserLogin);
      } else if (result.event === 'Delete') {
        this.ordersService.deleteOrder(result.data, null, this.currentUserLogin);
      } else if (result.event === 'Add') {
        this.ordersService.addNewOrderData(result.data, this.currentUserLogin);
      }
    });
  }

  // AutoCompleteDisplay(item: any):  string {
  //   if (!item) {return item; }
  //   return item.name;
  // }
  AutoCompleteDisplay(user?: Customer): string | undefined {
    return user ? user.name : undefined;
  }

  // displayFn(user: User): string {
  //   return user && user.name ? user.name : '';
  // }

  /** @title Auto-resizing textarea */
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
        .subscribe(() => this.autosize.resizeToFitContent(true));
  }

//   scrollFn(anchor: string): void{
//   	this._vps.scrollToAnchor(anchor)
// }

ngOnDestroy() {
  this.staffUsersSubscription.unsubscribe();
  this.contactsSubscription.unsubscribe();
}

  openSnackBar(localData, action: string) {
    type MatSnackBarHorizontalPosition = 'left';
    if (action === 'Add') {
      this._snackBar.open('New Lead - ' + this.localData.contactName, 'ADDED', {
        duration: 700,
      });
    } else if (action === 'Update') {
      this._snackBar.open(this.localData.contactName + ' - ' + localData.opportunityName, 'UPDATED', {
        duration: 700,
      });
    } else if (action === 'Save Task') {
      this._snackBar.open(this.localData.contactName + ' - ' + localData.opportunityName, 'TASK UPDATED', {
        duration: 700,
      });
    } else if (action === 'Deleted Task') {
        this._snackBar.open(this.localData.contactName + ' - ' + localData.opportunityName, 'TASK DELETED', {
          duration: 700,
        });
    } else {
      this._snackBar.open(this.localData.contactName + ' - ' + localData.opportunityName, 'DELETED', {
        duration: 700,
      });
    }
  }
}

export class CustomValidator {
  // Validates URL
  static urlValidator(url): any {
     if (url.pristine) {
        return null;
     }
     const URL_REGEXP = /^(http?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
     url.markAsTouched();
     if (URL_REGEXP.test(url.value)) {
        return null;
     }
     return {
        invalidUrl: true
     };
  }
  // Validates passwords
  static matchPassword(group): any {
     const password = group.controls.password;
     const confirm = group.controls.confirm;
     if (password.pristine || confirm.pristine) {
        return null;
     }
     group.markAsTouched();
     if (password.value === confirm.value) {
        return null;
     }
     return {
        invalidPassword: true
     };
  }
  // Validates numbers
  static numberValidator(number): any {
     if (number.pristine) {
        return null;
     }
     const NUMBER_REGEXP = /^-?[\d.]+(?:e-?\d+)?$/;
     number.markAsTouched();
     if (NUMBER_REGEXP.test(number.value)) {
        return null;
     }
     return {
        invalidNumber: true
     };
  }
  // Validates US SSN
  static ssnValidator(ssn): any {
     if (ssn.pristine) {
        return null;
     }
     const SSN_REGEXP = /^(?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
     ssn.markAsTouched();
     if (SSN_REGEXP.test(ssn.value)) {
        return null;
     }
     return {
        invalidSsn: true
     };
  }
  // Validates US phone numbers
  static phoneValidator(number): any {
     if (number.pristine) {
        return null;
     }
     const PHONE_REGEXP = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
     number.markAsTouched();
     if (PHONE_REGEXP.test(number.value)) {
        return null;
     }
     return {
        invalidNumber: true
     };
  }
  // Validates zip codes
  static zipCodeValidator(zip): any {
     if (zip.pristine) {
        return null;
     }
     const ZIP_REGEXP = /^[0-9]{5}(?:-[0-9]{4})?$/;
     zip.markAsTouched();
     if (ZIP_REGEXP.test(zip.value)) {
        return null;
     }
     return {
        invalidZip: true
     };
  }
  }
