import { Component, OnInit, ViewChild, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { AdminService } from '../service/admin.service';
import { Subscription } from 'rxjs';
import { Admin } from '../models/admin.model';
import {FormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { AuthService } from '../service/auth.service';

import { InstallcalendarComponent } from '../orders/installcalendar//installcalendar.component';
import { MatTable, MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  adminSettings: Subscription;
  adminDb: any;
  options: UntypedFormGroup;
  isAuth = false;
  loginUser: any;
  screenHeight: number;
  screenWidth: number;
  userData = [];
  tillEndofYearSum : number;
  isLoading = true;
  currentUserLogin: string;

  financialTableData  = [];
  @ViewChild(MatTable) table: MatTable<any>;


  displayedColumns: string[] = ['year', 'fiftyTwoWeekTarget', 'jan', 'feb', 'mar',
   'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'ytdTargetInvoice'];


  constructor(public adminService: AdminService,  public authService: AuthService,) {
    let name = JSON.parse(localStorage.getItem('user'));
      this.currentUserLogin = name !== null?name.displayName: '';
  }


  ngOnInit(): void {
    this.adminSettings = this.adminService.adminChanged.subscribe(
      (adminstuff: Admin[]) => {
        this.financialTableData = JSON.parse(JSON.stringify(adminstuff));
        this.isLoading = false;
      }
    );
    this.adminService.fetchAdminData();
  }

  addNewYearFinancials() {
    let howManyYears = this.financialTableData[0].financials.length;
    this.financialTableData[0].financials.push({

      year: Number(this.financialTableData[0].financials[howManyYears - 1].year) + 1,
      fiftyTwoWeekTarget: 0.00,
      scheduleInvoicing: 0.00,
      ytdTarget: 0.00,
      weeklyTarget: 0.00,
      variableWeeklyTarget: 0.00,
      ytdTargetInvoice: 0.00,
      monthlyInvoiceValues: [{month:'January', value: 0.00}, {month:'February', value: 0.00}, {month:'March', value: 0.00},
      {month:'April', value: 0.00}, {month:'May', value: 0.00}, {month:'June', value: 0.00}, {month:'July', value: 0.00}, {month:'August', value: 0.00},
      {month:'September', value: 0.00}, {month:'October', value: 0.00}, {month:'November', value: 0.00}, {month:'December', value: 0.00}],
      updated: Date(),
    } );
    this.table.renderRows();
  }
  saveFinancials(obj) {
    obj = this.setytdTargetInvoice(obj)
    this.adminService.upDateAdminSettings(obj);
  }

  setytdTargetInvoice(data) {
    for( let i = 0; i < data[0].financials.length; i++) {
      data[0].financials[i].ytdTargetInvoice = data[0].financials[i].monthlyInvoiceValues.reduce((a, b) => {
        return b['value'] === (typeof b !== 'number') ? 0 : a + Number(b['value']);
    }, 0); }
   return data;
  }

}

