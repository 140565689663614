import { Component, OnInit, Pipe, PipeTransform, EventEmitter, ViewChild, AfterViewInit, OnDestroy, OnChanges, ViewChildren, QueryList  } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragEnter, CdkDragExit, CdkDragEnd} from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OrdersService } from '../../service/orders.service';
import { CustomerService } from 'src/app/service/customer.service';
import { AddNewLeadComponent } from '../add-new-lead/add-new-lead.component';
import { AngularFirestore} from '@angular/fire/compat/firestore';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../service/auth.service';

import { Orders } from '../../models/orders.model';
import { TaskDialogueComponent } from '../task/task-dialogue/task-dialogue.component';

@Component({
  selector: 'app-openclosed',
  templateUrl: './openclosed.component.html',
  styleUrls: ['./openclosed.component.css']
})
export class OpenclosedComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
  'projectNumber', 'contactName', 'opportunityName', 'dateUpdated', 'task', 'quote'];

  dbCompleted = new MatTableDataSource<Orders>();


  searchProspect = '';
  searchShow = true;
  searchAll = '';


  value = 'Clear me';
  company = [];

  // leadsWonSubscription: Subscription;
  jobsCompletedSubcription: Subscription;
  // contactsSubscription: Subscription;
  testcontact = [];
  isLoading = true;

  @ViewChild('table1', { read: MatSort, static: false }) sort: MatSort;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  currentUserLogin: string;


  constructor(public dialog: MatDialog, private ordersService: OrdersService, private customerService: CustomerService,
              private db: AngularFirestore , public authService: AuthService) {
    const name = JSON.parse(localStorage.getItem('user'));
    this.currentUserLogin = name.displayName ?? '';
  }

  ngOnInit() {
    this.jobsCompletedSubcription = this.ordersService.ordersCompletedChanged.subscribe(
      (newLeads: Orders[]) => {
        this.isLoading = false;
        this.dbCompleted.data = newLeads.filter(value => value.milestone === 'E. Project Complete');
      }
    );
    this.ordersService.fetchOrdersCompleted();
  }

  ngAfterViewInit() {
    this.dbCompleted.paginator = this.paginator.toArray()[0];
    this.dbCompleted.sort = this.sort;
    this.dbCompleted.sortingDataAccessor = (item: any, property) => {
      switch (property) {
          case 'dateUpdated':
            var t = new Date(1970, 0, 1); // Epoch
            t.setSeconds(item.updated)
              return new Date(t).getTime()
          default:
              return item[property]
      }
    }
  }

  doFilter(filterValue: string, milestone: string) {
    this.dbCompleted.filter = filterValue.trim().toLowerCase();
  }


  openDialog(action, obj, status) {
    obj.status = status;
    obj.action = action;
    obj.company = this.company;
    const dialogRef = this.dialog.open(AddNewLeadComponent, {
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Add') {
        this.ordersService.addNewOrderData(result.data, this.currentUserLogin);
      } else if (result.event === 'Update') {
        this.ordersService.updateOrder(result.data, this.currentUserLogin);
      } else if (result.event === 'Save Task') {
        this.ordersService.updateOrder(result.data, this.currentUserLogin);
      } else if (result.event === 'Delete') {
        this.ordersService.deleteOrder(result.data, null, this.currentUserLogin);
      }
    });
  }

  openTaskDialog(action, obj, index) {
    obj.action = action;
    obj.company = this.company;
    const dialogRef = this.dialog.open(TaskDialogueComponent, {
      width: (obj.action === 'Update' || obj.action === 'Add') ? '740px' : (obj.action === 'Save Task') ? 'auto' : '300px',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      if ((result === undefined) || (result === {event: "Cancel"})) {
        console.log(this.dialog);
      } else if (result.event === 'Update') {
        this.ordersService.updateOrder(result.data, this.currentUserLogin);
      } else if (result.event === 'Save Task') {
        this.ordersService.updateOrder(result.data, this.currentUserLogin);
      } else if (result.event === 'Delete') {
        this.ordersService.deleteOrder(result.data, null, this.currentUserLogin);
      } else if (result.event === 'Add') {
        this.ordersService.addNewOrderData(result.data, this.currentUserLogin);
      }
    });
  }

  updateMeetingMinutes(result) {
    this.ordersService.updateOrder(result, this.currentUserLogin);
  }

  ngOnDestroy() {
    this.jobsCompletedSubcription.unsubscribe();
  }
  clearFilters(milestone: any) {
    // milestone.filter = '';
    this.dbCompleted.filter = '';
  }
}
