import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Subject, Subscription } from 'rxjs';
import { Quotes, TemplatePriceList, PriceList } from '../models/quote.model';

import { map } from 'rxjs/operators';

// import * as dayjs from 'dayjs';
declare function require(name:string);

var dayjs = require('dayjs');
var weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);
var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);
var weekday = require('dayjs/plugin/weekday');
dayjs.extend(weekday);
var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  quoteChanged = new Subject<Quotes[]>();
  private availableData: Quotes[] = [];
  templateChanged = new Subject<TemplatePriceList[]>();
  private availableTemplate: TemplatePriceList[] = [];
  id: string;
  staffUsersSubscription: Subscription;
  dbQuotes: any;

  constructor( private quoteData: AngularFirestore) {
    this.staffUsersSubscription = this.quoteChanged.subscribe(
      ( quotes: Quotes[]) => {
        this.dbQuotes = JSON.parse(JSON.stringify(quotes));
      }
    );
    this.fetchQuoteData();
  }

  fetchQuoteData() {
    this.quoteData
    .collection('quotes')
    .snapshotChanges()
      .pipe(map(docArray => {
        return docArray.map(doc => {
          return {
            id: doc.payload.doc.id,
            orderId: doc.payload.doc.data()['orderId'],
            quote: doc.payload.doc.data()['quote'],
            acceptedQuoteId:  doc.payload.doc.data()['acceptedQuoteId'],
            lastUpdatedBy: doc.payload.doc.data()['lastUpdatedBy'],
            dateLastUpdated: doc.payload.doc.data()['dateLastUpdated'],
            notes: doc.payload.doc.data()['notes'],
            acceptedBy: doc.payload.doc.data()['acceptedBy'],
            dateAccepted: doc.payload.doc.data()['dateAccepted'],
            isAccepted: doc.payload.doc.data()['isAccepted'],
            createdBy: doc.payload.doc.data()['createdBy'],
          };
        });
      }))
      .subscribe((quotesData: Quotes[]) => {
        this.availableData = quotesData;
        this.quoteChanged.next([...this.availableData]);
      });
  }

  fetchTemplates() {
    this.quoteData
    .collection('priceListTemplates')
    .snapshotChanges()
      .pipe(map(docArray => {
        return docArray.map(doc => {
          return {
            id: doc.payload.doc.id,
            createdBy: doc.payload.doc.data()['createdBy'],
            datecreated: doc.payload.doc.data()['datecreated'],
            quotePriceList: doc.payload.doc.data()['quotePriceList'],
            title: doc.payload.doc.data()['title'],
          };
        });
      }))
      .subscribe((templateData: TemplatePriceList[]) => {
        this.availableTemplate = templateData;
        this.templateChanged.next([...this.availableTemplate]);
      });
  }


  updateQuotes(data, currentUserLogin) {
    this.id = data[0].id;
    data[0].quote[0].quoteDate = dayjs(data[0].quote[0].quoteDate).format();
    data[0].lastUpdatedBy = currentUserLogin;
    data[0].dateLastUpdated = dayjs().format();
    if (data[0].quote[0].quoteAprrovalData.length > 0) {
      data[0].quote[0].quoteAprrovalData[data[0].quote[0].quoteAprrovalData.length -1].dateOveride =
      dayjs(data[0].quote[0].quoteAprrovalData[data[0].quote[0].quoteAprrovalData.length -1].dateOveride).format();
    }

    if (data[0].quote[0].quoteAprrovalData.length > 0) {
      data[0].quote[0].quoteAprrovalData[data[0].quote[0].quoteAprrovalData.length -1].dateApproved =
      dayjs(data[0].quote[0].quoteAprrovalData[data[0].quote[0].quoteAprrovalData.length -1].dateApproved).format();
    }

    this.quoteData
        .collection('quotes')
        .doc(this.id)
        .set(data[0], { merge: true });
    this.quoteChanged.next([...this.availableData]);
  }
  addToExistingQuote(data, status, isDuplicate) {
    let defaultQuote = data[0].quote.filter( t => t.isDefault === true);
    let addtionalData = []
    if (!isDuplicate) {
      addtionalData = this.getQuotePrefilledData(status)
    } else if (isDuplicate) {
      addtionalData = JSON.parse(JSON.stringify(defaultQuote));
      addtionalData[0].isDefault = false;
    }
    data[0].quote.push(addtionalData[0]);
    return data;
  }

  addNewQuote(data) {
    this.quoteData
        .collection('quotes')
        .add(data[0]);
    this.quoteChanged.next([...this.availableData]);
  }

  addNewQTemplate(data) {
    this.quoteData
        .collection('priceListTemplates')
        .add(data[0]);
    this.quoteChanged.next([...this.availableData]);
  }

  addDataToNewQuote(orderData, status,  currentUserLogin, action, id) {
    let arr = [ {
      orderId: orderData.id,
      acceptedQuoteId: 0,
      quote: this.getQuotePrefilledData(status),
      lastUpdatedBy: currentUserLogin,
      datecreated: dayjs().format(),
      dateLastUpdated: dayjs().format(),
      createdBy: currentUserLogin,
    } ];
    console.log(arr)
    arr = action === 'copyId' ?this.getExistingQuote(arr, id) :arr;
    console.log(arr)
    return arr;
  }
  getExistingQuote(arr, id) {
    let existingQuote =  this.dbQuotes.filter( t=> t.orderId === id);
    let defaultQuote = existingQuote[0].quote.filter( t => t.isDefault === true)
    defaultQuote[0].quoteAprrovalData[0] =  {
      aprovedBy: '',
      dateApproved: '',
      revNumber: 0,
      isApproved: false,
      overRide: false,
      overRideBy: '',
      dateOveride: '',
      sendForAprroval: false,
      sendForAprrovalBy: '',
      personToApprove: '',
    };
    arr[0].quote = [defaultQuote[0]];
    return arr
  }

  getQuotePrefilledData(status){
    return [
      {
        quotePriceList: [],
        professionalTotal: 0,
        pmCommissionTotal: 0,
        salesCommissionTotal: 0,
        isDefault: status === 'non-default'? false: true,
        pstExempt: false,
        gstExempt: false,
        leadTime: '4 - 6 Weeks',
        serviceList: [
        { wbs: '1.1', status: false, task: 'Pre-Construction Design (typically creative)', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2', status: false, task: 'Development and building permit drawings, fees, and submission', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.1', status: false, task: 'Engineered structural solution', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.1.1', status: false, task: 'Certified welder (Required by law in Alberta)', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.2.1', status: false, task: 'Shop drawings ', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.4', status: false, task: 'Preliminary site inspection', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.4', status: false, task: 'Landlord criteria evaluation and auth. for permit app', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.5', status: false, task: 'Letter of Authorization', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.6', status: false, task: 'Land Survey', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.7', status: false, task: 'Real Property Report', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.8', status: false, task: 'Utility right-of-way search', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.8.1', status: false, task: 'Electrical', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.8.2', status: false, task: 'Gas (Primary & secondary)', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.8.3', status: false, task: 'Water', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.8.4', status: false, task: 'Drainage', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.8.5', status: false, task: 'Communication (Fibre or other)', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.9', status: false, task: 'Utility locates', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.10', status: false, task: 'Geotech Report', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.11', status: false, task: 'Landscaping Plan', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.12', status: false, task: 'Encroachment application', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.13', status: false, task: 'Notice Fees', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.14', status: false, task: 'Working permits', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.14.1', status: false, task: 'OSCAM (street closure)', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.14.2', status: false, task: 'OSCAM (sidewalk closure)', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.14.3', status: false, task: 'Hot works (welding on site)', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.2.14.4', status: false, task: 'Primary power disruption (eg: powerline blanketing)', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.3', status: false, task: 'Project Management', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.3.1', status: false, task: 'Sub-trade coordination (electrical, structural)', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.3.2', status: false, task: 'Project specific safefy plan, and site orientation', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.4', status: false, task: 'Estimating', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.5', status: false, task: 'Primary Sign Voltage (120/240V, 600V)', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},
        { wbs: '1.6', status: false, task: 'OTHER', units: 0, cost: 0.00, markup: 0, notes: '', type: 'professional'},

        { wbs: '2.1', status: false, task: 'Safety Plan (OSHA compliant)', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.2', status: false, task: 'Installation of sign', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.2.1', status: false, task: 'Additional Site Inspection', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.2.2', status: false, task: 'Sub-trade site meeting', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.2.3', status: false, task: 'Crating', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.2.4', status: false, task: 'Freight', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.2.5', status: false, task: 'Load signs', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.2.6', status: false, task: 'Travel to site, return', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.2.7', status: false, task: 'Kilometers (at $1.75/km)', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.2.8', status: false, task: 'Install sign - site works (breakdown hours per day)', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.2.8.1', status: false, task: 'Installation ', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.2.8.1.1', status: false, task: 'WCB', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.2.8.1.2', status: false, task: 'Insurance', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.2.8.1.3', status: false, task: 'Sub sub', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.2.9', status: false, task: 'Equipment rentals ', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.2.9.1', status: false, task: 'Separate rental fees and expected delivery fees', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.2.9.2', status: false, task: 'Scaffolding', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.2.10', status: false, task: 'Per Diem ($100/day per person)', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.2.11', status: false, task: 'Accomodations', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.2.12', status: false, task: 'Trailer ', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.2.13', status: false, task: 'Remedial work', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.2.14', status: false, task: 'Consumables ', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.3', status: false, task: 'Sub trades ', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.3.1', status: false, task: 'Installation of foundation or structural element ', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.3.1.1', status: false, task: 'Freight (anchors, template, other)', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.3.1.2', status: false, task: 'Remove tailings', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.3.1.3', status: false, task: 'Anchor bolts', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.3.1.4', status: false, task: 'Anchor bolt template', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.3.2', status: false, task: 'Installation of masonry, or other building finishes', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.3.2.1', status: false, task: 'Heating/Hoarding', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.3.3', status: false, task: 'Primary electrical connection', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},
        { wbs: '2.4', status: false, task: 'OTHER', units: 0, cost: 0.00, markup: 0, notes: '', type: 'construction'},

        { wbs: '3.1', status: false, task: 'Quoting materials and subs', units: 0, cost: 0.00, markup: 0, notes: '', type: 'product'},
        { wbs: '3.1', status: false, task: 'Production design setup', units: 0, cost: 0.00, markup: 0, notes: '', type: 'product'},
        { wbs: '3.3', status: false, task: 'cUL (UL and CSA) electrical certification', units: 0, cost: 0.00, markup: 0, notes: '', type: 'product'},
        { wbs: '3.4', status: true, task: 'Shop Consumables', units: 1, cost: 0.00, markup: 1, notes: '(1% of product costs - Automatically calculated)', type: 'product'},

        { wbs: '4.1', status: false, task: 'Risk Log', units: 0, cost: 0.00, markup: 0, notes: '', type: 'risk'},
        { wbs: '4.1.1', status: false, task: 'Contingency and Reserve Fund (line item per risk, if applicable)', units: 0, cost: 0.00, markup: 0, notes: '', type: 'risk'},
        { wbs: '4.1.2', status: false, task: 'Management Reserve (lump sump)', units: 0, cost: 0.00, markup: 0, notes: '', type: 'risk'},

        { wbs: '5.1', status: true, task: 'The purchaser covenants to pay the said purchase at the time or times and in the respective ' +
        'instalments set forth, and to pay interest on each instalment after maturity thereof until paid, at the ' +
        'rate of 2% per month (24% per annum).', type: 'toa'},
        { wbs: '5.2', status: true, task: 'The purchaser shall, on every date herewith, execute a promissory note for the amounts of such '+
        'instalments payable on the due dates thereof without days of grace, and bearing interest at the rates '+
        'aforesaid from due dates of payment until paid. Such promissory note (including any renewals, ' +
        'cheques, or substitutions thereof) is not to be given or received as payment or part payment of the ' +
        'purchase price, but is given and received merely as collateral security for payment of the Purchaser’s ' +
        'indebtedness under this agreement. The Vendor may discount or negotiate said note (including any ' +
        'renewals, extensions, or substitutions thereof) without changing its character as between the parties ' +
        'hereto, and the holder of such note shall not be subject to any equities between the parties hereto. As ' +
        'collateral security to the note the Purchaser affords the Company with a security interest in the goods ' +
        'to survive unless and until the Purchaser shall have paid the said purchase price in full, with the ' +
        'Company to enjoy all of the benefits and rights attendant to such security interest.', type: 'toa'},
        { wbs: '5.3', status: true, task: 'The Company shall commence the performance of the contract as soon as practicable having regard to ' +
        'other orders received or on hand, and shall perform the contract with reasonable dispatch provided, ' +
        'however, that performance by the Company shall always be subject to delay caused by strikes, ' +
        'lockouts, breakages, fires, unforeseen difficulties or acts of God; the Purchaser agrees that any promise ' +
        'for delivery made by the Company shall be subject always to the provision of this clause.', type: 'toa'},
        { wbs: '5.4', status: true, task: 'Until the purchase price of the said goods shall have been fully paid and satisfied, the Purchaser agrees ' +
        'to insure and at his own expense keep insured, the said goods from the date of delivery of same by the ' +
        'Company against loss by fire, wind storm, hail, or other external violence as would a prudent owner or ' +
        'as the Company may or shall require, for an amount not less than the actual purchase price, with such ' +
        'loss payable under any insurance on the said goods to the Company, and the Purchaser hereby assigns ' +
        'such insurance, together with the renewal or replacement of same to the Company, the proceeds of ' +
        'insurance to be applied in the uncontrolled discretion of the Company towards replacement or repair ' +
        'of the goods or a reduction of the balance owed by the Purchaser, the Company hereunder. In default ' +
        'of insurance by the Purchaser, the Company may insure the said goods and in every such event the cost ' +
        'of insurance shall be added to the purchase price and shall be repaid by the Purchaser to the company ' +
        'on demand and in default of payment bear interest at the rate of 3% per month (37.5% per annum).', type: 'toa'},
        { wbs: '5.5', status: true, task: 'The Purchaser agrees that the said goods, until the purchase price (including all costs, expenses, and ' +
          'payments which may be added to the purchase price under the terms hereof) and interest shall have ' +
          'been fully paid, shall be at his risk as to damage and destruction from any cause whatsoever, and that ' +
          'he will fulfil and perform his covenants and agreements as herein contained notwithstanding such ' +
          'damage or destruction. The Purchaser will promptly give notice to the Company of the loss of, ' +
          'damage to, or destruction of the goods or any part of the same.', type: 'toa'},
        { wbs: '5.6', status: true, task: 'If the Purchaser makes default in payment of any part of the purchase price or carrying charges, or if ' +
        'the Company deems any part of the said balance unsafe or insecure, the Company may, without ' +
        'notice, declare the said balance due and payable and the Company may take possession or ' +
        'repossession of the goods, together with any other relief or remedies afforded under the law, provided ' +
        'that the Company shall not be liable for any damages occasioned to the Purchaser’s property, ' +
        'including the goods, arising out of such removal, seizure, possession, or repossession, howsoever ' +
        'caused.', type: 'toa'},
        { wbs: '5.7', status: true, task: 'Until payment in full hereunder, no part of the said goods will be moved or removed, sold, or altered ' +
        'by the Purchaser without the written consent of the Company.', type: 'toa'},
        { wbs: '5.8', status: true, task: 'This agreement is subject to cancellation by the Company with or without cause and/or with or ' +
        'without notice within 30 days from the date hereof. In the event of such cancellation the Company or ' +
        'its agents may enter the premises of the Purchaser, and remove the goods and/or items incidental ' +
        'hereto, provided that where such cancellation arises without cause on the part of the Purchaser the ' +
        'Company shall refund any payments made thereunder. Unless and until so cancelled this agreement ' +
        'shall be in full force and effect.', type: 'toa'},
        { wbs: '5.9', status: true, task: 'The Purchaser hereby waives all rights to appropriation of payments and agrees that the Company, at ' +
        'its option, may appropriate any payment hereafter made by the Purchaser and/or the proceeds of any ' +
        'sale or disposition of the said goods made under or arising out of the provisions of Paragraphs 7 and ' +
        '8 above, to any current or other account, or towards the price of any part of the said goods.', type: 'toa'},
        { wbs: '5.10', status: true, task: 'This agreement shall be binding upon and ensure to the benefit of the heirs, executors, administrators, ' +
        'successors, and assigns of the respective parties hereto, but may be assigned only with the written ' +
        'consent of the company first had and obtained.', type: 'toa'},
        { wbs: '5.11', status: true, task: 'The Purchaser shall indemnify and save harmless the Company of and from all claims, actions, and ' +
        'demands of every kind and nature whatsoever, whether direct, indirect, consequential or otherwise, ' +
        'and howsoever caused, respecting which any person, firm, or corporation may, can, or shall have, by ' +
        'reason of any act, omission, or neglect of the Company, its agents, servants, or employees.', type: 'toa'},
        { wbs: '5.12', status: true, task: 'The Purchaser will pay all licence fees, taxes, including any value added taxes, and other charges ' +
        'whatsoever which may be payable in respect thereof. In the event of the said goods being seized or ' +
        'held for non-payment of taxes or other charges, the Company may pay the same and any costs in ' +
        'connection therewith and all such monies shall be payable forthwith by the Purchaser to the ' +
        'Company with interest at the rate of 2% per month (24% per annum) from the date on which the ' +
        'Company paid the same, and any monies not so paid shall be added to and form part of the purchase ' +
        'price hereunder.', type: 'toa'},
        { wbs: '5.13', status: true, task: 'The Purchaser agrees that the Company shall have the right to enforce any one or more of the ' +
        'remedies afforded hereunder or at law or any replacement or substitution of the same successively- ' +
        'or-concurrently, and any such possession, repossession, seizure, sale, or judgement or otherwise, ' +
        'shall not, nor shall any of them, operate to discharge the Purchaser until the Company has received ' +
        'payment of the full payment price. The Company may nevertheless release any portion of the said ' +
        'goods with or without consideration, as it, in its sole discretion, shall see fit, provided always, that ' +
        'such release shall not release any other of such goods or prejudice the rights of the Company whether ' +
        'arising under any covenant or condition herein or at law.', type: 'toa'},
        { wbs: '5.14', status: true, task: 'The Company, pursuant to this contract, provides to the Purchaser alone, a 12 month warranty on all ' +
        'parts and labour, with the exception of lamps which carry a 90 day warranty. The Company further ' +
        'guarantees for a period of 36 months to repair and/or replace at its own cost any structural ' +
        'manufacturing defects.', type: 'toa'},
        { wbs: '5.15', status: true, task: 'The above warranty will automatically become null and void if the sign is serviced by other than the ' +
        'original manufacturer or its approved agent.', type: 'toa'},
        { wbs: '5.16', status: true, task: 'Personal Guarantor: I, the under-named personal covenantor, for myself, my heirs, executors, ' +
        'administrators, and assigns, do hereby covenant and agree to and with the Company, its successors ' +
        'and assigns, that I will pay or cause to be paid all the monies and interest to fully satisfy the ' +
        'Purchaser’s obligations under this contract.', type: 'toa'},
        { wbs: '5.17', status: true, task: 'The Purchaser is responsible for obtaining permission to erect the goods from the owner of the lands ' +
        'and/or the building upon which the goods are to be erected, and it is understood that the Company will ' +
        'be in no way responsible for procuring this permission.', type: 'toa'},
        { wbs: '5.18', status: true, task: 'Purchase price is predicated on roofs being able to support roof signs, buildings being able to support ' +
        'projecting signs, free standing signs installed in undisturbed soil free of backfill, rock, or silt and other ' +
        'matter. The Purchaser shall be responsible to identify and reposition obstructions and utilities within ' +
        'walls where signs are installed.', type: 'toa'},
        { wbs: '5.19', status: true, task: 'The warranties and conditions herein shall survive the closing of the transaction embodied in this ' +
        'agreement and the payment of the purchase price respecting the goods.  ' +
        '\n\n \t' + '(Terms & Conditions - Revised July 26, 2019)', type: 'toa'},
      ],
      itemListTitle: [],
      deposits: [],
      quoteAprrovalData: [ {
          aprovedBy: '',
          dateApproved: '',
          revNumber: 0,
          isApproved: false,
          overRide: false,
          overRideBy: '',
          dateOveride: '',
          sendForAprroval: false,
          sendForAprrovalBy: '',
          personToApprove: '',
        },
      ],
      statements:
        '• Electrical connection to building primary not included in Quoted Subtotal and to be done by others.' + '\n' +
        '• Timer or photocell not included and to be coordinated by customer through their electrician, unless otherwise requested.' + '\n' +
        '• Subtotal is based on rendering submitted. Any adjustments, including additional sign elevations, or changes to dimensions, will be subject to pricing adjustment.' + '\n' +
        '• Supply and installation for wall-mounted signs assumes that all necessary backing has been supplied and installed by others; the typical requirement is 1/2" plywood. If roof or parapet penetrations are required, additional fees may apply.' + '\n' +
        '• Cost of any street occupancy or paid duty officer is not included in the subtotal and will be added in the final invoice.' + '\n' +
        '• If site conditions change from time of survey, whereas equipment rentals will be required, a charge of Cost + 10% will be added to Final Invoice.' + '\n' +
        '• Cost of Permits not included in Subtotal and to be added to Final Invoice. Please estimate approximately  $975 in Permit costs + ASA Engineering Label ($150) if applicable for this signage project.' + '\n' +
        '• This quotation is valid for 30 days and is issued subject to the terms and conditions as attached.' + '\n' +
        '• Payment to be made by cheque or wire transfer to: City Image Signs - 1887575 Alberta Inc.' + '\n' +
        '• Payments by Credit Card in excess of $5,000 will be subject to a surcharge of 3% of the payment amount.'
      }
    ];
  }
}
