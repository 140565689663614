<mat-tab-group  color="accent" animationDuration="8ms">
  <mat-tab label="OPEN ORDERS" cdkDropListGroup >
    <ng-template matTabContent>
        <div  fxLayout="row" fxLayout.xs="column" fxLayoutGap="2px" class="drag-scroller">
          <div class="dashboard-container" fxFlex.md="260px" fxFlex.lg="350px" fxFlex.gt-lg="20%" fxFlex.sm="280px"
          *ngFor = "let milestone of milestoneDbOpen; index as i; trackBy:trackByMilestoneOpen">
                <div class="header" fxLayout="row" fxLayoutAlign="space-between"><h3 >{{milestone | uppercase}}</h3><span fxLayoutAlign="end center center" style ="font-size: 8pt">{{this[milestone] | countOrders}}</span>
                  <span fxLayoutAlign="end center center" style ="font-size: 10pt">{{this[milestone] | sum: 'valuePerDuration' | currency}}  </span></div>
                <div class="skeleton" *ngIf="isLoadingOpen">
                  <ngx-skeleton-loader count="12" [theme]="{ 'border-radius': '5px', height: '62px' }"></ngx-skeleton-loader>
                </div>
                  <mat-accordion>
                  <cdk-virtual-scroll-viewport itemSize="10" class="viewport">
                    <div
                      cdkDropList
                      id = {{milestoneOpen[i]}}
                      #{{i}}="cdkDropList"
                      [cdkDropListData]="this[milestone]"
                      class="kanban-list"
                      cdkDropListSortingDisabled
                      (cdkDropListDropped)="drop($event)">
                      <div *ngFor="let prospect of (this[milestone] | filter : mySearchText); index as i; trackBy: trackByProspectId" cdkDrag  [cdkDragDisabled] = "mySearchText !== ''"[cdkDragData]="prospect"
                      (contextmenu)="onRightClick($event, {content: prospect, index: i})">
                          <mat-expansion-panel #panel
                                              hideToggle
                                              class="example-headers-align"
                                              >
                            <mat-expansion-panel-header>
                              <mat-panel-title [ngClass]="{
                                'twoWeekOverdue': ((prospect | overdueLeadsPipe) === '2 weeks'),
                                'fourWeekOverdue': ((prospect | overdueLeadsPipe) === '4 weeks'),
                                'notOverdue': ((prospect | overdueLeadsPipe) === 'upToDate')}"
                                [matBadge]="prospect.taskCount" matBadgeColor="accent"  matBadgeSize="small" matBadgePosition="above after"
                              style ="padding: 5px 5px 0 12px">
                                <div >{{prospect.contactName}} - <span style ="color: rgb(160, 160, 160)" >{{ prospect.opportunityName }} : {{ prospect.valuePerDuration | currency }}</span></div>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-divider></mat-divider>
                              <small>Project Manager <span style ="color: rgb(160, 160, 160)">- {{prospect.owner }}</span></small><br>
                              <small>Project Coordinator <span style ="color: rgb(160, 160, 160)">- {{prospect.projectCoordinator }}</span></small><br>
                              <small>Last Updated By <span style ="color: rgb(160, 160, 160)">- {{prospect.updatedBy}} on {{prospect.updated.seconds *1000 | date: 'medium'}}</span></small>
                              <mat-divider style="padding: 10px 0 10px 0"></mat-divider>
                                  <div>
                                     Add Notes
                                  </div>
                                  <div class="notes-container">
                                    <mat-form-field class="meeting" appearance="outline">
                                      <textarea matInput cdkTextareaAutosize
                                      #autosize="cdkTextareaAutosize"
                                      cdkAutosizeMinRows="1"
                                      cdkAutosizeMaxRows="10"
                                      [(ngModel)]="meetingNotes[i]"
                                      name="textarea">
                                    </textarea>
                                      <mat-icon *ngIf="meetingNotes[i]" matSuffix (click)= "updateMeetingMinutesNotes(prospect, '💬 '+meetingNotes[i],'notes'); meetingNotes[i]=[]" style= "cursor: pointer; "
                                        onMouseOver="this.style.color='#2e7d32'" onMouseOut="this.style.color='#000'">
                                        add_circle
                                      </mat-icon>
                                    </mat-form-field>
                                  </div>
                                  <div
                                    *ngFor="let notes of prospect.meetingMinutesDetail; index as i"  >
                                    <div fxLayout="row" fxLayoutGap="10px">
                                      <div fxLayoutAlign = "start center" fxFlex="10%">
                                        <span style="display:inline; padding-top: 5px"><img matTooltip="{{notes.updatedBy}}" class="dotPic" [src]="dbStaffUsers | staffPic: notes.updatedBy" referrerpolicy="no-referrer"></span>
                                      </div>
                                      <div fxLayoutAlign = "start center" fxFlex="90%">
                                        {{notes.meetingNotes}}
                                      </div>
                                    </div>
                                        <small style="color:#949494;">Added On: {{notes.dateUpdated}}</small>
                                    <mat-divider *ngIf="prospect.meetingMinutesDetail.length != (i+1)"></mat-divider>
                                  </div>
                                  <mat-action-row class="flyoutExpanded">
                                    <div  fxLayout="row">
                                      <div fxLayoutAlign = "start center">
                                        <mat-button-toggle-group  (change)="toggleChange($event)">
                                          <mat-button-toggle [routerLink]="['/quote',{ id: prospect.id, status: 'default'}]"  *ngIf="prospect.hasQuote === true"><mat-icon matTooltip="Edit Estimate" style="color:#949494;">layers</mat-icon></mat-button-toggle>
                                          <mat-button-toggle *ngIf="(prospect.hasQuote === false) || prospect.hasQuote === undefined" (click)="openCloneEstimateDialogue(prospect)"
                                          ><mat-icon matTooltip="Add Estimate"  style="color:#949494;">add_box</mat-icon></mat-button-toggle>
                                          <mat-button-toggle (click)="openTaskDialog('Save Task',prospect, i)"><mat-icon matTooltip="Task" style="color:#949494">alarm</mat-icon></mat-button-toggle>
                                          <mat-button-toggle (click)="openOrderDialog('Update',prospect, 'open')"><mat-icon matTooltip="Edit Lead" style="color:#949494;">edit</mat-icon></mat-button-toggle>
                                          <mat-button-toggle (click)="openOrderDialog('Delete',prospect, 'close')"><mat-icon matTooltip="Delete Lead" style="color:#949494;">delete</mat-icon></mat-button-toggle>
                                        </mat-button-toggle-group>
                                      </div>
                                    </div>
                                  </mat-action-row>
                          </mat-expansion-panel>
                      </div>
                    </div>
                  </cdk-virtual-scroll-viewport>
                </mat-accordion>
          </div>

        </div>
        </ng-template>
  </mat-tab>
  <mat-tab label="CLOSED ORDERS" cdkDropListGroup>
      <div  fxLayout="row" fxLayout.xs="column" fxLayoutGap="2px" class="drag-scroller">
        <div class="dashboard-container" fxFlex.md="260px" fxFlex.lg="350px" fxFlex.gt-lg="20%" fxFlex.sm="280px"
        *ngFor = "let milestone of milestoneDbClosed; index as x; trackBy:trackByMilestoneClosed">
              <div class="header" fxLayout="row" fxLayoutAlign="space-between"><h3 >{{milestone | milestoneTitle | uppercase}}</h3><span fxLayoutAlign="end center center" style ="font-size: 8pt">{{this[milestone] | countOrders}}</span>
                <span fxLayoutAlign="end center center" style ="font-size: 10pt">{{this[milestone] | sum: 'valuePerDuration' | currency}}  </span></div>
              <div class="skeleton" *ngIf="isLoadingClosed">
                <ngx-skeleton-loader count="12" [theme]="{ 'border-radius': '5px', height: '62px' }"></ngx-skeleton-loader>
              </div>
                <mat-accordion>
                <cdk-virtual-scroll-viewport itemSize="10" class="viewport">
                  <div
                    cdkDropList
                    id = {{milestoneClosed[x]}}
                    #won{{x}}="cdkDropList"
                    [cdkDropListData]="this[milestone]"
                    class="kanban-list"
                    cdkDropListSortingDisabled
                    (cdkDropListDropped)="drop($event)">
                    <div *ngFor="let prospect of (this[milestone] | filter : mySearchText); index as i; trackBy: trackByProspectId"
                    cdkDrag
                    [cdkDragDisabled] = "(mySearchText !== '') ||
                    ((currentUserLogin !== 'Aaron Seutter')&& (currentUserLogin !== 'Matthew Pennycook') &&
                    ((milestone === 'install')||(milestone === 'followUp')))"
                    [cdkDragData]="prospect"
                    (contextmenu)="onRightClick($event, {content: prospect, index: i})"
                    class="scroll-kanban">
                        <mat-expansion-panel #panel
                                            hideToggle
                                            class="example-headers-align">
                          <mat-expansion-panel-header>
                            <mat-panel-title [matBadge]="prospect.taskCount" matBadgeColor="accent"  matBadgeSize="small" matBadgePosition="above after" style ="padding: 5px 5px 0 12px">
                              <div>{{prospect.contactName}} - <span style ="color: rgb(160, 160, 160)" >{{ prospect.opportunityName }} - {{ prospect.valuePerDuration | currency }}</span></div>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                            <mat-divider></mat-divider>
                            <small>Project Manager <span style ="color: rgb(160, 160, 160)">- {{prospect.owner }}</span></small><br>
                            <small>Project Coordinator <span style ="color: rgb(160, 160, 160)">- {{prospect.projectCoordinator }}</span></small><br>
                            <small>Last Updated By <span style ="color: rgb(160, 160, 160)">- {{prospect.updatedBy}} on {{prospect.updated.seconds *1000 | date: 'medium'}}</span></small>

                            <mat-divider style="padding: 10px 0 10px 0"></mat-divider>
                            <div>
                                   Add Notes
                                </div>
                                <div>
                                  <mat-form-field class="meeting" appearance="outline">
                                    <textarea matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="1"
                                    cdkAutosizeMaxRows="10"
                                    [(ngModel)]="meetingNotes[i]">
                                    </textarea>
                                    <mat-icon *ngIf="meetingNotes[i]" matSuffix (click)= "updateMeetingMinutesNotes(prospect, '💬 '+meetingNotes[i],'notes'); meetingNotes[i]=[]" style= "cursor: pointer; "
                                      onMouseOver="this.style.color='#2e7d32'" onMouseOut="this.style.color='#000'">
                                      add_circle
                                    </mat-icon>
                                  </mat-form-field>
                                </div>
                                <div
                                    *ngFor="let notes of prospect.meetingMinutesDetail; index as i"  >
                                    <div fxLayout="row" fxLayoutGap="10px">
                                      <div fxLayoutAlign = "start center" fxFlex="10%">
                                        <span style="display:inline; padding-top: 5px"><img matTooltip="{{notes.updatedBy}}" class="dotPic" [src]="dbStaffUsers | staffPic: notes.updatedBy " referrerpolicy="no-referrer"></span>
                                      </div>
                                      <div fxLayoutAlign = "start center" fxFlex="90%">
                                        {{notes.meetingNotes}}
                                      </div>
                                    </div>
                                        <small style="color:#949494;">Added On: {{notes.dateUpdated}}</small>
                                    <mat-divider *ngIf="prospect.meetingMinutesDetail.length != (i+1)"></mat-divider>
                                  </div>
                            <mat-action-row class="flyoutExpanded">
                              <div  fxLayout="row">
                                <div fxLayoutAlign = "start center">
                                  <mat-button-toggle-group  (change)="toggleChange($event)">
                                    <mat-button-toggle [routerLink]="['/quote',{ id: prospect.id, status: 'default'}]"  *ngIf="prospect.hasQuote === true"><mat-icon matTooltip="Edit Estimate" style="color:#949494;">layers</mat-icon></mat-button-toggle>
                                      <mat-button-toggle *ngIf="(prospect.hasQuote === false) || prospect.hasQuote === undefined" (click)="openCloneEstimateDialogue(prospect)"
                                    ><mat-icon matTooltip="Add Estimate"  style="color:#949494;">add_box</mat-icon></mat-button-toggle>
                                    <mat-button-toggle (click)="openTaskDialog('Save Task',prospect, i)"><mat-icon matTooltip="Task" style="color:#949494">alarm</mat-icon></mat-button-toggle>
                                    <mat-button-toggle (click)="openOrderDialog('Update',prospect, 'open')"><mat-icon matTooltip="Edit Lead" style="color:#949494;">edit</mat-icon></mat-button-toggle>
                                    <mat-button-toggle (click)="openOrderDialog('Delete',prospect, 'close')"><mat-icon matTooltip="Delete Lead" style="color:#949494;">delete</mat-icon></mat-button-toggle>
                                  </mat-button-toggle-group>
                                </div>
                              </div>
                            </mat-action-row>
                        </mat-expansion-panel>
                    </div>
                  </div>
                </cdk-virtual-scroll-viewport>
              </mat-accordion>
        </div>
        </div>
  </mat-tab>
  <mat-tab label="PROSPECTS">
    <div class="dashboard-container" fxFlex.md="260px" fxFlex.lg="350px" fxFlex.gt-lg="20%" fxFlex.sm="280px" >
      <div class="header" fxLayout="row" fxLayoutAlign="space-between"><h3 >PROSPECTS</h3><span fxLayoutAlign="end center center" style ="font-size: 8pt">{{prospect | countOrders}}</span>
        <span fxLayoutAlign="end center center" style ="font-size: 10pt">{{prospect | sum: 'valuePerDuration' | currency  }}  </span></div>
      <div class="skeleton" *ngIf="isLoadingClosed">
        <ngx-skeleton-loader count="12" [theme]="{ 'border-radius': '5px', height: '62px' }"></ngx-skeleton-loader>
      </div>
      <mat-accordion>
        <cdk-virtual-scroll-viewport itemSize="10" class="viewport">
          <div
            cdkDropList
            id = "D. Customer Follow Up"
            #prospectList="cdkDropList"
            [cdkDropListData]="prospect"
            class="kanban-list"
            cdkDropListSortingDisabled
            (cdkDropListDropped)="drop($event)">
            <div *ngFor="let followup of (prospect| filter : mySearchText); index as i; trackBy: trackByPackId" cdkDrag
            [cdkDragDisabled] = "mySearchText !== ''" [cdkDragData]="followup"
            (contextmenu)="onRightClick($event, {content: followup, index: i})">
                <mat-expansion-panel #panel
                                    hideToggle
                                    class="example-headers-align">
                  <mat-expansion-panel-header>
                    <mat-panel-title [matBadge]= "followup.taskCount" matBadgeColor="accent"  matBadgeSize="small" matBadgePosition="above after" style ="padding: 5px 5px 0 12px">
                        <div>{{followup.contactName}} - <span style ="color: rgb(160, 160, 160)" >{{followup.opportunityName}} - {{followup.valuePerDuration | currency }}</span></div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-divider></mat-divider>
                  <small>Project Manager <span style ="color: rgb(160, 160, 160)">- {{followup.owner }}</span></small><br>
                  <small>Project Coordinator <span style ="color: rgb(160, 160, 160)">- {{followup.projectCoordinator }}</span></small><br>
                  <small>Last Updated By <span style ="color: rgb(160, 160, 160)">- {{followup.updatedBy}} on {{followup.updated.seconds *1000 | date: 'medium'}}</span></small>
                  <mat-divider style="padding: 10px 0 10px 0"></mat-divider>
                      <div>
                         Add Notes
                      </div>
                      <div>
                        <mat-form-field class="meeting" appearance="outline">
                          <textarea matInput cdkTextareaAutosize
                          #autosize="cdkTextareaAutosize"
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="10"
                          [(ngModel)]="meetingNotes[i]">
                          </textarea>
                          <mat-icon *ngIf="meetingNotes[i]" matSuffix (click)= "updateMeetingMinutesNotes(followup, '💬 '+meetingNotes[i],'notes'); meetingNotes[i]=[]" style= "cursor: pointer; "
                            onMouseOver="this.style.color='#2e7d32'" onMouseOut="this.style.color='#000'">
                            add_circle
                          </mat-icon>
                        </mat-form-field>
                      </div>
                      <div
                        *ngFor="let notes of followup.meetingMinutesDetail; index as i"  >
                        <div fxLayout="row" fxLayoutGap="10px">
                          <div fxLayoutAlign = "start center" fxFlex="10%">
                            <span style="display:inline; padding-top: 5px"><img matTooltip="{{notes.updatedBy}}" class="dotPic" [src]="dbStaffUsers | staffPic: notes.updatedBy " referrerpolicy="no-referrer"></span>
                          </div>
                          <div fxLayoutAlign = "start center" fxFlex="90%">
                            {{notes.meetingNotes}}
                          </div>
                        </div>
                            <small style="color:#949494;">Added On: {{notes.dateUpdated}}</small>
                        <mat-divider *ngIf="followup.meetingMinutesDetail.length != (i+1)"></mat-divider>
                      </div>
                      <mat-action-row class="flyoutExpanded">
                        <div  fxLayout="row">
                          <div fxLayoutAlign = "start center">
                            <mat-button-toggle-group  (change)="toggleChange($event)">
                              <mat-button-toggle [routerLink]="['/quote',{ id: followup.id, status: 'default'}]"  *ngIf="followup.hasQuote === true"><mat-icon matTooltip="Edit Estimate" style="color:#949494;">layers</mat-icon></mat-button-toggle>
                              <!-- <mat-button-toggle *ngIf="(followup.hasQuote === false) || followup.hasQuote === undefined" (click)="saveQuoteData(followup)" [routerLink]="['/quote',{ id: followup.id, status: 'default'}]" -->
                              <mat-button-toggle *ngIf="(followup.hasQuote === false) || followup.hasQuote === undefined" (click)="openCloneEstimateDialogue(followup)"
                              ><mat-icon matTooltip="Add Estimate"  style="color:#949494;">add_box</mat-icon></mat-button-toggle>
                              <mat-button-toggle (click)="openTaskDialog('Save Task',followup, i)"><mat-icon matTooltip="Task" style="color:#949494">alarm</mat-icon></mat-button-toggle>
                              <mat-button-toggle (click)="openOrderDialog('Update',followup, 'open')"><mat-icon matTooltip="Edit Lead" style="color:#949494;">edit</mat-icon></mat-button-toggle>
                              <mat-button-toggle (click)="openOrderDialog('Delete',followup, 'close')"><mat-icon matTooltip="Delete Lead" style="color:#949494;">delete</mat-icon></mat-button-toggle>
                            </mat-button-toggle-group>
                          </div>
                        </div>

                      </mat-action-row>
                </mat-expansion-panel>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      </mat-accordion>
</div>

  </mat-tab>
  <mat-tab label="CIS">
    <div>
    <div class="dashboard-container" fxFlex.md="260px" fxFlex.lg="350px" fxFlex.gt-lg="20%" fxFlex.sm="280px" >
      <div class="header" fxLayout="row" fxLayoutAlign="space-between"><h3 >CITY IMAGE SIGNS PROJECTS</h3><span fxLayoutAlign="end center center" style ="font-size: 8pt">{{dbCISProjects | countOrders}}</span>
        <span fxLayoutAlign="end center center" style ="font-size: 10pt">{{dbCISProjects | sum: 'valuePerDuration' | currency  }}  </span></div>
      <div class="skeleton" *ngIf="isLoadingClosed">
        <ngx-skeleton-loader count="12" [theme]="{ 'border-radius': '5px', height: '62px' }"></ngx-skeleton-loader>
      </div>
      <mat-accordion>
        <cdk-virtual-scroll-viewport itemSize="10" class="viewport">
          <div
            cdkDropList
            id = "D. Customer Follow Up"
            #prospectList="cdkDropList"
            [cdkDropListData]="dbCISProjects"
            class="kanban-list"
            cdkDropListSortingDisabled
            (cdkDropListDropped)="drop($event)">
            <div *ngFor="let followup of (dbCISProjects| filter : mySearchText); index as i; trackBy: trackByPackId" cdkDrag
            [cdkDragDisabled] = "mySearchText !== ''" [cdkDragData]="followup"
            (contextmenu)="onRightClick($event, {content: followup, index: i})">
                <mat-expansion-panel #panel
                                    hideToggle
                                    class="example-headers-align">
                  <mat-expansion-panel-header>
                    <mat-panel-title [matBadge]= "followup.taskCount" matBadgeColor="accent"  matBadgeSize="small" matBadgePosition="above after" style ="padding: 5px 5px 0 12px">
                        <div>{{followup.contactName}} - <span style ="color: rgb(160, 160, 160)" >{{followup.opportunityName}} - {{followup.valuePerDuration | currency }}</span></div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-divider></mat-divider>
                  <small>Project Manager <span style ="color: rgb(160, 160, 160)">- {{followup.owner }}</span></small><br>
                  <small>Project Coordinator <span style ="color: rgb(160, 160, 160)">- {{followup.projectCoordinator }}</span></small><br>
                  <small>Last Updated By <span style ="color: rgb(160, 160, 160)">- {{followup.updatedBy}} on {{followup.updated.seconds *1000 | date: 'medium'}}</span></small>
                  <mat-divider style="padding: 10px 0 10px 0"></mat-divider>
                      <div>
                         Add Notes
                      </div>
                      <div>
                        <mat-form-field class="meeting" appearance="outline">
                          <textarea matInput cdkTextareaAutosize
                          #autosize="cdkTextareaAutosize"
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="10"
                          [(ngModel)]="meetingNotes[i]">
                          </textarea>
                          <mat-icon *ngIf="meetingNotes[i]" matSuffix (click)= "updateMeetingMinutesNotes(followup, '💬 '+meetingNotes[i],'notes'); meetingNotes[i]=[]" style= "cursor: pointer; "
                            onMouseOver="this.style.color='#2e7d32'" onMouseOut="this.style.color='#000'">
                            add_circle
                          </mat-icon>
                        </mat-form-field>
                      </div>
                      <div
                        *ngFor="let notes of followup.meetingMinutesDetail; index as i"  >
                        <div fxLayout="row" fxLayoutGap="10px">
                          <div fxLayoutAlign = "start center" fxFlex="10%">
                            <span style="display:inline; padding-top: 5px"><img matTooltip="{{notes.updatedBy}}" class="dotPic" [src]="dbStaffUsers | staffPic: notes.updatedBy " referrerpolicy="no-referrer"></span>
                          </div>
                          <div fxLayoutAlign = "start center" fxFlex="90%">
                            {{notes.meetingNotes}}
                          </div>
                        </div>
                            <small style="color:#949494;">Added On: {{notes.dateUpdated}}</small>
                        <mat-divider *ngIf="followup.meetingMinutesDetail.length != (i+1)"></mat-divider>
                      </div>
                      <mat-action-row class="flyoutExpanded">
                        <div  fxLayout="row">
                          <div fxLayoutAlign = "start center">
                            <mat-button-toggle-group  (change)="toggleChange($event)">
                              <mat-button-toggle [routerLink]="['/quote',{ id: followup.id, status: 'default'}]"  *ngIf="followup.hasQuote === true"><mat-icon matTooltip="Edit Estimate" style="color:#949494;">layers</mat-icon></mat-button-toggle>
                              <!-- <mat-button-toggle *ngIf="(followup.hasQuote === false) || followup.hasQuote === undefined" (click)="saveQuoteData(followup)" [routerLink]="['/quote',{ id: followup.id, status: 'default'}]" -->
                              <mat-button-toggle *ngIf="(followup.hasQuote === false) || followup.hasQuote === undefined" (click)="openCloneEstimateDialogue(followup)"
                              ><mat-icon matTooltip="Add Estimate"  style="color:#949494;">add_box</mat-icon></mat-button-toggle>
                              <mat-button-toggle (click)="openTaskDialog('Save Task',followup, i)"><mat-icon matTooltip="Task" style="color:#949494">alarm</mat-icon></mat-button-toggle>
                              <mat-button-toggle (click)="openOrderDialog('Update',followup, 'open')"><mat-icon matTooltip="Edit Lead" style="color:#949494;">edit</mat-icon></mat-button-toggle>
                              <mat-button-toggle (click)="openOrderDialog('Delete',followup, 'close')"><mat-icon matTooltip="Delete Lead" style="color:#949494;">delete</mat-icon></mat-button-toggle>
                            </mat-button-toggle-group>
                          </div>
                        </div>

                      </mat-action-row>
                </mat-expansion-panel>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      </mat-accordion>
    </div>
    <!-- <div class="dashboard-container" fxFlex="80%">
      <mat-card *ngIf="!isLoading">
        <google-chart #chart
        [type]="typeTimeLineChart"
        [data]="dataTimeLineChart"
        [options]="optionsTimeLineChart"
        [columns]="columnNamesForTimeLineChart"
        [width]="widthTimeLineChart"
        [height]="heightTimeLineChart"
        >
        </google-chart>

      </mat-card>
    </div> -->
    </div>

  </mat-tab>
</mat-tab-group>
<div class="main-div" *ngIf = "authService.isLoggedIn == false"  fxLayoutAlign="center center">
  <span>YOU ARE CURRENTLY LOGGED OUT</span>
  <br><button mat-raised-button (click)="authService.SignOut()" mat-flat-button color="accent">Log Me Back In</button>
</div>
<!-- an hidden div is created to set the position of appearance of the menu-->
<div style="visibility: hidden; position: fixed;"
[style.left]="menuTopLeftPosition.x"
[style.top]="menuTopLeftPosition.y"
[matMenuTriggerFor]="ordersMenu"></div>
<!-- standard material menu -->
<mat-menu #ordersMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <div style="padding: 10px">
      <label id="orders-radio-group-label">Change Milestone</label>
      <mat-radio-group
      aria-labelledby="orders-radio-group-label"
      class="orders-radio-group"
      [(ngModel)]= "item.content.milestone" [formControl]="control">
        <mat-radio-button class="orders-radio-button" (change)="radioChange($event , item.content, item.content.milestone)" [disabled]="((currentUserLogin !== 'Aaron Seutter') && (currentUserLogin !== 'Matthew Pennycook')) && ((item.milestone === 'C. Installation Complete') || (item.milestone === 'D. Customer Follow Up'))" value="1. Prospect">Prospect</mat-radio-button>
        <mat-radio-button class="orders-radio-button" (change)="radioChange($event , item.content, item.content.milestone)" [disabled]="((currentUserLogin !== 'Aaron Seutter') && (currentUserLogin !== 'Matthew Pennycook')) && ((item.milestone === 'C. Installation Complete') || (item.milestone === 'D. Customer Follow Up'))" value="2. Qualifying">Qualifying</mat-radio-button>
        <mat-radio-button class="orders-radio-button" (change)="radioChange($event , item.content, item.content.milestone)" [disabled]="((currentUserLogin !== 'Aaron Seutter') && (currentUserLogin !== 'Matthew Pennycook')) && ((item.milestone === 'C. Installation Complete') || (item.milestone === 'D. Customer Follow Up'))" value="3. Design">Design</mat-radio-button>
        <mat-radio-button class="orders-radio-button" (change)="radioChange($event , item.content, item.content.milestone)" [disabled]="((currentUserLogin !== 'Aaron Seutter') && (currentUserLogin !== 'Matthew Pennycook')) && ((item.milestone === 'C. Installation Complete') || (item.milestone === 'D. Customer Follow Up'))" value="EstimatesMilestone">Estimate</mat-radio-button>
        <mat-radio-button class="orders-radio-button" (change)="radioChange($event , item.content, item.content.milestone)" [disabled]="((currentUserLogin !== 'Aaron Seutter') && (currentUserLogin !== 'Matthew Pennycook')) && ((item.milestone === 'C. Installation Complete') || (item.milestone === 'D. Customer Follow Up'))" value="4. Proposal">Proposal</mat-radio-button>
        <mat-radio-button class="orders-radio-button" (change)="radioChange($event , item.content, item.content.milestone)" [disabled]="((currentUserLogin !== 'Aaron Seutter') && (currentUserLogin !== 'Matthew Pennycook')) && ((item.milestone === 'C. Installation Complete') || (item.milestone === 'D. Customer Follow Up'))" value="5. Operations">Operations</mat-radio-button>
        <mat-radio-button class="orders-radio-button" (change)="radioChange($event , item.content, item.content.milestone)" [disabled]="((currentUserLogin !== 'Aaron Seutter') && (currentUserLogin !== 'Matthew Pennycook')) && ((item.milestone === 'C. Installation Complete') || (item.milestone === 'D. Customer Follow Up'))" value="A. Production Design">Production Design</mat-radio-button>
        <mat-radio-button class="orders-radio-button" (change)="radioChange($event , item.content, item.content.milestone)" [disabled]="((currentUserLogin !== 'Aaron Seutter') && (currentUserLogin !== 'Matthew Pennycook')) && ((item.milestone === 'C. Installation Complete') || (item.milestone === 'D. Customer Follow Up'))" value="B. In Production">In Production</mat-radio-button>
        <mat-radio-button class="orders-radio-button" (change)="radioChange($event , item.content, item.content.milestone)" [disabled]="((currentUserLogin !== 'Aaron Seutter') && (currentUserLogin !== 'Matthew Pennycook')) && ((item.milestone === 'C. Installation Complete') || (item.milestone === 'D. Customer Follow Up'))" value="B. Service Order">Service Order</mat-radio-button>
        <mat-radio-button class="orders-radio-button" (change)="radioChange($event , item.content, item.content.milestone)" [disabled]="((currentUserLogin !== 'Aaron Seutter') && (currentUserLogin !== 'Matthew Pennycook')) && ((item.milestone === 'C. Installation Complete') || (item.milestone === 'D. Customer Follow Up'))" value="C. Installation Complete">Installation Complete</mat-radio-button>
        <mat-radio-button class="orders-radio-button" (change)="radioChange($event , item.content, item.content.milestone)" [disabled]="(currentUserLogin !== 'Aaron Seutter') && (currentUserLogin !== 'Matthew Pennycook')" value="D. Customer Follow Up">Customer Follow Up</mat-radio-button>
        <mat-radio-button class="orders-radio-button" (change)="radioChange($event , item.content, item.content.milestone)" [disabled]="(currentUserLogin !== 'Aaron Seutter') && (currentUserLogin !== 'Matthew Pennycook')" value="E. Project Complete">Project Complete</mat-radio-button>
        <mat-radio-button class="orders-radio-button" (change)="radioChange($event , item.content, item.content.milestone)" [disabled]="((currentUserLogin !== 'Aaron Seutter') && (currentUserLogin !== 'Matthew Pennycook')) && ((item.milestone === 'C. Installation Complete') || (item.milestone === 'D. Customer Follow Up'))" value="Dead Lead">Dead Lead</mat-radio-button>
        <mat-radio-button class="orders-radio-button" (change)="radioChange($event , item.content, item.content.milestone)" [disabled]="((currentUserLogin !== 'Aaron Seutter') && (currentUserLogin !== 'Matthew Pennycook')) && ((item.milestone === 'C. Installation Complete') || (item.milestone === 'D. Customer Follow Up'))" value="CIS">City Image Signs</mat-radio-button>
      </mat-radio-group>
    </div>
  </ng-template>
</mat-menu>

