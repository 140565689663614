<div class="main-div" fxLayout="column" fxLayoutGap="15px">
  <div class="login-card" *ngIf="isLoading"
    style="display: flex; justify-content: center; align-items: center">
    <mat-progress-spinner
      color="primary"
      mode="indeterminate">
    </mat-progress-spinner>
  </div>
  <mat-card class="login-card" *ngIf="!isLoading">
    <mat-card-header>
      <mat-card-title>City Image Signs</mat-card-title>
      <mat-card-subtitle>Project Management System</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <form
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
        >
        <mat-form-field>
          <input
            #userName
            matInput
            required
            >
          <mat-hint>Please enter a your username.</mat-hint>
          <mat-error>Invalid or missing email.</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="password"  placeholder="Password" #userPassword required>

          <mat-hint>Please enter your password.</mat-hint>
          <mat-error>Missing password.</mat-error>
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-card-actions fxLayoutAlign="center center">
        <button type="submit" mat-raised-button color="primary" (click)="authService.SignIn(userName.value, userPassword.value)">Submit</button>

    </mat-card-actions>
  </mat-card>
  <div *ngIf="!isLoading">
      <button type="submit" mat-raised-button  (click)="authService.GoogleAuth(); showLoader()">

          <img width="20px" style="float:left; margin-top:7px; margin-right:8px" alt="Google sign-in"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />

        GOOGLE LOGIN &nbsp;
      </button>
  </div>
</div>

