import { Component, OnInit,  NgZone, ViewChild, HostListener, OnDestroy, Input, ChangeDetectionStrategy} from '@angular/core';
import { CdkDragDrop} from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import { OrdersService } from '../service/orders.service';
import { CustomerService } from 'src/app/service/customer.service';
import { QuoteService } from 'src/app/service/quote.service';
import { CustomersComponent } from 'src/app/customers/customers.component';
import { AddNewLeadComponent } from './add-new-lead/add-new-lead.component';
import { TaskDialogueComponent } from './task/task-dialogue/task-dialogue.component';
import { AngularFirestore} from '@angular/fire/compat/firestore';
import { Subscription } from 'rxjs';
import {take} from 'rxjs/operators';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Orders } from '../models/orders.model';
import { Customer } from 'src/app/models/customer.model';
import { AuthService } from '../service/auth.service';
import { EmailsService } from '../service/emails.service';
import { EstimateCloneDialogueComponent } from '../estimate/estimate-clone-dialogue/estimate-clone-dialogue.component';
import { Router } from '@angular/router';
import { User } from '../models/user.model';
import { MatMenuTrigger } from '@angular/material/menu';
import { UntypedFormControl } from '@angular/forms';
import {pairwise, startWith} from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';


declare function require(name: string);

const dayjs = require('dayjs');
const weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);
const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);
const weekday = require('dayjs/plugin/weekday');
dayjs.extend(weekday);
var minMax = require('dayjs/plugin/minMax')
dayjs.extend(minMax)

// *********************Start Code for orders Component *******************//

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css'],
})

export class OrdersComponent implements OnInit, OnDestroy {
  @Input() mySearchText = '';
  @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger;
  @ViewChild('autosize', { read: true, static: false }) autosize: CdkTextareaAutosize;

  public isEmojiPickerVisible: boolean;
  menuTopLeftPosition =  {x: '0', y: '0' };
  control = new UntypedFormControl();
  company = [];
  prospect: Orders[];
  qualifying: Orders[];
  design: Orders[];
  proposal: Orders[];
  operations: Orders[];
  estimate: Orders[];
  productionDesign: Orders[];
  production: Orders[];
  install: Orders[];
  followUp: Orders[];
  projectComplete: Orders[];
  serviceOrders: Orders[];
  dbCISProjects: Orders[];
  total = 0;
  currentUserLogin: string;
  dbStaffUsers: User[];
  staffPic: string;
  filtersLoaded: Promise<boolean>;
  leadsWonSubscription: Subscription;
  leadsSubscription: Subscription;
  staffUsersSubscription: Subscription;
  contacts: Customer[];
  testcontact = [];
  isLoadingOpen = true;
  isLoadingClosed = true;
  screenHeight: number;
  screenWidth: number;
  noDragIsSearched = false;
  meetingMimutesEdit = false;
  loginUser: any;
  meetingNotes: any[] = [];
  oldMilestone: string;
  milestoneDbOpen: string[] = ['qualifying', 'design','estimate', 'proposal', 'operations'];
  milestoneOpen: string[] = ['2. Qualifying', '3. Design', 'EstimatesMilestone', '4. Proposal', '5. Operations'];
  milestoneDbClosed: string[] = ['productionDesign', 'production', 'serviceOrders', 'install', 'followUp'];
  milestoneClosed: string[] = ['A. Production Design', 'B. In Production', 'B. Service Order', 'C. Installation Complete', 'D. Customer Follow Up', 'E. Project Complete'];
  trackByPackId(index, followup){followup.id};
  trackByProspectId(index, prospect) {prospect.id};
  trackByMilestoneOpen(index, item) {return index;}
  trackByMilestoneClosed(index, item) {return index;}

  dataTimeLineChart;
  columnNamesForTimeLineChart;
  typeTimeLineChart;
  titleTimeLineChart;
  optionsTimeLineChart;
  widthTimeLineChart;
  heightTimeLineChart;
  isLoading= true;


  constructor(public dialog: MatDialog, private ordersService: OrdersService, private customerService: CustomerService,
              private db: AngularFirestore, public authService: AuthService, private ngZone: NgZone,
              private quotesService: QuoteService, private emailServices: EmailsService,private snackBar: MatSnackBar,
              analytics: AngularFireAnalytics,
              public router: Router
              ) {
    this.getScreenSize();
    const name = JSON.parse(localStorage.getItem('user'));
    this.currentUserLogin = name.displayName;
    // this.setTimeLinesChart(this.production)

    //Firebase Analytics
    analytics.logEvent('Dashboard Event Being Used', { userLogin: this.currentUserLogin });
  }

  //Resize Screen to adapt to user screen
  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
          this.screenWidth = window.innerWidth;
          const vh = window.innerHeight * 0.01;
          const vw = window.innerWidth * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
          document.documentElement.style.setProperty('--vw', `${vw}px`);
    }

  ngOnInit() {

    //Get all users who are part of the staff
    this.staffUsersSubscription = this.customerService.usersChanged.subscribe(
      (users: User[]) => {
        this.dbStaffUsers = JSON.parse(JSON.stringify(users));
        this.dbStaffUsers = this.dbStaffUsers.filter( t => 'cityimagesigns.com' === (t.email.substring(t.email.lastIndexOf( '@' ) + 1)));
      }
    );

    //Get all Open Orders data from database
    this.leadsSubscription = this.ordersService.openCloseChanged.subscribe(
      (newLeads: Orders[]) => {
        this.isLoadingOpen = false;
        this.prospect = newLeads.filter(value => value.milestone === '1. Prospect');
        this.qualifying = newLeads.filter(value => value.milestone === '2. Qualifying');
        this.design  = newLeads.filter(value => value.milestone === '3. Design');
        this.proposal  = newLeads.filter(value => value.milestone === '4. Proposal');
        this.operations  = newLeads.filter(value => value.milestone === '5. Operations');
        this.estimate  = newLeads.filter(value => value.milestone === 'EstimatesMilestone');
      }
    );
    //Get all Closed Orders data from database
    this.leadsWonSubscription = this.ordersService.wonOrdersChanged.subscribe(
      (leadsWon: Orders[]) => {
        this.isLoadingClosed = false;
        this.productionDesign  = leadsWon.filter(value => value.milestone === 'A. Production Design');
        this.production  = leadsWon.filter(value => value.milestone === 'B. In Production');
        let data = JSON.parse(JSON.stringify(this.production));
        this.setTimeLinesChart(data);
        this.install  = leadsWon.filter(value => value.milestone === 'C. Installation Complete');
        this.followUp  = leadsWon.filter(value => value.milestone === 'D. Customer Follow Up');
        this.serviceOrders  = leadsWon.filter(value => value.milestone === 'B. Service Order');
        this.dbCISProjects  = leadsWon.filter(value => value.milestone === 'CIS');

        setTimeout(() =>  this.isLoading = false, 500); //Google charts very slow to load
      }
    );
    this.ordersService.fetchAvailableOrders('created');
    this.ordersService.fetchOrdersWon();
    this.customerService.fetchStaffMembers();



    //Right Click event trigger menu to change Milestone. Old milestone is recorded
    this.control.valueChanges.pipe(
      startWith(this.control.value),
      pairwise()
    ).subscribe(
      ([old, value]) => {
        this.oldMilestone = old;
      }
    );
  }

    // Drop And Drag Function
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      console.log('Nothing');
      // do nothing
    } else {
        if (event.container.id !== event.previousContainer.id) {
          const notes = this.ordersService.formatNotesMilestonesMessages(event.previousContainer.id, event.container.id);
          event.previousContainer.data[event.previousIndex] = event.container.id === 'C. Installation Complete' ? this.approveForInvoicing(event.previousContainer.data[event.previousIndex]): event.previousContainer.data[event.previousIndex];
          event.previousContainer.data[event.previousIndex] = this.ordersService.updateMeetingMinutes(event.previousContainer.data[event.previousIndex], notes, 'milestoneChange', this.currentUserLogin);
          event.container.id === 'C. Installation Complete' ? this.installCompleteSnackBar(): '';
        }
      this.ordersService.updateOrderDrag(event.previousContainer.data, event.container.id, event.previousIndex, this.currentUserLogin) ;
    }
  }

  // Open dialog on orders using the action
  openOrderDialog(action, obj, status) {
    obj.status = status;
    obj.action = action;
    obj.company = this.company;
    const dialogRef = this.dialog.open(AddNewLeadComponent, {
      width: (obj.action === 'Update' || obj.action === 'Add') ? 'auto' : (obj.action === 'Save Task') ? '800px' : '300px',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        result = null;
      } else if (result.event === 'Update') {
        if (result.data.milestone !== result.oldMilestone) {
          const notes = this.ordersService.formatNotesMilestonesMessages(result.oldMilestone, result.data.milestone);
          result.data = result.data.milestone === 'C. Installation Complete' ? this.approveForInvoicing(result.data): result.data;
          result.data = this.ordersService.updateMeetingMinutes(result.data, notes, 'milestoneChange', this.currentUserLogin);
          this.ordersService.updateOrder(result.data, this.currentUserLogin);
          result.data.milestone === 'C. Installation Complete' ? this.installCompleteSnackBar(): '';
          this.meetingNotes = [];
        } else {
          this.ordersService.updateOrder(result.data, this.currentUserLogin);
        }
      } else if (result.event === 'Save Task') {
        this.ordersService.updateOrder(result.data, this.currentUserLogin);
      } else if (result.event === 'Delete') {
        this.ordersService.deleteOrder(result.data, null, this.currentUserLogin);
      } else if (result.event === 'Add') {
        this.ordersService.addNewOrderData(result.data, this.currentUserLogin);
      } else if (result.event === 'Cancel') {
        result = null;
      }
    });
  }

  approveForInvoicing(leadData) {
    leadData.task.unshift( {taskDiscription: 'Approve for Invoicing',
    taskDate: dayjs().add(2, 'day').format(), taskAssigned: leadData.owner,  taskStatus: true, createdBy: this.currentUserLogin });
    this.emailServices.sendTaskApproveForInvoiceEmail(leadData);
    return leadData;
  }


  updateMeetingMinutesNotes(result, notes, type) {
    this.ordersService.updateMeetingMinutes(result, notes, type, this.currentUserLogin);
    this.ordersService.updateOrder(result, this.currentUserLogin);
  }

  openTaskDialog(action, obj, index) {
    obj.action = action;
    obj.company = this.company;
    const dialogRef = this.dialog.open(TaskDialogueComponent, {
      width:  (obj.action === 'Update' || obj.action === 'Add') ? 'auto' : (obj.action === 'Save Task') ? '90vw' : '800px',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      if ((result === undefined) || (result === {event: 'Cancel'})) {
      } else if (result.event === 'Update') {
        this.ordersService.updateOrder(result.data, this.currentUserLogin);
      } else if (result.event === 'Save Task') {
        if (result.data.task.length > obj.task.length) {
          this.emailServices.sendTaskEmail((result.data.task.length - obj.task.length), result.data);
        }
        this.ordersService.updateOrder(result.data, this.currentUserLogin);
      } else if (result.event === 'Delete') {
        this.ordersService.deleteOrder(result.data, null, this.currentUserLogin);
      } else if (result.event === 'Add') {
        this.ordersService.addNewOrderData(result.data, this.currentUserLogin);
      }
    });
  }

  contactDialog(action, index) {
    const dialogRef = this.dialog.open(CustomersComponent, {
      width: '720px',
    });
    dialogRef.afterClosed().subscribe(result => {
        this.ordersService.addNewOrderData(result.data, this.currentUserLogin);
    });
  }

  openCloneEstimateDialogue(orderData) {
    const dialogRef = this.dialog.open(EstimateCloneDialogueComponent, {
      width: '720px',
      data: orderData
    });
    dialogRef.afterClosed().subscribe(result => {
      const data = this.quotesService.addDataToNewQuote(result.data, 'default', this.currentUserLogin, result.event, result.id);
      this.quotesService.addNewQuote(data);
      result.data.hasQuote = true;
      this.ordersService.updateOrder(result.data, this.currentUserLogin);
      this.router.navigate(['/quote', { id: result.data.id, status: 'default'}] );
    });
  }

    /** @title Auto-resizing textarea */
  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable.pipe(take(1))
        .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  // saveQuoteData( orderData ) {
  //   let data = this.quotesService.addDataToNewQuote(orderData, 'default', this.currentUserLogin, 'empty', 'non');
  //   this.quotesService.addNewQuote(data);
  //   orderData.hasQuote = true;
  //   this.ordersService.updateOrder(orderData);
  // }

  toggleChange(event) {
    const toggle = event.source;
    const group = toggle.buttonToggleGroup;
    group.value = 0;
  }

  /**
   *
   * @param $event What was Right-Clicked
   * @param obj Order Data
   * @description Change Milestone on Menu Right-Click
   */
  radioChange($event, obj, oldMilestone) {
    obj.milestone = $event.value;
    if (obj.milestone !== oldMilestone) {
      const notes = this.ordersService.formatNotesMilestonesMessages(oldMilestone, obj.milestone);
      obj = obj.milestone === 'C. Installation Complete' ? this.approveForInvoicing(obj): obj;
      obj = this.ordersService.updateMeetingMinutes(obj, notes, 'milestoneChange', this.currentUserLogin);
      this.ordersService.updateOrder(obj, this.currentUserLogin);
      obj.milestone === 'C. Installation Complete'? this.installCompleteSnackBar(): '';
    } else {
      this.ordersService.updateOrder(obj, this.currentUserLogin);
    }

  }

  onRightClick(event: MouseEvent, item) {
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    // we open the menu
    // we pass to the menu the information about our object
    this.matMenuTrigger.menuData = { item };

    // we open the menu
    this.matMenuTrigger.openMenu();
}

  installCompleteSnackBar() {
    type MatSnackBarHorizontalPosition = 'left';
      this.snackBar.open('Sending...', 'Notifying Project Manager that install is complete ', {
        duration: 500,
      });
  }

  setTimeLinesChart(data) {
    // this.dataTimeLineChart = [];
    // this.columnNamesForTimeLineChart =  [{ type: 'string', id: 'Term' }, { type: 'string', id: 'Name' },{ type: 'date', id: 'Start' }, { type: 'date', id: 'End' }];

    //   let filteredData = data.filter( t => t.meetingMinutesDetail !== undefined);
    //   filteredData = filteredData.filter( t => t.multiInstall !== undefined);


    //   for (let i = 0; i < filteredData.length; i++) {
    //     let datesActionTypes = filteredData[i].multiInstall.map(a => dayjs(a.installDate));
    //     for (let x = 1; x < datesActionTypes.length; x++) {
    //       console.log(!datesActionTypes[x].isValid())
    //       if (!datesActionTypes[x].isValid()) {
    //         datesActionTypes.splice(x--, 1);
    //       }
    //     }
    //     this.dataTimeLineChart.push([(i+1).toString()]);
    //     this.dataTimeLineChart[i][1]=(data[i].contactName + ' - ' + data[i].opportunityName);

    //     filteredData[i].meetingMinutesDetail.forEach( element => {
    //       switch (element.meetingNotes) {
    //         case 'Milestone Change - Production Design to In Production': this.dataTimeLineChart[i][2] = dayjs(element.dateUpdated).toDate();
    //           this.dataTimeLineChart[i][3] = dayjs.max(datesActionTypes).toDate();
    //         break;
    //         case 'Milestone Change - Operations to In Production': this.dataTimeLineChart[i][2] = dayjs(element.dateUpdated).toDate();
    //           this.dataTimeLineChart[i][3] = dayjs.max(datesActionTypes).toDate();
    //         break;
    //       }
    //     })
    //   }
    //   for (var i = 0; i < this.dataTimeLineChart.length; i++) {
    //     if (this.dataTimeLineChart[i].length !== 4) {
    //       this.dataTimeLineChart.splice(i--, 1);continue;
    //     }
    //     if (dayjs( this.dataTimeLineChart[i][2]).isAfter(dayjs(this.dataTimeLineChart[i][3]))) {
    //       this.dataTimeLineChart.splice(i--, 1); continue;
    //     }
    //     if (this.dataTimeLineChart[i][2]==undefined ) {
    //       this.dataTimeLineChart.splice(i--, 1);continue;
    //     }
    //  }
    //  this.dataTimeLineChart.unshift(['\0', 'Now', new Date(), new Date()]);
    //   this.typeTimeLineChart='Timeline';
    //   this.optionsTimeLineChart =  {

    //   };
    //   this.widthTimeLineChart = this.screenWidth*0.73;
    //   this.heightTimeLineChart = this.screenHeight*0.82;
  }

  ngOnDestroy() {
    this.leadsSubscription.unsubscribe();
    this.leadsWonSubscription.unsubscribe();
    this.staffUsersSubscription.unsubscribe();
  }
}
