import { NgModule } from '@angular/core';
import { Routes, RouterModule , Params, ExtraOptions} from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { OrdersComponent } from './orders/orders.component';
import { OpenclosedComponent } from './orders/openclosed/openclosed.component';
import { LoginComponent } from './auth/login/login.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './auth/verify-email/verify-email.component';
import { AdminComponent } from './admin/admin.component';

import { SecureInnerPagesGuard } from './shared/guard/secure-inner-pages.guard';
import { AdminGuard } from './shared/guard/admin.guard';
import { AngularFireAuthGuard, hasCustomClaim, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/compat/auth-guard';
import { AuthGuard } from './auth/auth.guard';
import { EstimateComponent } from './estimate/estimate.component';
import { CustomerDashboardComponent } from './customers/customer-dashboard/customer-dashboard.component';
import { PricelistComponent } from './estimate/pricelist/pricelist.component';
import { UnauthorizedComponent } from './auth/login/unauthorized/unauthorized.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['sign-in']);


const routes: Routes = [
  { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
  { path: 'sign-in', component: LoginComponent, canActivate: [SecureInnerPagesGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  { path: 'register-user', component: SignUpComponent, canActivate: [SecureInnerPagesGuard]},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'forgot-password', component: ForgotPasswordComponent , canActivate: [SecureInnerPagesGuard] },
  { path: 'admin', component: AdminComponent , canActivate: [AdminGuard] },
  { path: 'completed', component: OpenclosedComponent , canActivate: [AdminGuard] },
  { path: 'verify-email-address', component: VerifyEmailComponent, canActivate: [SecureInnerPagesGuard] },
  { path: 'estimate', component: EstimateComponent, canActivate: [AuthGuard] },
  { path: 'customer-dashboard', component: CustomerDashboardComponent, canActivate: [AuthGuard] },
  { path: 'pricelist', component: PricelistComponent, canActivate: [AuthGuard] },
  { path: 'unauthorize', component: UnauthorizedComponent },
  { path: 'quote',
    component: EstimateComponent,
    children: [
             {
               path: 'quote/:id', // :id is dynamic here
               component: EstimateComponent
             }
           ]
  },
  { path: 'subquote',
    component: EstimateComponent,
    children: [
             {
               path: 'quote/:id', // :id is dynamic here
               component: EstimateComponent
             }
           ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),RouterModule.forRoot(routes, routerOptions),
  RouterModule.forRoot(routes, { anchorScrolling: 'enabled'})],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
