<mat-card>
  <mat-tab-group>
    <mat-tab label="Graphics">
            <div fxLayout="row" fxLayoutAlign="space-between" class="openclosefilter">
                <mat-form-field  class="input-search" fxFlex="40%" fxFlex.xs="100%" appearance="outline">
                  <mat-icon *ngIf="searchShow" matPrefix>search</mat-icon>
                  <input matInput  type="text" (keyup)="doFilter($event.target.value, 'prospect')" [(ngModel)]="searchAll"
                  (focus) = "searchShow = !searchShow" (blur) = "searchShow = true" appearance="outline">
                  <button mat-button *ngIf="searchAll" matSuffix mat-icon-button aria-label="Clear" (click)="searchAll=''; clearFilters(pricelistGraphics); searchShow = true">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <div>
                  <button matSuffix mat-icon-button color="accent" aria-label="add new item" (click)="openPriceListDialog('Add New Item', 'Graphics')">
                    <mat-icon>add_circle
                    </mat-icon>
                  </button>
                </div>
            </div>
          <mat-card *ngIf="isLoading"
            style="display: flex; justify-content: center; align-items: center">
            <mat-progress-spinner
              color="primary"
              mode="indeterminate">
            </mat-progress-spinner>
          </mat-card>
            <div class="table-responsive">
            <mat-table #table [dataSource]="pricelistGraphics" matSort >
            <ng-container matColumnDef="item" class="project">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Item</mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.item }}</mat-cell>
            </ng-container>
      
            <ng-container matColumnDef="description" class="project">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
            </ng-container>
      
            <ng-container matColumnDef="unit" class="project">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Unit</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <input matInput [(ngModel)]="element.unit">
            </mat-cell>
            </ng-container>
      
            <ng-container matColumnDef="price" class="project">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Price</mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.price }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="status" class="project">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
              <mat-cell *matCellDef="let element">Updated on {{ element.dateUpdated | date }} by {{element.updatedBy }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="delete" class="project">
              <mat-header-cell *matHeaderCellDef>Delete/ Edit</mat-header-cell>
              <mat-cell *matCellDef="let element; let i = index;">
                <a fxLayoutAlign="center center" fxLayoutGap="20px" mat-flat-button
                (click)="openPriceListDialog('Delete',element)"
                ><mat-icon aria-hidden="delete" aria-label="delete icon"
                  >delete_forever</mat-icon></a>
                <a fxLayoutAlign="center center" fxLayoutGap="20px" mat-flat-button
                (click)="openPriceListDialog('Update',element)">
                  <mat-icon aria-hidden="delete" aria-label="delete icon"
                    >edit
                  </mat-icon>
                </a>
              </mat-cell>
            </ng-container>
      
            <mat-header-row *matHeaderRowDef="displayedPricelist; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedPricelist;" [ngClass]="{hovered: row.hovered}"
            (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>
          </mat-table>
          <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
             </div>          
             
    </mat-tab>

    <mat-tab label="Channel Letters">
          <div fxLayout="row" fxLayoutAlign="space-between" class="openclosefilter">
              <mat-form-field  class="input-search" fxFlex="40%" fxFlex.xs="100%" appearance="outline">
                <mat-icon *ngIf="searchShow" matPrefix>search</mat-icon>
                <input matInput  type="text" (keyup)="doFilter($event.target.value, 'prospect')" [(ngModel)]="searchAll"
                (focus) = "searchShow = !searchShow" (blur) = "searchShow = true" appearance="outline">
                <button mat-button *ngIf="searchAll" matSuffix mat-icon-button aria-label="Clear" (click)="searchAll=''; clearFilters(pricelistChannelLetters); searchShow = true">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
              <div>
                <button matSuffix mat-icon-button color="accent" aria-label="add new item" (click)="openPriceListDialog('Add New Item', 'Channel Letters')">
                  <mat-icon>add_circle
                  </mat-icon>
                </button>
              </div>
          </div>
        <mat-card *ngIf="isLoading"
          style="display: flex; justify-content: center; align-items: center">
          <mat-progress-spinner
            color="primary"
            mode="indeterminate">
          </mat-progress-spinner>
        </mat-card>
          <div class="table-responsive">
          <mat-table #table [dataSource]="pricelistChannelLetters" matSort >
          <ng-container matColumnDef="item" class="project">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Item</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.item }}</mat-cell>
          </ng-container>
    
          <ng-container matColumnDef="description" class="project">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
          </ng-container>
    
          <ng-container matColumnDef="unit" class="project">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Unit</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <input matInput [(ngModel)]="element.unit">
          </mat-cell>
          </ng-container>
    
          <ng-container matColumnDef="price" class="project">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Price</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.price }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="status" class="project">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
            <mat-cell *matCellDef="let element">Updated on {{ element.dateUpdated | date }} by {{element.updatedBy }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="delete" class="project">
            <mat-header-cell *matHeaderCellDef>Delete/ Edit</mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index;">
              <a fxLayoutAlign="center center" fxLayoutGap="20px" mat-flat-button
              (click)="openPriceListDialog('Delete',element)"
              ><mat-icon aria-hidden="delete" aria-label="delete icon"
                >delete_forever</mat-icon></a>
              <a fxLayoutAlign="center center" fxLayoutGap="20px" mat-flat-button
              (click)="openPriceListDialog('Update',element)">
                <mat-icon aria-hidden="delete" aria-label="delete icon"
                  >edit
                </mat-icon>
              </a>
            </mat-cell>
          </ng-container>]
    
          <mat-header-row *matHeaderRowDef="displayedPricelist; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedPricelist;" [ngClass]="{hovered: row.hovered}"
          (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
          </div>          
          
    </mat-tab>

    <mat-tab label="LED and Lighting">
      <div fxLayout="row" fxLayoutAlign="space-between" class="openclosefilter">
          <mat-form-field  class="input-search" fxFlex="40%" fxFlex.xs="100%" appearance="outline">
            <mat-icon *ngIf="searchShow" matPrefix>search</mat-icon>
            <input matInput  type="text" (keyup)="doFilter($event.target.value, 'prospect')" [(ngModel)]="searchAll"
            (focus) = "searchShow = !searchShow" (blur) = "searchShow = true" appearance="outline">
            <button mat-button *ngIf="searchAll" matSuffix mat-icon-button aria-label="Clear" (click)="searchAll=''; clearFilters(pricelistLed); searchShow = true">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <div>
            <button matSuffix mat-icon-button color="accent" aria-label="add new item" (click)="openPriceListDialog('Add New Item', 'LED and Lighting')">
              <mat-icon>add_circle
              </mat-icon>
            </button>
          </div>
      </div>
    <mat-card *ngIf="isLoading"
      style="display: flex; justify-content: center; align-items: center">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate">
      </mat-progress-spinner>
    </mat-card>
      <div class="table-responsive">
      <mat-table #table [dataSource]="pricelistLed" matSort >
      <ng-container matColumnDef="item" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Item</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.item }}</mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="description" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="unit" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Unit</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <input matInput [(ngModel)]="element.unit">
      </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="price" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Price</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.price }}</mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="status" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
        <mat-cell *matCellDef="let element">Updated on {{ element.dateUpdated | date }} by {{element.updatedBy }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="delete" class="project">
        <mat-header-cell *matHeaderCellDef>Delete/ Edit</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index;">
          <a fxLayoutAlign="center center" fxLayoutGap="20px" mat-flat-button
          (click)="openPriceListDialog('Delete',element)"
          ><mat-icon aria-hidden="delete" aria-label="delete icon"
            >delete_forever</mat-icon></a>
          <a fxLayoutAlign="center center" fxLayoutGap="20px" mat-flat-button
        (click)="openPriceListDialog('Update',element)">
          <mat-icon aria-hidden="delete" aria-label="delete icon"
            >edit
          </mat-icon>
        </a>
        </mat-cell>
      </ng-container>
    
      <mat-header-row *matHeaderRowDef="displayedPricelist; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedPricelist;" [ngClass]="{hovered: row.hovered}"
      (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>          
      
    </mat-tab>

    <mat-tab label="Electrical">
      <div fxLayout="row" fxLayoutAlign="space-between" class="openclosefilter">
          <mat-form-field  class="input-search" fxFlex="40%" fxFlex.xs="100%" appearance="outline">
            <mat-icon *ngIf="searchShow" matPrefix>search</mat-icon>
            <input matInput  type="text" (keyup)="doFilter($event.target.value, 'prospect')" [(ngModel)]="searchAll"
            (focus) = "searchShow = !searchShow" (blur) = "searchShow = true" appearance="outline">
            <button mat-button *ngIf="searchAll" matSuffix mat-icon-button aria-label="Clear" (click)="searchAll=''; clearFilters(pricelistElectrical); searchShow = true">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <div>
            <button matSuffix mat-icon-button color="accent" aria-label="add new item" (click)="openPriceListDialog('Add New Item', 'Electrical')">
              <mat-icon>add_circle
              </mat-icon>
            </button>
          </div>
      </div>
    <mat-card *ngIf="isLoading"
      style="display: flex; justify-content: center; align-items: center">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate">
      </mat-progress-spinner>
    </mat-card>
      <div class="table-responsive">
      <mat-table #table [dataSource]="pricelistElectrical" matSort >
      <ng-container matColumnDef="item" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Item</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.item }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="description" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="unit" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Unit</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <input matInput [(ngModel)]="element.unit">
      </mat-cell>
      </ng-container>

      <ng-container matColumnDef="price" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Price</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.price }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="status" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
        <mat-cell *matCellDef="let element">Updated on {{ element.dateUpdated | date }} by {{element.updatedBy }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="delete" class="project">
        <mat-header-cell *matHeaderCellDef>Delete/ Edit</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index;">
          <a fxLayoutAlign="center center" fxLayoutGap="20px" mat-flat-button
          (click)="openPriceListDialog('Delete',element)"
          ><mat-icon aria-hidden="delete" aria-label="delete icon"
            >delete_forever</mat-icon></a>
          <a fxLayoutAlign="center center" fxLayoutGap="20px" mat-flat-button
          (click)="openPriceListDialog('Update',element)">
            <mat-icon aria-hidden="delete" aria-label="delete icon"
              >edit
            </mat-icon>
          </a>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedPricelist; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedPricelist;" [ngClass]="{hovered: row.hovered}"
      (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>          
      
    </mat-tab>

    <mat-tab label="Sheet Stock">
      <div fxLayout="row" fxLayoutAlign="space-between" class="openclosefilter">
          <mat-form-field  class="input-search" fxFlex="40%" fxFlex.xs="100%" appearance="outline">
            <mat-icon *ngIf="searchShow" matPrefix>search</mat-icon>
            <input matInput  type="text" (keyup)="doFilter($event.target.value, 'prospect')" [(ngModel)]="searchAll"
            (focus) = "searchShow = !searchShow" (blur) = "searchShow = true" appearance="outline">
            <button mat-button *ngIf="searchAll" matSuffix mat-icon-button aria-label="Clear" (click)="searchAll=''; clearFilters(pricelistSheetStock); searchShow = true">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <div>
            <button matSuffix mat-icon-button color="accent" aria-label="add new item" (click)="openPriceListDialog('Add New Item', 'Sheet Stock')">
              <mat-icon>add_circle
              </mat-icon>
            </button>
          </div>
      </div>
    <mat-card *ngIf="isLoading"
      style="display: flex; justify-content: center; align-items: center">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate">
      </mat-progress-spinner>
    </mat-card>
      <div class="table-responsive">
      <mat-table #table [dataSource]="pricelistSheetStock" matSort >
      <ng-container matColumnDef="item" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Item</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.item }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="description" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="unit" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Unit</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <input matInput [(ngModel)]="element.unit">
      </mat-cell>
      </ng-container>

      <ng-container matColumnDef="price" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Price</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.price }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="status" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
        <mat-cell *matCellDef="let element">Updated on {{ element.dateUpdated | date }} by {{element.updatedBy }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="delete" class="project">
        <mat-header-cell *matHeaderCellDef>Delete/ Edit</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index;">
          <a fxLayoutAlign="center center" fxLayoutGap="20px" mat-flat-button
          (click)="openPriceListDialog('Delete',element)"
          ><mat-icon aria-hidden="delete" aria-label="delete icon"
            >delete_forever</mat-icon></a>
          <a fxLayoutAlign="center center" fxLayoutGap="20px" mat-flat-button
          (click)="openPriceListDialog('Update',element)">
            <mat-icon aria-hidden="delete" aria-label="delete icon"
              >edit
            </mat-icon>
          </a>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedPricelist; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedPricelist;" [ngClass]="{hovered: row.hovered}"
      (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>          
      
    </mat-tab>

    <mat-tab label="Steel and Extrusions">
      <div fxLayout="row" fxLayoutAlign="space-between" class="openclosefilter">
          <mat-form-field  class="input-search" fxFlex="40%" fxFlex.xs="100%" appearance="outline">
            <mat-icon *ngIf="searchShow" matPrefix>search</mat-icon>
            <input matInput  type="text" (keyup)="doFilter($event.target.value, 'prospect')" [(ngModel)]="searchAll"
            (focus) = "searchShow = !searchShow" (blur) = "searchShow = true" appearance="outline">
            <button mat-button *ngIf="searchAll" matSuffix mat-icon-button aria-label="Clear" (click)="searchAll=''; clearFilters(pricelistSteel); searchShow = true">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <div>
            <button matSuffix mat-icon-button color="accent" aria-label="add new item" (click)="openPriceListDialog('Add New Item', 'Steel and Extrusions')">
              <mat-icon>add_circle
              </mat-icon>
            </button>
          </div>
      </div>
    <mat-card *ngIf="isLoading"
      style="display: flex; justify-content: center; align-items: center">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate">
      </mat-progress-spinner>
    </mat-card>
      <div class="table-responsive">
      <mat-table #table [dataSource]="pricelistSteel" matSort >
      <ng-container matColumnDef="item" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Item</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.item }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="description" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="unit" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Unit</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <input matInput [(ngModel)]="element.unit">
      </mat-cell>
      </ng-container>

      <ng-container matColumnDef="price" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Price</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.price }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="status" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
        <mat-cell *matCellDef="let element">Updated on {{ element.dateUpdated | date }} by {{element.updatedBy }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="delete" class="project">
        <mat-header-cell *matHeaderCellDef>Delete/ Edit</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index;">
          <a fxLayoutAlign="center center" fxLayoutGap="20px" mat-flat-button
          (click)="openPriceListDialog('Delete',element)"
          ><mat-icon aria-hidden="delete" aria-label="delete icon"
            >delete_forever</mat-icon></a>
          <a fxLayoutAlign="center center" fxLayoutGap="20px" mat-flat-button
          (click)="openPriceListDialog('Update',element)">
            <mat-icon aria-hidden="delete" aria-label="delete icon"
              >edit
            </mat-icon>
          </a>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedPricelist; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedPricelist;" [ngClass]="{hovered: row.hovered}"
      (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>          
      
    </mat-tab>

    <mat-tab label="Labour">
      <div fxLayout="row" fxLayoutAlign="space-between" class="openclosefilter">
          <mat-form-field  class="input-search" fxFlex="40%" fxFlex.xs="100%" appearance="outline">
            <mat-icon *ngIf="searchShow" matPrefix>search</mat-icon>
            <input matInput  type="text" (keyup)="doFilter($event.target.value, 'prospect')" [(ngModel)]="searchAll"
            (focus) = "searchShow = !searchShow" (blur) = "searchShow = true" appearance="outline">
            <button mat-button *ngIf="searchAll" matSuffix mat-icon-button aria-label="Clear" (click)="searchAll=''; clearFilters(pricelistLabour); searchShow = true">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <div>
            <button matSuffix mat-icon-button color="accent" aria-label="add new item" (click)="openPriceListDialog('Add New Item', 'Labour')">
              <mat-icon>add_circle
              </mat-icon>
            </button>
          </div>
      </div>
    <mat-card *ngIf="isLoading"
      style="display: flex; justify-content: center; align-items: center">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate">
      </mat-progress-spinner>
    </mat-card>
      <div class="table-responsive">
      <mat-table #table [dataSource]="pricelistLabour" matSort >
      <ng-container matColumnDef="item" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Item</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.item }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="description" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="unit" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Unit</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <input matInput [(ngModel)]="element.unit">
      </mat-cell>
      </ng-container>

      <ng-container matColumnDef="price" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Price</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.price }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="status" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
        <mat-cell *matCellDef="let element">Updated on {{ element.dateUpdated | date }} by {{element.updatedBy }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="delete" class="project">
        <mat-header-cell *matHeaderCellDef>Delete/ Edit</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index;">
          <a fxLayoutAlign="center center" fxLayoutGap="20px" mat-flat-button
          (click)="openPriceListDialog('Delete',element)">
            <mat-icon aria-hidden="delete" aria-label="delete icon"
              >delete_forever
            </mat-icon>
          </a>
          <a fxLayoutAlign="center center" fxLayoutGap="20px" mat-flat-button
          (click)="openPriceListDialog('Update',element)">
            <mat-icon aria-hidden="delete" aria-label="delete icon"
              >edit
            </mat-icon>
          </a>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedPricelist; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedPricelist;" [ngClass]="{hovered: row.hovered}"
      (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>          
      
    </mat-tab>
  
  </mat-tab-group>
</mat-card>
  
  
  
