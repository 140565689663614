  <!-- Table Prospect -->
  <mat-card>
  <mat-tab-group *ngIf = "authService.isLoggedIn == true" color="accent">
      <mat-tab label="Completed Orders"  >
          <div class="table-responsive">
              <div fxLayout="row" fxLayoutAlign="space-between" class="openclosefilter">
                  <mat-form-field  class="input-search" fxFlex="40%" fxFlex.xs="100%" appearance="outline">
                    <mat-icon *ngIf="searchShow" matPrefix>search</mat-icon>
                    <input matInput  type="text" (keyup)="doFilter($event.target.value, 'prospect')" [(ngModel)]="searchAll"
                    (focus) = "searchShow = !searchShow" (blur) = "searchShow = true" appearance="outline">
                    <!-- <button mat-button *ngIf="searchProspect" matSuffix mat-icon-button aria-label="Clear" (click)="searchProspect=''; clearFilters(dbCompleted); searchShow = true">
                      <mat-icon>close</mat-icon>
                    </button> -->
                    <a *ngIf="searchAll" matSuffix mat-icon-button aria-label="Clear" (click)="searchAll=''; clearFilters(dbCompleted)">
                      <mat-icon>close</mat-icon>
                  </a>
                  </mat-form-field>
              </div>
              <mat-card *ngIf="isLoading"
                style="display: flex; justify-content: center; align-items: center">
                <mat-progress-spinner
                  color="primary"
                  mode="indeterminate">
                </mat-progress-spinner>
              </mat-card>
              <mat-table #table1 [dataSource]="dbCompleted" matSort >

              <ng-container matColumnDef="projectNumber" class="project">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Project#</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.projectNumber }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="contactName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Contact Name</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <a mat-flat-button (click)="openDialog('Update',element, 'open')">{{ element.contactName }}</a>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="opportunityName">
                <mat-header-cell *matHeaderCellDef>Opportunity Name</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.opportunityName }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="dateUpdated">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Date Last Updated</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.updated.seconds *1000 | date: 'dd MMM., yyyy' }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="task">
                  <mat-header-cell *matHeaderCellDef>Task</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <a *ngIf="(element.task) && (element.task.length > 0)"mat-flat-button (click)="openTaskDialog('Save Task',element, 1)">
                      <mat-icon [matBadge]="element.taskCount" matBadgeColor="warn"  matBadgeSize="small" matBadgePosition="above after">alarm</mat-icon>
                    </a>
                    <span *ngIf="(!element.task) || (element.task.length == 0)" style="color: rgb(167, 163, 163)">No Task</span>

                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="quote">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Quote</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <a [routerLink]="['/quote',{ id: element.id, status: 'default'}]" *ngIf="element.hasQuote === true"><mat-icon matTooltip="Click to see Estimate" style="color:#949494;">layers</mat-icon></a>
                    <span *ngIf="!element.hasQuote"> No Quote Available</span>
                  </mat-cell>
                </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
          </div>
        </mat-tab>

 
  </mat-tab-group>
  <div class="main-div" *ngIf = "authService.isLoggedIn == false"  fxLayoutAlign="center center">
    <span>YOU ARE CURRENTLY LOGGED OUT</span>
    <br><button mat-raised-button (click)="authService.SignOut()" mat-flat-button color="accent">Log Me Back In</button>
  </div>
  </mat-card>
