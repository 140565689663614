import { Component, Inject, Optional, EventEmitter, OnInit, NgZone, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {take} from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UntypedFormControl, FormGroupDirective, UntypedFormBuilder, NgForm, Validators, UntypedFormGroup,} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { CustomerService} from 'src/app/service/customer.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Customer } from 'src/app/models/customer.model';
// import * as dayjs from 'dayjs';
import { AbstractControl } from '@angular/forms';
import { AuthService } from 'src/app/service/auth.service';
import { MatSort, MatSortable } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';

declare function require(name:string);

var dayjs = require('dayjs');
var weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);
var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);
var weekday = require('dayjs/plugin/weekday');
dayjs.extend(weekday);

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export function RequireMatch(control: AbstractControl) {
    const selection: any = control.value;
    if (typeof selection === 'string') {
        return { incorrect: true };
    }
    return null;
}


@Component({
  selector: 'app-task-dialogue',
  templateUrl: './task-dialogue.component.html',
  styleUrls: ['./task-dialogue.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TaskDialogueComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'index','taskStatus', 'taskDiscription','taskAssigned', 'taskDate','createdBy', 'delete'];

  valueCtrl = new UntypedFormControl('', [Validators.required, CustomValidator.numberValidator]);
  action: string;
  localData: any;
  selected: true;
  dueDateFormControl = new UntypedFormControl('', [Validators.required]);
  matcher = new MyErrorStateMatcher();
  formTask: UntypedFormGroup;
  formOrder: UntypedFormGroup;
  currentUserLogin: string;
  private itemDoc: AngularFirestoreDocument<Customer>;
  arrTaskOpen = [];
  dataSource = new MatTableDataSource();
  staffUsersSubscription: Subscription;
  dbStaffUsersTask: User[];
  taskArr: any;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatTable) mytable: MatTable<any>;

  constructor(
    fbTask: UntypedFormBuilder,
    private fbOrder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<TaskDialogueComponent>,
    // *** @Optional() is used to prevent error if no data is passed ***
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Customer,
    private _ngZone: NgZone,
    private _snackBar: MatSnackBar,
    private customerService: CustomerService,
    private afs: AngularFirestore,
    public authService: AuthService) {
      const curentUserData = this.authService.userData;
      this.currentUserLogin = curentUserData.displayName;
      this.localData = JSON.parse(JSON.stringify({...data}));
      this.localData = this.changeTaskAssignedFirstNames(this.localData);
      this.action = this.localData.action;
      if (this.localData.task === undefined){
        this.localData.task = [];
       }
       this.taskArr = JSON.parse(JSON.stringify(this.localData.task));
      this.taskArr.forEach((item, i) => {
        item.id = i + 1;
      });
      this.dataSource.data = JSON.parse(JSON.stringify(this.taskArr));
  }

  ngOnInit() {
      this.formOrder = this.fbOrder.group({
        numberInput: ['', [Validators.required, CustomValidator.numberValidator]]
    });
    //  this.localData = {...this.data};
    //  this.dbTask.data = this.localData;
      this.staffUsersSubscription = this.customerService.usersChanged.subscribe(
      (users: User[]) => {
        this.dbStaffUsersTask = JSON.parse(JSON.stringify(users));
        this.dbStaffUsersTask = this.dbStaffUsersTask.filter( t => 'cityimagesigns.com'
          === (t.email.substring(t.email.lastIndexOf( '@' ) + 1)));
    });
      this.customerService.fetchStaffMembers();
  }

  ngAfterViewInit() {
    this.sort.sort(({ id: 'taskStatus', start: 'desc'}) as MatSortable);
    this.dataSource.sort = this.sort;
  }

  doAction() {
    this.localData.task = this.dataSource.data;
    this.taskDateTodayMoment(this.localData);
    this.dialogRef.close({event: this.action, data: this.localData});
  }

  closeDialog(order) {
    this.localData.data = null;
    this.dialogRef.close({event: 'Cancel'});
  }

  addTask() {
    this.taskArr.unshift( {taskDiscription: '',
    taskDate: null, taskAssigned: '',  taskStatus: true, createdBy: this.currentUserLogin });
    this.taskArr.forEach((item, i) => {
      item.id = i + 1;
    });
    // this.dbTask.data = this.localData;
    // this.dataSource.data = this.localData.task;
    this.dataSource.data = JSON.parse(JSON.stringify(this.taskArr));
    // console.log( this.dataSource.data[0].taskAssigned)

    this.sort.sort(({ id: 'taskStatus', start: 'asc'}) as MatSortable);
    this.sort.sort(({ id: 'taskStatus', start: 'desc'}) as MatSortable);
    this.dataSource.sort = this.sort;
    this.mytable.renderRows();
  }

  deleteTask( taskItemIndex ) {
    this.taskArr = this.taskArr.filter( t => t.id !== taskItemIndex);
    this.dataSource.data = JSON.parse(JSON.stringify(this.taskArr));
    this.mytable.renderRows();
  }

  /** @title Auto-resizing textarea */
  @ViewChild('autosize', { read: true, static: false }) autosize: CdkTextareaAutosize;
  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
        .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  openSnackBar(localData, action: string) {
    type MatSnackBarHorizontalPosition = 'left';
    this._snackBar.open(this.localData.contactName + ' - ' + localData.opportunityName, 'TASK UPDATED', {
        duration: 2000,
      });
  }

  public taskDateTodayMoment(obj) {
      // if(obj.hasOwnProperty(prop)){
    for (let i = 0; i < obj.task.length; i++) {
      // if(obj[prop].task.hasOwnProperty(i)){
      obj.task[i].taskDate = dayjs(Date.parse(obj.task[i].taskDate)).format()
    }
    return obj;
  }

  //Change Firstnames to Full names. Previous setup change
   changeTaskAssignedFirstNames (obj) {
      for (let i = 0; i < obj.task.length; i++) {
         // if(obj[prop].task.hasOwnProperty(i)){
         if (obj.task[i].taskAssigned === 'aaron') {
            obj.task[i].taskAssigned = 'Aaron Seutter';
         } else if (obj.task[i].taskAssigned === 'ashley') {
            obj.task[i].taskAssigned = 'Ashley Tillapaugh';
         } else if (obj.task[i].taskAssigned === 'charlon') {
            obj.task[i].taskAssigned = 'Charlon Fabela';
         } else if (obj.task[i].taskAssigned === 'darryl') {
            obj.task[i].taskAssigned = 'Darryl Benn';
         } else if (obj.task[i].taskAssigned === 'gary') {
            obj.task[i].taskAssigned = 'Gary Julius';
         } else if (obj.task[i].taskAssigned === 'kyle') {
            obj.task[i].taskAssigned = 'Kyle Tadei';
         } else if (obj.task[i].taskAssigned === 'matt') {
            obj.task[i].taskAssigned = 'Matthew Pennycook';
         } else if (obj.task[i].taskAssigned === 'michael') {
            obj.task[i].taskAssigned = 'Michael Thompson';
         } else if (obj.task[i].taskAssigned === 'nicole') {
            obj.task[i].taskAssigned = 'Nicole Dauvin';
         } else if (obj.task[i].taskAssigned === 'ron') {
            obj.task[i].taskAssigned = 'Ron Procyk';
         }
       }
      return obj;
   }

}

export class CustomValidator {
  // Validates URL
  static urlValidator(url): any {
     if (url.pristine) {
        return null;
     }
     const URL_REGEXP = /^(http?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
     url.markAsTouched();
     if (URL_REGEXP.test(url.value)) {
        return null;
     }
     return {
        invalidUrl: true
     };
  }
  // Validates passwords
  static matchPassword(group): any {
     const password = group.controls.password;
     const confirm = group.controls.confirm;
     if (password.pristine || confirm.pristine) {
        return null;
     }
     group.markAsTouched();
     if (password.value === confirm.value) {
        return null;
     }
     return {
        invalidPassword: true
     };
  }
  // Validates numbers
  static numberValidator(number): any {
     if (number.pristine) {
        return null;
     }
     const NUMBER_REGEXP = /^-?[\d.]+(?:e-?\d+)?$/;
     number.markAsTouched();
     if (NUMBER_REGEXP.test(number.value)) {
        return null;
     }
     return {
        invalidNumber: true
     };
  }
  // Validates US SSN
  static ssnValidator(ssn): any {
     if (ssn.pristine) {
        return null;
     }
     const SSN_REGEXP = /^(?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
     ssn.markAsTouched();
     if (SSN_REGEXP.test(ssn.value)) {
        return null;
     }
     return {
        invalidSsn: true
     };
  }
  // Validates US phone numbers
  static phoneValidator(number): any {
     if (number.pristine) {
        return null;
     }
     const PHONE_REGEXP = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
     number.markAsTouched();
     if (PHONE_REGEXP.test(number.value)) {
        return null;
     }
     return {
        invalidNumber: true
     };
  }
  // Validates zip codes
  static zipCodeValidator(zip): any {
     if (zip.pristine) {
        return null;
     }
     const ZIP_REGEXP = /^[0-9]{5}(?:-[0-9]{4})?$/;
     zip.markAsTouched();
     if (ZIP_REGEXP.test(zip.value)) {
        return null;
     }
     return {
        invalidZip: true
     };
  }
  }
