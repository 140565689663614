<mat-sidenav-container>
  <mat-sidenav #sidenav role="navigation" [ngClass]="{
    'contentnav': toggleButtonActivated == 'assignment'}">
    <app-sidenav-list (closeSidenav)="sidenav.close()"></app-sidenav-list>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{
    'contentnavtask': toggleButtonActivated === 'assignment',
    'contentnav': toggleButtonActivated !== 'assignment'
  }">
    <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
    <main>
      <router-outlet>
      </router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
<app-toaster [show]="updateAvailable"></app-toaster>

