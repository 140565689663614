import { Component, OnInit, EventEmitter, Output, HostListener } from '@angular/core';
import { Subscription } from 'rxjs/';

import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Output() sidenavToggle = new EventEmitter<void>();
  isAuth = false;
  firstName: string;
  screenHeight: number;
  screenWidth: number;

  constructor(public authService: AuthService) {
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
          this.screenWidth = window.innerWidth;
          let vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

  ngOnInit() {
    // var userData = JSON.parse(localStorage.getItem('user'));
    // var fullName = userData !== null?userData.displayName.split(' '): ' ';
    // this.firstName = fullName[0];
  }

  onToggleSidenav() {
    this.sidenavToggle.emit();
  }

}
