import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Pipe, PipeTransform, EventEmitter, ViewChild, AfterViewInit,
   OnDestroy, OnChanges, ViewChildren, QueryList, Optional, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OrdersService } from '../../service/orders.service';
import { CustomerService } from 'src/app/service/customer.service';
import { AngularFirestore} from '@angular/fire/compat/firestore';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../service/auth.service';

import { Orders } from '../../models/orders.model';

@Component({
  selector: 'app-estimate-clone-dialogue',
  templateUrl: './estimate-clone-dialogue.component.html',
  styleUrls: ['./estimate-clone-dialogue.component.css']
})
export class EstimateCloneDialogueComponent implements OnInit, AfterViewInit, OnDestroy{
  localData: any;
  displayedColumns: string[] = [
    'projectNumber', 'contactName', 'opportunityName', 'milestone'];
    dbExistingJobs = new MatTableDataSource<Orders>();

    searchProspect = '';
    searchShow = true;
    searchAll = '';

    value = 'Clear me';
    company = [];

    // leadsWonSubscription: Subscription;
    closedJobsSubcription: Subscription;
    openJobsSubcription: Subscription;
    // contactsSubscription: Subscription;
    testcontact = [];
    isLoading = true;
    chosenToCopy : boolean;

    @ViewChild('table1', { read: MatSort, static: false }) sort: MatSort;
    @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<any>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Orders,
    private ordersService: OrdersService, private customerService: CustomerService,
    private db: AngularFirestore , public authService: AuthService
  ) { this.localData = JSON.parse(JSON.stringify({...data}));
    this.chosenToCopy === false;
  }

  ngOnInit() {
    this.closedJobsSubcription = this.ordersService.wonOrdersChanged.subscribe(
      (newLeads: Orders[]) => {
        this.isLoading = false;
        this.dbExistingJobs.data = newLeads.filter(value => value.hasQuote === true);
      }
    );
    this.openJobsSubcription = this.ordersService.openCloseChanged.subscribe(
      (newLeads: Orders[]) => {
        this.isLoading = false;
        this.dbExistingJobs.data = (this.dbExistingJobs.data).concat(newLeads.filter(value => value.hasQuote === true));
      }
    );
    this.ordersService.fetchOrdersWon();
    this.ordersService.fetchAvailableOrders('updated');
  }

  ngAfterViewInit() {
    this.dbExistingJobs.paginator = this.paginator.toArray()[0];
  }
  doCopy() {
    this.chosenToCopy = true;
  }

  doFilter(filterValue: string, milestone: string) {
    this.dbExistingJobs.filter = filterValue.trim().toLowerCase();
  }
  doAction(row, action) {
    if ( action === 'copyId') {
      this.dialogRef.close({event: action, data: this.localData, id: row.id});
    } else {
      this.dialogRef.close({event: action, data: this.localData});
    }
  }

  closeDialog(order) {
    this.localData.data = null;
    this.dialogRef.close({event: 'Cancel'});
  }

  ngOnDestroy() {
    this.openJobsSubcription.unsubscribe();
    this.closedJobsSubcription.unsubscribe();
  }
  clearFilters(milestone: any) {
    // milestone.filter = '';
    this.dbExistingJobs.filter = '';
  }

}
