<div >
  <div  fxLayout = "row" >
      <div class="monthlyinvoice-container-left" fxFlex="50%"fxFlexFill >
        <mat-card *ngIf="isLoading">
          <div class="skeleton" >
            <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '5px', height: '35vh' }"></ngx-skeleton-loader>
          </div>
        </mat-card>
        <mat-card *ngIf="!isLoading">
          <div fxLayout = "row" fxLayoutAlign="center center">
            <div fxFlex = "50%">
              <google-chart #chart
                [title]="titleForMilestonePieChart"
                [type]="typeForMilestonePieChart"
                [data]="dataForMilestonePieChart"
                [options]="optionsForMilestonePieChart"
                [width]="widthForMilestonePieChart"
                [height]="heightForMilestonePieChart"
                [columns]="columnNamesForMilestonePieChart"
                [dynamicResize]="dynamicResize"
              >
              </google-chart>
            </div>
            <div  fxFlex = "50%">
              <google-chart #chart
                [title]="titleForMilestoneClosePieChart"
                [type]="typeForMilestoneClosePieChart"
                [data]="dataForMilestoneClosePieChart"
                [options]="optionsForMilestoneClosePieChart"
                [width]="widthForMilestoneClosePieChart"
                [height]="heightForMilestoneClosePieChart"
                [columns]="columnNamesForMilestoneClosePieChart"
                [dynamicResize]="dynamicResize"
                >
              </google-chart>
            </div>
          </div>
        </mat-card>
    </div>
    <div class="monthlyinvoice-container-right" fxFlex="50%"fxFlexFill >
      <mat-card *ngIf="isLoading">
        <div class="skeleton" >
          <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '5px', height: '35vh'}"></ngx-skeleton-loader>
        </div>
      </mat-card>
      <mat-card *ngIf="!isLoading">
        <google-chart #chart
        [title]="titleDataForProjectsOnTheMoveValues"
        [type]="typedataDataForProjectsOnTheMoveValues"
        [data]="dataForProjectsOnTheMoveValues"
        [options]="optionsDataForProjectsOnTheMoveValues"
        [width]="widthDataForProjectsOnTheMoveValues"
        [height]="heightDataForProjectsOnTheMoveValues"
        [columns]="columnNamesForProjectsOnTheMoveValues"
        [dynamicResize]="dynamicResize"

        >
        </google-chart>

      </mat-card>
  </div>
  </div>
  <div  fxLayout = "row" >
    <div class="monthlyinvoice-container-left" fxFlex="50%"fxFlexFill >
      <mat-card *ngIf="isLoading">
        <div class="skeleton" >
          <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '5px', height: '35vh' }"></ngx-skeleton-loader>
        </div>
      </mat-card>
      <mat-card *ngIf="!isLoading">
        <google-chart #chart
        [title]="titledataWeeklyInstallValues"
        [type]="typedataWeeklyInstallValues"
        [data]="dataWeeklyInstallValues"
        [options]="optionsdataWeeklyInstallValues"
        [width]="widthDataForProjectsOnTheMoveValues"
        [height]="heightdataWeeklyInstallValues"
        [columns]="columnNamesdataWeeklyInstallValues"
        [dynamicResize]="dynamicResize"
        >
        </google-chart>

      </mat-card>
  </div>
  <div class="monthlyinvoice-container-right" fxFlex="50%" fxFlexFill >
    <mat-card *ngIf="isLoading">
      <div class="skeleton" >
        <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '5px', height: '35vh' }"></ngx-skeleton-loader>
      </div>
    </mat-card>
    <mat-card *ngIf="!isLoading">
      <google-chart #chart
      [title]="titledataMonthlyInvoiceValues"
      [type]="typedataMonthlyInvoiceValues"
      [data]="dataMonthlyInvoiceValues"
      [options]="optionsdataMonthlyInvoiceValues"
      [width]="widthdataMonthlyInvoiceValues"
      [height]="heightdataMonthlyInvoiceValues"
      [columns]="columnNamesdataMonthlyInvoiceValues"
      [dynamicResize]="dynamicResize"

      >
      </google-chart>

    </mat-card>

</div>
</div>

</div>

