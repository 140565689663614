// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    // apiKey: 'AIzaSyBUfQWl05ZxfCk-YtKgqai109Ny_bY1fhY',
    // authDomain: 'cis-pms.firebaseapp.com',
    // databaseURL: 'https://cis-pms.firebaseio.com',
    // projectId: 'cis-pms',
    // storageBucket: 'cis-pms.appspot.com',
    // messagingSenderId: '1004875129061',
    apiKey: "AIzaSyBUfQWl05ZxfCk-YtKgqai109Ny_bY1fhY",
    authDomain: "cis-pms.firebaseapp.com",
    databaseURL: "https://cis-pms.firebaseio.com",
    projectId: "cis-pms",
    storageBucket: "cis-pms.appspot.com",
    messagingSenderId: "1004875129061",
    appId: "1:1004875129061:web:b7a6091badb285fcf8b40c",
    measurementId: "G-CC0SLLDS4L"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
