import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { AngularFirestore} from '@angular/fire/compat/firestore';
import { Admin, ChangelogInfo } from '../models/admin.model';
import { PriceList } from '../models/quote.model';
import { map } from 'rxjs/operators';
// declare var dayjs: any;

declare function require(name:string);

var dayjs = require('dayjs');
var weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);
var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);
var weekday = require('dayjs/plugin/weekday');
dayjs.extend(weekday);

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private ytdInvoiceSource = new BehaviorSubject(0);
  currentYtdInvoice = this.ytdInvoiceSource.asObservable();

  adminChanged = new Subject<Admin[]>();
  private availableData: Admin[] = [];

  changelogChanged = new Subject<ChangelogInfo[]>();
  private availableChangelogData: ChangelogInfo[] = [];

  priceListChanged = new Subject<PriceList[]>();
  private availableList: PriceList[] = [];

  id: string;
  obj = {};

  constructor(private adminData: AngularFirestore, private priceListData: AngularFirestore , private dbChangelogData: AngularFirestore) { }

  // * Get the total sum of invoice due this year from install calendar *//
  changeYtdNumber(ytd: number) {
    this.ytdInvoiceSource.next(ytd);
  }
  fetchAdminData() {
    this.adminData
    .collection('admin')
    .snapshotChanges()
      .pipe(map(docArray => {
        return docArray.map(doc => {
          return {
            id: doc.payload.doc.id,
            // tslint:disable-next-line: no-string-literal
            financials: doc.payload.doc.data()['financials'],
          };
        });
      }))
      .subscribe((adminSettings: Admin[]) => {
        this.availableData = adminSettings;
        this.adminChanged.next([...this.availableData]);
      });
  }

  fetchPriceListData() {
    this.priceListData
    .collection('inventory')
    .snapshotChanges()
      .pipe(map(docArray => {
        return docArray.map(doc => {
          return {
            id: doc.payload.doc.id,
            description: doc.payload.doc.data()['description'],
            item: doc.payload.doc.data()['item'],
            unit: doc.payload.doc.data()['unit'],
            qauntity: doc.payload.doc.data()['qauntity'],
            price: doc.payload.doc.data()['price'],
            type: doc.payload.doc.data()['type'],
            dateUpdated: doc.payload.doc.data()['dateUpdated'],
            updatedBy: doc.payload.doc.data()['updatedBy'],
          };
        });
      }))
      .subscribe((priceList: PriceList[]) => {
        this.availableList = priceList;
        this.priceListChanged.next([...this.availableList]);
      });
  }

  fetchChangelogData() {
    this.dbChangelogData
    .collection('changelog', ref => ref
    .orderBy('dateAdded', 'desc'))
    .snapshotChanges()
      .pipe(map(docArray => {
        return docArray.map(doc => {
          return {
            // tslint:disable-next-line: no-string-literal
            dateAdded: doc.payload.doc.data()['dateAdded'],
            version: doc.payload.doc.data()['version'],
            description: doc.payload.doc.data()['description'],
          };
        });
      }))
      .subscribe((changelog: ChangelogInfo[]) => {
        this.availableChangelogData = changelog;
        this.changelogChanged.next([...this.availableChangelogData]);
      });
  }


  upDateAPriceList(data) {
    delete data[0].action;
    this.id = data[0].id;
    this.adminData
        .collection('inventory')
        .doc(this.id)
        .set(data[0], { merge: true });
    this.priceListChanged.next([...this.availableList]);
  }

  addNewPriceListItem(data) {
    delete data[0].action;
    this.adminData
        .collection('inventory')
        .add(data[0]);
    this.priceListChanged.next([...this.availableList]);
  }

  deletePriceListItem(data) {
    this.id = data[0].id;
    this.adminData
    .collection('inventory')
    .doc(this.id)
    .delete();
    this.priceListChanged.next([...this.availableList]);
  }

  upDateAdminSettings(data) {
    this.id = data[0].id;
    data[0].financials[0].updated = new Date();
    delete data[0].id;
    this.adminData
        .collection('admin')
        .doc(this.id)
        .set(data[0], { merge: true });
    this.adminChanged.next([...this.availableData]);
  }

  getWeekSumTotals(weeks: number, data, typeOfTotal) {
    let projectionTotal = 0;
    const currentWeek = dayjs().week();
    const firstDayOfWeekDate = dayjs().subtract(0, 'week').startOf('week').format();
    let weekData = data.filter(it => (it.multiInstall !== undefined));
    weekData = weekData.filter(it => (it.multiInstall !== null));
    weekData = weekData.filter(it => ((Object.keys(it.multiInstall).length > 0)));

    if ( typeOfTotal === 'ytd' ) {
      weekData.forEach(element =>  {
        element.multiInstall.forEach(a => {
          let installDate = dayjs(a.installDate);
          if (((dayjs(installDate).week()) <= 52) && ((dayjs(installDate).week()) >=
            currentWeek) && (dayjs(a.installDate).year() === (dayjs().year()))) {
          projectionTotal += (1 * a.progressValue);
          }
        });
      });
    } else {
      weekData.forEach(element =>  {
        element.multiInstall.forEach(a => {
          const installDate = dayjs(a.installDate);
          if ((dayjs(a.installDate).year() === (dayjs().year()) && ((dayjs(installDate).week() === (currentWeek + weeks)) ||
            (dayjs(installDate).week() === (currentWeek + (weeks + 1 ))) ||
            (dayjs(installDate).week() === (currentWeek + (weeks + 2 ))) ||
            (dayjs(installDate).week() === (currentWeek + (weeks + 3 )))))
          ) {
          projectionTotal += (dayjs(a.installDate).isBefore(dayjs(firstDayOfWeekDate).add(112, 'days'))) === true
                ? (1 * a.progressValue) : 0;
          }
        });
      });
    }
    return projectionTotal;
  }
}
