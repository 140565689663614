import {EventEmitter, Injectable} from '@angular/core';
import { Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import { Orders } from '../models/orders.model';
import { ApproveForProduction, PermitAppliedFor, SiteSurvey, MultiInstall  } from '../models/productionData.model';

import { Customer } from 'src/app/models/customer.model';
import { Weeklies } from '../models/weeklies.model';
import { AuthService } from './auth.service';

// import * as dayjs from 'dayjs';
// change to correct dates
declare function require(name: string);

var dayjs = require('dayjs');
var weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);
var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);
var weekday = require('dayjs/plugin/weekday');
dayjs.extend(weekday);

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  id: string;
  ordersChanged = new Subject<Orders[]>();
  ordersCompletedChanged = new Subject<Orders[]>();
  operationsChanged = new Subject<Orders[]>();
  openCloseChanged = new Subject<Orders[]>();
  openCloseCompletedChanged = new Subject<Orders[]>();
  private availableOrders: Orders[] = [];
  lostOrdersChanged = new Subject<Orders[]>();
  private lostOrders: Orders[] = [];
  private availableOperationsOrders: Orders[] = [];
  wonOrdersChanged = new Subject<Orders[]>();
  private availableWonOrders: Orders[] = [];
  private availableCompletedOrders: Orders[] = [];
  activatedToggleButtonEmitter = new Subject<string>();
  private weeklyStream: Weeklies[] = [];
  weekliesChanged = new Subject<Weeklies[]>();



  contactsChanged = new Subject<Customer[]>();
  availableContacts: Customer[] = [];
  category: string;
  loginUser: any;
  currentUserLogin: string;
  currentUserLoginFirebase: string;


  constructor(private dbLeads: AngularFirestore, private dbLeadsOpen: AngularFirestore,
    private dbLeadsLost: AngularFirestore,private dbCompletedOrders: AngularFirestore, private dbNotes: AngularFirestore, public authService: AuthService) {
    // this.loginUser = JSON.parse(localStorage.getItem('user'));
    // let alias = authService.isLoggedIn === true ? this.loginUser.displayName: '';
    // console.log(alias)

    // let curentUserData = this.authService.userData;
    // this.currentUserLoginFirebase = curentUserData.displayName;
      // let name = JSON.parse(localStorage.getItem('user'));
      // this.currentUserLogin = name !== null?name.displayName: '';
  }

  //** OPEN ORDERS */
  fetchAvailableOrders(state: string) {
    this.dbLeadsOpen
    .collection('orders', ref => ref
      .orderBy('updated', 'desc')
      )
    .snapshotChanges()
      .pipe(map(docArray => {
        return docArray.map(doc => {
          return this.getDataFields(doc);
        });
      }))
      .subscribe((orders: Orders[]) => {
        this.availableOrders = orders;
        this.ordersChanged.next([...this.availableOrders]);
        this.openCloseChanged.next([...this.availableOrders]);
      });
  }

  setWeeklyProductionNotes(obj) {
    if (obj.data[0].id) {
      obj.data[0].lastDayOfWeekDate = this.dateConverterObject(obj.data[0].lastDayOfWeekDate);
      obj.data[0].productionTask = !obj.data[0].productionTask ? [] : obj.data[0].productionTask;
      this.id = obj.data[0].id;
      this.dbLeads
          .collection('weeklies')
          .doc(this.id)
          .set(obj.data[0], { merge: true });
      // this.weekliesChanged.next([...this.weeklyStream]);
    } else {
      obj.data[0].week = Number(obj.weekNumber);
      // obj.data[0].lastDayOfWeekDate = dayjs(Date.parse(obj.date)).format();
      obj.data[0].lastDayOfWeekDate = this.dateConverterObject(obj.date);
      // obj.data[0].lastDayOfWeekDate = this.dateConverterObject(obj.date);
      obj.data[0].productionTask = [];
      this.dbLeads.collection('weeklies').add(obj.data[0]);
    }
    this.weekliesChanged.next([...this.weeklyStream]);
  }

  fetchOrdersWon() {
    this.dbLeads
    .collection('orders-won'
    ,ref => ref
      .where('milestone', '!=', 'E. Project Complete').orderBy('milestone', 'desc').orderBy('updated', 'desc')
      )
    .snapshotChanges()
      .pipe(map(docArray => {
        return docArray.map(doc => {
          return this.getDataFields(doc);
        });
      }))
      .subscribe((ordersWon: Orders[]) => {
        this.availableWonOrders = ordersWon;
        this.wonOrdersChanged.next([...this.availableWonOrders]);
      })
  }

  fetchOrdersLost() {
    this.dbLeadsLost
    .collection('orders-lost',ref =>
        ref.where('meetingMinutesDetail','!=', null))
    .snapshotChanges()
      .pipe(map(docArray => {
        return docArray.map(doc => {
          return this.getDataFields(doc);
        });
      }))
      .subscribe((ordersWon: Orders[]) => {
        this.lostOrders = ordersWon;
        this.lostOrdersChanged.next([...this.lostOrders]);
      });
  }

  fetchOrdersIncludeCompleted() {
    this.dbCompletedOrders
    .collection('orders-won'
      )
    .snapshotChanges()
      .pipe(map(docArray => {
        return docArray.map(doc => {
          return this.getDataFields(doc);
        });
      }))
      .subscribe((ordersWon: Orders[]) => {
        this.availableOperationsOrders = ordersWon;
        this.openCloseCompletedChanged.next([...this.availableOperationsOrders]);
      });
  }

  fetchOrdersCompleted() {
    this.dbCompletedOrders
    .collection('orders-won'
    ,ref => { return ref
      .where('milestone', '==', 'E. Project Complete')}
      )
    .snapshotChanges()
      .pipe(map(docArray => {
        return docArray.map(doc => {
          return this.getDataFields(doc);
        });
      }))
      .subscribe((ordersWon: Orders[]) => {
        this.availableCompletedOrders = ordersWon;
        this.ordersCompletedChanged.next([...this.availableCompletedOrders]);
      });
  }
  fetchWeeklies() {
    this.dbNotes
      .collection('weeklies')
      .snapshotChanges()
      .pipe(map(docArray => {
        return docArray.map(doc => {
          return {
            id: doc.payload.doc.id,
            week: doc.payload.doc.data()[ 'week' ],
            lastDayOfWeekDate: doc.payload.doc.data()['lastDayOfWeekDate'],
            note: doc.payload.doc.data()['note'],
            productionTask: doc.payload.doc.data()['productionTask'],
          };
        });
      }))
      .subscribe((weekUpdates: Weeklies[]) => {
        this.weeklyStream = weekUpdates;
        this.weekliesChanged.next([...this.weeklyStream]);
      });
  }



  addNewOrderData(data, user) {
    if (data.milestone === '1. Prospect' || data.milestone === '2. Qualifying' || data.milestone === '3. Design' ||
      data.milestone === '4. Proposal' || data.milestone === '5. Operations' || data.milestone === 'EstimatesMilestone') {
        this.category = 'orders';
    } else {
      this.category = 'orders-won';
    }
    delete data.id;
    delete data.company;
    (data.task !== undefined ) ? data.task  : delete data.task;
    (data.meetingMinutes === undefined ) ? delete data.meetingMinutes  :data.meetingMinutes;
    (data.taskCount === undefined ) ? delete data.taskCount  :data.taskCount;
    (data.projectCoordinator === undefined ) ? delete data.projectCoordinator  : data.projectCoordinator;
    (data.task !== undefined ) ? data.task  : delete data.task;
    (data.approvedForProduction !== undefined ) ? data.approvedForProduction  : delete data.approvedForProduction;
    (data.permitAppliedFor !== undefined ) ? data.permitAppliedFor  : delete data.permitAppliedFor;
    (data.siteSurvey !== undefined ) ? data.siteSurvey  : delete data.siteSurvey;
    (data.multiInstall !== undefined ) ? data.multiInstall  : delete data.multiInstall;

    data.expectedCloseDate = (data.expectedCloseDate == undefined )|| (data.expectedCloseDate == null )?delete data.expectedCloseDate: this.dateConverterObject(data.expectedCloseDate);
    data.hasQuote = false;
    data.updated = new Date();
    data.created = new Date();
    data.updatedBy = user;
    data.opportunityId = 0;
    data.meetingMinutesDetail = [{
      updatedBy: user,
      dateUpdated: dayjs().format(),
      completed: false,
      meetingNotes: '🌱 New Lead',
      type: 'newLead'
    }];
    this.dbLeads.collection(this.category).add(data);

    // (this.category !== 'orders-won') ? this.ordersChanged.next([...this.availableOrders]) :
    // this.wonOrdersChanged.next([...this.availableWonOrders]);
 }

  updateOrderDrag(data, newContainerMilestone, previousIndex , user) {
    if (newContainerMilestone === '1. Prospect' || newContainerMilestone === '2. Qualifying' || newContainerMilestone === '3. Design' || newContainerMilestone === 'EstimatesMilestone'
    || newContainerMilestone === '4. Proposal' || newContainerMilestone === '5. Operations' ) {
      this.category = 'orders';

    } else {
      this.category = 'orders-won';
    }
    for (let key in data[previousIndex]) {
      if (data[previousIndex].hasOwnProperty(key)) {
        (data[previousIndex][key] !== undefined ) ? data[previousIndex][key]  : delete data[previousIndex][key];
      }
    }

    this.id = data[previousIndex].id;
    delete data[previousIndex].estimatedValue;
    delete data[previousIndex].cadValue;
    (data[previousIndex].expectedCloseDate !== undefined ) ? dayjs(data[previousIndex].expectedCloseDate).format() :
     delete data[previousIndex].expectedCloseDate;
    (data[previousIndex].actualCloseDate !== undefined ) ? dayjs(data[previousIndex].actualCloseDate).format() :
     delete data[previousIndex].actualCloseDate;
    data[previousIndex].updated = new Date();
    data[previousIndex].milestone = newContainerMilestone;
    delete data[previousIndex].id;
    this.checkEntries(data[previousIndex], user);

    this.dbLeads
        .collection(this.category)
        .doc(this.id)
        .set(data[previousIndex], { merge: true });
    // (this.category !== 'orders-won') ? this.ordersChanged.next([...this.availableOrders]) :
    // this.wonOrdersChanged.next([...this.availableWonOrders]);
  }

  installCalendarDragUpdate(data, newContainerDate, oldContainerDate, previousIndex, user) {
    if (data[previousIndex].milestone === '1. Prospect' || data[previousIndex].milestone === '2. Qualifying'
    || data[previousIndex].milestone === '3. Design' || data[previousIndex].milestone === 'EstimatesMilestone' ||
    data[previousIndex].milestone === '4. Proposal' || data[previousIndex].milestone === '5. Operations' ) {
      this.category = 'orders';

    } else {
      this.category = 'orders-won';
    }
    for (const index in data[previousIndex].multiInstall ) {
      // if (data[previousIndex].multiInstall[index].installDate === dayjs(Date.parse(oldContainerDate)).format()) {
        if (dayjs(data[previousIndex].multiInstall[index].installDate).format('DD/MM/YYYY') === dayjs(oldContainerDate).format('DD/MM/YYYY')) {
        data[previousIndex].multiInstall[index].installDate = this.dateConverterObject(newContainerDate);
        //   dayjs(Date.parse(newContainerDate)).format());
        } else {
        continue;
      }
    }
    this.id = data[previousIndex].id;
    this.checkEntries(data[previousIndex], user);
    this.dbLeads
    .collection(this.category)
    .doc(this.id)
    .set(data[previousIndex], { merge: true });
    // (this.category !== 'orders-won') ? this.ordersChanged.next([...this.availableOrders]) :
    // this.wonOrdersChanged.next([...this.availableWonOrders]);
  }

  // ** Send Emails ** //
  sendEmail(email, message, cc) {
    this.dbLeads.collection('mail').add({
      to: email,
      cc: cc,
      message: message
    })
  }


  // *** Dialog Delete Action *** //
  deleteOrder(data, milestone, user) {
    if (data.milestone === '1. Prospect' || data.milestone === '2. Qualifying' || data.milestone === '3. Design' || data.milestone === 'EstimatesMilestone' ||
      data.milestone === '4. Proposal' || data.milestone === '5. Operations' ) {
      this.category = 'orders';
    } else {
      this.category = 'orders-won';
    }

    this.dbLeads
        .collection(this.category)
        .doc(data.id)
        .delete();
    // (this.category !== 'orders-won') ? this.ordersChanged.next([...this.availableOrders]) :
    // this.wonOrdersChanged.next([...this.availableWonOrders]);

    this.id = data.id;
    data = this.checkEntries(data,user );
    data.taskCount = this.matBadgeCount(data);
    this.dbLeads.collection('recycleBin').add(data);
    }

  updateOrder(data, user) {
    if (data.milestone === '1. Prospect' || data.milestone === '2. Qualifying' || data.milestone === '3. Design' || data.milestone === 'EstimatesMilestone' ||
      data.milestone === '4. Proposal' || data.milestone === '5. Operations' ) {
      this.category = 'orders';
      this.dbLeads
        .collection('orders-won')
        .doc(data.id)
        .delete();
    } else if ( data.milestone === 'Dead Lead') {
        this.category = 'orders-lost';
        this.dbLeads
        .collection('orders')
        .doc(data.id)
        .delete();
        this.dbLeads
        .collection('orders-won')
        .doc(data.id)
        .delete();
      } else {
        this.category = 'orders-won';
        this.dbLeads
        .collection('orders')
        .doc(data.id)
        .delete();
      }
    this.id = data.id;
    data = this.checkEntries(data, user);
    data.taskCount = this.matBadgeCount(data);

    this.dbLeads
        .collection(this.category)
        .doc(this.id)
        .set(data, { merge: true });
    this.ordersChanged.next([...this.availableOrders]);
    this.wonOrdersChanged.next([...this.availableWonOrders]);
    this.ordersCompletedChanged.next([...this.availableCompletedOrders]);
    this.openCloseChanged.next([...this.availableOrders]);
  }

// ***Check if fields are undefined before saving to the database ***
  private checkEntries(data, user) {

    // delete data.id;
    delete data.company;
    data.updated = new Date();
    data.updatedBy = user;
    (data.task !== undefined ) ? data.task : data.task = [];
    data.expectedCloseDate = (data.expectedCloseDate == undefined )||
    (data.expectedCloseDate === null )?null: this.dateConverterObject(data.expectedCloseDate);
    data.followupBy = (data.followupBy !== undefined )|| (data.followupBy === null ) ? null:
      this.dateConverterObject(data.followupBy);

    data.scheduledInstallDate = (data.scheduledInstallDate === undefined) || (data.scheduledInstallDate === null ) ?null:
      this.dateConverterObject(data.scheduledInstallDate);
    data.actualInstallationDate = (data.actualInstallationDate === undefined ) || (data.actualInstallationDate === null ) ?null:
      this.dateConverterObject(data.actualInstallationDate);
    data.actualCloseDate = (data.actualCloseDate === undefined ) || (data.actualCloseDate === null ) ? null:
      this.dateConverterObject(data.actualCloseDate);
    for (const key in data.multiInstall) {
      var offset = new Date().getTimezoneOffset()*60*1000;
      if (data.multiInstall.hasOwnProperty(key)) {
        data.multiInstall[key].installDate = (data.multiInstall[key].installDate == undefined ) || (data.multiInstall[key].installDate == null )?
        null: this.dateConverterObject((data.multiInstall[key].installDate));
      }
    }

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        (data[key] !== undefined ) ? data[key]  : delete data[key];
      }
    }
    return data;
  }
 dateConverterObject (dateObject) {
   if(dateObject === 'Invalid Date') {
     return null
   }
   if (dateObject instanceof Date) {
    return dateObject = dayjs((dateObject).setHours(12)).format();
   } else {
    dateObject = new Date(dateObject);
    return dateObject = dayjs((dateObject).setHours(12)).format()
   }

 }

 formatNotesMilestonesMessages(oldMilestone, newMilestone) {
  let notes : string;
  if (newMilestone === '5. Operations') {
    let olderMilestone = this.changeMilestoneTitle(oldMilestone);
    let newerMilestone = this.changeMilestoneTitle(newMilestone);
    notes = '🛎️🛎️🛎️ Milestone Change - ' + olderMilestone + ' to ' + newerMilestone;
  } else if (newMilestone === 'C. Installation Complete') {
    let olderMilestone = this.changeMilestoneTitle(oldMilestone);
    let newerMilestone = this.changeMilestoneTitle(newMilestone);
    notes = '✅ Milestone Change - ' + olderMilestone + ' to ' + newerMilestone + '';
  } else if (newMilestone === 'Dead Lead') {
    let olderMilestone = this.changeMilestoneTitle(oldMilestone);
    let newerMilestone = this.changeMilestoneTitle(newMilestone);
    notes = '👎 Moved to Dead Leads';
  } else {
    let olderMilestone = this.changeMilestoneTitle(oldMilestone);
    let newerMilestone = this.changeMilestoneTitle(newMilestone);
    notes = 'Milestone Change - ' + olderMilestone + ' to ' + newerMilestone;
  }
  return notes;
 }

 updateMeetingMinutes(result, notes, type, user) {
  if (!result.meetingMinutesDetail) {
      result.meetingMinutesDetail = [];
  }
  result.meetingMinutesDetail.unshift( {
      updatedBy: user,
      dateUpdated: dayjs().format(),
      completed: false,
      meetingNotes: notes,
      type: type
    });
    return result
  // this.updateOrder(result);
}

 changeMilestoneTitle(milestone) {
  if (milestone === '1. Prospect' ){
        return 'Prospect';
    } else if (milestone === '2. Qualifying'){
        return 'Qualifying';
    } else if (milestone === '3. Design'){
        return 'Design';
    } else if (milestone === 'EstimatesMilestone'){
      return 'Estimate';
    } else if (milestone === '4. Proposal'){
        return 'Proposal';
    } else if (milestone === '5. Operations' ){
        return 'Operations';
    } else if (milestone === 'A. Production Design' ){
      return 'Production Design';
    } else if (milestone === 'B. In Production' ){
      return 'In Production';
    } else if (milestone === 'B. Service Order' ){
      return 'Service';
    } else if (milestone === 'C. Installation Complete' ){
      return 'Installation Complete';
    } else if (milestone === 'D. Customer Follow Up' ){
      return 'Customer Follow Up';
    } else if (milestone === 'E. Project Complete' ){
      return 'Project Complete';
    } else if (milestone === 'Dead Lead' ){
      return 'Dead Leads';
    } else {
      return milestone;
    }
  }

// ***count the amount of task for each order***
  matBadgeCount(order) {
    let count = 0;
    if (Object.keys(order.task).length > 0) {
      for (const index in order.task ) {
        if (order.task[index].taskStatus === true) {
          count++;
        } else {
          count = count;
        }
      }
      return (count === 0) ? null : count;
    } else {
      return null;
    }
  }

  getDataFields(doc) {
    return {
      id: doc.payload.doc.id,
      opportunityId: doc.payload.doc.data()['opportunityId'],
      opportunityName: doc.payload.doc.data()['opportunityName'],
      contactName: doc.payload.doc.data()['contactName'],
      projectCoordinator: doc.payload.doc.data()['projectCoordinator'],
      opportunityDescription: doc.payload.doc.data()['opportunityDescription'],
      folderLocation: doc.payload.doc.data()['folderLocation'],
      created: doc.payload.doc.data()['created'],
      updated : doc.payload.doc.data()['updated'],
      updatedBy : doc.payload.doc.data()['updatedBy'],
      clientId: doc.payload.doc.data()['clientId'],
      owner: doc.payload.doc.data()['owner'],
      milestone: doc.payload.doc.data()['milestone'],
      estimatedValue: doc.payload.doc.data()['estimatedValue'],
      cadValue: doc.payload.doc.data()['cadValue'],
      valuePerDuration: doc.payload.doc.data()['valuePerDuration'],
      actualCloseDate: doc.payload.doc.data()['actualCloseDate'],
      expectedCloseDate: doc.payload.doc.data()['expectedCloseDate'],
      contactId: doc.payload.doc.data()['contactId'],
      email: doc.payload.doc.data()['email'],
      phone: doc.payload.doc.data()['phone'],
      address: doc.payload.doc.data()['address'],
      city: doc.payload.doc.data()['city'],
      tags: doc.payload.doc.data()['tags'],
      renderingFileName: doc.payload.doc.data()['renderingFileName'],
      leadSource: doc.payload.doc.data()['leadSource'],
      followupBy: doc.payload.doc.data()['followupBy'],
      decisionMaker: doc.payload.doc.data()['decisionMaker'],
      billingContact: doc.payload.doc.data()['billingContact'],
      siteContact: doc.payload.doc.data()['siteContact'],
      siteContactPhone: doc.payload.doc.data()['siteContactPhone'],
      siteContactEmail: doc.payload.doc.data()['siteContactEmail'],
      landlordInfo: doc.payload.doc.data()['landlordInfo'],
      paymentMethod: doc.payload.doc.data()['paymentMethod'],
      projectNumber: doc.payload.doc.data()['projectNumber'],
      scheduledInstallDate: doc.payload.doc.data()['scheduledInstallDate'],
      actualInstallationDate: doc.payload.doc.data()['actualInstallationDate'],
      lostReason: doc.payload.doc.data()['lostReason'],
      task: doc.payload.doc.data()['task'],
      taskCount: doc.payload.doc.data()['taskCount'],
      meetingMinutes: doc.payload.doc.data()['meetingMinutes'],
      meetingMinutesDetail: doc.payload.doc.data()['meetingMinutesDetail'],
      isMultiInstall: doc.payload.doc.data()['isMultiInstall'],
      budgetConfirmed: doc.payload.doc.data()['budgetConfirmed'],
      approvedForProduction: doc.payload.doc.data()['approvedForProduction'],
      permitAppliedFor: doc.payload.doc.data()['permitAppliedFor'],
      permitApproved: doc.payload.doc.data()['permitApproved'],
      signedDrawingsReceived: doc.payload.doc.data()['signedDrawingsReceived'],
      engineeringReceived: doc.payload.doc.data()['engineeringReceived'],
      depositReceived: doc.payload.doc.data()['depositReceived'],
      addToCommissionSpreadsheet: doc.payload.doc.data()['addToCommissionSpreadsheet'],
      landlordDocsReceived: doc.payload.doc.data()['landlordDocsReceived'],
      customerAgreementReceived: doc.payload.doc.data()['customerAgreementReceived'],
      siteSurveyReceived: doc.payload.doc.data()['siteSurveyReceived'],
      readyToInvoice: doc.payload.doc.data()['readyToInvoice'],
      siteSurvey: doc.payload.doc.data()['siteSurvey'],
      multiInstall: doc.payload.doc.data()['multiInstall'],
      hasQuote: doc.payload.doc.data()['hasQuote'],
      state: doc.payload.doc.data()['state']
    };
  }
}
