import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs/';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { User } from '../models/user.model';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { CustomerService } from './customer.service';
import { OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
// import { auth } from  'firebase/app';
declare function require(name:string);

var auth = require('firebase/auth');
var provider = new auth.GoogleAuthProvider();


@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any; // Save logged in user data
  staffUsersSubscription: Subscription;
  dbStaffUsers: User[];
  userDataSub = new Subject<any>();

  constructor(
    private customerService: CustomerService,
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      if (user && this.checkIfEmailValid(user.email)) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
      this.userDataSub.next([this.userData]);
    }),error => {
      window.alert(error);

      };
      this.staffUsersSubscription = this.customerService.usersChanged.subscribe(
        (users: User[]) => {
      this.dbStaffUsers = JSON.parse(JSON.stringify(users));
}
);            this.customerService.fetchStaffMembers();


  }



  // Sign in with email/password
  SignIn(email, password) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
    .then((result) => {
      this.ngZone.run(() => {
        let i = 0;
        const loginTime = setInterval (() => {
          if ( i === 2 ) {
            this.router.navigate(['/dashboard']);
            clearInterval(loginTime);
          }
          i++;
          }, 1000);
        });
      this.SetUserData(result.user);
          }).catch((error) => {
        window.alert(error.message);
      });
  }

  // Sign up with email/password
  SignUp(email, password) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        this.SendVerificationMail();
        this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error.message)
      })
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.onAuthStateChanged((user) => {
      user.sendEmailVerification();
})
    .then(() => {
      this.router.navigate(['verify-email-address']);
    })
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      window.alert('Password reset email sent, check your inbox.');
    }).catch((error) => {
      window.alert(error)
    })
  }

  getUserLogin() {
    return this.afAuth.authState.pipe(first()).toPromise();;
  }

  async doSomething() {
    const user = await this.getUserLogin()
    if (user) {
      return user;
    } else {
      // do something else
   }
 }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    // const user = JSON.parse(localStorage.getItem('user'));
    // if (this.userData || (user.emailVerified === true)) {
    //   if( (user.emailVerified === true) || (this.userData.emailVerified === true)){
    //     return true;
    //   }
    // } else {
    //   return false
    // }
    // const user = this.userData;
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  get userDataRecieved(): any {
    console.log(this.userDataSub)
    return this.userDataSub.asObservable();
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(provider);
    // return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
    .then((result) => {
      this.ngZone.run(() => {
        let i = 0;
        const loginTime = setInterval (() => {
          if ( i === 2 ) {
            this.checkIfEmailValid(result.user.email) === true? this.router.navigate(['/dashboard']): this.router.navigate(['/unauthorize']);
            clearInterval(loginTime);
            this.checkIfEmailValid(result.user.email) === true? this.SetUserData(result.user):  null;
          }
          i++;
          }, 1000);
        });

          }).catch((error) => {
        alert(error)
      });
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user) {
    // let compareUser = this.dbStaffUsers.filter( t => t.uid === user.id);
    // let typeUser = compareUser !== undefined? compareUser[0].type: '';
    // let roleUser = compareUser !== undefined? compareUser[0].role: '';
    if ((this.dbStaffUsers.some(t => t.email === user.email)) === false) {
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
      const userData: User = {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        emailVerified: user.emailVerified,
        role: 'editor',
        type: 'adminStaff',
        permissions: [
          {
          setPermissions : false,
          generateEditsEstimates : false,
          approveEstimatesRevisions : false,
          approvesProjectsForProduction : false,
          generateProjects : false,
          moveProjects : false,
          moveProjectOperationsToProduction : false,
          moveProjectProductionToInstallComplete : false,
          moveProjectInstallToFollowup : false,
          makeChangesInstallCalendar : false,
          bookSwitchInstallDates : false,
          projectCloseoutStatusUpdates : false,
          viewOpenProjectServiceProfiles : false,
          authoriseProjectsDocsIncomplete: false,
          }
        ]
      };
      return userRef.set(userData, {
      merge: true
      });
    }
  }

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      this.userData = null;
      localStorage.removeItem('user');
      this.router.navigate(['sign-in']);
    })
  }

  checkIfEmailValid (value) {
    return /^\w+([-+.']\w+)*@?(cityimagesigns.com|signulator.com)$/.test(value.trim());
  }

  ngOnDestroy() {
    this.staffUsersSubscription.unsubscribe();
  }

}
