
  <mat-tab-group class="estimate-header" color="accent" [selectedIndex]="selectedTab" *ngIf="!isLoading" animationDuration="0ms">
  <mat-tab label="QUOTE">
    <div class="header-card">
      <mat-card >
        <div fxLayout = "row">
          <div fxLayoutAlign="start">
            <mat-card-title *ngIf="(localDataOpen.length !== undefined) && (localDataOpen.length > 0)">{{statusParameter | uppercase}} QUOTE {{indexOfCurrentQuote+1}}: {{localDataOpen[0].contactName | uppercase}} - {{localDataOpen[0].opportunityName | uppercase}}</mat-card-title>
          <mat-card-title *ngIf="(localDataClosed.length !== undefined) && (localDataClosed.length > 0)">{{statusParameter | uppercase}} QUOTE {{indexOfCurrentQuote+1}}: {{localDataClosed[0].contactName | uppercase}} - {{localDataClosed[0].opportunityName | uppercase}}</mat-card-title>
          </div>

        </div>
      </mat-card>
    </div>
    <div fxLayout = "row" fxLayoutGap="16px grid" class="estimate tabs-container">
      <div fxFlex="80%" >
        <mat-card >
              <mat-card-header fxLayout = "row" class="quote-header">
                  <div  fxLayoutAlign= "start center">
                    <mat-button-toggle-group class="toggle-estimate" (change)="toggleChange($event)">
                      <mat-button-toggle (click)="openDialogOrders('Update',leadData[0], 'open')"><a><mat-icon matTooltip="Project Details"  style="color:#949494;">edit</mat-icon></a></mat-button-toggle>
                      <mat-button-toggle (click)="contactDialog('Update',contacts[0])"><a><mat-icon matTooltip="Client Details" style="color:#949494">corporate_fare</mat-icon></a></mat-button-toggle>
                      <mat-button-toggle (click)="contactDialog('UpdatePerson',contactperson[0])"><a><mat-icon matTooltip="Edit Lead" style="color:#949494;">person</mat-icon></a></mat-button-toggle>
                      <mat-button-toggle (click)="openTaskDialog('Save Task',leadData[0], 1)"><a><mat-icon matTooltip="Task" style="color:#949494;">alarm</mat-icon></a></mat-button-toggle>
                      <mat-button-toggle *ngIf = "leadData[0].folderLocation !== 'No Folder Link Available'"><a target="_blank" href="{{folderURL}}"><mat-icon matTooltip="Drive Folder" style="color:#949494">folder</mat-icon></a></mat-button-toggle>                    </mat-button-toggle-group>&nbsp;&nbsp;
                    <mat-button-toggle-group (change)="toggleChange($event)">
                      <mat-button-toggle (click)="generatePdf('print')"><a><mat-icon matTooltip="Print"  style="color:#949494;">print</mat-icon></a></mat-button-toggle>
                      <mat-button-toggle (click)="generatePdf('download')"><a><mat-icon matTooltip="Download PDF" style="color:#949494">picture_as_pdf</mat-icon></a></mat-button-toggle>
                      <mat-button-toggle (click)="addNewQuoteData(true)" ><a><mat-icon matTooltip="Duplicate Quote" style="color:#949494;">file_copy</mat-icon></a></mat-button-toggle>
                      <mat-button-toggle (click)="addNewQuoteData(false)"><a><mat-icon matTooltip="Add New Empty Quote" style="color:#949494;">add_box</mat-icon></a></mat-button-toggle>
                    </mat-button-toggle-group>
                  </div>
                  <div  fxLayoutAlign="end" class="button-display" >
                    <div>
                      <!-- <a  class="icon-add-upper" [matMenuTriggerFor]="menu" aria-label="icon-button with a menu">
                        <mat-icon color="primary" class="icon-add" >add_circle</mat-icon>
                      </a> -->
                      <div class="fab " [matMenuTriggerFor]="menu" aria-label="icon-button with a menu"> + </div>
                    </div>

                    <mat-menu #menu="matMenu" xPosition="before">
                      <div class="estimate">
                      <button [disabled] = "isApproved == true" mat-menu-item (click)="addTab()">
                        <mat-icon>list</mat-icon>
                        <span>Add New Sign</span>
                      </button>
                        <hr>
                        <button [disabled] = "isApproved == true" mat-menu-item  [matMenuTriggerFor]="templateMenu">
                          <span>Templates</span>
                        </button>
                        <hr>
                      </div>
                    </mat-menu>

                    <mat-menu #templateMenu="matMenu" xPosition="before">
                      <button *ngFor = "let item of dbTemplates; index as i" [disabled] = "isApproved == true" mat-menu-item (click)="addTemplateData('none', i, 'templateCopy')">
                        {{i+1 +'.  ' }} {{item.title}}
                      </button>
                    </mat-menu>
                  </div>
              </mat-card-header>
              <mat-card-content fxLayout = "column" >
                <mat-grid-list cols="6" rowHeight="27px">
                  <mat-grid-tile
                      *ngFor="let tile of header"
                      [colspan]="tile.cols"
                      [rowspan]="tile.rows"
                      [style.background]="tile.color"
                      [style.color]= "tile.textcolor">
                    {{tile.text}}
                  </mat-grid-tile>
                </mat-grid-list>
                <mat-card *ngIf="isLoading"
                style="display: flex; justify-content: center; align-items: center">
                  <mat-progress-spinner
                    color="accent"
                    mode="indeterminate">
                  </mat-progress-spinner>
                </mat-card>
                <mat-grid-list *ngIf="!isLoading" cols="6" rowHeight="27px" [ngStyle]="{'padding-bottom': 2 + 'px'}">
                  <mat-grid-tile
                      *ngFor="let tile of gridArr | estimateItemsExcludePipe; index as i"
                      [colspan]="tile.cols"
                      [style.border]="tile.border"
                      class="description-tile"
                      >
                      <!-- discription -->
                      <div *ngIf = "tile.discription" [ngStyle]="{'padding-top': 6 + 'px'}">
                        <mat-form-field class="description-quote" appearance="none" [style.width.%]="100">
                          <input matInput [(ngModel)]= "dbQuotes[0].quote[indexOfCurrentQuote].itemListTitle[tile.number].title"

                            [disabled] = "isApproved == true">

                        </mat-form-field>
                      </div>
                       <!-- service discription -->
                       <div *ngIf = "tile.discription === false" class="description-service">
                        {{tile.data}}
                      </div>
                      <!-- quantity -->
                      <div *ngIf = "tile.quantity">
                        <mat-form-field appearance="none" >
                          <input [disabled] = "isApproved == true" [ngStyle]="{'text-align': 'center' ,'padding-top': 6 + 'px'}"
                          type="number" matInput [(ngModel)]="dbQuotes[0].quote[indexOfCurrentQuote].itemListTitle[tile.number].qauntity"
                           >
                        </mat-form-field>
                      </div>
                      <!-- service quantity -->
                      <div *ngIf = "tile.quantity === false">
                        <mat-form-field appearance="none">
                          <input disabled [ngStyle]="{'text-align': 'center' ,'padding-top': 6 + 'px'}"  type="number" matInput value=1
                           >
                        </mat-form-field>
                      </div>
                      <div *ngIf = "tile.price === false"> 0.00</div>
                      <div *ngIf = "tile.amount  === false"> 0.00</div>
                      <div *ngIf = "tile.price"> {{tile.data | currency}}</div>
                      <div *ngIf = "tile.amount"> {{tile.data | currency}}</div>

                  </mat-grid-tile>
                </mat-grid-list>
              <mat-grid-list *ngIf="!isLoading" cols="6" rowHeight="27px">
                <mat-grid-tile
                *ngFor="let tile of gridTotalArr; index as i"
                [colspan]="tile.cols"
                [style.background]="tile.color"
                [style.color]= "tile.textcolor"
                [style.border]="tile.border"
                >
                <div *ngIf = "tile.empty"> {{tile.text}}</div>
                <div *ngIf = "tile.labels"  [ngStyle]="{'text-align': 'center' ,'padding-top': 6 + 'px' ,'padding-bottom': 6 + 'px'}"> {{tile.text}}</div>
                <div *ngIf = "tile.sum"> {{tile.data | currency}}</div>
                <!-- deposit custom labels -->
                <div *ngIf = "(tile.labels === false)">
                  <mat-form-field appearance="none">
                    <input [ngStyle]="{'text-align': 'center' ,'padding-top': 6 + 'px'}"  type="text" matInput [(ngModel)]="dbQuotes[0].quote[indexOfCurrentQuote].deposits[((i-13)/3)].title">
                  </mat-form-field>
                </div>
                <div *ngIf = "tile.sum === false && i === 14">
                  <mat-form-field appearance="none">
                    <input [ngStyle]="{'text-align': 'center' ,'padding-top': 6 + 'px'}"  type="text" matInput [ngModel]= "dbQuotes[0].quote[indexOfCurrentQuote].deposits[0].amount | currency"
                    (blur) = "changeToNumber($event.srcElement.value, 0,'','deposit')">
                  </mat-form-field>
                </div>
                <div *ngIf = "(tile.sum === false) && i > 14">
                  <mat-form-field appearance="none">
                    <input [ngStyle]="{'text-align': 'center' ,'padding-top': 6 + 'px'}"   [ngStyle]="{'text-align': 'center' ,'padding-top': 6 + 'px'}"  type="text" matInput [ngModel]="dbQuotes[0].quote[indexOfCurrentQuote].deposits[((i-14)/3)].amount | currency"
                    (blur) = "changeToNumber($event.srcElement.value, (i-14)/3, '', 'deposit')">
                  </mat-form-field>
                </div>

            </mat-grid-tile>
              </mat-grid-list>
              <div fxLayoutAlign="end center" class="deposit-button-row" [ngStyle]="{'padding-top': 12 + 'px'}">
                <div >
                  <mat-checkbox [ngStyle]="{'padding-right':50 + 'px'}"
                  *ngIf="quoteHasLoaded === true"
                    [checked]="dbQuotes[0].quote[indexOfCurrentQuote].gstExempt"
                    color="primary"
                    (change)="dbQuotes[0]?.quote[indexOfCurrentQuote].gstExempt = !dbQuotes[0].quote[indexOfCurrentQuote].gstExempt; saveQuoteData()"
                    [disabled] = "isApproved == true">
                    GST EXEMPT
                  </mat-checkbox>
                </div>
                <div *ngIf="quoteHasLoaded === true">
                  <mat-checkbox [ngStyle]="{'padding-right': 30 + 'px'}"
                  [checked]="dbQuotes[0].quote[indexOfCurrentQuote].pstExempt"
                  color="primary"
                  (change)="dbQuotes[0].quote[indexOfCurrentQuote].pstExempt = !dbQuotes[0].quote[indexOfCurrentQuote].pstExempt; saveQuoteData()"
                  [disabled] = "isApproved == true">
                  PST EXEMPT</mat-checkbox>
                </div>
                <button mat-raised-button color="primary" (click) = "addRemoveDepositRows('add')">Add Deposit Rows</button>&nbsp;
                <button *ngIf="quoteHasLoaded === true" mat-raised-button [disabled]='(dbQuotes[0].quote[indexOfCurrentQuote].deposits.length === 0) || (dbQuotes[0].quote[indexOfCurrentQuote].deposits === [])' color="accent" (click) = "addRemoveDepositRows('remove')" >Reset</button>
              </div>
              <div *ngIf="quoteHasLoaded === true">
                <mat-form-field class="full-width-card" appearance="outline" >
                  <mat-label>Project Scope Assumptions</mat-label>
                  <textarea matInput style="line-height: 1.6;" [(ngModel)] ="dbQuotes[0].quote[indexOfCurrentQuote].statements"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="8"
                  [disabled] = "isApproved == true"
                  class="estimateTextArea">
                  </textarea>
                </mat-form-field>
              </div>
              </mat-card-content>
              <mat-card-actions>
                <button (click)="saveQuoteData()" [disabled] = "isApproved == true" mat-button>SAVE</button>
              </mat-card-actions>
            </mat-card>
      </div>

      <!-- Client Details Card -->
      <div fxFlex="20%" >
        <mat-card class="estimate">
          <mat-card-header *ngIf="leadData.length > 0">
            <mat-card-title >{{leadData[0].contactName}} </mat-card-title>
            <mat-card-subtitle>Opportunity Name: {{leadData[0].opportunityName}}</mat-card-subtitle>
            <mat-card-subtitle>Project Manager: {{leadData[0].owner}}</mat-card-subtitle>
            <mat-card-subtitle>Project Coordinator: {{leadData[0].projectCoordinator}}</mat-card-subtitle>
            <mat-card-subtitle>Quote ID: {{dbQuotes[0].id}}</mat-card-subtitle>
          </mat-card-header>
          <!-- <mat-card-header *ngIf="localDataClosed.length > 0">
            <mat-card-title >{{localDataClosed[0].contactName}}</mat-card-title>
            <mat-card-subtitle>Opportunity Name: {{localDataClosed[0].opportunityName}}</mat-card-subtitle>
            <mat-card-subtitle>Project Manager: {{localDataClosed[0].owner}}</mat-card-subtitle>
            <mat-card-subtitle>Project Coordinator: {{localDataClosed[0].projectCoordinator}}</mat-card-subtitle>

          </mat-card-header> -->
          <mat-card-content>
          </mat-card-content>
        </mat-card><br>

        <!-- Project Settings -->
        <mat-card class="estimate">
          <mat-card-header>
            <mat-card-title >Project Settings</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div *ngIf="quoteHasLoaded === true">
              <mat-form-field color="accent" appearance="outline">
                <mat-label>Estimate Date</mat-label>
                <input [disabled] = "isApproved == true" matInput [matDatepicker]="picker1" [(ngModel)]="dbQuotes[0].quote[indexOfCurrentQuote].quoteDate">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
            </div>
            <div *ngIf="quoteHasLoaded === true">
              <mat-form-field class="full-width-card" appearance="outline">
                <mat-label>Project Lead Time</mat-label>
                <input matInput placeholder="4 - 6 Weeks" [(ngModel)] ="dbQuotes[0].quote[indexOfCurrentQuote].leadTime"
                [disabled] = "isApproved == true">
              </mat-form-field>
            </div>
            <div *ngIf="quoteHasLoaded === true">
              <mat-form-field class="full-width-card" appearance="outline" >
                <mat-label>Quote Notes</mat-label>
                <textarea matInput style="line-height: 1.6;" [(ngModel)] ="dbQuotes[0].quote[indexOfCurrentQuote].notes"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="8"
                [disabled] = "isApproved == true">
                </textarea>
              </mat-form-field>
            </div>&nbsp;
            <div>
              <div *ngIf="(isApproved === true)" >
                <span>APPROVED BY: {{dbQuotes[0].quote[indexOfCurrentQuote].quoteAprrovalData[quouteApprovalLenght - 1].aprovedBy}}<br /><hr>
                DATE APPROVED: {{this.dbQuotes[0].quote[this.indexOfCurrentQuote].quoteAprrovalData[0].dateApproved | date:'medium'}}<br /><hr>
                REVISION #: {{dbQuotes[0].quote[indexOfCurrentQuote].quoteAprrovalData[quouteApprovalLenght - 1].revNumber}}</span><hr>
              </div>
              <div *ngIf="(dbQuotes[0].quote[indexOfCurrentQuote].quoteAprrovalData[0].overRide === true)&&
              (dbQuotes[0].quote[indexOfCurrentQuote].quoteAprrovalData[0].sendForAprroval === false)">
                <span>OVERIDE APPROVAL BY: {{dbQuotes[0].quote[indexOfCurrentQuote].quoteAprrovalData[quouteApprovalLenght - 1].overRideBy}}<br /><hr>
                DATE OVERIDE: {{dbQuotes[0].quote[indexOfCurrentQuote].quoteAprrovalData[quouteApprovalLenght - 1].dateOveride | date:'medium'}}<br /><hr>
                REVISION NUMBER #: {{dbQuotes[0].quote[indexOfCurrentQuote].quoteAprrovalData[quouteApprovalLenght - 1].revNumber}}</span><hr>
              </div>&nbsp;&nbsp;&nbsp;
              <div  *ngIf="(this.dbQuotes[0].quote[this.indexOfCurrentQuote].quoteAprrovalData[0].sendForAprroval === true)">
                <span> SEND FOR APPROVAL TO:  {{this.dbQuotes[0].quote[this.indexOfCurrentQuote].quoteAprrovalData[0].personToApprove}}<br /><hr>
                  DATE SEND:  {{this.dbQuotes[0].quote[this.indexOfCurrentQuote].quoteAprrovalData[0].dateSendForApproval | date:'medium'}}<br /><hr>
                  SEND BY: {{ this.dbQuotes[0].quote[this.indexOfCurrentQuote].quoteAprrovalData[0].sendForAprrovalBy}}
                </span><hr>
              </div>&nbsp;&nbsp;&nbsp;

              <div>
                <button mat-raised-button color="primary"
                *ngIf="(this.dbQuotes[0].quote[this.indexOfCurrentQuote].quoteAprrovalData[0].sendForAprroval === true)"
                (click)="inputDialog('approvalComplete', 'non')"
                [disabled] = "(currentUserLogin !== 'Matthew Pennycook') &&
                (currentUserLogin !== 'Darryl Benn') &&
                (currentUserLogin !== 'Kyle Tadei') &&
                (currentUserLogin !== 'Ron Procyk') &&
                (currentUserLogin !== 'Gary Julius') &&
                (currentUserLogin !== 'Aaron Seutter')"
                > Approve Quote
              </button>&nbsp;
              <button mat-raised-button color="primary"
                *ngIf="(this.dbQuotes[0].quote[this.indexOfCurrentQuote].quoteAprrovalData[0].sendForAprroval === true)"
                (click)="inputDialog('personToApproveQuote', 'non')">
               Send For Approval Again
              </button>&nbsp;
              <button mat-raised-button color="primary"
                *ngIf="(this.dbQuotes[0].quote[this.indexOfCurrentQuote].quoteAprrovalData[0].sendForAprroval === false) &&
                (this.dbQuotes[0].quote[this.indexOfCurrentQuote].quoteAprrovalData[0].isApproved === false)"
                (click)="inputDialog('personToApproveQuote', 'non')"
                > Send For Approval
              </button>&nbsp;
              <button mat-raised-button color="primary"
              *ngIf="(this.dbQuotes[0].quote[this.indexOfCurrentQuote].quoteAprrovalData[0].isApproved === true)"
              (click)="overideApprovalData()">Overide Approval
            </button>&nbsp;

              </div>
            </div>
          </mat-card-content>
        </mat-card>&nbsp;&nbsp;&nbsp;

                <!-- Other Quotes -->
        <mat-card class="estimate" >
          <mat-card-header >
            <mat-card-title *ngIf="statusParameter === 'default'">Additional Quotes List </mat-card-title>
          </mat-card-header>
          <mat-card-content *ngIf="statusParameter === 'default'">
            <div *ngFor = "let otherQoute of dbOtherQuotes[0].quote; index as i">
              <a *ngIf="otherQoute.isDefault === false" [routerLink]="['/subquote',{ id: id, status: 'non-default', index: i}]"
              > Quote - {{i+1}}
              </a>
            </div>
          </mat-card-content>
          <mat-card-actions>
              <button *ngIf="statusParameter === 'non-default'" mat-raised-button color="primary"
                [routerLink]="['/quote',{ id: id, status: 'default'}]"> Show Default Quote
              </button>&nbsp;
              <button *ngIf="statusParameter === 'non-default'" mat-raised-button color="primary"
                (click)="makeDefault()" [routerLink]="['/quote',{ id: id, status: 'default'}]"
                [ngStyle]="{'margin-top': 10+ 'px'}" > Make Default
              </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>

  </mat-tab>
  <!-- Services Tab -->
  <mat-tab label="SERVICES">
    <div
    class="wrapper mat-elevation-z2">
    <div fxLayout = "row" fxLayoutGap="16px" class="header-card">
      <mat-card fxFlex="33%" >
        <mat-card-header class="estimate">
          <mat-card-title>
            {{ 'Project Management And Sales Commission' | uppercase }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="total-title">
            <div *ngIf="quoteHasLoaded === true">
              <mat-form-field appearance="outline" style="width:40%">
                <mat-label style="font-size:12pt">Project Management Commission Rate</mat-label>
                <input type= "number" matInput placeholder="0.00"  [(ngModel)] ="dbQuotes[0].quote[indexOfCurrentQuote].pmCommissionRate"
                [disabled] = "isApproved == true">
                <mat-hint align="start" > %</mat-hint>
              </mat-form-field>&nbsp;

              <mat-form-field appearance="outline" style="width:40%">
                <mat-label style="font-size:12pt">Sales Commission Rate</mat-label>
                <input type= "number" matInput placeholder="0.00" [(ngModel)] ="dbQuotes[0].quote[indexOfCurrentQuote].salesCommissionRate"
                [disabled] = "isApproved == true">
                <mat-hint align="start" > %</mat-hint>
              </mat-form-field>
            </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="estimate" fxFlex="33%">
        <mat-card-header class="estimate">
          <mat-card-title>
            {{ 'Professional Services' | uppercase }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="quoteHasLoaded === true" class="total-title">
          <mat-form-field fxLayout = "column">
            <input disabled matInput fxFlex="100%"
             [value] = "(dbQuotes[0].quote[indexOfCurrentQuote].professionalTotal | currency) + ' + ' +
             (dbQuotes[0].quote[indexOfCurrentQuote].pmCommissionTotal | currency) + ' + ' +
             (dbQuotes[0].quote[indexOfCurrentQuote].salesCommissionTotal | currency)
             " >
            <mat-hint align="start" >Professional Items + PM Commission + Sales Commission</mat-hint>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
      <mat-card fxFlex="33%" class="estimate">
        <mat-card-header class="estimate">
          <mat-card-title>
            {{ 'Product Services And Risk Management' | uppercase }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="total-title" *ngIf="quoteHasLoaded === true">
          <input disabled matInput
          [value] = "(dbQuotes[0].quote[indexOfCurrentQuote].serviceList | sumQuotes: 'cost' : '0' : 'units' : 'risk') + (dbQuotes[0].quote[indexOfCurrentQuote].serviceList | sumQuotes: 'cost' : '0' : 'units' : 'product') | currency">
        </mat-card-content>
        <mat-card-header class="estimate">
          <mat-card-title>
            {{ 'Construction Services' | uppercase }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="total-title" *ngIf="quoteHasLoaded === true">
          <input disabled matInput
          [value] = "dbQuotes[0].quote[indexOfCurrentQuote].serviceList | sumQuotes: 'cost' : '0' : 'units' : 'construction' | currency" >
        </mat-card-content>
      </mat-card>
    </div>
    <div fxLayout = "column" fxLayoutGap="16px grid" style="padding: 20px">
      <div fxFlex="100%" >
        <mat-card >

          <mat-card-header  fxLayout = "row">
              <div fxFlex="50" fxLayoutAlign="start center">
                <mat-card-title >
                  Professional Services Items
                </mat-card-title>
              </div>
              <div  fxFlex="50" fxLayoutAlign="end center" style="padding-bottom: 10px;">
                  <button mat-raised-button
                    class="delete-tab-button"
                    (click)="saveQuoteData()"
                    [disabled] = "isApproved == true">
                    Save
                  </button>
              </div>

          </mat-card-header>
          <mat-card-content>
            <mat-card *ngIf="isLoading"
            style="display: flex; justify-content: center; align-items: center">
              <mat-progress-spinner
                color="accent"
                mode="indeterminate">
              </mat-progress-spinner>
            </mat-card>
            <!-- Professional line items table -->
            <mat-table  *ngIf="!isLoading" #table class="servicetables" [dataSource]="serviceItemsProfessional" [trackBy]="serviceTrackById">
              <ng-container matColumnDef="wbs">
                  <mat-header-cell *matHeaderCellDef>WBS #</mat-header-cell>
                  <mat-cell *matCellDef="let element; let i = index;">
                    <mat-form-field  appearance="legacy">
                      <input type="text" disabled matInput [(ngModel)]="element.wbs"
                    >
                    </mat-form-field>
                  </mat-cell>
              </ng-container>
              <ng-container matColumnDef="activity" class="project">
                <mat-header-cell *matHeaderCellDef>Activity</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-checkbox
                    [checked]="element.status"
                    [(ngModel)]="element.status"
                    [disabled] = "isApproved == true">
                   </mat-checkbox>
                </mat-cell>

              </ng-container>
              <ng-container matColumnDef="task" class="project">
                  <mat-header-cell *matHeaderCellDef>Task</mat-header-cell>
                  <mat-cell *matCellDef="let element; let i = index">

                    <mat-form-field  appearance="legacy">
                      <input  matInput [(ngModel)]="element.task"
                      [disabled] = "isApproved == true">
                    </mat-form-field>
                  </mat-cell>
              </ng-container>
                <ng-container matColumnDef="units" class="project">
                  <mat-header-cell *matHeaderCellDef>Units</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  fxLayoutAlign="center center" color="accent">
                          <input type="number" matInput [(ngModel)]="element.units"
                          [disabled] = "isApproved == true">
                      </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="cost" class="project">
                  <mat-header-cell *matHeaderCellDef>Cost $ or rate</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  fxLayoutAlign="center center" color="accent">
                          <input matInput [(ngModel)]="element.cost"
                          [disabled] = "isApproved == true">
                      </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="totalCost" class="project">
                  <mat-header-cell *matHeaderCellDef>Total Cost</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  fxLayoutAlign="center center" color="accent">
                          <input matInput disabled  appearance="none" [value]="element.units * element.cost | currency">
                      </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="markup" class="project">
                  <mat-header-cell *matHeaderCellDef>Markup 1+%</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  fxLayoutAlign="center center" color="accent">
                          <input [disabled] = "isApproved == true" type="number" matInput [(ngModel)]="element.markup"
                         >
                      </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="price" class="project">
                  <mat-header-cell *matHeaderCellDef>Price</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  fxLayoutAlign="center center" color="accent">
                          <input disabled matInput [value]="(element.units * element.cost) * element.markup | currency"
                          >
                      </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="notes" class="project">
                  <mat-header-cell *matHeaderCellDef>Descriptions and special requests</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  color="accent">
                          <input [disabled] = "isApproved == true" class="description-notes" matInput [(ngModel)]= "element.notes">
                      </mat-form-field>
                  </mat-cell>
                </ng-container>


                <!-- <ng-container matColumnDef="entredby" class="project">
                  <mat-header-cell *matHeaderCellDef>Project</mat-header-cell>
                  <mat-cell *matCellDef="let element.task">
                     Hello
                  </mat-cell>
                </ng-container> -->

              <mat-header-row *matHeaderRowDef="displayedMisc; sticky: true"></mat-header-row >
              <mat-row *matRowDef="let row; columns: displayedMisc;"></mat-row >
          </mat-table>
          </mat-card-content>

        </mat-card>

      </div>
      <div fxFlex="100%" >
        <mat-card>
          <mat-card-header fxLayout = "row">
            <div fxFlex="50" fxLayoutAlign="start center">
              <mat-card-title >
                Construction Services
              </mat-card-title>
            </div>
            <div  fxFlex="50" fxLayoutAlign="end center" style="padding-bottom: 10px;">
                <button mat-raised-button
                  class="delete-tab-button"
                  (click)="saveQuoteData()"
                  [disabled] = "isApproved == true">
                  Save
                </button>
            </div>
          </mat-card-header>
          <mat-card-content class="table-container">
            <mat-card *ngIf="isLoading"
            style="display: flex; justify-content: center; align-items: center">
              <mat-progress-spinner
                color="accent"
                mode="indeterminate">
              </mat-progress-spinner>
            </mat-card>

            <!-- Table For Professional Services -->
            <mat-table  *ngIf="!isLoading" #table class="servicetables" [dataSource]="serviceItemsConstruction" [trackBy]="serviceTrackById">
              <ng-container matColumnDef="wbs">
                  <mat-header-cell *matHeaderCellDef>WBS #</mat-header-cell>
                  <mat-cell *matCellDef="let element; let i = index;">
                    <mat-form-field  appearance="legacy">
                      <input type="text" disabled matInput [(ngModel)]="element.wbs"
                    >
                    </mat-form-field>
                  </mat-cell>
              </ng-container>
              <ng-container matColumnDef="activity" class="project">
                <mat-header-cell *matHeaderCellDef>Activity</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-checkbox
                    [checked]="element.status"
                    [(ngModel)]="element.status"
                    [disabled] = "isApproved == true">
                   </mat-checkbox>
                </mat-cell>

              </ng-container>
              <ng-container matColumnDef="task" class="project">
                  <mat-header-cell *matHeaderCellDef>Task</mat-header-cell>
                  <mat-cell *matCellDef="let element; let i = index">

                    <mat-form-field  appearance="legacy">
                      <input  matInput disabled [(ngModel)]="element.task"
                      [disabled] = "isApproved == true">

                    </mat-form-field>
                  </mat-cell>
              </ng-container>
                <ng-container matColumnDef="units" class="project">
                  <mat-header-cell *matHeaderCellDef>Units</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  fxLayoutAlign="center center" color="accent">
                          <input type="number" matInput [(ngModel)]="element.units"
                          [disabled] = "isApproved == true"
                          >
                      </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="cost" class="project">
                  <mat-header-cell *matHeaderCellDef>Cost $ or rate</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  fxLayoutAlign="center center" color="accent">
                          <input matInput [(ngModel)]="element.cost"
                          [disabled] = "isApproved == true">
                      </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="totalCost" class="project">
                  <mat-header-cell *matHeaderCellDef>Total Cost</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  fxLayoutAlign="center center" color="accent">
                          <input matInput disabled  appearance="none" [value]="element.units * element.cost | currency">
                      </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="markup" class="project">
                  <mat-header-cell *matHeaderCellDef>Markup 1+%</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  fxLayoutAlign="center center" color="accent">
                          <input  type="number" matInput [(ngModel)]="element.markup"
                          [disabled] = "isApproved == true">
                      </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="price" class="project">
                  <mat-header-cell *matHeaderCellDef>Price</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  fxLayoutAlign="center center" color="accent">
                          <input disabled matInput [value]="(element.units * element.cost) * element.markup | currency"
                          >
                      </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="notes" class="project">
                  <mat-header-cell *matHeaderCellDef>Descriptions and special requests</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  color="accent">
                          <input class="description-notes" matInput [(ngModel)]= "element.notes" [disabled] = "isApproved == true">
                      </mat-form-field>
                  </mat-cell>
                </ng-container>


                <!-- <ng-container matColumnDef="entredby" class="project">
                  <mat-header-cell *matHeaderCellDef>Project</mat-header-cell>
                  <mat-cell *matCellDef="let element.task">
                     Hello
                  </mat-cell>
                </ng-container> -->

              <mat-header-row *matHeaderRowDef="displayedMisc; sticky: true"></mat-header-row >
              <mat-row *matRowDef="let row; columns: displayedMisc;"></mat-row >
          </mat-table>
          </mat-card-content>
      </mat-card>
      </div>

      <!-- Product Services -->
      <div fxFlex="100%" >
        <mat-card>
          <mat-card-header fxLayout = "row">
            <div fxFlex="50" fxLayoutAlign="start center">
              <mat-card-title >
                Product Services
              </mat-card-title>
            </div>
            <div  fxFlex="50" fxLayoutAlign="end center" style="padding-bottom: 10px;">
                <button mat-raised-button
                  class="delete-tab-button"
                  (click)="saveQuoteData()"
                  [disabled] = "isApproved == true">
                  Save
                </button>
            </div>
          </mat-card-header>
          <mat-card-content class="table-container">
            <mat-card *ngIf="isLoading"
            style="display: flex; justify-content: center; align-items: center">
              <mat-progress-spinner
                color="accent"
                mode="indeterminate">
              </mat-progress-spinner>
            </mat-card>

            <!-- Table For Product Services -->
            <mat-table  *ngIf="!isLoading" #table class="servicetables" [dataSource]="serviceItemsProduct" [trackBy]="serviceTrackById">
              <ng-container matColumnDef="wbs">
                  <mat-header-cell *matHeaderCellDef>WBS #</mat-header-cell>
                  <mat-cell *matCellDef="let element; let i = index;">
                    <mat-form-field  appearance="legacy">
                      <input type="text" disabled matInput [(ngModel)]="element.wbs"
                    >
                    </mat-form-field>
                  </mat-cell>
              </ng-container>
              <ng-container matColumnDef="activity" class="project">
                <mat-header-cell *matHeaderCellDef>Activity</mat-header-cell>
                <mat-cell *matCellDef="let element" >
                    <mat-checkbox
                    [checked]="element.status"
                    [(ngModel)]="element.status"
                    [disabled] = "isApproved == true">
                   </mat-checkbox>
                </mat-cell>

              </ng-container>
              <ng-container matColumnDef="task" class="project">
                  <mat-header-cell *matHeaderCellDef>Task</mat-header-cell>
                  <mat-cell *matCellDef="let element; let i = index">

                    <mat-form-field  appearance="legacy">
                      <input  matInput disabled [(ngModel)]="element.task"
                      [disabled] = "isApproved == true">

                    </mat-form-field>
                  </mat-cell>
              </ng-container>
                <ng-container matColumnDef="units" class="project">
                  <mat-header-cell *matHeaderCellDef>Units</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  fxLayoutAlign="center center" color="accent">
                          <input type="number" matInput [(ngModel)]="element.units"
                          [disabled] = "isApproved == true">
                      </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="cost" class="project">
                  <mat-header-cell *matHeaderCellDef>Cost $ or rate</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  fxLayoutAlign="center center" color="accent">
                          <input matInput [(ngModel)]="element.cost"
                          [disabled] = "isApproved == true">
                      </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="totalCost" class="project">
                  <mat-header-cell *matHeaderCellDef>Total Cost</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  fxLayoutAlign="center center" color="accent">
                          <input matInput disabled  appearance="none" [value]="element.units * element.cost | currency">
                      </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="markup" class="project">
                  <mat-header-cell *matHeaderCellDef>Markup 1+%</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  fxLayoutAlign="center center" color="accent">
                          <input  type="number" matInput [(ngModel)]="element.markup"
                          [disabled] = "isApproved == true">
                      </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="price" class="project">
                  <mat-header-cell *matHeaderCellDef>Price</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  fxLayoutAlign="center center" color="accent">
                          <input disabled matInput [value]="(element.units * element.cost) * element.markup | currency"
                          >
                      </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="notes" class="project">
                  <mat-header-cell *matHeaderCellDef>Descriptions and special requests</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  color="accent">
                          <input class="description-notes" matInput [(ngModel)]= "element.notes"
                          [disabled] = "isApproved == true">
                      </mat-form-field>
                  </mat-cell>
                </ng-container>


                <!-- <ng-container matColumnDef="entredby" class="project">
                  <mat-header-cell *matHeaderCellDef>Project</mat-header-cell>
                  <mat-cell *matCellDef="let element.task">
                     Hello
                  </mat-cell>
                </ng-container> -->

              <mat-header-row *matHeaderRowDef="displayedMisc; sticky: true"></mat-header-row >
              <mat-row *matRowDef="let row; columns: displayedMisc;"></mat-row >
          </mat-table>
          </mat-card-content>
      </mat-card>
      </div>

      <!-- Begin Risk Table  -->
      <div fxFlex="100%" >
        <mat-card>
          <mat-card-header fxLayout = "row">
            <div fxFlex="50" fxLayoutAlign="start center">
              <mat-card-title >
                Risk Management
              </mat-card-title>
            </div>
            <div  fxFlex="50" fxLayoutAlign="end center" style="padding-bottom: 10px;">
                <button mat-raised-button
                  class="delete-tab-button"
                  (click)="saveQuoteData()"
                  [disabled] = "isApproved == true">
                  Save
                </button>
            </div>
          </mat-card-header>
          <mat-card-content class="table-container">
            <mat-card *ngIf="isLoading"
            style="display: flex; justify-content: center; align-items: center">
              <mat-progress-spinner
                color="accent"
                mode="indeterminate">
              </mat-progress-spinner>
            </mat-card>

            <!-- Table For Risk Services -->
            <mat-table  *ngIf="!isLoading" #table class="servicetables" [dataSource]="serviceItemsRisk" [trackBy]="serviceTrackById">
              <ng-container matColumnDef="wbs">
                  <mat-header-cell *matHeaderCellDef>WBS #</mat-header-cell>
                  <mat-cell *matCellDef="let element; let i = index;">
                    <mat-form-field  appearance="legacy">
                      <input type="text" disabled matInput [(ngModel)]="element.wbs"
                    >
                    </mat-form-field>
                  </mat-cell>
              </ng-container>
              <ng-container matColumnDef="activity" class="project">
                <mat-header-cell *matHeaderCellDef>Activity</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-checkbox
                    [checked]="element.status"
                    [(ngModel)]="element.status"
                    [disabled] = "isApproved == true">
                   </mat-checkbox>
                </mat-cell>

              </ng-container>
              <ng-container matColumnDef="task" class="project">
                  <mat-header-cell *matHeaderCellDef>Task</mat-header-cell>
                  <mat-cell *matCellDef="let element; let i = index">

                    <mat-form-field  appearance="legacy">
                      <input  matInput disabled [(ngModel)]="element.task"
                      [disabled] = "isApproved == true">

                    </mat-form-field>
                  </mat-cell>
              </ng-container>
                <ng-container matColumnDef="units" class="project">
                  <mat-header-cell *matHeaderCellDef>Units</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  fxLayoutAlign="center center" color="accent">
                          <input type="number" matInput [(ngModel)]="element.units"
                          [disabled] = "isApproved == true">
                      </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="cost" class="project">
                  <mat-header-cell *matHeaderCellDef>Cost $ or rate</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  fxLayoutAlign="center center" color="accent">
                          <input matInput [(ngModel)]="element.cost"
                          [disabled] = "isApproved == true">
                      </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="totalCost" class="project">
                  <mat-header-cell *matHeaderCellDef>Total Cost</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  fxLayoutAlign="center center" color="accent">
                          <input matInput disabled  appearance="none" [value]="element.units * element.cost | currency">
                      </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="markup" class="project">
                  <mat-header-cell *matHeaderCellDef>Markup 1+%</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  fxLayoutAlign="center center" color="accent">
                          <input  type="number" matInput [(ngModel)]="element.markup"
                          [disabled] = "isApproved == true">
                      </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="price" class="project">
                  <mat-header-cell *matHeaderCellDef>Price</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  fxLayoutAlign="center center" color="accent">
                          <input disabled matInput [value]="(element.units * element.cost) * element.markup | currency"
                          >
                      </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="notes" class="project">
                  <mat-header-cell *matHeaderCellDef>Descriptions and special requests</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field  color="accent">
                          <input class="description-notes" matInput [(ngModel)]= "element.notes"
                          [disabled] = "isApproved == true">
                      </mat-form-field>
                  </mat-cell>
                </ng-container>


                <!-- <ng-container matColumnDef="entredby" class="project">
                  <mat-header-cell *matHeaderCellDef>Project</mat-header-cell>
                  <mat-cell *matCellDef="let element.task">
                     Hello
                  </mat-cell>
                </ng-container> -->

              <mat-header-row *matHeaderRowDef="displayedMisc; sticky: true"></mat-header-row >
              <mat-row *matRowDef="let row; columns: displayedMisc;"></mat-row >
          </mat-table>
          </mat-card-content>
      </mat-card>
      </div>
    </div>
  </div>


  </mat-tab>

  <!-- Sign Items Tabs -->
  <mat-tab *ngFor="let tab of tabs; let indexTab = index"  [label]="tab">
    <ng-template matTabLabel>
      <span *ngIf="dbQuotes[0].quote[indexOfCurrentQuote].itemListTitle[indexTab].excludeInLineItem" matBadge="!" matBadgeOverlap="false" matBadgeColor="accent" matBadgeSize="small">{{tab}}</span>
      <span *ngIf="!dbQuotes[0].quote[indexOfCurrentQuote].itemListTitle[indexTab].excludeInLineItem">{{tab}}</span>

  </ng-template>
    <div class="header-card tabs-container">
    <div fxLayout = "row" fxLayoutGap="16px">
      <mat-card fxFlex="33%">
        <mat-card-header class="estimate">
          <mat-card-title>
          MATERIAL COST - {{tab | uppercase}}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="total-title">
          {{dbQuotes[0].quote[indexOfCurrentQuote].quotePriceList | sumQuotes: 'price' : indexTab : 'qauntity' : 'material' | currency  }} x
          <mat-form-field style="width: 20%;">
              <input type="number" matInput [(ngModel)]="dbQuotes[0].quote[indexOfCurrentQuote].itemListTitle[indexTab].markUp" maxlength="3"
              [disabled] = "isApproved == true">
              <mat-hint >Markup %</mat-hint>
          </mat-form-field> % = {{ ((dbQuotes[0].quote[indexOfCurrentQuote].quotePriceList | sumQuotes: 'price' : indexTab : 'qauntity' : 'material') * (dbQuotes[0].quote[indexOfCurrentQuote].itemListTitle[indexTab].markUp /100)) | currency }}
        </mat-card-content>
      </mat-card>
      <mat-card class="estimate" fxFlex="33%">
        <mat-card-header class="estimate">
          <mat-card-title>
          LABOUR COST - {{tab | uppercase}}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="total-title">
          {{dbQuotes[0].quote[indexOfCurrentQuote].quotePriceList | sumQuotes: 'price' : indexTab : 'qauntity' : 'Labour' | currency  }}
        </mat-card-content>
      </mat-card>
      <mat-card fxFlex="33%" class="estimate">
        <mat-card-header class="estimate">
          <mat-card-title>
          TOTAL FOR {{tab | uppercase}}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="total-title">
          <div>
            {{(dbQuotes[0].quote[indexOfCurrentQuote].quotePriceList | sumQuotes: 'price' : indexTab : 'qauntity' : 'Labour') +
            (dbQuotes[0].quote[indexOfCurrentQuote].quotePriceList | sumQuotes: 'price' : indexTab : 'qauntity' : 'material') * (dbQuotes[0].quote[indexOfCurrentQuote].itemListTitle[indexTab].markUp /100) | currency }}
            &nbsp;&nbsp;
          </div>
          <div>  <br>
            <mat-checkbox
              [(ngModel)]="dbQuotes[0].quote[indexOfCurrentQuote].itemListTitle[indexTab].excludeInLineItem"
              [disabled] = "isApproved == true"
              (change)="saveQuoteData()"
              >Exclude From Line Items
            </mat-checkbox>
          </div>
        </mat-card-content>

      </mat-card>
    </div>
    <div fxLayout = "column" fxLayoutGap="16px grid" style = "padding-top: 20px">
      <div fxLayoutAlign="end">

        <div>
        <button mat-raised-button
          class="delete-tab-button"
          (click)="removeTab(indexTab, dbQuotes)"
          [disabled] = "isApproved == true">
          Delete Sign
        </button>
        <button mat-raised-button
          class="delete-tab-button"
          (click)="inputDialog('addTemplateTitle', indexTab)">
          Create Template
        </button>
        <button mat-raised-button
          class="delete-tab-button"
          (click)="duplicateSign(this['lineItem'+indexTab], this['lineItemLabour'+indexTab])"
          [disabled] = "isApproved == true">
          Duplicate
        </button>
        <button mat-raised-button
          class="delete-tab-button"
          (click)="saveQuoteData()"
          [disabled] = "isApproved == true">
          Save
        </button>
      </div>
      </div>
      <div fxFlex="50%" >
        <mat-card>
          <mat-card-header class="estimate">
            <div>
              <mat-card-title>
                {{'Materials Items' | uppercase}}
              </mat-card-title>
            </div>
            <div  fxLayoutAlign="end center" class="button-display" >
                    <div>
                      <div class="fab" [matMenuTriggerFor]="menu" aria-label="icon-button with a menu"> + </div>
                    </div>
                  <mat-menu #menu="matMenu" xPosition="before">
                    <button mat-menu-item (click)="openPriceListDialog('Graphics', indexTab)" [disabled] = "isApproved == true">
                      <mat-icon>style</mat-icon>
                      <span >Add Graphics</span>
                    </button>
                    <button mat-menu-item (click)="openPriceListDialog('Channel Letters', indexTab)" [disabled] = "isApproved == true">
                      <mat-icon>radio_button_checked</mat-icon>
                      <span>Add Channel Letter Material</span>
                    </button>
                    <button mat-menu-item (click)="openPriceListDialog('LED and Lighting', indexTab)" [disabled] = "isApproved == true">
                      <mat-icon>highlight</mat-icon>
                      <span>Add LED and Lighting</span>
                    </button>
                    <button mat-menu-item (click)="openPriceListDialog('Sheet Stock', indexTab)" [disabled] = "isApproved == true">
                      <mat-icon>layers</mat-icon>
                      <span>Add Sheet Stock</span>
                    </button>
                    <button mat-menu-item (click)="openPriceListDialog('Electrical', indexTab)" [disabled] = "isApproved == true" >
                      <mat-icon>electrical_services</mat-icon>
                      <span>Add Electrical</span>
                    </button>
                    <button mat-menu-item (click)="openPriceListDialog('Steel and Extrusions', indexTab)" [disabled] = "isApproved == true">
                      <mat-icon>straighten</mat-icon>
                      <span>Add Steel and Extrusions</span>
                    </button>
                    <button mat-menu-item (click)=" addLineItem('custom', indexTab, 'material')" [disabled] = "isApproved == true">
                      <mat-icon>construction</mat-icon>
                      <span>Custom</span>
                    </button>
                    <button mat-menu-item (click)=" addLineItem('header', indexTab, 'material')" [disabled] = "isApproved == true">
                      <mat-icon>title</mat-icon>
                      <span>Add A Header</span>
                    </button>
                  </mat-menu>
                  </div>
          </mat-card-header>
          <mat-card-content>
            <!-- <mat-table [dataSource]="this[dbQuotes[0].quote[indexOfCurrentQuote].quotePriceList[0]]"> -->

            <!-- <mat-table id="testTable{{indexTab}}" [dataSource]="this['lineItem'+indexTab]"></mat-table> -->
            <mat-card *ngIf="isLoading"
            style="display: flex; justify-content: center; align-items: center">
              <mat-progress-spinner
                color="accent"
                mode="indeterminate">
              </mat-progress-spinner>
            </mat-card>
            <!-- material line items table -->
            <mat-table  matTableExporter [dataSource]="this['lineItem'+indexTab]"
              cdkDropList
              [cdkDropListData]="this['lineItem'+indexTab]"
              (cdkDropListDropped)="dropTable($event, this['lineItem'+indexTab], 'notLabour', indexTab)"
              [cdkDropListDisabled]="dragDisabled"
               #exporter="matTableExporter"
               class="materialtable"
               [trackBy]="professionalTrackById"
               >
              <ng-container matColumnDef="material">
                  <mat-header-cell *matHeaderCellDef>Material</mat-header-cell>
                  <mat-cell *matCellDef="let element; let i = index;">
                    <small [ngStyle]="{'color': 'white'}" >{{element.description}}</small>
                    <mat-form-field   *ngIf="element.id!== 'header'" appearance="none">
                      <input class="itemslinetabs" type="text" matInput [(ngModel)]="element.description"
                      [disabled] = "isApproved == true">
                    </mat-form-field>
                    <mat-form-field   *ngIf="(element.id === 'header')&& element.type === 'material'"
                    [ngStyle]="{'font-size': 18 + 'px', 'font-weight': 'bold', 'text-transform': 'uppercase'}"
                    appearance="none">
                      <input type="text" class="itemslinetabs" matInput [(ngModel)]="element.description"
                      [disabled] = "isApproved == true">
                    </mat-form-field>
                    <div class="handle" cdkDragHandle (mousedown)="dragDisabled = false;">
                      <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                      </svg>
                    </div>
                  </mat-cell>
              </ng-container>
              <ng-container matColumnDef="type" class="project">
                <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <small [ngStyle]="{'color': 'white'}" >{{element.type}}</small>
                  <mat-form-field   appearance="legacy" *ngIf="(element.id !== 'header') && element.id !== 'custom'">
                    <input  matInput [disabled]="(element.type !== 'header')||element.type !== 'custom'" [value]="element.type">
                  </mat-form-field>
                  <mat-form-field   appearance="legacy" *ngIf="(element.id === 'custom')">
                    <input  matInput [(ngModel)]="element.type" [disabled] = "isApproved == true">
                  </mat-form-field>
                </mat-cell>

              </ng-container>
              <ng-container matColumnDef="um" class="project">
                  <mat-header-cell *matHeaderCellDef>U/M</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <small [ngStyle]="{'color': 'white'}" >{{element.unit}}</small>
                    <mat-form-field   *ngIf="(element.id !== 'header') && element.id !== 'custom'">
                      <input [disabled]="element.item" matInput  [value]="element.unit"
                    >
                    </mat-form-field>
                    <mat-form-field   appearance="legacy" *ngIf="(element.id === 'custom')">
                      <input  matInput [(ngModel)]="element.unit" [disabled] = "isApproved == true">
                    </mat-form-field>
                  </mat-cell>
              </ng-container>

              <ng-container matColumnDef="quantity" class="project">
                  <mat-header-cell *matHeaderCellDef>Quantity</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <small [ngStyle]="{'color': 'white'}" >{{element.qauntity}}</small>
                    <mat-form-field   *ngIf="(element.id !== 'header')" appearance="legacy">
                      <input type="number" matInput [(ngModel)]="element.qauntity" [disabled] = "isApproved == true">
                    </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="priceMat" class="project">
                  <mat-header-cell *matHeaderCellDef>Price</mat-header-cell>
                  <mat-cell *matCellDef="let element; let i = index;">
                    <small [ngStyle]="{'color': 'white'}" >{{element.price}}</small>
                    <mat-form-field  *ngIf="(element.id !== 'header') && element.id !== 'custom'" appearance="legacy">
                      <input  [disabled]="(element.type !== 'header')||element.type !== 'custom'" matInput [value]="element.price | number : '1.2-2'">
                      <span matPrefix>$&nbsp;</span>
                    </mat-form-field>
                    <mat-form-field  *ngIf="(element.id === 'custom')" appearance="legacy">
                      <input  matInput [ngModel]="element.price | number : '1.2-2'"
                      (blur) = "changeToNumber($event.srcElement.value, i, 'material',indexTab)"
                      [disabled] = "isApproved == true">
                      <span matPrefix>$&nbsp;</span>
                    </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ext" class="project">
                  <mat-header-cell *matHeaderCellDef>Ext.</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <small [ngStyle]="{'color': 'white'}" >{{(element.qauntity)*(element.price) | currency}}</small>
                      <mat-form-field   *ngIf="element.id !== 'header'" fxLayoutAlign="center center" color="accent">
                          <input [disabled]="element.item" matInput [value]="(element.qauntity)*(element.price) | currency"
                          required>
                      </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="deleteMat" class="project">
                  <mat-header-cell *matHeaderCellDef>Delete</mat-header-cell>
                  <mat-cell *matCellDef="let element; let i = index;">
                    <a fxLayoutAlign="center center" fxLayoutGap="20px" mat-flat-button
                    *ngIf = "isApproved == false"
                    (click)="deleteLineItem(this['lineItem'+indexTab], 'material', i, indexTab)"
                    ><mat-icon aria-hidden="delete" aria-label="delete icon"
                       >delete_forever</mat-icon></a>
                  </mat-cell>
                </ng-container>


                <!-- <ng-container matColumnDef="entredby" class="project">
                  <mat-header-cell *matHeaderCellDef>Project</mat-header-cell>
                  <mat-cell *matCellDef="let element.task">
                     Hello
                  </mat-cell>
                </ng-container> -->

              <mat-header-row *matHeaderRowDef="displayedMaterialColumns; sticky: true"></mat-header-row >
              <mat-row *matRowDef="let row; columns: displayedMaterialColumns;" cdkDragLockAxis="y" cdkDrag [cdkDragData]="row"></mat-row >
          </mat-table>
          </mat-card-content>
          <button mat-raised-button (click)="exporter.exportTable('xlsx',{fileName:leadData[0].contactName + leadData[0].opportunityName})">Excel Download</button>             </mat-card>
      </div>
      <div fxFlex="50%" >
        <mat-card>
          <mat-card-header class="estimate">
            <div>
              <mat-card-title>
                {{'Labour Items' | uppercase}}
              </mat-card-title>
            </div>
            <div  fxLayoutAlign="end center" class="button-display" >
              <div>
                <div class="fab" [matMenuTriggerFor]="menuLabour" aria-label="icon-button with a menu"> + </div>
              </div>
            <mat-menu #menuLabour="matMenu" xPosition="before">
              <button mat-menu-item (click)="openPriceListDialog('Labour', indexTab)" [disabled] = "isApproved == true">
                <mat-icon>style</mat-icon>
                <span >Add Labour Item</span>
              </button>
              <button mat-menu-item (click)=" addLineItem('custom', indexTab, 'Labour')" [disabled] = "isApproved == true">
                <mat-icon>construction</mat-icon>
                <span>Custom</span>
              </button>
              <button mat-menu-item (click)=" addLineItem('header', indexTab, 'Labour')" [disabled] = "isApproved == true">
                <mat-icon>title</mat-icon>
                <span>Add A Header</span>
              </button>
            </mat-menu>
            </div>
          </mat-card-header>
          <mat-card-content>
            <!-- <mat-table [dataSource]="this[dbQuotes[0].quote[indexOfCurrentQuote].quotePriceList[0]]"> -->

            <!-- <mat-table id="testTable{{indexTab}}" [dataSource]="this['lineItem'+indexTab]"></mat-table> -->
            <mat-card *ngIf="isLoading"
            style="display: flex; justify-content: center; align-items: center">
              <mat-progress-spinner
                color="accent"
                mode="indeterminate">
              </mat-progress-spinner>
            </mat-card>
            <!-- Table For Labour -->
            <mat-table  *ngIf="!isLoading" #table [dataSource]="this['lineItemLabour'+indexTab]"
            cdkDropList
            [cdkDropListData]="this['lineItemLabour'+indexTab]"
            (cdkDropListDropped)="dropTable($event, this['lineItemLabour'+indexTab], 'Labour', indexTab)"
            [trackBy]="professionalTrackById"
            class="materialtable">
              <ng-container matColumnDef="labourTask">
                  <mat-header-cell *matHeaderCellDef>Labour Task</mat-header-cell>
                  <mat-cell *matCellDef="let element; let i = index;">
                    <mat-form-field   *ngIf="element.id!== 'header'" appearance="none">
                      <input class="itemslinetabs" type="text" matInput [(ngModel)]="element.description"
                      [disabled] = "isApproved == true">
                    </mat-form-field>
                    <mat-form-field   *ngIf="(element.id === 'header')&& element.type === 'Labour'"
                    [ngStyle]="{'font-size': 18 + 'px', 'font-weight': 'bold', 'text-transform': 'uppercase'}"
                    appearance="none">
                      <input class="itemslinetabs" type="text" matInput [(ngModel)]="element.description"
                      [disabled] = "isApproved == true">
                    </mat-form-field>
                    <div class="handle" cdkDragHandle>
                      <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                      </svg>
                    </div>
                  </mat-cell>
              </ng-container>
              <ng-container matColumnDef="hours" class="project">
                <mat-header-cell *matHeaderCellDef>Hours</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <mat-form-field   appearance="legacy" *ngIf="(element.id !== 'header')">
                    <input type="number" matInput  [(ngModel)]="element.qauntity" [disabled] = "isApproved == true">
                  </mat-form-field>
                </mat-cell>

              </ng-container>
              <ng-container matColumnDef="billingRate" class="project">
                  <mat-header-cell *matHeaderCellDef>Billing Rate</mat-header-cell>
                  <mat-cell *matCellDef="let element; let i = index">
                    <mat-form-field   *ngIf="(element.id !== 'header') && element.id !== 'custom'" appearance="legacy">
                      <input [disabled]="element.item" matInput [value]="element.price | number : '1.2-2'"
                      [disabled] = "isApproved == true">
                      <span matPrefix>$&nbsp;</span>
                    </mat-form-field>
                    <mat-form-field   *ngIf="element.id === 'custom'" appearance="legacy">
                      <input  matInput  [ngModel]="element.price | number : '1.2-2'"
                      (blur) = "changeToNumber($event.srcElement.value, i, 'Labour',indexTab)">
                      <span matPrefix>$&nbsp;</span>
                    </mat-form-field>
                  </mat-cell>
              </ng-container>
                <ng-container matColumnDef="priceLab" >
                  <mat-header-cell *matHeaderCellDef>Price</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-form-field   *ngIf="element.id !== 'header'" fxLayoutAlign="center center" color="accent">
                          <input [disabled]="element.item" matInput [value]="(element.qauntity)*(element.price) | currency"
                          required>
                      </mat-form-field>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="delete" class="project">
                  <mat-header-cell *matHeaderCellDef>Delete</mat-header-cell>
                  <mat-cell *matCellDef="let element; let i = index;">
                      <a fxLayoutAlign="center center" fxLayoutGap="20px" mat-flat-button
                      *ngIf = "isApproved == false"
                      (click)="deleteLineItem(this['lineItemLabour'+indexTab], 'Labour', i, indexTab)"
                      ><mat-icon aria-hidden="delete" aria-label="delete icon"
                         >delete_forever</mat-icon></a>
                  </mat-cell>
                </ng-container>


                <!-- <ng-container matColumnDef="entredby" class="project">
                  <mat-header-cell *matHeaderCellDef>Project</mat-header-cell>
                  <mat-cell *matCellDef="let element.task">
                     Hello
                  </mat-cell>
                </ng-container> -->

              <mat-header-row *matHeaderRowDef="displayedLabourColumns; sticky: true"></mat-header-row >
              <mat-row *matRowDef="let row; columns: displayedLabourColumns;" cdkDragLockAxis="y" cdkDrag [cdkDragData]="row"></mat-row >
          </mat-table>
          </mat-card-content>
      </mat-card>
      </div>
      <div fxFlex="50%">
        <mat-card class="header-card">
        <div [ngStyle]="{'padding': 10 + 'px'}">
        <button mat-raised-button
          class="delete-tab-button"
          (click)="removeTab(indexTab, dbQuotes)"
          [disabled] = "isApproved == true">
          Delete Sign
        </button>
        <button mat-raised-button
          class="delete-tab-button"
          (click)="inputDialog('addTemplateTitle', indexTab)">
          Create Template
        </button>
        <button mat-raised-button
          class="delete-tab-button"
          (click)="duplicateSign(this['lineItem'+indexTab], this['lineItemLabour'+indexTab])"
          [disabled] = "isApproved == true">
          Duplicate
        </button>
        <button mat-raised-button
          class="delete-tab-button"
          (click)="saveQuoteData()"
          [disabled] = "isApproved == true">
          Save
        </button>
      </div>
      </mat-card>
      </div>

    </div>
    </div>
  </mat-tab>
</mat-tab-group>
