import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'installDate'
})
export class InstallDatePipe implements PipeTransform {

  transform(obj: any): any {
    console.log(obj);
    if (!obj) { return obj = [{contactName : 'Add new Install'}];
    } else {
        return obj;
    }
  }
}
