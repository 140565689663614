<mat-card>
  <mat-tab-group>
    <mat-tab label="Companies">
      <div class="table-responsive">
        <div fxLayout="row" fxLayoutAlign="space-between" class="openclosefilter">
            <mat-form-field  class="input-search" fxFlex="40%" fxFlex.xs="100%" appearance="outline">
              <mat-icon *ngIf="searchShow" matPrefix>search</mat-icon>
              <input matInput  type="text" (keyup)="doFilter($event.target.value, 'prospect')" [(ngModel)]="searchAll"
              (focus) = "searchShow = !searchShow" (blur) = "searchShow = true" appearance="outline">
              <button mat-button *ngIf="searchCompany" matSuffix mat-icon-button aria-label="Clear" (click)="searchCompany=''; clearFilters(contacts); searchShow = true">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
        </div>
        <mat-card *ngIf="isLoading"
          style="display: flex; justify-content: center; align-items: center">
          <mat-progress-spinner
            color="primary"
            mode="indeterminate">
          </mat-progress-spinner>
        </mat-card>
        <mat-table #table1 [dataSource]="contacts" matSort >
          <ng-container matColumnDef="name" class="project">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Company Name</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="type" class="project">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.tags }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="updated" class="project">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Updated</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.updated }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{hovered: row.hovered}"
          (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"  (click)="contactDialog('Update',row)"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>
    </mat-tab>
    <mat-tab label="Contacts">
      <div class="table-responsive">
        <div fxLayout="row" fxLayoutAlign="space-between" class="openclosefilter">
            <mat-form-field  class="input-search" fxFlex="40%" fxFlex.xs="100%" appearance="outline">
              <mat-icon *ngIf="searchShow" matPrefix>search</mat-icon>
              <input matInput  type="text" (keyup)="doFilter($event.target.value, 'prospect')" [(ngModel)]="searchAll"
              (focus) = "searchShow = !searchShow" (blur) = "searchShow = true" appearance="outline">
              <button mat-button *ngIf="searchCompany" matSuffix mat-icon-button aria-label="Clear" (click)="searchCompany=''; clearFilters(contactperson); searchShow = true">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
        </div>
        <mat-card *ngIf="isLoading"
          style="display: flex; justify-content: center; align-items: center">
          <mat-progress-spinner
            color="primary"
            mode="indeterminate">
          </mat-progress-spinner>
        </mat-card>
        <mat-table #table2 [dataSource]="contactperson" matSort >
          <ng-container matColumnDef="name" class="project">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Coontact Name</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="company" class="project">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Company</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.organization }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="clientId" class="project">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Company ID#</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.clientId }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="id" class="project">
            <mat-header-cell *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.id }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="updated" class="project">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Updated</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.updated }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedPersonColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedPersonColumns;" [ngClass]="{hovered: row.hovered}"
          (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"  (click)="contactDialog('UpdatePerson',row)"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>
    </mat-tab>

  </mat-tab-group>
</mat-card>


