import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { PriceList } from 'src/app/models/quote.model';
import { AdminService } from 'src/app/service/admin.service';
import { AuthService } from 'src/app/service/auth.service';
import { EstimateDialogueComponent } from '../estimate-dialogue/estimate-dialogue.component';

declare function require(name:string);

var dayjs = require('dayjs');
var weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);
var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);
var weekday = require('dayjs/plugin/weekday');
dayjs.extend(weekday);

@Component({
  selector: 'app-pricelist-dialogue',
  templateUrl: './pricelist-dialogue.component.html',
  styleUrls: ['./pricelist-dialogue.component.css']
})
export class PricelistDialogueComponent implements OnInit {
  actionType: string;
  displayedPricelist: string[] = ['item', 'description', 'unit', 'price', 'type'];
  loginUser: any;
  localData: any;
  pricelistSubscription: Subscription;
  pricelistGraphics: MatTableDataSource<PriceList>;
  currentUserLogin: any;

  constructor(
    public dialogRef: MatDialogRef<EstimateDialogueComponent>,
    public authService: AuthService,
    // *** @Optional() is used to prevent error if no data is passed ***
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private priceListService: AdminService, private _snackBar: MatSnackBar
  ) {
    const curentUserData = this.authService.userData;
    this.currentUserLogin = curentUserData.displayName;
    // this.loginUser = JSON.parse(localStorage.getItem('user'));
    this.actionType = data.action;
  }

  ngOnInit(): void {
    this.pricelistSubscription = this.priceListService.priceListChanged.subscribe(
      (price: PriceList[]) => {
        // this.dbPriceList  = JSON.parse(JSON.stringify(price));
        // this.dbPriceList = this.dbPriceList.filter(value => value.type === this.actionType);
        if ( typeof this.data === 'string') {
          this.localData = [];
          this.localData.push (
            {
              action: 'Add New Item',
              description: '',
              item: '',
              unit: '',
              qauntity: 1,
              price: 0.00,
              type: this.data,
              dateUpdated: dayjs().format(),
              updatedBy: this.currentUserLogin,
              tabIndex: 0,
            }
          )
          this.actionType = this.localData[0].action;
        } else {
          this.localData  = JSON.parse(JSON.stringify(price));
          this.localData = this.localData.filter(value => value.id === this.data.id );
        }
     }
    );
    this.priceListService.fetchPriceListData();
  }

  closeDialog() {
    this.dialogRef.close({event: 'Cancel'});
  }

  doAction(obj) {
    // const filterResult = this.dbPriceList.filter( t => t.id = id);
    // const filterResult = obj.id;
    obj[0].updatedBy = this.currentUserLogin;
    obj[0].dateUpdated = dayjs().format();
    const typeOfItem = obj.type;
    this.dialogRef.close({event: this.actionType, data: obj, type: typeOfItem});
  }

  ngOnDestroy() {
    this.pricelistSubscription.unsubscribe();
    // this.contactsSubscription.unsubscribe();
  }
  

}
