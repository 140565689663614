import { Inject, OnDestroy } from '@angular/core';
import { Component, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { AdminService } from '../admin.service';
import { CustomerService } from '../customer.service';

@Component({
  selector: 'app-input-dialogue',
  templateUrl: './input-dialogue.component.html',
  styleUrls: ['./input-dialogue.component.css']
})
export class InputDialogueComponent implements OnInit, OnDestroy {
  actionType: string;
  title: string;
  ccPersons: any [];
  subtasks: any;
  staffUsersSubscription: Subscription;
  dbStaffUsers: User[];
  emailNotes: string;
  
  constructor(
    public dialogRef: MatDialogRef<InputDialogueComponent>,
  // *** @Optional() is used to prevent error if no data is passed ***
  @Optional() @Inject(MAT_DIALOG_DATA) public action: string,
  private priceListService: AdminService,
  private customerService: CustomerService

) {
  this.actionType = action;
}

  ngOnInit(): void {
    this.staffUsersSubscription = this.customerService.usersChanged.subscribe(
      (users: User[]) => {
        this.dbStaffUsers = JSON.parse(JSON.stringify(users));
        this.dbStaffUsers = this.dbStaffUsers.filter( t => t.type === 'adminStaff');
        this.ccPersons = this.getStaffInfo(this.dbStaffUsers);
      }
    );
    this.customerService.fetchStaffMembers();
  }

  getStaffInfo(data) {
    let persons = [];
    for (let i = 0; i < data.length; i++) {
      persons.push(
        {email: data[i].email, name: data[i].displayName, 
          completed: (data[i].displayName === 'Kyle Tadei')||(data[i].displayName === 'Nicole Dauvin') ? true:false}
      )
    }
    return persons;
  }

  doAction(personToApprove, cc, notes) {
    let accSplit: any;
    if (!notes) {
      notes = 'Non'
    };
    if ( this.action !== 'addTemplateTitle') {
      let filterredResult = cc.filter( t => t.completed === true);
    let acc = filterredResult.map( t => { return t.email})
    accSplit = acc.join(';');
    } else {
     accSplit = '';
    }
    this.dialogRef.close({event: this.action, data: personToApprove, ccData: accSplit , notes});
  }

  closeDialog() {
    this.dialogRef.close({event: 'Cancel'});
  }

  ngOnDestroy() {
    this.staffUsersSubscription.unsubscribe();
  }
}
