import {  Component, HostListener, NgZone, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {MatDialog} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Admin } from '../models/admin.model';
import { Orders } from '../models/orders.model';
import { AdminService } from '../service/admin.service';
import { AuthService } from '../service/auth.service';
import { CustomerService } from '../service/customer.service';
import { OrdersService } from '../service/orders.service';

// import * as dayjs from 'dayjs';
declare function require(name:string);


var dayjs = require('dayjs');
var weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);
var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);
var weekday = require('dayjs/plugin/weekday');
dayjs.extend(weekday);
var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
var Holidays = require('date-holidays');
var hd = new Holidays();
hd.getCountries();
hd.getStates('CA');
hd.getRegions('CA', 'ab');
hd = new Holidays('CA', 'ab');

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit{
  currentUserLogin: string;
  loginUser: any;
  localeString = 'en-ca';
  navDate: any;
  dbInProduction: Orders[];
  dbOpen: Orders[];
  dbOperations: Orders[];
  leadsWonSubscription: Subscription;
  leadsOpenSubscription: Subscription;
  adminSettingSubscription: Subscription;
  isLoading = true;
  currentWeekNow: number;
  screenHeight: number;
  screenWidth: number;
  adminDb: any;
  objectKeys = Object.keys;
  weeklyTarget: number;
  budgetedWeeklyTarget: number;
  currentYearData: any;
  toggleOpenChecked: boolean;
  toggleHideShowCompleteChecked: boolean;

  titledataMonthlyInvoiceValues
  typedataMonthlyInvoiceValues
  dataMonthlyInvoiceValues
  columnNamesdataMonthlyInvoiceValues
  optionsdataMonthlyInvoiceValues
  widthdataMonthlyInvoiceValues
  heightdataMonthlyInvoiceValues
  dataForProjectsOnTheMoveValues: any[];
  columnNamesForProjectsOnTheMoveValues: string[];
  titleDataForProjectsOnTheMoveValues: string;
  typedataDataForProjectsOnTheMoveValues: string;
  optionsDataForProjectsOnTheMoveValues
  widthDataForProjectsOnTheMoveValues: number;
  heightDataForProjectsOnTheMoveValues: number;
  dataWeeklyInstallValues
  columnNamesdataWeeklyInstallValues: string[];
  titledataWeeklyInstallValues
  typedataWeeklyInstallValues
  optionsdataWeeklyInstallValues
  widthdataWeeklyInstallValues
  heightdataWeeklyInstallValues
  heightForMilestonePieChart
  widthForMilestonePieChart
  optionsForMilestonePieChart
  typeForMilestonePieChart
  titleForMilestonePieChart
  columnNamesForMilestonePieChart
  dataForMilestonePieChart: any[];
  heightForMilestoneClosePieChart
  widthForMilestoneClosePieChart
  optionsForMilestoneClosePieChart
  typeForMilestoneClosePieChart
  titleForMilestoneClosePieChart
  columnNamesForMilestoneClosePieChart
  dataForMilestoneClosePieChart
  dynamicResize= false;

   constructor(public dialog: MatDialog, private ordersService: OrdersService, private customerService: CustomerService,
    private db: AngularFirestore, public authService: AuthService,
    private adminData: AdminService, private ngZone: NgZone,
    analytics: AngularFireAnalytics) {
      this.getScreenSize();
      const fullNameLogin = JSON.parse(localStorage.getItem('user'));
      this.currentUserLogin = fullNameLogin.displayName;
      analytics.logEvent('Install Calendar Being Used', { userLogin:this.currentUserLogin });
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
      this.screenHeight = window.innerHeight;
      this.screenWidth = window.innerWidth;
      this.dynamicResize = true;
    }

  ngOnInit() {
    this.currentWeekNow = dayjs().week();
    this.navDate = dayjs();

    this.adminSettingSubscription = this.adminData.adminChanged.subscribe(
      (adminstuff: Admin[]) => {

        this.adminDb = JSON.parse(JSON.stringify(adminstuff));
        this.currentYearData =  this.adminDb[0].financials.filter(t => t.year === dayjs().year());
        this.budgetedWeeklyTarget =(this.currentYearData[0].fiftyTwoWeekTarget -
          this.currentYearData[0].ytdTargetInvoice) / (dayjs().isoWeeksInYear() - dayjs().isoWeek());
        this.setDataForMonthlyInvoiceValues(this.adminDb);
        setTimeout(() =>  this.isLoading = false, 3000); //Google charts very slow to load
      }
    );
    this.leadsOpenSubscription = this.ordersService.openCloseChanged.subscribe(
      (leadsOpen: Orders[]) => {
        this.dbOpen = JSON.parse(JSON.stringify(leadsOpen));
        this.dbOperations = this.dbOpen.filter( t => t.milestone === "5. Operations");
        this.setDataForProjectsOnTheMove(this.dbOpen);
        this.setDataForMilestoneOpenPieChart(this.dbOpen)
      }
    );

    this.leadsWonSubscription = this.ordersService.openCloseCompletedChanged.subscribe(
      (leadsWon: Orders[]) => {
        this.dbInProduction  = JSON.parse(JSON.stringify(leadsWon));
        this.setDataForWeeklyInstallValues(this.dbInProduction);
        this.setDataForMilestoneClosePieChart(this.dbInProduction)
      }
    );

    this.adminData.fetchAdminData();
    this.ordersService.fetchWeeklies();
    this.ordersService.fetchOrdersWon();
    this.ordersService.fetchAvailableOrders('created');
    this.ordersService.fetchOrdersIncludeCompleted();

  }

  setDataForMonthlyInvoiceValues(data) {
    this.dataMonthlyInvoiceValues = [];
    this.columnNamesdataMonthlyInvoiceValues = ['Months'];
    for (let i = (data[0].financials.length-4); i < (data[0].financials.length); i++) {
      this.columnNamesdataMonthlyInvoiceValues.push(data[0].financials[i].year.toString())
      for (let month = 0; month < 12; month++) {
        if (this.dataMonthlyInvoiceValues[month] === undefined) { //Add month for each column
          this.dataMonthlyInvoiceValues.push([data[0].financials[i].monthlyInvoiceValues[month].month]);
        }
      this.dataMonthlyInvoiceValues[month].push(data[0].financials[i].monthlyInvoiceValues[month].value === 0?
        null:data[0].financials[i].monthlyInvoiceValues[month].value); //Avoid coming months showing zero
      }
    }
    this.titledataMonthlyInvoiceValues = 'Monthly Invoice Values for current year and 3 previous years';
    this.typedataMonthlyInvoiceValues='LineChart';
    this.optionsdataMonthlyInvoiceValues = {
      vAxis:{
        title: 'Invoiced Values',
        format: 'currency'
      },
      animation: {
        duration: 1500,
        startup: true
    }
    };
    this.widthdataMonthlyInvoiceValues = this.screenWidth/2 - 60;
    this.heightdataMonthlyInvoiceValues = this.screenHeight/2.75;
  }

  setDataForWeeklyInstallValues(data) {
    let orderData = data.filter( t => t.multiInstall !== undefined);
    orderData = orderData.filter( t => t.multiInstall.length !== 0 );
    orderData = orderData.concat(this.dbOperations);
    let currentyear =  dayjs().year();
    this.dataWeeklyInstallValues = JSON.parse(JSON.stringify(this.getDataForWeeklyInstallValues()));
    this.columnNamesdataWeeklyInstallValues = ['Week #', {type: 'string', role: 'annotation'}, {type: 'string', role: 'annotationText'}, currentyear.toString(), 'Weekly Target'];
    for (let i = 0; i < orderData.length; i++) {
      for (let x = 0; x < orderData[i].multiInstall.length; x++) {
        if (dayjs(orderData[i].multiInstall[x].installDate).year() === currentyear) {
          this.dataWeeklyInstallValues[dayjs(orderData[i].multiInstall[x].installDate).week()-2][3] += Number(orderData[i].multiInstall[x].progressValue);
        }
      }
    }
    this.titledataWeeklyInstallValues = 'Weekly Install Values for Closed Orders';
    this.typedataWeeklyInstallValues='LineChart';
    this.optionsdataWeeklyInstallValues = {
      vAxis:{
        title: 'Weekly Install Values',
        format: 'currency'
      },
      hAxis: {title: 'Week#'},
      annotations: {
        stem: {
          color: '#38b53e'
        },
        style: 'line'
      },
      animation: {
        duration: 1500,
        startup: true
    }
    };
    this.widthdataWeeklyInstallValues = this.screenWidth/2 - 60;
    this.heightdataWeeklyInstallValues = this.screenHeight/2.75;
  }

  setDataForProjectsOnTheMove(data) {
    this.dataForProjectsOnTheMoveValues = [
      ['New Leads', 0, 0, 0, 0],
      ['Qualifying to Design', 0, 0, 0, 0],
      ['Design to Estimate', 0, 0, 0, 0],
      ['Estimate to Proposal', 0, 0, 0, 0],
      ['To Operations', 0, 0, 0, 0],
    ];
    let filteredData = data.filter( t => t.meetingMinutesDetail !== undefined);
    // let milestoneChange = [];
    for (let i = 0; i < filteredData.length; i++) {
      let milestoneChange =filteredData[i].meetingMinutesDetail.filter( t => t.meetingNotes !== undefined);
      milestoneChange.forEach( element => {
        switch (element.meetingNotes) {
          case '🌱 New Lead': this.getDataForProjectsOnTheMove(element, 0); break;
          case 'Milestone Change - Qualifying to Design': this.getDataForProjectsOnTheMove(element, 1); break;
          case 'Milestone Change - Design to Estimate': this.getDataForProjectsOnTheMove(element, 2); break;
          case 'Milestone Change - Estimate to Proposal': this.getDataForProjectsOnTheMove(element, 3); break;
          case '🛎️🛎️🛎️ Milestone Change - Proposal to Operations': this.getDataForProjectsOnTheMove(element, 4); break;
          case '🛎️🛎️🛎️ Milestone Change - Estimate to Operations': this.getDataForProjectsOnTheMove(element, 4); break;
          case '🛎️🛎️🛎️ Milestone Change - Design to Operations': this.getDataForProjectsOnTheMove(element, 4); break;
          case '🛎️🛎️🛎️ Milestone Change - Qualifying to Operations': this.getDataForProjectsOnTheMove(element, 4); break;
        }
      })
    }
    let currentWeek = dayjs().week()-1;

    this.columnNamesForProjectsOnTheMoveValues =  ['Month', 'Week '+(currentWeek-2),'Week' +(currentWeek-1), 'Week '+currentWeek, 'Average'];

    this.titleDataForProjectsOnTheMoveValues = 'Milestone Changes for last 3 weeks in '+ dayjs().year();
    this.typedataDataForProjectsOnTheMoveValues='ComboChart';
    this.optionsDataForProjectsOnTheMoveValues =  {
      // title : 'Monthly Coffee Production by Country',
          vAxis: {title: 'Number of Changes'},
          hAxis: {title: 'Milestone Change'},
          seriesType: 'bars',
          series: {3: {type: 'line'}},
          animation: {
            duration: 1500,
            startup: true
        }
    };
    this.dataForProjectsOnTheMoveValues[0][4] =  this.averageDataForProjectsOnTheMove( this.dataForProjectsOnTheMoveValues[0]);
    this.dataForProjectsOnTheMoveValues[1][4] =  this.averageDataForProjectsOnTheMove( this.dataForProjectsOnTheMoveValues[1])
    this.dataForProjectsOnTheMoveValues[2][4] =  this.averageDataForProjectsOnTheMove( this.dataForProjectsOnTheMoveValues[2])
    this.dataForProjectsOnTheMoveValues[3][4] =  this.averageDataForProjectsOnTheMove( this.dataForProjectsOnTheMoveValues[3])
    this.dataForProjectsOnTheMoveValues[4][4] =  this.averageDataForProjectsOnTheMove( this.dataForProjectsOnTheMoveValues[4])

    this.widthDataForProjectsOnTheMoveValues = this.screenWidth/2 - 60;
    this.heightDataForProjectsOnTheMoveValues = this.screenHeight/2.75;

  }

  setDataForMilestoneOpenPieChart(data) {
  let prospect = data.filter( t => t.milestone === '1. Prospect');
  let qualifying = data.filter( t => t.milestone === '2. Qualifying');
  let design = data.filter( t => t.milestone === '3. Design');
  let estimates = data.filter( t => t.milestone === 'EstimatesMilestone');
  let proposal = data.filter( t => t.milestone === '4. Proposal');
  let operations = data.filter( t => t.milestone === '5. Operations');

    this.dataForMilestonePieChart = [
      [ 'Prospect',prospect.length],
      ['Qualifying', qualifying.length],
      ['Design', design.length],
      ['Estimates', estimates.length ],
      ['Proposal', proposal.length],
      ['Operations', operations.length],
    ];

    this.columnNamesForMilestonePieChart =  ['Milestone', 'Number of Orders'];

    this.titleForMilestonePieChart = 'Milestone For Open Orders';
    this.typeForMilestonePieChart='PieChart';
    this.optionsForMilestonePieChart =  {
      legend: { position: 'left', alignment: 'start' },
      is3D: true,
      animation: {
        duration: 1500,
        startup: true
      }
    };

    this.widthForMilestonePieChart = this.screenWidth/4.2;
    this.heightForMilestonePieChart = this.screenHeight/2.75;
  }

  setDataForMilestoneClosePieChart(data) {
    let productionDesign = data.filter( t => t.milestone === 'A. Production Design');
    let inProduction = data.filter( t => t.milestone === 'B. In Production');
    let serviceOrder = data.filter( t => t.milestone === 'B. Service Order');
    let installationComplete = data.filter( t => t.milestone === 'C. Installation Complete');

      this.columnNamesForMilestoneClosePieChart =  ['Milestone', 'Number of Orders'];

      this.titleForMilestoneClosePieChart = 'Milestone For Closed Orders';
      this.typeForMilestoneClosePieChart='PieChart';
      this.optionsForMilestoneClosePieChart =  {
        legend: { position: 'left', alignment: 'start' },
        is3D: true,
        animation: {
          duration: 1500,
          easing: 'in',
          startup: true
        },
      };
        this.dataForMilestoneClosePieChart = [
                ['Production Design', productionDesign.length],
                ['In Production', inProduction.length],
                ['Service Order', serviceOrder.length ],
                ['Installation Complete', installationComplete.length],
              ];
      this.widthForMilestoneClosePieChart = this.screenWidth/4.2;
      this.heightForMilestoneClosePieChart = this.screenHeight/3;
    }

  getDataForProjectsOnTheMove(data, index){
    let currentWeek = dayjs().week()-1;
    let weekBeforeCurrentWeek = currentWeek-1;
    let secondWeekBeforeCurrentWeek = currentWeek-2;
    // let dataWeek = dayjs(data.dateUpdated).week()-1
    if ((dayjs(data.dateUpdated).week()-1 ===  currentWeek ) &&  dayjs(data.dateUpdated).year() === dayjs().year()) {
      this.dataForProjectsOnTheMoveValues[index][3] += 1;
    } else if (( dayjs(data.dateUpdated).week()-1 ===  weekBeforeCurrentWeek ) &&  dayjs(data.dateUpdated).year() === dayjs().year()) {
        this.dataForProjectsOnTheMoveValues[index][2] += 1;
    } else if (( dayjs(data.dateUpdated).week()-1 ===  secondWeekBeforeCurrentWeek) &&  dayjs(data.dateUpdated).year() === dayjs().year()) {
        this.dataForProjectsOnTheMoveValues[index][1] += 1;
    }

  }

  averageDataForProjectsOnTheMove(arr) {
    return Math.round((arr[1]+arr[2]+arr[3])/3);
  }

  getDataForWeeklyInstallValues() {
    let weeksInAYear = dayjs().isoWeeksInYear()
    let weekArray = []
    for (let i = 0; i < weeksInAYear; i++) {
      if ( dayjs().isoWeek() === i+1) {
        weekArray.push([i+1 , 'Week '+dayjs().isoWeek(), 'Week annotation', 0, Math.round(this.budgetedWeeklyTarget)])
      }else {
        weekArray.push([i+1 , null, null, 0, Math.round(this.budgetedWeeklyTarget)])
      }
    }
    return weekArray;
  }

}
