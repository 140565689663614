import { Component, Inject, Optional, EventEmitter, OnInit, NgZone, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UntypedFormControl, FormGroupDirective, UntypedFormBuilder, NgForm, Validators, UntypedFormGroup,} from '@angular/forms';
import {ErrorStateMatcher, MatOption} from '@angular/material/core';
import { CustomerService } from '../service/customer.service';
import {Observable, Subscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Customer } from '../models/customer.model';
import { AbstractControl } from '@angular/forms';

/************** Error when invalid control is dirty, touched, or submitted. ****************/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
export function RequireMatch(control: AbstractControl) {
    const selection: any = control.value;
    if (typeof selection === 'string') {
        return { incorrect: true };
    }
    return null;
}

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent {
  companyCtrl = new UntypedFormControl('', [Validators.required, RequireMatch]);
  inputCtrl = new UntypedFormControl('', Validators.required);
  serializedDate: any;
  filteredCompanies: Observable<Customer[]>;
  companytest: any;
  action: string;
  localData: any;
  selected: true;
  dueDate: Date;
  dueDateFormControl = new UntypedFormControl('', [Validators.required]);
  // matcher = new MyErrorStateMatcher();
  formTask: UntypedFormGroup;

  private itemDoc: AngularFirestoreDocument<Customer>;
  item: Observable<Customer>;
  contactTest: any[];
  contacts: Customer[] = [];
  contactperson: Customer[] = [];
  contactsSubscription: Subscription;

  constructor(
    fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CustomersComponent>,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Customer,
    private _ngZone: NgZone,
    private _snackBar: MatSnackBar,
    private customerService: CustomerService,
    private afs: AngularFirestore) {
      this.contactsSubscription = this.customerService.contactsChanged.subscribe(
      contacts => (this.contacts = contacts.filter(value => value.type === 'Organization')),
      contactperson => (this.contactperson = contactperson .filter(value => value.type === 'Person')));
      this.customerService.fetchAvailableContacts();
      this.localData = JSON.parse(JSON.stringify({...data}));
      this.action = this.localData.action;
      // this.localData.country = 'canada';

      // ********************** AUTO COMPLETE START ****************************

      this.companyCtrl.setValue(this.localData.organization);
      this.filteredCompanies = this.companyCtrl.valueChanges
      .pipe(
        startWith(''),
        map(company => company ? this._filterCompanies(company) : this.contacts.slice())
      );

  }

  // ************************** AUTO COMPLETE FUNCTIONS START *************************************
  private _filterCompanies(value: string): Customer[] {
    const filterValue = value.toLowerCase();
    this.localData.clientId = this.companyCtrl.value.id;
    return this.contacts.filter(company => company.name.toLowerCase().indexOf(filterValue) === 0);
  }
  AutoCompleteDisplay(item: any): string {
    if (item.name === undefined) {return item; }
    // this.localData.contactId = item.id;
    // console.log(this.localData.contactId);
    return item.name;
  }
  onSelectionChanged(event$) {
    this.localData.clientId = event$.option.value.id;
    this.localData.organization = event$.option.value.name;
  }

  doAction(action) {
    // this.localData.action = action === 'delete' ? action : this.localData.action;
    this.dialogRef.close({event: this.action, data: this.localData});
  }

  closeDialog(order) {
    this.localData.data = null;
    this.dialogRef.close({event: 'Cancel'});
  }

  openSnackBar(localData, action: string) {
    type MatSnackBarHorizontalPosition = 'left';
    if (action === 'Add Company') {
      this._snackBar.open('New Company - ' + this.localData.name, 'ADDED', {
        duration: 400,
      });
    } else if ( action === 'Contact' ) {
      this._snackBar.open( 'New Contact - ' + this.localData.firstName + ' ' + this.localData.lastName, 'ADDED', {
        duration: 400,
      });
    } else if ( action === 'UpdatePerson' ) {
      this._snackBar.open( this.localData.firstName + ' ' + this.localData.lastName, 'Updated', {
        duration: 400,
      });
    } else if ( action === 'delete' ) {
        this._snackBar.open( this.localData.firstName + ' ' + this.localData.lastName, 'Deleted', {
          duration: 400,
        });
    } else {
      this._snackBar.open(  this.localData.name, 'Updated', {
        duration: 400,
      });
    }
  }
}
