import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChangelogDialogueComponent } from '../dialogue/changelog-dialogue/changelog-dialogue.component';

@Component({
  selector: 'app-toaster',
  template: `
    <div class="toast" [class.show]="show">
      <div class="img">
        <img src="/assets/icons/icon-72x72.png" />
      </div>
      <div class="desc">
        A new update is available!
        <br/>
        You can reload with the button below.
        <br/>
        <button (click)="reload()">Reload</button>
      </div>
    </div>
  `,
  styleUrls: [ './toaster.styles.scss' ]
})
export class ToasterComponent implements OnChanges {
  @Input() show = false;

  constructor(public dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges) {
    if ( this.show === true) {
      this.changeLogDialog()
    }
  }

  changeLogDialog() {
    const dialogRef = this.dialog.open(ChangelogDialogueComponent, {
      width: '860px',
    });
  }

  reload() {
    document.location.reload();
  }
}
