import { Component, OnInit, Pipe, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges,
  HostListener, ViewEncapsulation, ViewChild, AfterViewInit, ɵConsole } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragEnter, CdkDragExit, CdkDragEnd} from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { OrdersService } from '../service/orders.service';
import { CustomerService } from 'src/app/service/customer.service';
import { CustomersComponent } from 'src/app/customers/customers.component';
import { AddNewLeadComponent } from '../orders/add-new-lead/add-new-lead.component';
import { AngularFirestore} from '@angular/fire/compat/firestore';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../service/auth.service';
import {MatButtonToggleModule} from '@angular/material/button-toggle'
import { Orders } from '../models/orders.model';
import { Customer } from 'src/app/models/customer.model';
import { AdminService } from '../service/admin.service';
import { Admin } from '../models/admin.model';
import { User } from '../models/user.model';

declare function require(name:string);

var dayjs = require('dayjs');
var weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);
var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);
var weekday = require('dayjs/plugin/weekday');
dayjs.extend(weekday);
var weekYear = require('dayjs/plugin/weekYear');
dayjs.extend(weekYear);
var isoWeeksInYear = require('dayjs/plugin/isoWeeksInYear');
var isLeapYear = require('dayjs/plugin/isLeapYear'); // dependent on isLeapYear plugin
dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear);

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class DashboardComponent implements OnInit, OnDestroy {
  prospect: Orders[] = [];
  qaulifying = [];
  design = [];
  proposal = [];
  production = [];
  orders = [];
  dataSource = [];
  totalProspect = 0;
  totalQaulifying = 0;
  totalDesign = 0;
  totalProposal = 0;
  totalProduction = 0;
  company = [];
  dbProspect: Orders[];
  dbQualifying: Orders[];
  dbDesign: Orders[];
  dbProposal: Orders[];
  dbOperations: Orders[];
  dbProductionDesign: Orders[];
  dbInProduction: Orders[];
  dbInstall: Orders[];
  dbFollowUp: Orders[];
  dbProjectComplete: Orders[];
  dbAllClosed: Orders[];
  dbAllOpen: Orders[];
  total = 0;
  searchText = '';
  value = 'Clear me';
  selected: true;
  weeklyTarget: number;
  completed: boolean;

  staffUsersSubscription: Subscription;
  dbStaffUsers: User[];
  leadsWonSubscription: Subscription;
  leadsSubscription: Subscription;
  contacts: Customer[];
  contactsSubscription: Subscription;
  testcontact = [];
  firstName: string;
  selectedName: string;
  myFlagForButtonToggle: string;
  bottombar: boolean;

  screenHeight: number;
  screenWidth: number;

  adminSettingSubscription: Subscription;
  adminDb: any;

  tillEndofYearSum: number;
  tillEndofYearOperationsSum: number;
  tillEndofYearSumClosed: number;
  tillEndofYearSumOpen: number;
  tillEndofYearSumBoth: number;
  currentWeekNow: number;
  currentYearNumberOfWeeks: number;
  currentYearData: any;
  isLoading = true;
  budgetedWeeklyTarget: number;
  ytdTargetInvoice: number;
  fiftyTwoWeekTarget:number;
  radioChangedInstallCalendar: string;
  installCalendarToggleChange: boolean;

  private activatedRadioEmitter: Subscription;


  @Output() mainToggleStateChange = new EventEmitter<string>();
  currentUserLogin: any;

  constructor(public dialog: MatDialog, private ordersService: OrdersService, private customerService: CustomerService,
              private db: AngularFirestore, public authService: AuthService, public adminService: AdminService ) {
    // const userData = JSON.parse(localStorage.getItem('user'));

    let name = JSON.parse(localStorage.getItem('user'));
    this.currentUserLogin = name.displayName ?? '';
    // const fullName = userData.displayName.split(' ');
    // this.firstName = fullName[0];
    this.firstName = (this.firstName === 'Matthew'?'Matt' : this.firstName);
    this.selectedName = this.currentUserLogin.displayName === 'Matthew Pennycook'? 'Matt Pennycook': this.currentUserLogin.displayName;
    this.completed = false;
    this.bottombar = false;
    this.getScreenSize();
    this.radioChangedInstallCalendar = 'includeOpen';
    this.installCalendarToggleChange = true;
    this.currentYearNumberOfWeeks = dayjs().isoWeeksInYear();
  }

  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
          this.screenWidth = window.innerWidth;
          let vh = window.innerHeight * 0.01;
          let vw = window.innerWidth * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
          document.documentElement.style.setProperty('--vw', `${vw}px`);
    }

  ngOnInit() {
    this.currentWeekNow = dayjs().week();
    this.adminSettingSubscription = this.adminService.adminChanged.subscribe(
      (adminstuff: Admin[]) => {
        this.adminDb = JSON.parse(JSON.stringify(adminstuff));
        // this.adminDb =  this.adminDb.filter( t => t.financials[0].year === '2020');
        this.currentYearData =  this.adminDb[0].financials.filter(t => t.year === dayjs().year());
        this.budgetedWeeklyTarget = (this.currentYearData[0].fiftyTwoWeekTarget - this.currentYearData[0].ytdTargetInvoice) / dayjs().isoWeeksInYear();
        this.fiftyTwoWeekTarget = this.currentYearData[0].fiftyTwoWeekTarget;
        this.ytdTargetInvoice = this.currentYearData[0].monthlyInvoiceValues.reduce((a, b) => {
          return b['value'] === (typeof b !== 'number') ? 0 : a + Number(b['value']);
      }, 0);
      }
    );
    /*Get all Open Leads Estimated values that have been added to the install calendar  */
    this.leadsSubscription = this.ordersService.openCloseChanged.subscribe(
      (leadsOpen: Orders[]) => {
        this.dbAllOpen = JSON.parse(JSON.stringify(leadsOpen));
        // this.dbAllOpen = this.dbAllOpen.filter(value => value.milestone !== '5. Operations');
        // this.dbProspect = leadsOpen.filter(value => value.milestone === '1. Prospect');
        // this.dbQualifying  = leadsOpen.filter(value => value.milestone === '2. Qualifying');
        // this.dbDesign  = leadsOpen.filter(value => value.milestone === '3. Design');
        // this.dbProposal  = leadsOpen.filter(value => value.milestone === '4. Proposal');
        this.dbOperations  = leadsOpen.filter(value => value.milestone === '5. Operations');
        this.tillEndofYearSumOpen = this.adminService.getWeekSumTotals(( 52 - this.currentWeekNow),  this.dbAllOpen, 'ytd' );
        this.tillEndofYearOperationsSum = this.adminService.getWeekSumTotals(( 52 - this.currentWeekNow),  this.dbOperations, 'ytd' );
      }
    );

    /*Get all Closed Leads Estimated values that have been added to the install calendar  */
    this.leadsWonSubscription = this.ordersService.wonOrdersChanged.subscribe(
      (leadsClosed: Orders[]) => {
        this.dbAllClosed = JSON.parse(JSON.stringify(leadsClosed));
        // this.dbProductionDesign  = leadsClosed.filter(value => value.milestone === 'A. Production Design');
        // this.dbInProduction  = leadsClosed.filter(value => value.milestone === 'B. In Production');
        // this.dbInstall  = leadsClosed.filter(value => value.milestone === 'C. Installation Complete');
        // this.dbFollowUp  = leadsClosed.filter(value => value.milestone === 'D. Customer Follow Up');
        // this.dbProjectComplete  = leadsClosed.filter(value => value.milestone === 'E. Project Complete');

        // INCLUDE Operations in Closed Total
        this.tillEndofYearSumClosed = this.adminService.getWeekSumTotals(( 52 - this.currentWeekNow), this.dbAllClosed, 'ytd' );
        this.tillEndofYearSumClosed += this.tillEndofYearOperationsSum;
        this.tillEndofYearSumClosed = (Math.ceil(this.tillEndofYearSumClosed * 100) / 100)
        + Number(this.currentYearData === undefined ? 0 : this.ytdTargetInvoice);
        this.tillEndofYearSumBoth = this.tillEndofYearSumClosed + this.tillEndofYearSumOpen - this.tillEndofYearOperationsSum ;
        this.tillEndofYearSumBoth =  (Math.ceil(this.tillEndofYearSumBoth  * 100) / 100);
        this.isLoading = false;
      }
    );
    this.staffUsersSubscription = this.customerService.usersChanged.subscribe(
      (users: User[]) => {
        this.dbStaffUsers = JSON.parse(JSON.stringify(users));
        this.dbStaffUsers = this.dbStaffUsers.filter( t => 'cityimagesigns.com' === (t.email.substring(t.email.lastIndexOf("@") +1)));
      }
    );
    this.contactsSubscription = this.customerService.contactsChanged.subscribe(
      contacts => (this.contacts = contacts.filter(value => value.type === 'Organization')));
    this.myFlagForButtonToggle = 'assignment';

    this.adminService.fetchAdminData();
    this.ordersService.fetchAvailableOrders('updated');
    this.ordersService.fetchOrdersWon();
    this.customerService.fetchAvailableContacts();
    this.customerService.fetchStaffMembers()

  }

  somethingChanged(myFlagForButtonToggle) {
    this.mainToggleStateChange.emit(myFlagForButtonToggle);
  }

    // Drop And Drag Function
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      // do nothing
    } else {
      this.ordersService.updateOrderDrag(event.previousContainer.data, event.container.id, event.previousIndex, this.currentUserLogin) ;
    }
  }

  // Open dialog on orders using the action
  openDialog(action, obj, index) {
    obj.action = action;
    obj.company = this.company;
    const dialogRef = this.dialog.open(AddNewLeadComponent, {
      width: (obj.action === 'Update' || obj.action === 'Add') ? '740px' : (obj.action === 'Add Task') ? '800px' : '300px',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Add') {
        this.ordersService.addNewOrderData(result.data, this.currentUserLogin);
      } else if (result.event === 'Update') {
        this.ordersService.updateOrder(result.data, this.currentUserLogin);
      } else if (result.event === 'Add Task') {
        this.ordersService.updateOrder(result.data, this.currentUserLogin);
      } else if (result.event === 'Delete') {
        this.ordersService.deleteOrder(result.data, null, this.currentUserLogin);
      }
    });
  }

  contactDialog(action,obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(CustomersComponent, {
      width: '740px',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
        this.customerService.addNewAndUpdateClientData(result.data);
    });
  }

  onActivateToggleButton(matToggle) {
    this.ordersService.activatedToggleButtonEmitter.next(matToggle);
  }

  receiveMessage($event) {
    this.radioChangedInstallCalendar = $event;
  }

  receiveToggleOpenMessageFromInstallCalendar($event) {
    this.installCalendarToggleChange = $event;
  }

  ngOnDestroy() {
    this.leadsSubscription.unsubscribe();
    this.contactsSubscription.unsubscribe();
    this.leadsWonSubscription.unsubscribe();
    this.adminSettingSubscription.unsubscribe();
    this.staffUsersSubscription.unsubscribe();
  }
}
