import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user.model';
import { CustomerService } from './customer.service';

// import * as dayjs from 'dayjs';
declare function require(name: string);

const dayjs = require('dayjs');
const weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);
const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);
const weekday = require('dayjs/plugin/weekday');
dayjs.extend(weekday);
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

@Injectable({
  providedIn: 'root'
})
export class EmailsService implements OnDestroy {
  loginUser: any;
  currentUserLogin: string;
  currentUserEmail: any;
  staffUsersSubscription: Subscription;
  dbStaffUsers: any;

  constructor(private dbLeads: AngularFirestore, private customerService: CustomerService ) {
    this.loginUser = JSON.parse(localStorage.getItem('user'));
    this.currentUserLogin = this.loginUser.displayName;
    this.currentUserEmail = this.loginUser.email;

    this.staffUsersSubscription = this.customerService.usersChanged.subscribe(
      (users: User[]) => {
        this.dbStaffUsers = JSON.parse(JSON.stringify(users));
      }
    );
    this.customerService.fetchStaffMembers();
  }

   // ** Send Emails ** //
   sendEmail(email, cc, message) {
    this.dbLeads.collection('mail').add({
      to: email,
      cc: cc,
      message: message
    });
  }


  sendTaskEmail(numberOfTask, obj) {
    for (let i = 0; i < numberOfTask; i++ ) {
      let email = this.getEmailAssignee(obj.task[i].taskAssigned);
      let cc = this.getEmailAssignee(obj.task[i].createdBy);
      let message = {
        subject: 'NEW TASK - '+ obj.contactName + ' - ',
        html: 'Hi ' + email.displayName + ', <br> <br>' + obj.task[i].createdBy + ' have assigned you a task for the following project: <br><br>' +
        '<strong>CLIENT: </strong>' + obj.contactName +
        '<br><strong>OPPORTUNITY NAME: </strong>' + obj.opportunityName +
        '<br><strong>TASK DESCRIPTION: </strong>' + obj.task[i].taskDiscription +
        '<br><strong>DUE DATE: </strong>' + dayjs(obj.task[i].taskDate).format('MMMM D, YYYY') +
        '<br><br> Regards, <br>'+ obj.task[i].createdBy
        }
        this.sendEmail(email.email,cc.email, message);
    }
  }

  sendTaskEmailToAccounting(obj) {
    let email = this.getEmailAssignee(obj.task[0].taskAssigned);
    let cc = this.getEmailAssignee(obj.task[0].createdBy);
    let message = {
      subject: 'TASK - READY FOR INVOICING - '+ obj.contactName,
      html: 'Hi ' + email.displayName + ', <br> <br>'+obj.task[0].createdBy+ ' approved the following project for invoicing: <br><br>' +
      '<strong>CLIENT: </strong>' + obj.contactName +
      '<br><strong>OPPORTUNITY NAME: </strong>' + obj.opportunityName +
      '<br><strong>TASK DESCRIPTION: </strong>' + obj.task[0].taskDiscription +
      '<br><strong>DUE DATE: </strong>' + dayjs(obj.task[0].taskDate).format('MMMM D, YYYY') +
      '<br><br> Regards, <br>'+ obj.task[0].createdBy
      }
      this.sendEmail(email.email, cc.email, message);
  }


  sendTaskApproveForInvoiceEmail(obj) {
      let email = this.getEmailAssignee(obj.task[0].taskAssigned);
      let cc = this.getEmailAssignee(obj.task[0].createdBy);
      let message = {
        subject: 'TASK - Approve For Invoicing - '+ obj.contactName,
        html: 'Hi ' + email.displayName + ', <br> <br> Please approve for invoicing. Install was completed for the following project: <br><br>' +
        '<strong>CLIENT: </strong>' + obj.contactName +
        '<br><strong>OPPORTUNITY NAME: </strong>' + obj.opportunityName +
        '<br><strong>TASK DESCRIPTION: </strong>' + obj.task[0].taskDiscription +
        '<br><strong>DUE DATE: </strong>' + dayjs(obj.task[0].taskDate).format('MMMM D, YYYY') +
        '<br><br><strong>DUE DATE: </strong>' + dayjs(obj.task[0].taskDate).format('MMMM D, YYYY') +
        '<br><br> Regards, <br>'+ obj.task[0].createdBy
        }
        this.sendEmail(email.email, cc.email, message);
  }

  getEmailAssignee(name) {
    let email = this.dbStaffUsers.filter(t => (t.displayName === name)&&('cityimagesigns.com'
    === (t.email.substring(t.email.lastIndexOf( '@' ) + 1))));
    return email[0];
  }

  ngOnDestroy() {
    this.staffUsersSubscription.unsubscribe();
  }
}
