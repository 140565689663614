import { Component, ViewChild, OnChanges, SimpleChanges, OnDestroy, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrdersService } from './service/orders.service';
import { SwUpdate } from '@angular/service-worker';
import { CheckForUpdateService } from './service/check-for-update.service';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import { AuthService } from './service/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css', './app.styles.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'CIS -  Project Management System';
  updateAvailable = false;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  showIdle = false;

  toggleButtonActivated: any;
  private activatedToggle: Subscription;

  constructor(private updates: SwUpdate, private ordersService: OrdersService,private idle: Idle, private keepalive: Keepalive,
    private checkForUpdateService: CheckForUpdateService, public authService: AuthService) {
      this.showIdle = false;
      this.updates.versionUpdates.subscribe((evt) => {
        switch (evt.type) {
          case 'VERSION_READY':
            console.log(`Current app version: ${evt.currentVersion.hash}`);
            console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
            this.updateAvailable = true;
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
            break;
        }
      });
      // sets an idle timeout of 8 hours
    idle.setIdle(28800);
    // sets a timeout period of 300 seconds. after 8 hours  of inactivity, the user will be considered timed out.
    idle.setTimeout(300);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      authService.SignOut()
    });
    idle.onIdleStart.subscribe(() => {this.idleState = 'You\'ve gone idle!'});
    idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
    }

  ngOnInit() {
    this.activatedToggle = this.ordersService.activatedToggleButtonEmitter.subscribe(didActivate => {
      this.toggleButtonActivated = didActivate;
    });
  }
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  ngOnDestroy(): void {
    // this.activatedToggle.unsubscribe();
  }
}
