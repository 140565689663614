<!-- <mat-dialog-content>
  <mat-form-field class="discription">
    <mat-label>Choose an option</mat-label>
    <mat-select [(ngModel)]="selectedMaterialValue">
      <mat-option *ngFor="let price of dbPriceList" [value]="price.id">
        {{price.item}} -  {{price.description}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="submit"  mat-button mat-flat-button color="primary" (click)="doAction(selectedMaterialValue)">Add</button>
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button>
</mat-dialog-actions> -->

<mat-dialog-content>
  <div class="order-container">
    <div fxLayout="row" fxLayoutAlign="space-between" class="openclosefilter">
        <mat-form-field  class="input-search" fxFlex="40%" fxFlex.xs="100%" appearance="outline">
          <mat-icon *ngIf="searchShow" matPrefix>search</mat-icon>
          <input matInput  type="text" (keyup)="doFilter($event.target.value, 'prospect')" [(ngModel)]="searchAll"
          (focus) = "searchShow = !searchShow" (blur) = "searchShow = true" appearance="outline">
          <button mat-button *ngIf="searchCompany" matSuffix mat-icon-button aria-label="Clear" (click)="searchCompany=''; clearFilters(pricelistGraphics); searchShow = true">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
    </div>
    <mat-card *ngIf="isLoading"
      style="display: flex; justify-content: center; align-items: center">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate">
      </mat-progress-spinner>
    </mat-card>
    <mat-table #table [dataSource]="pricelistGraphics" matSort *ngIf = "actionType === 'Graphics'" >
      <ng-container matColumnDef="item" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Item</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.item }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="description" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="unit" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Unit</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.unit }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="price" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Price</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.price }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedPricelist; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedPricelist;" [ngClass]="{hovered: row.hovered}"
      (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"  (click)="doAction(row)"></mat-row>
    </mat-table>
    <mat-table #table [dataSource]="pricelistChannelLetters" matSort *ngIf = "actionType === 'Channel Letters'" >
      <ng-container matColumnDef="item" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Item</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.item }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="description" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="unit" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Unit</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.unit }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="price" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Price</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.price }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedPricelist; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedPricelist;" [ngClass]="{hovered: row.hovered}"
      (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"  (click)="doAction(row)"></mat-row>
    </mat-table>
    <mat-table #table [dataSource]="pricelistLed" matSort *ngIf = "actionType === 'LED and Lighting'" >
      <ng-container matColumnDef="item" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Item</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.item }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="description" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="unit" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Unit</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.unit }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="price" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Price</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.price }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedPricelist; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedPricelist;" [ngClass]="{hovered: row.hovered}"
      (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"  (click)="doAction(row)"></mat-row>
    </mat-table>
    <mat-table #table [dataSource]="pricelistSheetStock" matSort *ngIf = "actionType === 'Sheet Stock'" >
      <ng-container matColumnDef="item" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Item</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.item }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="description" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="unit" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Unit</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.unit }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="price" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Price</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.price }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedPricelist; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedPricelist;" [ngClass]="{hovered: row.hovered}"
      (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"  (click)="doAction(row)"></mat-row>
    </mat-table>
    <mat-table #table [dataSource]="pricelistElectrical" matSort *ngIf = "actionType === 'Electrical'" >
      <ng-container matColumnDef="item" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Item</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.item }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="description" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="unit" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Unit</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.unit }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="price" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Price</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.price }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedPricelist; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedPricelist;" [ngClass]="{hovered: row.hovered}"
      (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"  (click)="doAction(row)"></mat-row>
    </mat-table>
    <mat-table #table [dataSource]="pricelistSteel" matSort *ngIf = "actionType === 'Steel and Extrusions'" >
      <ng-container matColumnDef="item" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Item</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.item }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="description" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="unit" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Unit</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.unit }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="price" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Price</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.price }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedPricelist; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedPricelist;" [ngClass]="{hovered: row.hovered}"
      (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"  (click)="doAction(row)"></mat-row>
    </mat-table>
    <mat-table #table [dataSource]="pricelistLabour" matSort *ngIf = "actionType === 'Labour'" >
      <ng-container matColumnDef="item" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Item</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.item }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="description" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="unit" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Unit</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.unit }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="price" class="project">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Price</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.price }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedPricelist; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedPricelist;" [ngClass]="{hovered: row.hovered}"
      (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"  (click)="doAction(row)"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button>
</mat-dialog-actions>


<!-- id: doc.payload.doc.id,
            description: doc.payload.doc.data()['description'],
            item: doc.payload.doc.data()['item'],
            unit: doc.payload.doc.data()['unit'],
            qauntity: doc.payload.doc.data()['qauntity'],
            price: doc.payload.doc.data()['price'],
            type: doc.payload.doc.data()['type'],
            dateUpdated: doc.payload.doc.data()['dateUpdated'],
            updatedBy: doc.payload.doc.data()['updatedBy'], -->