import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isLoading = false;

  constructor(
    public authService: AuthService
  ) {
  }

  ngOnInit() {
   }

  showLoader(){
    this.isLoading = true;
    setTimeout(() =>  this.isLoading = false, 15000)
  }
}
