import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dead-leads',
  templateUrl: './dead-leads.component.html',
  styleUrls: ['./dead-leads.component.css']
})
export class DeadLeadsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
