import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChangelogInfo } from 'src/app/models/admin.model';
import { AdminService } from 'src/app/service/admin.service';

@Component({
  selector: 'app-changelog-dialogue',
  templateUrl: './changelog-dialogue.component.html',
  styleUrls: ['./changelog-dialogue.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ChangelogDialogueComponent implements OnInit {
  adminSettingSubscription: Subscription;
  changelogData: any;
  constructor(public adminService: AdminService ) { }

  ngOnInit(): void {
    this.adminSettingSubscription = this.adminService.changelogChanged.subscribe(
      (changelogData: ChangelogInfo[]) => {
        this.changelogData = JSON.parse(JSON.stringify(changelogData));
      }
    );
    this.adminService.fetchChangelogData();
  }

}
