<div class="table-container" fxLayout = "row" fxFlexAlign="stretch">
  <!-- <div  style="padding: 5px; height: 2vh; background-color: #eee;"></div> -->
  <!-- <div class="maincard-div" *ngIf= "(userHasTaskAssignedLeadsWon || userHasTaskAssignedNewLeads)"> -->
  <div class="task-container"
    fxFlex="60%">
    <mat-card >
      <mat-card-title>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <div fxFlex="40%" fxLayoutAlign="start center" style="padding-left: 20px">
            Project Task
          </div>
          <div fxLayoutAlign="start center" fxFlex="40%">
            <mat-form-field *ngIf = "authService.isLoggedIn === true" class="task-select">
              <mat-select #toto [(ngModel)] ="this.selectedName" matTooltip="Filter Staff Members" (selectionChange)="onChangeTaskNameSelected()">
                <mat-option value="All CIS">All</mat-option>
                <mat-option *ngFor="let staff of this.dbStaffUsersTask" [value]="staff.displayName" >{{staff.displayName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayoutAlign="end center" fxFlex="20%">
            <mat-button-toggle [checked]="showCompleted" color="accent" class="toggle-dash" (change)="showCompleted = $event.source.checked" (click)="onChangeTaskNameSelected()"
            >
              <mat-icon
              matTooltip="Show Completed Task"
              >assignment_turned_in</mat-icon>
            </mat-button-toggle>
          </div>
        </div>
      </mat-card-title>
    <mat-card-content  *ngIf = "(!userHasTaskAssignedNewLeads)"
    [style.height.vh]="'73'">
            <!-- <div *ngIf="isLoading"
              style="display: flex; justify-content: center; align-items: center">
              <mat-progress-spinner
                color="accent"
                mode="indeterminate">
              </mat-progress-spinner>
            </div> -->
            <div *ngIf="isLoading">
              <div fxLayout="row" fxLayoutGap="16px grid" *ngFor="let i of [].constructor(6)">
                <div fxFlex="10%" fxLayoutAlign="center center" >
                  <ngx-skeleton-loader appearance="circle" [theme]="{ width: '30px', height: '30px', 'padding-top': '20px'}">
                  </ngx-skeleton-loader>
                </div>
                <div fxFlex="30%">
                  <ngx-skeleton-loader count="2"  [theme]="{'margin-top': '20px'}"></ngx-skeleton-loader>
                </div>
                <div fxFlex="42%">
                  <ngx-skeleton-loader count="2" [theme]="{'margin-top': '20px'}"></ngx-skeleton-loader>
                </div>
                <div fxFlex="18%" fxLayoutAlign="start center">
                    <ngx-skeleton-loader appearance="circle" [theme]="{ width: '30px', height: '30px' }">
                    </ngx-skeleton-loader>
                </div>
                <mat-divider [style.width.%]="'95'"></mat-divider>
              </div>
            </div>

            <mat-card *ngIf="!isLoading">
              <mat-card-content >
                <h1 *ngIf = "firstNameLogin === firstName"> Great! You all caught up {{firstName}}</h1>
                <h1 *ngIf = "firstNameLogin !== firstName"> Great! {{firstName}} is all caught up</h1>
              </mat-card-content>
            </mat-card>
      </mat-card-content>
      <mat-card-content [style.overflow]="'auto'" [style.height.vh]="'73'" class="task-scroll"
        *ngIf = "(userHasTaskAssignedNewLeads)">
        <mat-table  #table [dataSource]="dbTask" matSort class="mat-elevation-z8">

          <ng-container matColumnDef="contactName" class="project">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Project</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <a mat-flat-button (click)="openDialog('Update',element, 1)">
                {{(element.contactName )}}
                <br><span style ="color: rgb(160, 160, 160)" >{{ element.opportunityName  }}</span></a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="assigned">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Assignee</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-cell class="inner-cell" *ngFor= "let task of element.task ">
                <span style="display:inline; padding-top: 5px"><img matTooltip="{{task.taskAssigned}}" class="dotPic2" [src]="this.dbStaffUsersTask | staffPic: task.taskAssigned" referrerpolicy="no-referrer">
                </span>

              </mat-cell>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Days Due</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-cell class="inner-cell" *ngFor= "let task of element.task ">
                <div *ngIf ="task.taskStatus === false" ><mat-icon class = "icon" matTooltip="COMPLETED!">assignment_turned_in</mat-icon><span> </span></div>
                <div *ngIf ="task.taskStatus === true && ((task.taskDate | dateTaskManipulator)<0)" class = "keepinline"><span class = "dotred" matTooltip="{{task.taskDate | dateTaskManipulator | negativeDays }} DAYS OVERDUE!">{{task.taskDate | dateTaskManipulator | negativeDays}}</span></div>
                <div *ngIf ="task.taskStatus === true && ((task.taskDate | dateTaskManipulator)>0)" class = "keepinline"><span class = "dotgreen" matTooltip="{{task.taskDate | dateTaskManipulator}} DAYS LEFT">{{task.taskDate | dateTaskManipulator}}</span></div>
                <div *ngIf ="task.taskStatus === true && ((task.taskDate | dateTaskManipulator)==0)" class = "keepinline"><span class = "dotorange" matTooltip="DUE TODAY"><strong>!</strong></span></div>
              </mat-cell>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="details">
            <mat-header-cell *matHeaderCellDef>What needs to be done</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-cell class="inner-cell" *ngFor= "let task of element.task">
                <a mat-flat-button (click)="openTaskDialog('Save Task',element, 1)">
                  {{ task.taskDiscription }}
                </a>
              </mat-cell>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="task">
            <mat-header-cell *matHeaderCellDef >Edit</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <a mat-flat-button (click)="openTaskDialog('Save Task',element, 1)">
                <mat-icon style="color: rgb(167, 163, 163)">edit</mat-icon>
              </a>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedTaskColumns; sticky: true"></mat-header-row >
          <mat-row *matRowDef="let row; columns: displayedTaskColumns;"></mat-row >
        </mat-table>
        <mat-paginator class="mat-paginator-sticky" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
  <div fxFlex="40%" class="notes-container" >
    <mat-card>
      <mat-card-title>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <div fxFlex="40%" fxLayoutAlign="start center" style="padding-left: 20px">
              Project Activities
            </div>
            <!-- <div fxLayoutAlign="start center center" fxFlex="5%">
              <a [matMenuTriggerFor]="menuNotes" aria-label="icon-button with a menu">
                <mat-icon style="font-size: 25px; cursor: pointer;" color="accent" > arrow_drop_down_circle_outlined</mat-icon>
              </a>
            </div> -->
            <div fxFlex="55%" fxLayoutAlign="end center">
              <mat-form-field  class="input-search-task" appearance="outline" style="font-size: 12px">
                <mat-icon *ngIf="searchShow" matPrefix>search</mat-icon>
                <input matInput  type="text" name="password" autocomplete="new-password" (keyup)="doFilter($event.target.value)" [(ngModel)]="searchAll"
                (focus) = "searchShow = !searchShow" (blur) = "searchShow = true">
                <button mat-button *ngIf="searchAll" matSuffix mat-icon-button aria-label="Clear" (click)="searchAll=''; clearFilters(); searchShow = true">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
          </div>
        </div>

      </mat-card-title>
      <mat-card-content [style.overflow]="'auto'" [style.height.vh]="'71.6'" class="task-scroll">
        <div *ngIf="isLoading">
          <div fxLayout="row" fxLayoutGap="10px grid" *ngFor="let i of [].constructor(8)" style="padding-bottom: 20px">
            <div fxFlex="10%" fxLayoutAlign="start center center" >
              <ngx-skeleton-loader appearance="circle" [theme]="{ width: '30px', height: '30px' }">
              </ngx-skeleton-loader>
            </div>
            <div fxFlex="90%">
              <ngx-skeleton-loader count="2" [theme]="{'margin-top': '20px'}"></ngx-skeleton-loader>
            </div>
            <mat-divider [style.width.%]="'95'"></mat-divider>
          </div>
        </div>
        <mat-table  *ngIf="!isLoading" #table [dataSource]="dbNotes" matSort class="mat-elevation-z8">

          <ng-container matColumnDef="person">
            <mat-cell *matCellDef="let element">
              <span style="display:inline; padding-top: 5px"><img matTooltip="{{element.updatedBy}}" class="dotPic" [src]="this.dbStaffUsersTask | staffPic: element.updatedBy" referrerpolicy="no-referrer"></span>

            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="notes">

            <mat-cell *matCellDef="let element">
              &nbsp;
              <small style="color:#949494;">{{element.contactName}} - Project: {{element.projectName}}</small>
             {{element.meetingNotes }}<br>
             <small style="color:#949494;">Posted On: {{element.dateUpdated | date : 'medium'}}</small>
             &nbsp;
            </mat-cell>
          </ng-container>

          <mat-row *matRowDef="let row; columns: displayedNotesColumns;"></mat-row >
        </mat-table>
        <mat-paginator class="mat-paginator-sticky" [pageSizeOptions]="[10, 5, 20]" showFirstLastButtons></mat-paginator>
      </mat-card-content>
      </mat-card>
  </div>


</div>

<mat-menu #menuNotes="matMenu" xPosition="after" >
    <ng-template matMenuContent let-item="item">
    <div style="padding: 10px">
      <label id="orders-radio-group-label">Filter Project Activities</label>
      <mat-radio-group
      aria-labelledby="orders-radio-group-label"
      class="orders-radio-group"
      [(ngModel)]= "notesFilterValue"
      >
        <mat-radio-button class="orders-radio-button" (change)="onChangeTaskNameSelected()" value="All">Show All</mat-radio-button>
        <mat-radio-button class="orders-radio-button" (change)="onChangeTaskNameSelected()" value="milestoneChange">Milestone Changes</mat-radio-button>
        <mat-radio-button class="orders-radio-button" (change)="onChangeTaskNameSelected()" value="estimate">Estimate Aprrovals</mat-radio-button>
        <mat-radio-button class="orders-radio-button" (change)="onChangeTaskNameSelected()" value="notes">Project Notes</mat-radio-button>
      </mat-radio-group>
    </div>

  </ng-template>
</mat-menu>
