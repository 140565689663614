import { Component, OnInit, Pipe, PipeTransform, EventEmitter, ViewChild, AfterViewInit, OnDestroy, OnChanges, ViewChildren, QueryList  } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragEnter, CdkDragExit, CdkDragEnd} from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OrdersService } from '../../service/orders.service';
import { CustomerService } from 'src/app/service/customer.service';
import { AngularFirestore} from '@angular/fire/compat/firestore';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../service/auth.service';

import { Orders } from '../../models/orders.model';
@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.css']
})
export class PermissionsComponent implements OnInit {

  displayedColumns: string[] = [
  'projectNumber', 'contactName', 'opportunityName', 'dateUpdated', 'task', 'quote'];

  dbCompleted = new MatTableDataSource<Orders>();


  searchProspect = '';
  searchShow = true;
  searchAll = '';


  value = 'Clear me';
  company = [];

  leadsWonSubscription: Subscription;
  leadsSubscription: Subscription;
  contactsSubscription: Subscription;
  testcontact = [];
  isLoading = true;

  @ViewChild('table1', { read: MatSort, static: false }) sort: MatSort;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  currentUserLogin: any;


  constructor( private ordersService: OrdersService, private customerService: CustomerService,
              private db: AngularFirestore , public authService: AuthService) {
    let name = JSON.parse(localStorage.getItem('user'));
    this.currentUserLogin = name.displayName ?? '';
  }

  ngOnInit() {
    this.leadsSubscription = this.ordersService.wonOrdersChanged.subscribe(
      (newLeads: Orders[]) => {
        this.isLoading = false;
        this.dbCompleted.data = newLeads.filter(value => value.milestone === 'E. Project Complete');
      }
    );
    this.ordersService.fetchOrdersWon();

  }

  ngAfterViewInit() {
    this.dbCompleted.paginator = this.paginator.toArray()[0];
    this.dbCompleted.sort = this.sort;
    this.dbCompleted.sortingDataAccessor = (item: any, property) => {
      switch (property) {
          case 'dateUpdated':
            var t = new Date(1970, 0, 1); // Epoch
            t.setSeconds(item.updated)
              return new Date(t).getTime()
          default:
              return item[property]
      }
    }
  }

  doFilter(filterValue: string, milestone: string) {
    this.dbCompleted.filter = filterValue.trim().toLowerCase();
  }

  updateMeetingMinutes(result) {
    this.ordersService.updateOrder(result, this.currentUserLogin);
  }

  ngOnDestroy() {
    this.leadsSubscription.unsubscribe();
  }
  clearFilters(milestone: any) {
    // milestone.filter = '';
    this.dbCompleted.filter = '';
  }
}
