import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contactsfilter'
})
export class ContactsfilterPipe implements PipeTransform {

  filteredItem: any;

  transform(order: any, item: any[]): any {
    this.filteredItem = item.filter(value => value.clientId === order);
    // console.log(this.filteredItem[0.name]);
    return this.filteredItem[0].name;
  }

}
