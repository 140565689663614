<div class="calendar">
<div fxLayout="row" style="padding: 5px" fxLayoutGap="20px" *ngIf="!isLoading">
  <button mat-raised-button style="background-color: #c8e6c9;"
    (click) = "changeNavMonth(0)"
    [disabled] = "sameMonth(navDate)">
    Today
  </button>
  <div class="calendar-nav-previous-month"fxLayoutAlign="start center" >
    <button mat-raised-button class="button is-text"
      (click) = "changeNavMonth(-1)"
      [disabled] = "!canChangeNavMonth(-1)">
      <mat-icon>chevron_left</mat-icon>
    </button>
  </div>
  <div class="calendar-nav-next-month" fxLayoutAlign="start center" >
    <button mat-raised-button class="button is-text"
    (click) = "changeNavMonth(1)"
      [disabled] = "!canChangeNavMonth(1)">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
  <div style="color: black" fxLayoutAlign="start center">{{navDate.format('MMMM YYYY') | uppercase}}</div>
  <div fxLayoutAlign="end center">
    <section class="example-section">
      <mat-checkbox
          class="example-margin"
          [(ngModel)]="toggleOpenChecked"
          (change)="toggleOpenCloseChange($event)">
          Include Open Orders
      </mat-checkbox>
    </section>&nbsp;
    <!-- <section class="example-section">
      <mat-checkbox
          class="example-margin"
          [(ngModel)]="toggleHideShowCompleteChecked"
          (change)="toggleShowHideCompleteChange($event)">
          Include Complete Orders
      </mat-checkbox>
    </section> -->
  </div>
  <div  fxLayoutAlign="center end" class="button-display" >
    <div>
      <a [matMenuTriggerFor]="menu" aria-label="icon-button with a menu">
        <mat-icon style="font-size: 25px; cursor: pointer;color: #005005;" >help_outline</mat-icon>
      </a>
    </div>

    <mat-menu #menu="matMenu" xPosition="after" >
      <div style="padding: 10px">
        COLOUR MARKERS
        <hr>
        <mat-list>
          <mat-list-item><span style="background-color: rgb(18, 187, 18);" class="dot"></span>&nbsp;&nbsp;Install </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item ><span style="background-color:rgb(112, 31, 133);" class="dot"></span>&nbsp;&nbsp;Site Survey</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item ><span style="background-color:rgb(42, 19, 145);" class="dot"></span>&nbsp;&nbsp;Service</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item ><span style="background-color: rgb(86, 72, 219);" class="dot"></span>&nbsp;&nbsp;Re-Work</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item ><span style="background-color: rgb(238, 120, 10);" class="dot"></span>&nbsp;&nbsp;Locates</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item ><span style="background-color: rgb(30, 148, 119);" class="dot"></span>&nbsp;&nbsp;Supply Only</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item ><span style="background-color: rgb(148, 30, 95);" class="dot"></span>&nbsp;&nbsp;Shipping</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item ><span style="background-color: rgb(159, 161, 15);" class="dot"></span>&nbsp;&nbsp;Invoice</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item ><span style="background-color: #a5a5a5;" class="dot"></span>&nbsp;&nbsp;Completed</mat-list-item>
          <mat-divider></mat-divider>
        </mat-list>
      </div>
    </mat-menu>
  </div>
</div>
<mat-card *ngIf="isLoading"
style="display: flex; justify-content: center; align-items: center">
<mat-progress-spinner
  color="accent"
  mode="indeterminate">
</mat-progress-spinner>
</mat-card>
<mat-grid-list cols="10" rowHeight="27px">
    <mat-grid-tile
        *ngFor="let tile of tilesHeader;"
        [colspan]="tile.cols"
        [rowspan]="tile.rows"
        [style.color]= "tile.textcolor"
        [style.background]="tile.color"
        [ngStyle]="{'font-size': 12 + 'px'}">
        {{tile.text}}
    </mat-grid-tile>
  </mat-grid-list>

<!-- Body of Calendar -->
  <mat-grid-list cols="10" rowHeight="fit" class="gridbody" gutterSize="0px" cdkDropListGroup *ngIf="!isLoading">
    <mat-grid-tile
        *ngFor="let tile of gridArr; index as t; ; trackBy: trackByDate"
        [colspan]="tile.cols"
        [rowspan]="tile.rows"
        [style.background]="tile.color"
        [style.border]="tile.border"
        [ngClass]="{'scollOn': tile.installsLength === true,
                      'scrollOff': tile.installsLength === false,
                    'production': tile.weekSum >= 0}"
        >
        <div  *ngIf="tile.weekSum >= 0" fxLayout="column" >
          <div fxLayoutAlign="center" >
            <strong style="font-size: 0.8em">{{tile.weekSum | currency}}</strong>
            <mat-icon  matTooltip="Below Weekly Target of {{budgetedWeeklyTarget | currency}}" *ngIf="tile.weekSum < budgetedWeeklyTarget" class ="icon-below" aria-hidden="false" aria-label="Warning" >arrow_downward</mat-icon>
            <mat-icon  matTooltip="Above Weekly Target of {{budgetedWeeklyTarget | currency}}" *ngIf="tile.weekSum > budgetedWeeklyTarget" class ="icon-above" aria-hidden="false" aria-label="Circle Check" >check_circle
            </mat-icon>
          </div>
          <br>
          <button fxLayoutAlign="center"  class="install" *ngIf="tile.weekSum >= 0">PRODUCTION<br>{{tile.weekNumber}}</button>
        </div>
        <div *ngIf="tile.notes" >
          <mat-form-field *ngFor="let notes of tile.data; index as i" class="description-calendar-notes" appearance="fill">
            <textarea matInput [(ngModel)]= "notes.note" (blur) ="updateWeeklies(tile)"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="8">
            </textarea>
          </mat-form-field>
        </div>

            <div
              cdkDropList
              id = {{tile.date}}
              #{{i}}="cdkDropList"
              [cdkDropListData]="tile.data"
              cdkDropListSortingDisabled
              (cdkDropListDropped)="drop($event)"
              *ngIf="!tile.notes"
              [ngClass]="{'scollOn': tile.installsLength === true,
                      'scrollOff': tile.installsLength === false,
                    'production': tile.weekSum >= 0}">
              <div *ngFor="let install of tile.data; index as i"  cdkDrag [cdkDragData]="install"
                [cdkDragDisabled]="install.disabled">
                <div class="custom-placeholder" *cdkDragPlaceholder></div>
                  <div
                  [style.color]="tile.textcolor"
                  *ngIf="install.disabled"><small>{{tile.isholiday}}</small>
                </div>
                <div *ngIf="install.contactName !== tile.isholiday">
                  <div *ngFor="let itemMultiInstall of install.multiInstall; index as t" >
                    <button *ngIf="itemMultiInstall.installDate | installValidPipe : tile.date"
                    matTooltip="{{itemMultiInstall | installToolTip : tile.date : install.opportunityName: install.projectCoordinator : install.milestone }}"
                    [matTooltipPosition]="matToolPosition"
                    matTooltipClass="tooltip-orders"
                    (click)="openDialog('Update',install, 'Install')"
                    (contextmenu)="onRightClick($event, {content: install, index: t})"
                    [ngClass]="{
                      'install': ((itemMultiInstall | actionType : tile.date) === 'Install'),
                      'sitesurvey': ((itemMultiInstall | actionType : tile.date) === 'Site Survey'),
                      'completed': ((itemMultiInstall | actionType : tile.date) === 'Completed'),
                      'serviceorders': ((itemMultiInstall | actionType : tile.date) === 'Service'),
                      'rework': ((itemMultiInstall | actionType : tile.date) === 'Re-work'),
                      'locates': ((itemMultiInstall | actionType : tile.date) === 'Locate'),
                      'supply': ((itemMultiInstall| actionType : tile.date) === 'Supply'),
                      'shipping': ((itemMultiInstall | actionType : tile.date) === 'Shipping'),
                      'invoice': ((itemMultiInstall | actionType : tile.date) === 'Invoice')
                    }"
                    style= "cursor: pointer; "
                    >{{install.contactName}}
                  </button>
                  </div>

                </div>
              </div>
            </div>

            <!-- <div *ngFor="let install of tile.data | installDate : tile.date"  >
              <button>{{install.contactName}}</button>
            </div> -->

      <mat-grid-tile-header [ngClass]="{'dotgreen': gridCellCount >= 45, 'dotpurple': gridCellCount < 45 }">
        <!-- <mat-chip>{{tile.text}}</mat-chip> -->
      <span>{{tile.text}}</span>
      </mat-grid-tile-header>
    </mat-grid-tile>
  </mat-grid-list>
  <div class="bottomtargets" *ngIf="showBottombar === true" fxLayout fxLayoutAlign="center center" fxLayoutGap="10px" >
    <mat-card>
      <mat-card-header>
        <mat-card-subtitle>
          4 Week - Target {{budgetedWeeklyTarget*4 | currency}}
        </mat-card-subtitle>
        <mat-card-content *ngIf = "!toggleOpenChecked">{{ fourweeksum | currency}}</mat-card-content>
        <mat-card-content *ngIf = "toggleOpenChecked">{{(fourweeksum + fourweeksumOpen) | currency}}</mat-card-content>
      </mat-card-header>
    </mat-card>
    <mat-card>
      <mat-card-header>
        <mat-card-subtitle>
          8 Week - Target {{budgetedWeeklyTarget*8 | currency}}
        </mat-card-subtitle>
        <mat-card-content *ngIf = "!toggleOpenChecked">{{ eightweeksum | currency}}</mat-card-content>
        <mat-card-content *ngIf = "toggleOpenChecked">{{(eightweeksum + eightweeksumOpen) | currency}}</mat-card-content>
      </mat-card-header>
    </mat-card>
    <mat-card>
      <mat-card-header>
        <mat-card-subtitle>
          12 Week - Target {{budgetedWeeklyTarget*12 | currency}}
        </mat-card-subtitle>
        <mat-card-content *ngIf = "!toggleOpenChecked">{{ twelftweeksum | currency}}</mat-card-content>
        <mat-card-content *ngIf = "toggleOpenChecked">{{ (twelftweeksum + twelftweeksumOpen) | currency}}</mat-card-content>
      </mat-card-header>
    </mat-card>
    <mat-card>
      <mat-card-header>
        <mat-card-subtitle>
          16 Week - Target {{budgetedWeeklyTarget*16 | currency}}
        </mat-card-subtitle>
        <mat-card-content *ngIf = "!toggleOpenChecked">{{ sixteenweeksum | currency}}</mat-card-content>
        <mat-card-content *ngIf = "toggleOpenChecked">{{ (sixteenweeksum + sixteenweeksumOpen) | currency}}</mat-card-content>

      </mat-card-header>
    </mat-card>
  </div>
</div>

<!-- an hidden div is created to set the position of appearance of the menu-->
<div style="visibility: hidden; position: fixed;"
[style.left]="menuTopLeftPosition.x"
[style.top]="menuTopLeftPosition.y"
[matMenuTriggerFor]="rightMenu"></div>

<!-- standard material menu -->
<mat-menu #rightMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="openTaskDialog('Save Task',item.content)">
      <mat-icon>alarm</mat-icon>
      <span>
        Add/ View Task
      </span></button>
    <button mat-menu-item *ngIf = "!item.content.multiInstall[item.index].isCompleted" (click)="activityCompleted(item.content, item.index, 'completed' )">
      <mat-icon>radio_button_checked</mat-icon>
        <span>
          Mark Activity Completed
        </span></button>
    <button mat-menu-item *ngIf = "item.content.multiInstall[item.index].isCompleted" (click)="activityCompleted(item.content, item.index, 'incompleted' )">
      <mat-icon>radio_button_unchecked</mat-icon>
      <span>
        Mark Activity Not Completed
      </span></button>
  </ng-template>
</mat-menu>
