<div class="container-bar">
  <div class="container" fxLayout="row" fxLayout.xs="column" style="background-color: white;">
      <div class="button-orders" fxLayoutAlign="start center center" fxFlex="20%" fxFlex.xs="100%">
        <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="myFlagForButtonToggle" value="assignment">
          <mat-button-toggle value="assignment" aria-label="task view" (click)="onActivateToggleButton(myFlagForButtonToggle)">
              <mat-icon
                matTooltip="TASK LIST"
                style="color:#383b38;">task
              </mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="reports" aria-label="task view" (click)="onActivateToggleButton(myFlagForButtonToggle)">
            <mat-icon
              matTooltip="ANALYTICS"
              style="color:#383b38;">incomplete_circle
            </mat-icon>
        </mat-button-toggle>
          <mat-button-toggle value="kanban" aria-label="kanban view" (click)="onActivateToggleButton(myFlagForButtonToggle)">
            <mat-icon
              matTooltip="KANBAN LIST VIEW"
              style="color:#383b38;">view_column
            </mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="installCalendar" aria-label="task view" (click)="onActivateToggleButton(myFlagForButtonToggle)">
            <mat-icon
              matTooltip="INSTALL CALENDAR"
              style="color:#383b38;">today
            </mat-icon>
        </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div *ngIf="myFlagForButtonToggle == 'listleads'" fxLayoutAlign="center center" fxFlex="40%" fxFlex.xs="100%"></div>
      <div *ngIf="myFlagForButtonToggle == 'kanban'" fxLayoutAlign="center center end" fxFlex="40%" fxFlex.xs="100%">
        <mat-form-field *ngIf = "authService.isLoggedIn === true" class="input-search">
          <mat-icon matPrefix>search</mat-icon>

          <input matInput [(ngModel)]="searchText">
          <a *ngIf="searchText" matSuffix mat-icon-button aria-label="Clear" (click)="searchText=''">
              <mat-icon>close</mat-icon>
          </a>
        </mat-form-field>
      </div>
      <div *ngIf="myFlagForButtonToggle == 'kanban'" fxLayoutAlign="center end">
        <div>
          <a [matMenuTriggerFor]="menu" aria-label="icon-button with a menu">
            <mat-icon style="font-size: 25px; cursor: pointer; padding: 0 0 10px 20px" color="primary" >help_outline</mat-icon>
          </a>
        </div>

        <mat-menu #menu="matMenu" xPosition="after" >
          <div style="padding: 10px">
            COLOUR REFERENCE
            <hr>
            <mat-list>
              <mat-list-item class="fourWeekOverdue"><small >&nbsp;&nbsp;INACTIVE - FOUR WEEKS</small></mat-list-item>&nbsp;
              <mat-divider></mat-divider>&nbsp;
              <mat-list-item class="twoWeekOverdue"><small >&nbsp;&nbsp;INACTIVE - TWO WEEKS</small></mat-list-item>&nbsp;
              <mat-divider></mat-divider>&nbsp;

              <mat-list-item class="notOverdue"><small >&nbsp;&nbsp;RECENTLY ACTIVE</small></mat-list-item>&nbsp;
              <mat-divider></mat-divider>&nbsp;
              <mat-list-item ><small style="background-color: #fd026b;" class="dot"></small><small>&nbsp;&nbsp;ACTIVE TASK</small></mat-list-item>
              <mat-divider></mat-divider>

            </mat-list>
          </div>
        </mat-menu>
      </div>
      <div *ngIf="group.value == 'assignment'" fxLayoutAlign="center center end" fxFlex="40%" fxFlex.xs="100%">
      </div>
      <div  class="targets" *ngIf="(group.value == 'installCalendar') && !isLoading" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="65%" fxFlex.xs="100%"
      (mouseenter)="bottombar = true" (mouseleave)="bottombar = false">
        <mat-card fxHide.lt-md ="true" fxLayoutAlign="center center">
            <mat-card-content><span style="color:rgb(0, 0, 0) ;"><strong>52 Week - Target - </strong>&nbsp;&nbsp;{{fiftyTwoWeekTarget | currency}}</span></mat-card-content>
        </mat-card>
        <mat-card  fxLayoutAlign="center center" *ngIf = "installCalendarToggleChange === true">
            <mat-card-content><span style="color:rgb(0, 0, 0)  ;"><strong>Scheduled Invoicing - </strong>&nbsp;&nbsp;{{ (tillEndofYearSumBoth )| currency}}</span></mat-card-content>
        </mat-card>
        <mat-card  fxLayoutAlign="center center" *ngIf = "installCalendarToggleChange === false">
          <mat-card-content><span style="color:rgb(0, 0, 0)  ;"><strong>Scheduled Invoicing - </strong>&nbsp;&nbsp;{{ (tillEndofYearSumClosed )| currency}}</span></mat-card-content>
      </mat-card>
        <mat-card fxHide.lt-md ="true" fxLayoutAlign="center center">
            <mat-card-content><span style="color:rgb(0, 0, 0)  ;"><strong>YTD Target - </strong>&nbsp;&nbsp;{{ (fiftyTwoWeekTarget/currentYearNumberOfWeeks)*currentWeekNow | currency}}</span></mat-card-content>
        </mat-card>
        <mat-card fxLayoutAlign="center center">
            <mat-card-content><span style="color:rgb(0, 0, 0) ;"><strong>YTD Invoicing - </strong>&nbsp;&nbsp; {{ytdTargetInvoice | currency}} </span></mat-card-content>
        </mat-card>
      </div>
    </div>
  <app-orders *ngIf="group.value == 'kanban'" [mySearchText] = "searchText" ></app-orders>
  <app-task #taskview *ngIf="group.value == 'assignment'"></app-task>
  <app-reports #taskview *ngIf="group.value == 'reports'"></app-reports>
  <app-installcalendar *ngIf="group.value == 'installCalendar'" [showBottombar] = "bottombar" (radioChangeEvent)="receiveMessage($event)" (toggleChangeEvent)="receiveToggleOpenMessageFromInstallCalendar($event)"></app-installcalendar>

  <div  class="button-display-front" >
    <div fxLayoutAlign="end center " fxLayoutGap="10px" fxFlex="40%" fxFlex.xs="100%">
      <div class="fab-front" [matMenuTriggerFor]="menu" aria-label="icon-button with a menu"> + </div>
    </div>

  <mat-menu #menu="matMenu" xPosition="before">
    <button mat-menu-item (click)="openDialog('Add', { orderTask:[]},0)">
      <mat-icon>filter_alt</mat-icon>
      <span>Add New Lead Or Service</span>
    </button>
    <button mat-menu-item (click)="contactDialog('Add Company',{})" >
      <mat-icon>add_business</mat-icon>
      <span>Add New Company</span>
    </button>
    <button mat-menu-item (click)="contactDialog('Add Person',{})">
      <mat-icon>perm_identity</mat-icon>
      <span>Add Contact</span>
    </button>
  </mat-menu>
  </div>
</div>
