<div class="main-div" fxLayout="column" fxLayoutGap="15px">
  <div class="login-card"
    style="display: flex; justify-content: center; align-items: center">
  </div>
  <mat-card class="login-card" >
    <mat-card-header>
      <mat-card-title fxLayoutAlign="center center">Access Denied: Invalid Credentials</mat-card-title>
      <mat-card-subtitle fxLayoutAlign="center center">Please Contact Admin</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>

    </mat-card-content>
    <mat-card-actions fxLayoutAlign="center center">
        <button mat-raised-button color="primary" routerLink="/sign-in">Go Back</button>
    </mat-card-actions>
  </mat-card>
</div>

