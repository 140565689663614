import { Pipe, PipeTransform } from '@angular/core';
import { Orders } from '../models/orders.model';
import { Customer } from 'src/app/models/customer.model';
// import * as dayjs from 'dayjs';

declare function require(name:string);

var dayjs = require('dayjs');
var weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);
var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);
var weekday = require('dayjs/plugin/weekday');
dayjs.extend(weekday);

@Pipe({name: 'negativeDays'})

export class NegativeDaysPipe implements PipeTransform {
  transform(num: number, args?: any): any {
    return Math.abs(num);
  }
}

/**
 * Filter task duedate and substract from NOW
 */
@Pipe({
  name: 'dateTaskManipulator'
})
export class FilterDateTaskDuePipe implements PipeTransform {

  transform(items: Date ) {
    var now = dayjs();
    // dayjs(items);
    if (dayjs(items).diff(now, 'day') === 0) {
      if (!(dayjs().isSame(items, 'day'))) {
        return 1;
      } else {
        return dayjs(items).diff(now, 'day');
      }
    } else {
      return dayjs(items).diff(now, 'day');
    }
   }
  }

/**
 * Filter task dashboard by users
 */
@Pipe({
  name: 'userfilter'
})
export class FilterUserPipe implements PipeTransform {

  transform(items: any, searchText: string): any[] {
    if (!items) { return []; }
    if (!searchText) { return items; }

    searchText = searchText.toLowerCase();
    return items.filter( it => {
      return (it.taskAssigned.toString().toLowerCase().includes(searchText));
    });
   }

}

/**
 * Filter task dashboard by users
 */
@Pipe({
  name: 'priceListFilter'
})
export class FilterPricelIistPipe implements PipeTransform {

  transform(items: any, id: string): any[] {
    if (!items) { return []; }
    return items.filter( it => it.id === id);
   }

}


/**
 * Filter task dashboard by users
 */
//TODO : FILTER ROW THATS HAS INSTANCE OF FALSE IN task.statusAssigned
@Pipe({
  name: 'taskActivefilter'
})
export class FilterActivePipe implements PipeTransform {

  transform(item: any, obj: any, showtaskCompleted: boolean, activeUser: string): any[] {
    // this.arrTaskClosed = this.cleanResourceWon.filter(d => d.task.some(it => it.taskAssigned === this.firstName.toLowerCase()));

    for (let i = 0; i < obj.task.length; i++) {
      if ((obj.task[i].taskStatus === true)
          && ((obj.task[i].taskAssigned === activeUser.toLowerCase())
          && (showtaskCompleted === false))) {
            return item;
      } else if ((obj.task[i].taskStatus === true)
          && ((obj.task[i].taskAssigned === activeUser.toLowerCase())
          && (showtaskCompleted === true))){
            return item;
      } else if ((obj.task[i].taskStatus === false)
          && ((obj.task[i].taskAssigned === activeUser.toLowerCase())
          && (showtaskCompleted === true))){
            return item;
      }
    }
    return item = null;
  }
}

/**
 * Filter task dashboard by completed/due task
 */
@Pipe({
  name: 'statusTaskFilter',
  pure: false
})
export class FilterTaskPipe implements PipeTransform {

  transform(obj: any, showtaskCompleted: boolean): any[] {
const newarr = [...obj]; // best to copy and not change the original
if (showtaskCompleted === true) { return obj;
    } else {
        for (let i = 0; i < newarr.length; i++) {
            if (newarr[i].taskStatus === false){
              const removeIndex = newarr.map(t => t.taskStatus).indexOf(false);
              newarr.splice(removeIndex, 1);
            }
          }
        return newarr;
    }
  }
}

/**
 * Filter Leads/Orders by various categories
 */
@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {
  transform(items: Orders[], searchText: string): any[] {
    if (!items) { return []; }
    if (!searchText) { return items; }
    searchText = searchText.toLowerCase();
    return items.filter( it => {
      return (
        it.contactName.toString().toLowerCase().includes(searchText)
        || ((it.opportunityName !== undefined) ? it.opportunityName.toString().toLowerCase().includes(searchText) : '')
        || ((it.projectCoordinator !== undefined) ? it.projectCoordinator.toString().toLowerCase().includes(searchText) : '')
        || ((it.owner !== undefined) ? it.owner.toString().toLowerCase().includes(searchText) : '')
        || ((it.multiInstall !== undefined) ? it.multiInstall.some( t => t.installCrew.toString().toLowerCase().includes(searchText) ) : ''));
    });
   }
}

/**
 * Fix for old database where milestone has a prefix
 */
@Pipe({
  name: 'milestone'
})
export class MilestonePipe implements PipeTransform {
  transform(items: any): any[] {
    return items = items.split('.').pop();
   }
}
/**
 * Install calendar filter MultiInstall values
 */

@Pipe({
  name: 'installToolTip'
})
export class InstallDetailPipe implements PipeTransform {
  transform(items: any, date: string, opportunityName: string, projectCoordinator: string, milestone: string ): any {
    if ( !items ) {
      return items;
    }
        // if (items.installDate === dayjs(Date.parse(date)).format()) {
        if  (dayjs(items.installDate).format('DD/MM/YYYY') === dayjs(date).format('DD/MM/YYYY')) {
          return ("Activity Type: " + items.actionTypes+"\n"+
          'Project Name: ' +opportunityName + "\n Description: "+items.discription+ "\n Project Coordinator: " +projectCoordinator+ "\n Vendor: " + items.installCrew +
             '\n Milestone: ' + milestone + '\n Value: $' + items.progressValue);
        } else {
          return ' No Details';
        }
      }
}

/**
 * Find out if lead is overdue - 2weeks and 4weeks
 */

 @Pipe({
  name: 'overdueLeadsPipe'
})
export class OverdueLeadsPipe implements PipeTransform {
  transform(items: any ): any {
    var now = dayjs();
    let dayUpdated = dayjs.unix(items.updated.seconds);
    if ((dayjs(dayUpdated).diff(now, 'day') <= -14) &&
    (dayjs(dayUpdated).diff(now, 'day') >= -28)) {
      return '2 weeks';
    } else if (dayjs(dayUpdated).diff(now, 'day') < -28){
      return '4 weeks';
    } else if ((dayUpdated === 'Invalid Date') || (dayUpdated === undefined)){
      return '4 weeks';
    } else {
      return 'upToDate';
    }
      }
}

// @Pipe({
//   name: 'actionType'
// })
// export class ActionTypePipe implements PipeTransform {
//   transform(items: any[], date: string): any {
//     if ( !items ) {
//       return items;
//     }
//     for (const index in items ) {
//         if ((items[index].installDate === dayjs(Date.parse(date)).format()) && (items[index].isCompleted === false)) {
//           return items[index].actionTypes;
//         } else if ( (items[index].installDate === dayjs(Date.parse(date)).format()) && items[index].isCompleted === true ) {
//           return 'Completed';
//         } else {
//           continue;
//         }
//       }
//     return 'Install';
//   }
// }
@Pipe({
  name: 'actionType'
})
export class ActionTypePipe implements PipeTransform {
  transform(items: any, date: string): any {
    if ( !items ) {
      return items;
    }
        if (items.isCompleted === false) {
          return items.actionTypes;
        } else if ( items.isCompleted === true ) {
          return 'Completed';
        } else {
          return 'Install';
        }
  }
}

@Pipe({
  name: 'installValidPipe'
})
export class InstallValidPipe implements PipeTransform {
  transform(items: any[], date: string): any {
    if ( !items ) {
      return false;
    }
        if (dayjs(items).format('DD/MM/YYYY') === dayjs(date).format('DD/MM/YYYY')) {
        // if ((items === dayjs(Date.parse(date)).format())) {
          return true;
        } else {
          return false;
        }
  }
}
@Pipe({
  name: 'sum'
})
  export class SumPipe implements PipeTransform {
    transform(items: any[], attr: string): any {
      if ( !items ) {
        return 0;
      } else {
        return items.reduce((a, b) => {
          return b[attr] === (typeof b !== 'number') ? 0 : a + Number(b[attr]);
      }, 0); }
    }
}

@Pipe({
  name: 'staffPic'
})
  export class StaffPicPipe implements PipeTransform {
    transform(items: any[], name: string): any {
      if ( !items ) {
        return '';
      } else {
        let pic = [{photoURL : "/assets/img/empty_person_load.png"}];
        let picture = items.filter( t => t.displayName === name) !== []?items.filter( t => t.displayName === name): pic;
        return picture[0].photoURL
      }
    }
}

@Pipe({
  name: 'milestoneTitle'
})
  export class MilestoneTitlePipe implements PipeTransform {
    transform(items: string): any {
      if ( !items ) {
        return '';
      } else if (items === 'install'){
        return 'Install Complete'
      } else if (items === 'productionDesign'){
        return 'Production Design'
      } else if (items === 'serviceOrders'){
        return 'Service'
      } else if (items === 'followUp'){
        return 'Follow Up'
      } else {
        return items
      }
    }
  }

@Pipe({
  name: 'sumQuotes'
})
  export class SumQuotesPipe implements PipeTransform {
    transform(items: any[], attr: string, tabIndex: string, qauntity: string, notMyType: string): any {
      if ( !items ) {
        return 0;
      } else if ( notMyType === 'professional') {
        items = items.filter( t => t.type  === 'professional' );
        items = items.filter( t => t.status  === true );
        return items.reduce((a, b) => {
          return b[attr] === (typeof b !== 'number') ? 0 : a + (Number(b[attr]) * Number(b[qauntity]) * b.markup);
        }, 0);
      } else if (notMyType === 'construction') {
        items = items.filter( t => t.type  === 'construction' );
        items = items.filter( t => t.status  === true );
        return items.reduce((a, b) => {
          return b[attr] === (typeof b !== 'number') ? 0 : a + (Number(b[attr]) * Number(b[qauntity]) * b.markup);
        }, 0);
      }  else if ( notMyType === 'risk' ) {
        items = items.filter( t => t.type  === 'risk' );
        items = items.filter( t => t.status  === true );
        return items.reduce((a, b) => {
          return b[attr] === (typeof b !== 'number') ? 0 : a + (Number(b[attr]) * Number(b[qauntity]) * b.markup);
        }, 0);
      }  else if ( notMyType === 'product' ) {
        items = items.filter( t => t.type  === 'product' );
        items = items.filter( t => t.status  === true );
        return items.reduce((a, b) => {
          return b[attr] === (typeof b !== 'number') ? 0 : a + (Number(b[attr]) * Number(b[qauntity]) * b.markup);
        }, 0);
      } else {
          items = items.filter( t => t.tabIndex === tabIndex);
          items = items.filter( t => notMyType === 'material' ?  t.type !== 'Labour' : t.type === 'Labour');
          return items.reduce((a, b) => {
            return b[attr] === (typeof b !== 'number') ? 0 : a + (Number(b[attr]) * Number(b[qauntity]));
        }, 0); }
    }
}

/**
 * Get how many orders are there in each milestone
 */

@Pipe({
  name: 'countOrders'
})
  export class CountOrdersPipe implements PipeTransform {
    transform(items: any[]): any {
      if (!items) {
        return 0;
      } else {
        return items.length;
     }
  }
}

/**
 * Filter items on estimate that havee bool to now show on final estimate
 */
@Pipe({
  name: 'estimateItemsExcludePipe',
})
export class EstimateItemsExcludePipe {

  transform(objects: any[]): any[] {
      if(objects) {
          return objects.filter(object => object.excludeInLineItem === false);
      }
  }

}

@Pipe({
    name: 'momentPipe'
})
export class MomentPipe implements PipeTransform {
  transform(value: Date , ...args: any[]): any {
      let [format] = args;
      return dayjs(value).format(format);
  }
}

